import React, { useState } from "react";
import { useStyles } from "./signin.style";
import GeneralHeader from "../../components/LandingPage/generalHeader";
import Footer from "../../components/LandingPage/footer";
import img from "../../images/signup-bg.png";
import { Eye, Profile } from "../../icons";
import { EyeIcon, EyeSlashIcon } from "@heroicons/react/24/outline";
import { Link } from "react-router-dom";
import { NavLink, useNavigate } from "react-router-dom";
import * as yup from "yup";
import { useFormik } from "formik";

import { useUserStore } from "../../state";
import { useMutation } from "@tanstack/react-query";
import StorageService from "../../StorageService";
import { logIn } from "../../api/authApi";
import { roles } from "../../helpers";

const SignIn = () => {
  const classes = useStyles();
  const [showPassword, setshowPassword] = useState(false);
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  const handleClickShowPassword = () => {
    setshowPassword(!showPassword);
  };

  const loginUser = useUserStore((state) => state.loginUser);

  const { isLoading, mutate } = useMutation(logIn, {
    onSuccess: (res) => {
      const { access_token, user } = res.data?.data;
      StorageService.setToken({ access_token });
      loginUser(user);
    },
    onError: (e) => {
      if (e.response?.data?.data?.unverified)
        loginUser({
          email: formik.values.identity,
          role: roles.USER,
          email_verified: false,
        });
      else console.log(e.response);
      setError(
        e.response?.data?.message ??
          e.message ??
          "something went wrong try again"
      );
    },
  });

  const initialValues = {
    identity: "",
    password: "",
  };
  let validationSchema = yup.object({
    identity: yup.string().required("this field is required"),
    password: yup.string().required("this field is required"),
  });

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      setError(null);
      const { identity, password } = values;
      const fb = new FormData();
      fb.append("identity", identity);
      fb.append("password", password);
      mutate(values);
    },
  });
  return (
    <div className={classes.container}>
      <GeneralHeader />
      <div className={classes.signInSection}>
        <div className="wrapper">
          <img
            src={img}
            alt="signup"
            height={"545px"}
            width={"auto"}
            className="imgContainer"
          />

          <div className="formContainer">
            <h6>Sign in to your account</h6>
            {error && <div className="error-text">{error}</div>}
            <form onSubmit={formik.handleSubmit}>
              <div className="form-group">
                <label htmlFor="username">Email/Username</label>
                <div className="input-group">
                  <input
                    type="text"
                    id="identity"
                    name="identity"
                    value={formik.values.identity}
                    onChange={formik.handleChange}
                    placeholder="Enter your username"
                  />
                  <div className="input-group-append">
                    <Profile />
                  </div>
                </div>
              </div>
              <div className="form-group">
                <label htmlFor="password">Password</label>
                <div className="input-group">
                  <input
                    type={showPassword ? "text" : "password"}
                    id="password"
                    name="password"
                    value={formik.values.password}
                    onChange={formik.handleChange}
                    placeholder="Enter your password"
                  />
                  <div
                    className="input-group-append"
                    onClick={handleClickShowPassword}
                  >
                    {!showPassword ? (
                      <EyeSlashIcon
                        style={{
                          color: "#1A3A5E",
                          width: "24px",
                        }}
                      />
                    ) : (
                      <EyeIcon
                        style={{
                          color: "#1A3A5E",
                          width: "24px",
                        }}
                      />
                    )}
                  </div>
                </div>
                <Link to="/forgotpassword" className="forgot-password">
                  {" "}
                  Forgot Password?
                </Link>
              </div>

              <div className="button-group">
                <button className="primary" disabled={isLoading} type="submit">
                  {isLoading ? "Loading..." : "Sigin"}
                </button>
                <div className="divider">Don't have an account?</div>
                <Link className="secondary" to={"/signup"}>
                  Create an Account
                </Link>
              </div>
            </form>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default SignIn;
