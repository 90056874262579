import React from "react";
import PropTypes from "prop-types";
import {
  Box,
  LinearProgress,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";

function SourceCard() {
  const data = [
    {
      label: "Direct",
      value: "1,422,555",
      v: 80,
    },
    {
      label: "Referall",
      value: "1,422",
      v: 40,
    },
    {
      label: "Media",
      value: "1,422",
      v: 70,
    },
  ];

  return (
    <Box
      sx={{
        // minWwidth: "243px",
        height: "100%",
        border: "1px solid #E6E6EF",
        borderRadius: "20px",
        padding: "20px",
        display: "flex",
        flexDirection: "column",

        // justifyContent: "space-between",
      }}
    >
      <Box
        sx={{
          color: "rgba(157, 157, 157, 1)",
          fontSize: "11px",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <Typography
          sx={{
            color: "rgba(128, 128, 128, 1)",
            fontSize: "19px",
            fontWeight: "bold",
            textOverflow: "ellipsis",
            overflow: "hidden",
            whiteSpace: "nowrap",
          }}
        >
          Sales Report
        </Typography>
        <Select variant="outlined" size="small" value={10}>
          <MenuItem value={10}></MenuItem>
        </Select>
      </Box>
      <Box
        sx={{
          width: "100%",
          flexDirection: "column",
          justifyContent: "space-between",
          height: "100%",
          paddingTop: "50px",
        }}
      >
        {data.map((v) => (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              // justifyContent: "space-between",
              minHeight: "80px",
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                paddingBottom: "10px",
              }}
            >
              <Typography
                sx={{
                  color: "rgba(rgba(2, 3, 66, 1))",
                  fontSize: "13px",
                  fontWeight: "500",
                }}
              >
                {v.label}
              </Typography>
              <Typography
                sx={{
                  color: "rgba(134, 134, 134, 1)",
                  fontFamily: "Plus Jakarta Sans",
                }}
                variant="small"
              >
                {v.value}
              </Typography>
            </Box>
            <LinearProgress color="inherit" value={v.v} variant="determinate" />
          </div>
        ))}
      </Box>
    </Box>
  );
}

SourceCard.propTypes = {};

export default SourceCard;
