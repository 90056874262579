import { Add } from "@mui/icons-material";
import { Checkbox, CircularProgress, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { useMutation, useQuery } from "@tanstack/react-query";
import React, { useState } from "react";
import { createWithdrawal, getBanks } from "../../api/userApi";
import CustomButton from "../../components/Button";
import AddBank from "../../components/modals/AddBank";
import ConfirmWithrawal from "../../components/modals/ConfirmWithrawal";
import SummaryCardAlt from "../../components/User/SummaryCardAlt";
import { useNotificationStore } from "../../state";

function Withdrawal() {
  const [showAdd, setShowAdd] = useState(false);
  const [showConfirm, setShowConfirm] = useState(false);

  const [selectedBank, setSelectedBank] = useState(null);

  const handleOpen = () => {
    setShowAdd(!showAdd);
  };

  const handleConfirmOpen = () => {
    setShowConfirm(!showConfirm);
  };

  const { data, isLoading, isRefetching, refetch } = useQuery(
    ["banks"],
    getBanks
  );

  const banks = data?.data?.data || [];

  const displayNotificaton = useNotificationStore(
    (state) => state.displayNotificaton
  );

  const { isLoading: withdrawLoading, mutate } = useMutation(createWithdrawal, {
    onSuccess: (res) => {
      displayNotificaton(res?.data?.message);
    },
    onError: (e) => {
      displayNotificaton(
        e.response?.data?.message ??
          e.message ??
          "something went wrong try again"
      );
    },
  });

  const sendRequest = ({ pin, amount }) => {
    handleConfirmOpen();
    mutate({ amount, bank_id: selectedBank?.id, pin: pin });
  };

  return (
    <Box
      sx={{
        padding: "20px 20px",
        width: {
          xs: "100%",
          md: "578px",
        },
      }}
    >
      {showAdd && <AddBank handleOpen={handleOpen} refetch={refetch} />}
      {showConfirm && (
        <ConfirmWithrawal proceed={sendRequest} cancel={handleConfirmOpen} />
      )}
      <Typography
        sx={{
          color: "#808080",
          fontWeight: "500",
          textAlign: "start",
          marginBottom: "20px",
        }}
      >
        Withdrawal
      </Typography>
      <SummaryCardAlt />
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          width: "100%",
          alignItems: "center",
          marginTop: "40px",
          marginBottom: "20px",
        }}
      >
        <Box sx={{ textAlign: "start", flex: 1 }}>
          <Typography sx={{ color: "#020239", fontWeight: "500" }}>
            Select Bank
          </Typography>
          <Typography
            sx={{
              color: "#B3B3B3",
              fontWeight: "500",
              marginTop: "5px",
              fontSize: "11px",
            }}
          >
            Select any bank to withdraw directly to your bank
          </Typography>
        </Box>
        <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
          <Box
            sx={{
              // width: "137px",
              height: "45px",
              fontWeight: "500",
              padding: "16px 60px",
              borderRadius: "10px",
              fontSize: "11px",
              whiteSpace: "nowrap",
              width: "120px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              border: "1px solid #020239",
              cursor: "pointer",
            }}
            variant="outlined"
            onClick={handleOpen}
          >
            <Add
              style={{ width: "25px", marginRight: "5px", color: "#020239" }}
            />
            Add Bank
          </Box>
        </Box>
      </Box>
      <Box
        sx={{
          justifyContent: "space-between",
          width: "100%",
          position: "relative",
          paddingBottom: 20,
        }}
      >
        {isLoading || isRefetching ? (
          <Box>
            <CircularProgress />
          </Box>
        ) : (
          banks.map((bank, i) => (
            <Box
              key={i}
              sx={{
                display: "flex",
                alignItems: "center",
                padding: "20px 10px 20px 20px",
                border: "1px solid #DFDFDF",
                borderRadius: "20px",
                margin: "10px 0",
              }}
              onClick={() => setSelectedBank(bank)}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",

                  width: "100%",
                  textAlign: "left",
                }}
              >
                <Typography
                  sx={{
                    fontWeight: "700",
                  }}
                >
                  {bank.account_name}
                </Typography>

                <Typography>
                  {bank.account_number?.substring(0, 5)}
                  ******
                </Typography>

                <Typography sx={{}}>{bank.bank_name}</Typography>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                }}
              >
                <Checkbox checked={selectedBank?.id === bank.id} />
              </Box>
            </Box>
          ))
        )}
      </Box>
      <Box
        sx={{
          position: "fixed",
          top: "90vh",
          left: { xs: "0vw", md: "10px" },
          width: {
            xs: "100vw",
            md: "calc(100vw - 260px)",
          },
          // mt: 10,
          display: "flex",
          justifyContent: "center",
        }}
      >
        <CustomButton
          sx={{
            padding: "13px 22px",
            backgroundColor: "#020239",
            borderRadius: "12px",
            width: { xs: "90vw", md: "min(528px,90%)" },
          }}
          disabled={!selectedBank}
          onClick={() => handleConfirmOpen()}
          loading={withdrawLoading}
        >
          Continue
        </CustomButton>
      </Box>
    </Box>
  );
}

export default Withdrawal;
