import React from "react";
import { Breadcrumbs, Typography } from "@mui/material";
import { NavigateNextOutlined } from "@mui/icons-material";
import { Box } from "@mui/system";
import { useTheme } from "@mui/material/styles";
import SummaryCardAlt from "../../components/User/SummaryCardAlt";

import CableForm from "../../components/Services/CableForm";

function CableTv() {
  const theme = useTheme();
  return (
    <Box sx={{ p: "0px 20px" }}>
      <Box sx={{ display: { xs: "none", sm: "block", margin: "20px 0px" } }}>
        <Box sx={{ display: "flex" }}>
          <Typography variant="header" color={theme.palette.neutral.N200}>
            Cable Subsciption
          </Typography>
        </Box>
        <Breadcrumbs
          separator={<NavigateNextOutlined fontSize="22px" />}
          aria-label="breadcrumb"
        >
          {/* <Typography color={theme.palette.neutral.N200}>Resellers</Typography> */}
          <Typography color="primary"> Cable Subsciption</Typography>
        </Breadcrumbs>
      </Box>
      <Box sx={{ display: { xs: "block", sm: "none" }, textAlign: "left" }}>
        <Typography
          sx={{ fontWeight: "500", color: "#808080", marginBottom: "10px" }}
        >
          Cable Subsciption
        </Typography>
        <SummaryCardAlt />
      </Box>
      <CableForm />
    </Box>
  );
}

export default CableTv;
