import {
  ArrowTopRightOnSquareIcon,
  BoltIcon,
  CreditCardIcon,
  DevicePhoneMobileIcon,
  PaperAirplaneIcon,
  RectangleStackIcon,
  TvIcon,
  UsersIcon,
  WifiIcon,
} from "@heroicons/react/24/outline";
import { ArrowOutwardOutlined } from "@mui/icons-material";
import { Button, CircularProgress, Divider, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React, { useEffect, useState } from "react";
import SummaryCard from "../../components/User/SummaryCard";
import TransactionCard from "../../components/User/TransactionCard";
import { NavLink } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import { getTransactions } from "../../api/userApi";
import ViewTransaction from "../../components/modals/ViewTransaction";
import { useAnalytic, useUser } from "../../hooks";

function Home() {
  const [open, setOpen] = useState(false);

  const items = [
    {
      label: "Data",
      icon: <WifiIcon style={{ width: "50%", color: "#020239" }} />,
      route: "mobile-topup/data",
      bgcolor: "#E6F0EA",
      color: "#00421B",
    },
    {
      label: "Airtime",
      icon: (
        <DevicePhoneMobileIcon style={{ width: "50%", color: "#020239" }} />
      ),
      route: "mobile-topup/airtime",
      bgcolor: "#E6E6EF",
      color: "#020239",
    },

    {
      label: "Cable Tv",
      icon: <TvIcon style={{ width: "50%", color: "#020239" }} />,
      route: "cable-tv",
      bgcolor: "#F5F3E9",
      color: "#5F5013",
    },
    {
      label: "Utility",
      icon: <BoltIcon style={{ width: "50%", color: "#020239" }} />,
      route: "utility",
      bgcolor: "#FFE6E6",
      color: "#9C0000",
    },
    {
      label: "Send Money",
      icon: <PaperAirplaneIcon style={{ width: "50%", color: "#020239" }} />,
      route: "transfer",
      bgcolor: "#E6F0EA",
      color: "#00421B",
    },
    {
      label: "Cards",
      icon: <CreditCardIcon style={{ width: "50%", color: "#020239" }} />,
      route: "home",
      bgcolor: "#E6E6EF",
      color: "#020239",
      extraInfo: "coming soon",
    },

    {
      label: "LeaderBoard",
      icon: <RectangleStackIcon style={{ width: "50%", color: "#020239" }} />,
      route: "leaderboard",
      bgcolor: "#F5F3E9",
      color: "#5F5013",
    },
    {
      label: "Refer & Earn",
      icon: <UsersIcon style={{ width: "50%", color: "#020239" }} />,
      route: "profile",
      bgcolor: "#FFE6E6",
      color: "#9C0000",
    },
  ];

  const { data, isLoading, isRefetching, refetch } = useQuery(
    ["transactions"],
    getTransactions
  );
  const { fetchUser } = useUser();
  const { fetchAnalytic } = useAnalytic();
  useEffect(() => {
    fetchUser();
    fetchAnalytic();
  }, []);
  const transactions = data?.data?.data?.data || [];

  return (
    <Box
      sx={{
        padding: "0px 20px",
        width: {
          xs: "100%",
          md: "min(692px , 80%)",
        },
      }}
    >
      {open && <ViewTransaction data={open} handleClose={() => setOpen()} />}
      <Box
        sx={{
          padding: "20px 0px",
          display: {
            xs: "none",
            sm: "block",
          },
        }}
      />
      <SummaryCard />
      <Box
        sx={{
          minHeight: "55vh",
          mt: 2,
          // p: 1,
          border: {
            // sm: "1px solid #e6e6ef",
          },
          borderRadius: "20px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            flexWrap: "wrap",
          }}
        >
          <Box
            sx={{
              display: { xs: "none", md: "flex" },

              justifyContent: "space-between",
              width: "100%",
              "& .active": {
                backgroundColor: "transparent !important",
              },
              alignItems: "start",
            }}
          >
            {items.map((v, i) => (
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  flexDirection: "column",
                  alignItems: "center",
                  mb: 1,
                  width: {
                    xs: "80px",
                    md: "min(70px , 12%)",
                  },
                }}
                key={i}
              >
                <NavLink
                  style={{
                    padding: 0,
                    margin: 0,
                  }}
                  to={`/user/${v.route}`}
                >
                  <Box
                    sx={{
                      borderRadius: "10px",
                      display: "flex",
                      justifyContent: "center",
                      flexDirection: "column",
                      alignItems: "center",
                      width: "100%",
                      height: "71px",
                      backgroundColor: "#E6E6EF",
                      cursor: "pointer",
                    }}
                  >
                    {v.icon}
                  </Box>
                  <Typography
                    sx={{
                      color: "#020239",
                      // p: 1,
                      fontSize: { xs: "13px", sm: "11px", lg: "12px" },
                      fontWeight: "500",
                      whiteSpace: "nowrap",
                      // width: {
                      //   xs: "72px",
                      //   md: "100px",
                      //   lg: "80px",
                      // },
                      textAlign: "center",
                      overflow: "clip",
                    }}
                  >
                    {v.label}
                  </Typography>
                  {v.extraInfo && (
                    <Typography
                      sx={{
                        color: "#020239",
                        fontSize: { xs: "8px" },
                        backgroundColor: "#E6E6EF",
                        borderRadius: "10px",
                        padding: "2px",
                        mt: "2px",
                        // width: {
                        //   xs: "70px",
                        //   md: "100px",
                        //   lg: "80px",
                        // },
                        textAlign: "center",
                      }}
                    >
                      {v.extraInfo}
                    </Typography>
                  )}
                </NavLink>
              </Box>
            ))}
          </Box>
          <Box
            sx={{
              display: { xs: "flex", md: "none" },
              justifyContent: "space-between",
              width: "100%",
              "& .active": {
                backgroundColor: "transparent !important",
              },
              alignItems: "start",
            }}
          >
            {items.slice(0, 4).map((v, i) => (
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  flexDirection: "column",
                  alignItems: "center",
                  mb: 1,
                  width: "min(80px , 24%)",
                }}
                key={i}
              >
                <NavLink
                  style={{
                    padding: 0,
                    margin: 0,
                  }}
                  to={`/user/${v.route}`}
                >
                  <Box
                    sx={{
                      borderRadius: "10px",
                      display: "flex",
                      justifyContent: "center",
                      flexDirection: "column",
                      alignItems: "center",
                      width: "100%",
                      height: "71px",
                      backgroundColor: "#E6E6EF",
                      cursor: "pointer",
                    }}
                  >
                    {v.icon}
                  </Box>
                  <Typography
                    sx={{
                      color: "#020239",
                      // p: 1,
                      fontSize: { xs: "13px", sm: "11px", lg: "12px" },
                      fontWeight: "500",
                      whiteSpace: "nowrap",
                      // width: {
                      //   xs: "72px",
                      //   md: "100px",
                      //   lg: "80px",
                      // },
                      textAlign: "center",
                      overflow: "clip",
                    }}
                  >
                    {v.label}
                  </Typography>
                  {v.extraInfo && (
                    <Typography
                      sx={{
                        color: "#020239",
                        fontSize: { xs: "8px" },
                        backgroundColor: "#E6E6EF",
                        borderRadius: "10px",
                        padding: "2px",
                        mt: "2px",
                        // width: {
                        //   xs: "70px",
                        //   md: "100px",
                        //   lg: "80px",
                        // },
                        textAlign: "center",
                      }}
                    >
                      {v.extraInfo}
                    </Typography>
                  )}
                </NavLink>
              </Box>
            ))}
          </Box>
          <Box
            sx={{
              display: { xs: "flex", md: "none" },

              justifyContent: "space-between",

              width: "100%",
              "& .active": {
                backgroundColor: "transparent !important",
              },
              alignItems: "start",
            }}
          >
            {items.slice(4, 8).map((v, i) => (
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  flexDirection: "column",
                  alignItems: "center",
                  mb: 1,
                  width: "min(80px , 24%)",
                }}
                key={i}
              >
                <NavLink
                  style={{
                    padding: 0,
                    margin: 0,
                  }}
                  to={`/user/${v.route}`}
                >
                  <Box
                    sx={{
                      borderRadius: "10px",
                      display: "flex",
                      justifyContent: "center",
                      flexDirection: "column",
                      alignItems: "center",
                      width: "100%",
                      height: "71px",
                      backgroundColor: "#E6E6EF",
                      cursor: "pointer",
                    }}
                  >
                    {v.icon}
                  </Box>
                  <Typography
                    sx={{
                      color: "#020239",
                      // p: 1,
                      fontSize: { xs: "13px", sm: "11px", lg: "12px" },
                      fontWeight: "500",
                      whiteSpace: "nowrap",
                      // width: {
                      //   xs: "72px",
                      //   md: "100px",
                      //   lg: "80px",
                      // },
                      textAlign: "center",
                      overflow: "clip",
                    }}
                  >
                    {v.label}
                  </Typography>
                  {v.extraInfo && (
                    <Typography
                      sx={{
                        color: "#020239",
                        fontSize: { xs: "8px" },
                        backgroundColor: "#E6E6EF",
                        borderRadius: "10px",
                        padding: "2px",
                        mt: "2px",
                        // width: {
                        //   xs: "70px",
                        //   md: "100px",
                        //   lg: "80px",
                        // },
                        textAlign: "center",
                      }}
                    >
                      {v.extraInfo}
                    </Typography>
                  )}
                </NavLink>
              </Box>
            ))}
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            marginTop: "10px",
            fontWeight: "500px",
          }}
        >
          <Typography sx={{ color: "#808080" }}>Transactions</Typography>
          <Button>
            <NavLink
              style={{ color: "#020239", fontSize: "13px", display: "flex" }}
              to="/user/transactions"
            >
              <Typography
                sx={{
                  color: "#020239",
                  fontSize: "13px",
                  textTransform: "none",
                }}
              >
                View all
              </Typography>
              <ArrowTopRightOnSquareIcon style={{ width: "16px" }} />
            </NavLink>
          </Button>
        </Box>
        <Divider />
        <Box>
          {isLoading || isRefetching ? (
            <Box>
              <CircularProgress />
            </Box>
          ) : (
            transactions?.slice(0, 4).map((transaction, i) => (
              <Box
                sx={{
                  cursor: "pointer",
                }}
                onClick={() => setOpen(transaction)}
              >
                <TransactionCard
                  key={i}
                  transaction={transaction}
                  refetch={refetch}
                />
              </Box>
            ))
          )}
        </Box>
      </Box>
    </Box>
  );
}

export default Home;
