import axios from "axios";
import StorageService from "../StorageService";

 export const BASEURL = "https://api.zainnest.com/api";
// export const BASEURL = "http://127.0.0.1:8888/api";
// export const BASEURL = "https://staging.api.zainnest.ng/api";



axios.interceptors.request.use(
  (config) => {
    const token = StorageService.getAccessToken();

    if (token) {
      config.headers["Authorization"] = "Bearer " + token;
    }
    // config.headers["Content-Type"] = "application/json";
    config.headers["Accept"] = "application/json";
    config.headers["X-Requested-With"] = "XMLHttpRequest";
    // config.withCredentials = true;
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

export const http = axios;
