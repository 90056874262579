/* eslint-disable eqeqeq */
import React, { useEffect } from "react";
import PropTypes from "prop-types";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import MenuIcon from "@mui/icons-material/Menu";
import Toolbar from "@mui/material/Toolbar";
import {
  Button,
  CircularProgress,
  Collapse,
  Menu,
  MenuItem,
  Typography,
} from "@mui/material";
import {
  ExpandMore,
  PhoneAndroidOutlined,
  WhatsApp,
} from "@mui/icons-material";
import {
  HomeIcon,
  UserIcon,
  ClockIcon,
  DevicePhoneMobileIcon,
  TvIcon,
  WifiIcon,
  BoltIcon,
  BellIcon
} from "@heroicons/react/24/outline";
import {
  NavLink,
  useLocation,
  Outlet,
  Navigate,
  useNavigate,
} from "react-router-dom";
import "./sidebar.css";
import { useMutation, useQuery } from "@tanstack/react-query";
import { logOut, upgradeResseller } from "../api/authApi";
import StorageService from "../StorageService";
import { useNotificationStore, useUserStore } from "../state";
import { roles } from "../helpers";
import Notification from "./Notification";
import { getMe, getNotifications } from "../api/userApi";
import Logo from "../../src/icons/Logo.png";
import ConfirmReseller from "../components/modals/ConfirmReseller";
const drawerWidth = 260;
const sidebarTopItems = [
  {
    label: "Home",
    icon: <HomeIcon style={{ width: "20px" }} />,
    route: "home",
  },
  {
    label: "Data",
    icon: <WifiIcon style={{ width: "20px" }} />,
    route: "mobile-topup/data",
  },
  {
    label: "Airtime",
    icon: <DevicePhoneMobileIcon style={{ width: "20px" }} />,
    route: "mobile-topup/airtime",
  },
  {
    label: "Cable Tv",
    icon: <TvIcon style={{ width: "20px" }} />,
    route: "cable-tv",
  },
  {
    label: "Utility",
    icon: <BoltIcon style={{ width: "20px" }} />,
    route: "utility",
  },
  {
    label: "Transactions",
    icon: <ClockIcon style={{ width: "20px" }} />,
    route: "transactions",
  },
  {
    label: "Profile",
    icon: <UserIcon style={{ width: "20px" }} />,
    route: "profile",
  },
];
function UserLayout({ window, children }) {
  const navigate = useNavigate();
  const loggedIn = useUserStore((state) => state.loggedIn);
  const logoutUser = useUserStore((state) => state.logoutUser);
  const {
    open: notificationOpen,
    message,
    displayNotificaton,
  } = useNotificationStore((state) => state);
  const user = useUserStore((state) => state.user);
  const setUser = useUserStore((state) => state.setUser);
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [resellerOpen, setResellerOpen] = React.useState(false);
  const [webOpen] = React.useState(true);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };
  let location = useLocation();
  const { pathname } = location;
  // to close  the nav menu on page navigation
  useEffect(() => {
    setMobileOpen(false);
  }, [pathname]);
  const { mutate: upgradeMutate, isLoading: upgradeLoading } = useMutation(
    upgradeResseller,
    {
      onSuccess: async (res) => {
        displayNotificaton(res.data.message);
        if (res.data.status) {
          const res = await getMe();
          console.info({ res });
          if (res.data?.status) setUser(res.data?.data);
        }
        setResellerOpen();
      },
      onError: async (e) => {
        setResellerOpen();
        displayNotificaton(
          e.response?.data?.message ??
            e.message ??
            "something went wrong try again"
        );
      },
    }
  );
  const [anchorEl2, setAnchorEl2] = React.useState(null);
  const open2 = Boolean(anchorEl2);
  const handleClick2 = (event) => {
    setAnchorEl2(event.currentTarget);
  };
  const handleClose2 = () => {
    setAnchorEl2(null);
  };
  const drawer = (
    <div>
      <Toolbar sx={{ height: 65 }} />
      <List
        sx={{
          padding: "15px",
          height: "65%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
        }}
      >
        {sidebarTopItems.map((item, index) =>
          item.sublinks?.length > 0 ? (
            <ListItem
              key={index}
              disablePadding
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
                // width: "calc(100% - 20px)",
                mb: 7,
              }}
              className={pathname.includes(item.route) ? "active" : ""}
            >
              {" "}
              <NavLink end>
                <ListItemButton
                  className={
                    pathname.includes(item.route) ? "active" : "sidebar-links"
                  }
                  sx={{
                    height: "40px",
                    paddingLeft: "30px",
                    width: "100%",
                  }}
                >
                  <ListItemIcon sx={{ minWidth: 40 }}>{item.icon}</ListItemIcon>
                  <ListItemText primary={item.label} />
                  {item.icon2}
                </ListItemButton>
              </NavLink>
              <Collapse
                sx={{ marginLeft: "40px" }}
                in={pathname.includes(item.route)}
              >
                {item.sublinks.map((k, i) => (
                  <NavLink to={`${item.route}/${k.route}`} end>
                    <Box
                      className={"sublink"}
                      sx={{ padding: "10px 0", fontWeight: "400" }}
                    >
                      {k.label}
                    </Box>
                  </NavLink>
                ))}
              </Collapse>
            </ListItem>
          ) : (
            <ListItem key={index} disablePadding>
              <NavLink to={`${item.route}`} end>
                <ListItemButton
                  className={`${
                    pathname === `/${item.route}` ? "active" : "sidebar-links"
                  } `}
                  sx={{ height: "50px", paddingLeft: "30px" }}
                >
                  <ListItemIcon sx={{ minWidth: 40 }}>{item.icon}</ListItemIcon>
                  <ListItemText
                    // sx={{ color: pathname === item.route ? "black" : "" }}
                    primary={item.label}
                  />
                </ListItemButton>
              </NavLink>
            </ListItem>
          )
        )}
      </List>
      <Divider sx={{ padding: "0px" }} />
      <ListItem disablePadding>
        <NavLink to={`/user/analytics`}>
          <ListItemButton
            className={`${
              pathname === `/user/analytics` ? "active" : "sidebar-links"
            } `}
            sx={{ height: "60px", paddingLeft: "30px" }}
          >
            <ListItemText sx={{ color: "" }} primary={"Analytics"} />
          </ListItemButton>
        </NavLink>
      </ListItem>
      <ListItem disablePadding>
        <a href={`https://documenter.getpostman.com/view/9514924/2s8Z75SUzU`}>
          <ListItemButton
            className={`${
              pathname === `/user/analytics` ? "active" : "sidebar-links"
            } `}
            sx={{ height: "60px", paddingLeft: "30px" }}
          >
            <ListItemText sx={{ color: "" }} primary={"Api Doc"} />
          </ListItemButton>
        </a>
      </ListItem>
      <ListItem disablePadding>
        <NavLink to={`/user/leaderboard`}>
          <ListItemButton
            className={`${
              pathname === `/user/leaderboard` ? "active" : "sidebar-links"
            } `}
            sx={{ height: "60px", paddingLeft: "30px" }}
          >
            <ListItemText sx={{ color: "" }} primary={"LeaderBoard"} />
          </ListItemButton>
        </NavLink>
      </ListItem>
      <ListItem disablePadding>
        <Menu
          id="demo-positioned-menu"
          aria-labelledby="demo-positioned-button"
          anchorEl={anchorEl2}
          open={open2}
          onClose={handleClose2}
          anchorOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
        >
          <a href="https://wa.me/+2348062718818?text=Hello">
            <MenuItem onClick={handleClose}>
              <WhatsApp sx={{ marginRight: "10px" }} /> WhatsApp
            </MenuItem>
          </a>
          <a href="tel:08062718818">
            <MenuItem onClick={handleClose}>
              <PhoneAndroidOutlined sx={{ marginRight: "10px" }} /> Phone1
            </MenuItem>
          </a>
          <a href="tel:09122516049">
            <MenuItem onClick={handleClose}>
              <PhoneAndroidOutlined sx={{ marginRight: "10px" }} /> Phone2
            </MenuItem>
          </a>
        </Menu>
        <ListItemButton
          onClick={handleClick2}
          sx={{ height: "60px", paddingLeft: "30px" }}
          className={`${
            pathname === `/user/leaderboard` ? "active" : "sidebar-links"
          } `}
        >
          <ListItemText
            sx={{ color: "rgba(0, 0, 0, 0.54)" }}
            primary={"Support"}
          />
        </ListItemButton>
      </ListItem>
      {user?.role === "user" && (
        <>
          <Box
            sx={{
              mt: 1,
              display: "flex",
              justifyContent: "center",
              width: "100%",
              mb: 1,
              paddingBottom: 2,
            }}
          >
            {upgradeLoading ? (
              <CircularProgress />
            ) : (
              <Button
                sx={{
                  border: "1px solid #020239",
                  borderRadius: "10px",
                  width: "220px",
                  height: "56px",
                }}
                onClick={() => setResellerOpen(true)}
              >
                <Typography
                  sx={{
                    fontFamily: "Public Sans",
                    textTransform: "none",
                    fontStyle: "normal",
                  }}
                >
                  Switch To Reseller
                </Typography>
              </Button>
            )}
            {resellerOpen && (
              <ConfirmReseller
                confirm={upgradeMutate}
                handleClose={() => setResellerOpen()}
                loading={upgradeLoading}
              />
            )}
          </Box>
        </>
      )}
    </div>
  );
  const container =
    window !== undefined ? () => window().document.body : undefined;
  const { mutate } = useMutation(logOut, {
    onSuccess: (res) => {
      StorageService.clearToken();
      logoutUser();
    },
    onError: (e) => {
      logoutUser();
    },
  });
  const logUserOut = () => {
    handleClose();
    mutate();
  };
  // eslint-disable-next-line no-unused-vars
  const { data, isLoading, isRefetching, refetch } = useQuery(
    ["notifications"],
    getNotifications,
    { refetchOnWindowFocus: false }
  );
  
  if (!loggedIn) return <Navigate to="/signin" replace />;
  if (user?.email_verified === false) return <Navigate to="/otp" />;
  if (!(user?.role == roles.USER || user?.role == roles.RESELLER))
    return <Navigate to="/admin/dashboard" replace />;
  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
     
      {notificationOpen && <Notification message={message} />}
      {/* mobile */}
     
      <AppBar
        position="fixed"
        sx={{
          width: { sm: `calc(100% - ${mobileOpen ? drawerWidth : 0}px)` },
          ml: { sm: `${drawerWidth}px` },
          backgroundColor: "white",
          boxShadow: "none",
          borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
          display: { xs: "block", sm: "none" },
        }}
      >
        <Toolbar sx={{ display: "flex" }}>
          <IconButton
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { sm: "block", color: "black" } }}
          >
            <MenuIcon />
          </IconButton>
          <img
            onClick={() => navigate("/")}
            style={{ cursor: "pointer" }}
            src={Logo}
            height="40px"
            alt=""
          />
          <Box
            sx={{
              marginLeft: "auto",
              color: "black",
              width: "120px",
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "center",
            }}
          >
            <BellIcon
              style={{ width: "24px", cursor: "pointer" }}
              onClick={() => navigate("/user/notification")}
            />
            
            <Box>
              <Button>
                <Box
                  sx={{
                    width: "32px",
                    height: "32px",
                    borderRadius: "8px",
                    backgroundColor: "aqua",
                  }}
                >
                  <img
                    style={{
                      objectFit: "fill",
                      width: "32px",
                      height: "32px",
                      // borderRadius: "8px",
                    }}
                    src={user?.avatar}
                    alt={user?.fullname?.substring(0, 2) ?? ""}
                  />
                </Box>
                <ExpandMore
                  id="basic-button"
                  aria-controls={open ? "basic-menu" : undefined}
                  aria-haspopup="true"
                  aria-expanded={open ? "true" : undefined}
                  onClick={handleClick}
                />
              </Button>
              <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                  "aria-labelledby": "basic-button",
                }}
              >
                <MenuItem
                  onClick={() => {
                    handleClose();
                    navigate("/user/profile");
                  }}
                >
                  Profile
                </MenuItem>
                <MenuItem onClick={logUserOut}>Logout</MenuItem>
              </Menu>
            </Box>
          </Box>
        </Toolbar>
      </AppBar>
      <Box
        component="nav"
        sx={{
          width: { xs: 0, sm: mobileOpen ? drawerWidth : 0 },
          flexShrink: { sm: 0 },
        }}
        aria-label="mailbox folders"
      >
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: "block", sm: "none" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
        >
          {drawer}
        </Drawer>
      </Box>
      <Box
        component="main"
        sx={{
          display: { xs: "block", sm: "none" },
          flexGrow: 1,
          p: mobileOpen ? "0px" : 0,
          pt: 3,
          width: "100%",
          overflowY: "auto",
          height: "100vh ",
        }}
      >
        <Toolbar />
        <Outlet />
      </Box>
      {/* desktop */}
      <AppBar
        position="fixed"
        sx={{
          width: { sm: `calc(100% - ${webOpen ? drawerWidth : 0}px)` },
          ml: { sm: `${drawerWidth}px` },
          backgroundColor: "white",
          boxShadow: "none",
          borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
          display: { xs: "none", sm: "block" },
        }}
      >
        <Toolbar sx={{ display: "flex" }}>
          <IconButton
            aria-label="open drawer"
            edge="start"
            // onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { sm: "block", color: "black" } }}
          >
            <MenuIcon />
          </IconButton>
          <img
            onClick={() => navigate("/")}
            style={{ cursor: "pointer" }}
            src={Logo}
            height="40px"
            alt=""
          />
          <Box
            sx={{
              marginLeft: "auto",
              color: "black",
              width: "120px",
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "center",
            }}
          >
            <BellIcon
              style={{ width: "24px", cursor: "pointer" }}
              onClick={() => navigate("/user/notification")}
            />
          
            <Box>
              <Button>
                <Box
                  sx={{
                    width: "32px",
                    height: "32px",
                    borderRadius: "8px",
                    backgroundColor: "aqua",
                  }}
                >
                  <img
                    style={{
                      objectFit: "fill",
                      width: "32px",
                      height: "32px",
                      // borderRadius: "8px",
                    }}
                    src={user?.avatar}
                    alt={user?.fullname?.substring(0, 2) ?? ""}
                  />
                </Box>
                <ExpandMore
                  id="basic-button"
                  aria-controls={open ? "basic-menu" : undefined}
                  aria-haspopup="true"
                  aria-expanded={open ? "true" : undefined}
                  onClick={handleClick}
                />
              </Button>
              <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                  "aria-labelledby": "basic-button",
                }}
              >
                <MenuItem
                  onClick={() => {
                    handleClose();
                    navigate("/user/profile");
                  }}
                >
                  Profile
                </MenuItem>
                <MenuItem onClick={logUserOut}>Logout</MenuItem>
              </Menu>
            </Box>
          </Box>
        </Toolbar>
      </AppBar>
      <Box
        component="nav"
        sx={{
          width: { xs: 0, sm: webOpen ? drawerWidth : 0 },
          flexShrink: { sm: 0 },
        }}
        aria-label="mailbox folders"
      >
        <Drawer
          variant="persistent"
          sx={{
            display: { xs: "none", sm: "block" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
          open={webOpen}
        >
          {drawer}
        </Drawer>
      </Box>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          display: { xs: "none", sm: "block" },
          p: webOpen ? "0px" : 0,
          // pt: 3,
          width: "100%",
          overflowY: "auto",
          height: "100vh ",
        }}
      >
        <Toolbar />
        <Outlet />
      </Box>
    </Box>
  );
}
UserLayout.propTypes = {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
};
export default UserLayout;
