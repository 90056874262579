import { BellAlertIcon } from "@heroicons/react/24/outline";
import { Typography } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";

function Notification({ message }) {
  return (
    <Box
      sx={{
        position: "fixed",
        zIndex: "1000000",
        display: "flex",
        width: "100vw",
        justifyContent: "center",
        top: "10px",
      }}
    >
      <Box
        sx={{
          textAlign: "left",
          padding: "15px 106px 15px 20px",
          boxShadow: "0px 2px 25px rgba(0, 0, 0, 0.11)",
          borderRadius: "20px",
          //   border: "1px solid #868686",
          backgroundColor: "white",
          width: { xs: "90vw", md: "600px" },
        }}
      >
        <Typography
          sx={{
            fontSize: "19px",
            fontWeight: "700",
            color: " #020239;",
          }}
        >
          Notification
        </Typography>

        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            width: { xs: "80vw", md: "550px" },
          }}
        >
          <Box
            sx={{
              padding: "5px",
              background: "#E6E6EF",
              borderRadius: "50%",
              // height: "40px",
              width: "40px",
              justifyContent: "center",
              alignItems: "center",
              display: "flex",
              marginRight: "5px",
            }}
          >
            <BellAlertIcon style={{ width: "20px", height: "20px" }} />
          </Box>
          <Box sx={{ width: "100%" }}>
            {message ?? "You have a new notification"}
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

export default Notification;
