import React, { useState } from "react";
import CustomButton from "../Button";
import { useTheme } from "@mui/material/styles";
import {
  Dialog,
  Box,
  Typography,
  IconButton,
  InputAdornment,
} from "@mui/material";
import LabeledTextField from "../LabeledTextField";
import { EyeIcon, EyeSlashIcon } from "@heroicons/react/24/outline";
import { useFormik } from "formik";
import * as yup from "yup";

function Confirm({ value, recipient, proceed, edit, message, loading }) {
  const theme = useTheme();

  const [showPassword, setshowPassword] = useState(false);
  const handleClickShowPassword = () => {
    setshowPassword(!showPassword);
  };

  const initialValues = {
    password: "",
  };
  let validationSchema = yup.object({
    password: yup.string().required("this field is required"),
  });

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      const { password } = values;
      proceed(password);
    },
  });

  return (
    <Dialog
      sx={{
        "& .MuiDialog-paper": {
          borderRadius: "30px",
          width: { xs: "100%", sm: "50%", md: "27%" },
        },
      }}
      onClose={edit}
      open={true}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-evenly",
          alignItems: "center",
          padding: "30px",
          width: { xs: "100%" },
          // height: "420px",
          background: "#FFFFFF",
          border: "1px solid #DFDFDF",
          borderRadius: "30px",
          textAlign: "center",
        }}
      >
        {!message ? (
          <Typography>
            Confirm you want to send{" "}
            <Typography variant="header2">{value}</Typography> to{" "}
            <Typography variant="header2">{recipient}</Typography>
          </Typography>
        ) : (
          <Typography variant="header2">{message}</Typography>
        )}

        <Box
          sx={{
            mb: 4,
            display: "flex",
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          <form onSubmit={formik.handleSubmit}>
            <Box sx={{ margin: "20px 0" }}>
              <LabeledTextField
                label="PIN"
                type={showPassword ? "text" : "password"}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      // onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {!showPassword ? (
                        <EyeSlashIcon
                          style={{
                            color: "grey",
                            width: "24px",
                          }}
                        />
                      ) : (
                        <EyeIcon
                          style={{
                            color: "grey",
                            width: "24px",
                          }}
                        />
                      )}
                    </IconButton>
                  </InputAdornment>
                }
                id="password"
                name="password"
                value={formik.values.password}
                onChange={formik.handleChange}
                error={
                  formik.touched.password && Boolean(formik.errors.password)
                }
                helperText={formik.touched.password && formik.errors.password}
                fullWidth
              />
            </Box>

            <Box sx={{ display: "flex" }}>
              <Box sx={{ mr: 1, width: "39%" }}>
                <CustomButton
                  bgcolor={theme.palette.secondary.main}
                  color={theme.palette.primary.main}
                  onClick={edit}
                >
                  Edit
                </CustomButton>
              </Box>
              <Box sx={{ width: "62%" }}>
                <CustomButton
                  bgcolor={theme.palette.primary.main}
                  type="submit"
                  loading={loading}
                >
                  Proceed
                </CustomButton>
              </Box>
            </Box>
          </form>
        </Box>
      </Box>
    </Dialog>
  );
}

export default Confirm;
