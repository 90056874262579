import React, { useState } from "react";
import "./style.css";
import { useStyles } from "./header.style";
import { Link } from "react-router-dom";
import logo from "../../../images/logo.png";
import { AppStore, Close, GooglePlay } from "../../../icons";
import { Menu } from "@mui/icons-material";

const GeneralHeader = () => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const toggleMenu = () => {
    setOpen(!open);
  };
  return (
    <div className={classes.header}>
      <div className={"wrapper"}>
        <div className="navbar">
          <Link to="/">
            <img src={logo} alt="logo" height={"47px"} />
          </Link>
          <ul className={open ? "nav-links " + classes.openMenu : "nav-links"}>
            <Close className="close" onClick={toggleMenu} />
            <li>
              <Link to="/" className="active-link">
                Home
              </Link>
            </li>
            <li>
              <Link to="/">Services</Link>
            </li>
            <li>
              <Link to="/">Documentation</Link>
            </li>
            <li>
              <Link to="/">About</Link>
            </li>
            <li className="button">
              <Link to={"/signin"}>Sign In</Link>
            </li>
          </ul>
          <div className="buttons">
            <Link to={"/signin"} className="button sign-in">
              Sign In
            </Link>
            <Link to="/signup" className="button get-started">
              Get Started
            </Link>
          </div>
          <Menu className="menu" onClick={toggleMenu} />
        </div>
      </div>
    </div>
  );
};

export default GeneralHeader;
