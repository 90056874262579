import React, { useState } from "react";
import {
  Breadcrumbs,
  Button,
  CircularProgress,
  Menu,
  MenuItem,
  Typography,
  ButtonBase,
} from "@mui/material";
import {
  MoreVert,
  NavigateNextOutlined,
  ArrowBackIos,
  ArrowForwardIos,
} from "@mui/icons-material";
import { Box } from "@mui/system";
import { useTheme } from "@mui/material/styles";
import TabHeader from "../../components/TabHeader";
import Grid from "@mui/material/Unstable_Grid2";
import Table from "../../components/Table";
import DefaultButton from "../../components/Button";
import { useMutation, useQuery } from "@tanstack/react-query";
import { useNotificationStore } from "../../state";
import {
  adminDeleteBank,
  approveBankrequest,
  getBankrequest,
  getBanks,
  rejectBankrequestl,
} from "../../api/adminApi";
import FundUser from "../../components/modals/FundUser";
import AddBank from "../../components/modals/AddBank";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function Banks(props) {
  const theme = useTheme();
  const [value, setValue] = useState(0);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const [fundModalOpen, setFundModalOpen] = useState();
  const [target, setTarget] = useState();
  const [showAdd, setshowAdd] = useState(false);

  const handleOpen = () => {
    if (showAdd) setTarget();
    setshowAdd(!showAdd);
  };

  const handleClick = (event, target) => {
    setAnchorEl(event.currentTarget);
    console.info(target);
    setTarget(target);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleFund = () => {
    setFundModalOpen(!fundModalOpen);
  };

  const { isLoading: deleteLoading, mutate: deleteMutate } = useMutation(
    () => adminDeleteBank(target?.id),
    {
      onSuccess: (res) => {
        console.info({ res });
        displayNotificaton(res?.data?.message);
        refetch();
      },
      onError: (e) => {
        displayNotificaton(
          e.response?.data?.message ??
            e.message ??
            "something went wrong try again"
        );
      },
    }
  );

  const bankColumns = [
    {
      Header: "BANK NAME",
      accessor: "bank_name",
    },
    {
      Header: "ACCOUNT NAME",
      accessor: "account_name",
    },

    {
      Header: "ACCOUNT NUMBER",
      accessor: "account_number",
    },
    {
      Header: "DATE",
      accessor: "created_at",
      cell: (v) => <Box>{v?.created_at?.toString?.substring(0, 13)} </Box>,
    },
    {
      Header: "",
      accessor: "",
      // Cell method will provide the cell value; we pass it to render a custom component
      Cell: (v) => (
        <Box sx={{ minHeight: "10px", display: "flex", alignItems: "center" }}>
          {target?.id === v?.id && deleteLoading ? (
            <CircularProgress size={18} color="primary" />
          ) : (
            <Button onClick={(e) => handleClick(e, v)}>
              <MoreVert
                id="basic-button"
                aria-controls={open ? "basic-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={open ? "true" : undefined}
              />
            </Button>
          )}
          <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            transformOrigin={{ horizontal: "right", vertical: "top" }}
            anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
            sx={{
              "& .MuiMenu-paper": {
                boxShadow: "0px 2px 25px rgba(0, 0, 0, 0.11)",
                display: v.is_banned ? "none" : "block",
              },
              display: v.is_banned ? "none" : "block",
            }}
            open={open}
            onClose={handleClose}
            MenuListProps={{
              "aria-labelledby": "basic-button",
            }}
          >
            <MenuItem
              sx={{ color: " #9D9D9D", fontSize: "13px" }}
              onClick={() => {
                handleClose();
                handleOpen();
              }}
            >
              Edit
            </MenuItem>
            <MenuItem
              onClick={() => {
                handleClose();
                deleteMutate();
              }}
              sx={{ color: " #9D9D9D", fontSize: "13px" }}
            >
              Delete
            </MenuItem>
          </Menu>
        </Box>
      ),
      // Cell: () => <Box>{v.is_banned}</Box>,
    },
  ];
  const labelKeys = ["approved", "pending"];

  const labels = ["Completed", "Pending", "Banks"];

  const { data, isLoading, isRefetching, refetch } = useQuery(
    ["banks"],
    getBanks
  );

  const banks = data?.data?.data || [];
  const lastPage = 2;

  const displayNotificaton = useNotificationStore(
    (state) => state.displayNotificaton
  );

  const columns = [
    {
      Header: "ACCOUNT NAME",
      accessor: "account_name",
    },
    {
      Header: "ACCOUNT NUMBER",
      accessor: "account_number",
    },

    {
      Header: "AMOUNT",
      accessor: "amount",
    },
    {
      Header: "BANK NAME",
      accessor: "bank_name",
    },
    {
      Header: "FULL NAME",
      accessor: "fullname",
    },
    // {
    //   Header: "USERNAME",
    //   accessor: "username",
    // },
    {
      Header: "STATUS",
      accessor: "status",
      Cell: (v) => (
        <Box
          sx={{
            width: "94px",
            height: "39px",
            padding: "10px",
            display: "flex",
            alignItems: "center",
            background: v.status === "approved" ? "#E6F0EA" : "#FFE6E6",
            borderRadius: "100px",
            justifyContent: "center",
          }}
        >
          {v.status}
        </Box>
      ),
    },
  ];
  const pendingcolumns = [
    {
      Header: "ACCOUNT NAME",
      accessor: "account_name",
    },
    {
      Header: "ACCOUNT NUMBER",
      accessor: "account_number",
    },

    {
      Header: "AMOUNT",
      accessor: "amount",
    },
    {
      Header: "BANK NAME",
      accessor: "bank_name",
    },
    {
      Header: "FULL NAME",
      accessor: "fullname",
    },
    // {
    //   Header: "USERNAME",
    //   accessor: "username",
    // },

    {
      Header: "",
      accessor: "",
      // Cell method will provide the cell value; we pass it to render a custom component
      Cell: (v) => (
        <Box sx={{ minHeight: "10px", display: "flex", alignItems: "center" }}>
          {target?.id === v?.id && (acceptLoading || rejectLoading) ? (
            <CircularProgress color="primary" sx={{ width: "24px" }} />
          ) : (
            <Button onClick={(e) => handleClick(e, v)}>
              <MoreVert
                id="basic-button"
                aria-controls={open ? "basic-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={open ? "true" : undefined}
              />
            </Button>
          )}
          <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            transformOrigin={{ horizontal: "right", vertical: "top" }}
            anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
            sx={{
              "& .MuiMenu-paper": {
                boxShadow: "0px 2px 25px rgba(0, 0, 0, 0.11)",
                display: v.is_banned ? "none" : "block",
              },
              display: v.is_banned ? "none" : "block",
            }}
            open={open}
            onClose={handleClose}
            MenuListProps={{
              "aria-labelledby": "basic-button",
            }}
          >
            <MenuItem
              sx={{ color: " #9D9D9D", fontSize: "13px" }}
              onClick={() => {
                handleClose();
                acceptMutate();
              }}
            >
              Accept
            </MenuItem>
            <MenuItem
              onClick={() => {
                handleClose();
                rejectMutate();
              }}
              sx={{ color: " #9D9D9D", fontSize: "13px" }}
            >
              Reject
            </MenuItem>
          </Menu>
        </Box>
      ),
      // Cell: () => <Box>{v.is_banned}</Box>,
    },
  ];

  const [search, setSearch] = useState("");
  const [currentPage, setCurrentPage] = useState(1);

  const {
    data: requestData,
    isLoading: requestIsLoading,
    isRefetching: requestIsRefetching,
    refetch: requestRefetch,
  } = useQuery(["bankrequests", value, search, currentPage], () =>
    getBankrequest({
      search,
      page: currentPage,
      status: labelKeys[value],
    })
  );

  const banktransfers =
    requestData?.data?.data?.banktransfers?.data?.map((v) => ({
      uuid: v.id,
      amount: v.amount,
      ...v.bank,
      fullname: v.user.fullname,
      status: v.status,
    })) || [];

  const { isLoading: acceptLoading, mutate: acceptMutate } = useMutation(
    () => approveBankrequest(target?.uuid),
    {
      onSuccess: (res) => {
        console.info({ res });
        displayNotificaton(res?.data?.data?.message);
        requestRefetch();
      },
      onError: (e) => {
        displayNotificaton(
          e.response?.data?.message ??
            e.message ??
            "something went wrong try again"
        );
      },
    }
  );

  const { isLoading: rejectLoading, mutate: rejectMutate } = useMutation(
    () => rejectBankrequestl(target?.uuid),
    {
      onSuccess: (res) => {
        console.info({ res });
        displayNotificaton(res?.data?.message);
        requestRefetch();
      },
      onError: (e) => {
        displayNotificaton(
          e.response?.data?.message ??
            e.message ??
            "something went wrong try again"
        );
      },
    }
  );

  return (
    <Box sx={{ width: { xs: "100%", sm: "55%", md: "85%", lg: "100%" } }}>
      <Box sx={{ display: "flex" }}>
        {showAdd && (
          <AddBank
            bank={target}
            admin
            handleOpen={handleOpen}
            refetch={refetch}
          />
        )}

        {fundModalOpen && (
          <FundUser user={target} handleClose={handleFund} refetch={refetch} />
        )}
        <Typography variant="header" color={theme.palette.neutral.N200}>
          Banks
        </Typography>
      </Box>
      <Breadcrumbs
        separator={<NavigateNextOutlined fontSize="22px" />}
        aria-label="breadcrumb"
      >
        <Typography color={theme.palette.neutral.N200}>Banks</Typography>
        <Typography color="primary">{labels[value]}</Typography>
      </Breadcrumbs>
      <Grid container spacing={2} mt={4}>
        <Grid xs={12} sm={12} md={12} xl={12}>
          <TabHeader
            labels={labels}
            value={value}
            handleChange={handleChange}
          />
          <Box className="card" sx={{ mt: 5, minHeight: "60vh" }}>
            <TabPanel value={value} index={0}>
              <Table
                loading={requestIsLoading || requestIsRefetching}
                columns={columns}
                data={banktransfers}
                setFilter={setSearch}
              />
            </TabPanel>
            <TabPanel value={value} index={1}>
              <Table
                loading={requestIsLoading || requestIsRefetching}
                columns={pendingcolumns}
                data={banktransfers}
                setFilter={setSearch}
              />
            </TabPanel>
            <TabPanel value={value} index={2}>
              <Table
                columns={bankColumns}
                data={banks}
                loading={isLoading || isRefetching}
                setFilter={setSearch}
                action={
                  <Box sx={{ width: "min(250%,250px)" }}>
                    <DefaultButton onClick={handleOpen}>
                      + Add
                      <Box sx={{ display: { xs: "none", md: "contents" } }}>
                        {" "}
                        Bank
                      </Box>
                    </DefaultButton>
                  </Box>
                }
              />
            </TabPanel>
          </Box>
          {value !== 2 && (
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-end",
                width: "100%",
              }}
            >
              <Box sx={{ display: "flex", justifyContent: "end", width: 120 }}>
                <ButtonBase
                  sx={{
                    color: "rgb(59, 59, 59))",
                    backgroundColor: "grey",
                    marginLeft: "auto",
                    width: "40px",
                    height: "40px",
                    cursor: "pointer",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    borderRadius: 1,
                  }}
                  disabled={currentPage <= 1}
                  onClick={() => setCurrentPage(currentPage - 1)}
                >
                  <ArrowBackIos />
                </ButtonBase>
                <Box
                  sx={{
                    marginLeft: "auto",
                    width: "40px",
                    height: "40px",
                    cursor: "pointer",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    borderRadius: 1,
                  }}
                >
                  {currentPage}
                </Box>
                <ButtonBase
                  sx={{
                    color: "rgb(59, 59, 59))",
                    backgroundColor: "grey",
                    marginLeft: "auto",
                    width: "40px",
                    height: "40px",
                    cursor: "pointer",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    borderRadius: 1,
                  }}
                  disabled={currentPage >= lastPage}
                  onClick={() => setCurrentPage(currentPage + 1)}
                >
                  <ArrowForwardIos />
                </ButtonBase>
              </Box>
            </Box>
          )}
        </Grid>
      </Grid>
    </Box>
  );
}

export default Banks;
