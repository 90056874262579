import { makeStyles } from "@mui/styles";
// import { colors } from "../../../styles/colors";
import hero from "../../images/hero.svg";
import banner from "../../images/services-banner.png";
import bigbanner from "../../images/bg-phone.svg";
import bigbanner2 from "../../images/bg-phone.png";
import explore from "../../images/explore-bg.png";
import phone from "../../images/phone.png";

export const useStyles = makeStyles({
  landing: {
    textAlign: "left !important",
    fontFamily: "'Public Sans', sans-serif",
  },
  solutionSection: {
    background: "#white",
    marginTop: "200px",
    marginBottom: "132px",
    "@media (max-width: 1300px)": {
      marginTop: "100px",
      marginBottom: "70px",
    },
    "@media (max-width: 900px)": {
      marginTop: "50px",
      marginBottom: "50px",
    },
    "& .wrapper": {
      padding: "154px 0px",
      display: "grid",
      gridTemplateColumns: "1.8fr 1fr",
      gap: "82px",
      "@media (max-width: 1300px)": {
        gridTemplateColumns: "1fr",
        gap: "61px",
      },
      "& h1": {
        fontSize: "48px",
        lineHeight: "60px",
        fontWeight: "900",
        color: "#1A3A5E",
        marginBottom: "78px",
        "@media (max-width: 900px)": {
          fontWeight: "700",
          fontSize: "23px",
          lineHeight: "28px",
          marginBottom: "40px",
        },
      },
      "& div.bannerContainer": {
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        "@media (max-width: 1300px)": {
          justifyContent: "center !important",
          "& img": {
            height: "auto",
            width: "100%",
          },
        },
      },

      "& .cards": {
        display: "flex",
        flexDirection: "column",
        gap: "25px",
        "& .card-item": {
          "& h6": {
            fontSize: "28px",
            lineHeight: "34px",
            fontWeight: "700",
            color: "#1A3A5E",
            marginTop: "26px",
          },
          "& p": {
            fontSize: "16px",
            lineHeight: "20px",
            color: "#808080",
            marginBottom: "53px",
          },
          "& div.line": {
            width: "100%",
            height: "1px",
            backgroundColor: "#9d9d9d",
          },
        },
      },
    },
  },
  stepsSection: {
    padding: "95px 0px",
    background: "#F5F5F5",
    "& .wrapper": {
      "& h1": {
        fontSize: "48px",
        lineHeight: "60px",
        fontWeight: "900",
        color: "#1A3A5E",
        textAlign: "center",
        marginBottom: "78px",
      },
      "& .cards": {
        display: "grid",
        gridTemplateColumns: "1fr 1fr 1fr",
        gap: "20px",
        "@media (max-width: 1300px)": {
          gridTemplateColumns: "1fr 1fr",
        },
        "@media (max-width: 900px)": {
          gridTemplateColumns: "1fr",
        },
        "& .card-item": {
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          "& h6": {
            fontSize: "28px",
            lineHeight: "34px",
            fontWeight: "700",
            color: "#1A3A5E",
            marginTop: "49px",
            marginBottom: "20px",
            "@media (max-width: 900px)": {
              fontSize: "23px",
              lineHeight: "28px",
            },
          },
          "& p": {
            fontSize: "23px",
            lineHeight: "37px",
            color: "#2b2b2c",
            textAlign: "center",
            "@media (max-width: 900px)": {
              fontSize: "16px",
              lineHeight: "19px",
            },
          },
        },
      },
    },
  },
  bigBanner: {
    paddingTop: "70px",
    paddingBottom: "100px",
    background: `url(${bigbanner})`,
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    height: "992px",
    marginBottom: "132px",
    "@media (max-width: 1300px)": {
      background: `url(${bigbanner2})`,
      backgroundSize: "cover",
      backgroundRepeat: "no-repeat",
      backgroundPosition: "center",

      height: "auto",
      marginBottom: "70px",
    },
    "& .wrapper": {
      background: `url(${phone})`,
      backgroundSize: "contain",
      backgroundRepeat: "no-repeat",
      backgroundPosition: "center",
      height: "100%",
      display: "flex",
      flexDirection: "column",
      // justifyContent: "center",
      paddingTop: "32px",

      alignItems: "center",
      "@media (max-width: 900px)": {
        alignItems: "flex-start",
        display: "block",
      },
      "& .items": {
        display: "flex",
        justifyContent: "space-between",

        // alignItems: "center",
        minWidth: "900px",
        "@media (max-width: 900px)": {
          flexDirection: "column",
          minWidth: "auto",
          marginBottom: "20px !important",
          "& .empty": {
            display: "none",
          },
        },
      },
    },
  },
  cardBanners: {
    // display: "flex",
    // justifyContent: "center",
    // alignItems: "center",
    "& .wrapper": {
      "& .cards": {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        gap: "20px",
        "@media (max-width: 900px)": {
          flexDirection: "column",
        },
        "& .first-card": {
          position: "relative",
          // background: `url(${firstcard})`,
          // backgroundSize: "cover",
          // backgroundRepeat: "no-repeat",
          // height: "831px",
          // borderRadius: "40px",
          // width: "100%",
          // padding: "54px 51px",
          "& .text": {
            position: "absolute",
            top: "54px",
            left: "51px",
          },
          "& span": {
            fontSize: "23px",
            lineHeight: "39px",
            fontWeight: "500",
            color: "#2b2b2c",
          },
          "& h6": {
            fontSize: "48px",
            lineHeight: "70px",
            fontWeight: "900",
            color: "#1A3A5E",
            marginTop: "32px",
          },
          "@media (max-width: 900px)": {
            "& img": {
              height: "auto",
              width: "100%",
            },
            "& span": {
              fontSize: "16px",
              lineHeight: "19px",
            },
            "& h6": {
              fontSize: "23px",
              lineHeight: "30px",
            },
          },
        },
        "& .second-card": {
          position: "relative",
          // background: `url(${secondcard})`,
          // backgroundSize: "cover",
          // backgroundRepeat: "no-repeat",
          // height: "831px",
          borderRadius: "40px",
          // width: "100%",
          // padding: "79px 68px",
          "& .text": {
            position: "absolute",
            top: "79px",
            left: "40px",
          },

          "& h6": {
            fontSize: "40px",
            lineHeight: "60px",
            fontWeight: "900",
            color: "#fff",
          },
          "@media (max-width: 900px)": {
            "& img": {
              height: "auto",
              width: "100%",
            },
            "& h6": {
              fontSize: "23px",
              lineHeight: "30px",
            },
          },
        },
      },
    },
    "& .explore": {
      marginTop: "-100px",
      background: `url(${explore})`,
      backgroundSize: "cover",
      backgroundRepeat: "no-repeat",
      height: "606px",
      "@media (max-width: 900px)": {
        height: "auto",
        marginTop: "-50px",
      },
      "& .wrapper": {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        "& h6": {
          fontSize: "64px",
          lineHeight: "80px",
          fontWeight: "900",
          color: "#fff",
          textAlign: "center",
          marginTop: "200px",
          "@media (max-width: 900px)": {
            fontSize: "32px",
            lineHeight: "40px",
            marginTop: "180px",
          },
        },
        "& p": {
          fontSize: "23px",
          lineHeight: "28px",
          color: "#fff",
          textAlign: "center",
          marginTop: "20px",
          "@media (max-width: 900px)": {
            fontSize: "16px",
            lineHeight: "19px",
          },
        },
        "& a": {
          textDecoration: "none",
          background: "#fff",
          borderRadius: "10px",
          padding: "16px 60px",
          marginTop: "54px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          gap: "10px",
          fontSize: "26px",
          lineHeight: "19px",
          color: "#1A3A5E",
          fontWeight: "500",
          width: "fit-content",
          marginBottom: "82px",
          "@media (max-width: 900px)": {
            fontSize: "16px",
            lineHeight: "19px",
            padding: "16px 40px",
          },
        },
      },
    },
  },
});
