import { BASEURL, http } from ".";

const PROFILE = `${BASEURL}/update-profile`;
const PASSWORD = `${BASEURL}/update-password`;
const UPDATEPIN = `${BASEURL}/update-pin`;
const CREATEPIN = `${BASEURL}/create-pin`;
const AVATAR = `${BASEURL}/update-avatar`;

export const updateProfile = async (data) => http.post(PROFILE, data);
export const updateAvatar = async (data) =>
  http.post(AVATAR, data, {
    headers: {
      accept: "application/json",
      "Accept-Language": "en-US,en;q=0.8",
      "Content-Type": `multipart/form-data; `,
    },
  });
export const updatePassword = async (data) => http.post(PASSWORD, data);
export const updatePin = async (data) => http.post(UPDATEPIN, data);
export const createPin = async (data) => http.post(CREATEPIN, data);

export const getMe = async () => await http.get(`${BASEURL}/me`);

export const getSecurityQquestions = async () =>
  await http.get(`${BASEURL}/security_questions`);

// bank
const BANKS = `${BASEURL}/banks`;
const BANK = `${BASEURL}/bank`;

export const getBanks = async () => await http.get(BANKS);
export const getBank = async (id) => http.get(`${BANK}/${id}`);
export const createBank = async (data) => http.post(BANK, data);
export const updateBank = async ({ id, values: data }) =>
  http.put(`${BANK}/${id}`, data);
export const deleteBank = async (id) => http.delete(`${BANK}/${id}`);

// wiithdrawal
const WITHDRAWALS = `${BASEURL}/withdrawals`;
const WITHDRAWAL = `${BASEURL}/withdrawal`;
export const getWithdrawals = async () => await http.get(WITHDRAWALS);
export const createWithdrawal = async (data) => http.post(WITHDRAWAL, data);

// transactions
const TRANSACTIONS = `${BASEURL}/transactions`;
export const getTransactions = async (params) =>
  await http.get(TRANSACTIONS, { params });

// wallet
export const getBanksforPayment = async () =>
  await http.get(`${BASEURL}/get-banks`);
export const fundWallet = async (data) =>
  http.post(`${BASEURL}/fund-wallet`, data);
export const verifyFundWallet = async (reference) =>
  http.post(`${BASEURL}/fund-wallet-verify/${reference}`);

// nin
export const addNin = async (data) =>
  http.post(`${BASEURL}/add-nin`, data);

  export const verifyAddNin = async (reference) =>
  http.post(`${BASEURL}/add-nin-verify/${reference}`);


//airtime
export const initiateAirtime = async (data) =>
  http.post(`${BASEURL}/airtime-purchase`, data);

//notification
export const getNotifications = async (params) =>
  await http.get(`${BASEURL}/notifications`, { params });

//electricity/utility
export const getElectricityProviders = async () =>
  await http.get(`${BASEURL}/electricity-providers`);
export const validateUtility = async (data) =>
  http.post(`${BASEURL}/electricity-meter-validate`, data);
export const utilityProviderProducts = async (data) =>
  http.post(`${BASEURL}/electricity-provider-product`, data);
export const buyUtility = async (data) =>
  http.post(`${BASEURL}/electricity-purchase`, data);

//cable
export const getCableProviders = async () =>
  await http.get(`${BASEURL}/cable-providers`);
export const validateCable = async (data) =>
  http.post(`${BASEURL}/cable-meter-validate`, data);
export const CableProviderProducts = async (data) =>
  http.post(`${BASEURL}/cable-provider-product`, data);
export const buyCable = async (data) =>
  http.post(`${BASEURL}/cable-purchase`, data);

//data
export const getDataPlans = async (data) =>
  http.post(`${BASEURL}/data-providers`, data);

export const initiateData = async (data) =>
  http.post(`${BASEURL}/data-purchase`, data);

//tranfer
export const getTranferBanks = async () =>
  await http.get(`${BASEURL}/fetch-banks`);
export const validateAccount = async (data) =>
  http.post(`${BASEURL}/resolve-account`, data);

export const makeTranfer = async (data) =>
  http.post(`${BASEURL}/make-transfer`, data);
// statistics
const STATISTICS = `${BASEURL}/statistics`;
export const getStatistics = async () => await http.get(STATISTICS);
