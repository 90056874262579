import React, { useState } from "react";
import { Box, Drawer, Typography } from "@mui/material";
import SummaryCard from "../../components/Dashboard/SummaryCard";
import Grid from "@mui/material/Unstable_Grid2";
import ReportCard from "../../components/Dashboard/ReportCard";
import SourceCard from "../../components/Dashboard/SourceCard";
import DashBoardTable from "../../components/Dashboard/DashBoardTable";
import "./index.css";
import { useQuery } from "@tanstack/react-query";
import { getStatistics } from "../../api/adminApi";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { formatter } from "../../helpers";
function Dashboard({ window }) {
  const { data } = useQuery(["statistics"], getStatistics);
  const [analytics, setAnalytics] = useState();
  const container =
    window !== undefined ? () => window().document.body : undefined;
  const { airtime, data: dataData, total, ...other } = analytics || {};
  return (
    <Box className={"main-container"}>
      <div className="top">
        <Grid container spacing={2}>
          <Grid xs={1} sm={6} md={2.4}>
            <Box
              sx={{ cursor: "pointer" }}
              onClick={() =>
                setAnalytics(data?.data?.data?.transactions_amount)
              }
            >
              {" "}
              <SummaryCard
                label="Total Sales"
                value={`N${data?.data?.data?.sales?.total ?? 0}`}
                increase={
                  data?.data?.data?.sales?.growth?.type === "up" ? true : false
                }
                percentage={data?.data?.data?.sales?.growth?.growth ?? 0}
              />
            </Box>
          </Grid>
          <Grid xs={12} sm={6} md={2.4}>
            <SummaryCard
              label="Total Transactions"
              value={data?.data?.data?.transactions?.total ?? 0}
              increase={
                data?.data?.data?.transactions?.growth?.type === "up"
                  ? true
                  : false
              }
              percentage={data?.data?.data?.transactions?.growth?.growth ?? 0}
            />
          </Grid>
          <Grid xs={12} sm={6} md={2.4}>
            <SummaryCard
              label="Total Resellers"
              value={data?.data?.data?.reseller?.total ?? 0}
              increase={
                data?.data?.data?.reseller?.growth?.type === "up" ? true : false
              }
              percentage={data?.data?.data?.reseller?.growth?.growth ?? 0}
            />
          </Grid>
          <Grid xs={12} sm={6} md={2.4}>
            <SummaryCard
              label="Total Customers"
              value={data?.data?.data?.customers?.total ?? 0}
              increase={
                data?.data?.data?.customers?.growth?.type === "up"
                  ? true
                  : false
              }
              percentage={data?.data?.data?.customers?.growth?.growth ?? 0}
            />
          </Grid>
          <Grid xs={12} sm={6} md={2.4}>
            <SummaryCard
              label="Total Current Wallet"
              value={data?.data?.data?.total_wallet_balance ?? 0}
              increase={true}
              percentage={data?.data?.data?.customers?.growth?.growth ?? 0}
            />
          </Grid>
        </Grid>{" "}
      </div>
      <div className="center">
        <Grid container spacing={2} sx={{ mt: 1 }}>
          <Grid xs={12} sm={12} md={9}>
            <ReportCard />
          </Grid>
          <Grid xs={12} sm={12} md={3}>
            <SourceCard />
          </Grid>
        </Grid>{" "}
      </div>
      <div className="bottom">
        <Box className="card" sx={{ overflowY: "auto", mt: 3 }}>
          <DashBoardTable />
        </Box>
        {analytics && (
          <Drawer
            container={container}
            variant="temporary"
            open={analytics}
            anchor={"right"}
            onClose={() => setAnalytics()}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
            sx={{
              "& .MuiDrawer-paper": {
                boxSizing: "border-box",
                width: "430px",
              },
            }}
          >
            <Box sx={{ padding: "8vh 10% 4vh" }}>
              <Box sx={{ display: "flex", justifyContent: "center" }}>
                <Box
                  sx={{
                    width: "40px",
                    height: "40px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    border: "1.5px solid #020239",
                    borderRadius: "10px",
                    cursor: "pointer",
                  }}
                  onClick={() => setAnalytics()}
                >
                  <XMarkIcon style={{ width: "20px", color: "#020239" }} />
                </Box>
              </Box>
              <Box
                sx={{
                  justifyContent: "center",
                  alignItems: "center",
                  marginBottom: "40px",
                }}
              >
                <Typography
                  sx={{
                    fontSize: "25px",
                    fontWeight: "500",
                    textAlign: "center",
                    color: "#808080",
                    marginTop: "20px",
                  }}
                >
                  Sales
                </Typography>
              </Box>
              <Box
                sx={{
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Grid container>
                  {Object.entries(airtime ?? {}).map(([k, v]) => (
                    <Grid item xs={6} sx={{ padding: "5px 0px" }}>
                      <Box
                        sx={{
                          width: "161px",
                          height: "106px",
                          /* Neutral/N50 */
                          border: "1px solid #DFDFDF",
                          borderRadius: "20px",
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "space-between",
                          alignItems: "flex-start",
                          padding: "20px",
                        }}
                      >
                        <Typography
                          sx={{
                            fontSize: "11px",
                            fontWeight: "500",
                            textAlign: "center",
                            color: "#9D9D9D",
                          }}
                        >
                          Airtime - {k}
                        </Typography>
                        <Typography
                          sx={{
                            fontSize: "28px",
                            fontWeight: "700",
                            textAlign: "center",
                            color: "#020239",
                            marginBottom: "1px",
                          }}
                        >
                          N{formatter.format(v)}{" "}
                        </Typography>
                      </Box>
                    </Grid>
                  ))}
                  {Object.entries(dataData ?? {}).map(([k, v]) => (
                    <Grid item xs={6} sx={{ padding: "5px 0px" }}>
                      <Box
                        sx={{
                          width: "161px",
                          height: "106px",
                          /* Neutral/N50 */
                          border: "1px solid #DFDFDF",
                          borderRadius: "20px",
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "space-between",
                          alignItems: "flex-start",
                          padding: "20px",
                        }}
                      >
                        <Typography
                          sx={{
                            fontSize: "11px",
                            fontWeight: "500",
                            textAlign: "center",
                            color: "#9D9D9D",
                          }}
                        >
                          Data - {k}
                        </Typography>
                        <Typography
                          sx={{
                            fontSize: "28px",
                            fontWeight: "700",
                            textAlign: "center",
                            color: "#020239",
                            marginBottom: "1px",
                          }}
                        >
                          N{formatter.format(v)}{" "}
                        </Typography>
                      </Box>
                    </Grid>
                  ))}
                  {Object.entries(other ?? {}).map(([k, v]) => (
                    <Grid item xs={6} sx={{ padding: "5px 0px" }}>
                      <Box
                        sx={{
                          width: "161px",
                          height: "106px",
                          /* Neutral/N50 */
                          border: "1px solid #DFDFDF",
                          borderRadius: "20px",
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "space-between",
                          alignItems: "flex-start",
                          padding: "20px",
                        }}
                      >
                        <Typography
                          sx={{
                            fontSize: "11px",
                            fontWeight: "500",
                            textAlign: "center",
                            color: "#9D9D9D",
                          }}
                        >
                          Utility - {k}
                        </Typography>
                        <Typography
                          sx={{
                            fontSize: "28px",
                            fontWeight: "700",
                            textAlign: "center",
                            color: "#020239",
                            marginBottom: "1px",
                          }}
                        >
                          N{formatter.format(v)}{" "}
                        </Typography>
                      </Box>
                    </Grid>
                  ))}
                  <Grid item xs={6} sx={{ padding: "5px 0px" }}>
                    <Box
                      sx={{
                        width: "161px",
                        height: "106px",
                        /* Neutral/N50 */
                        border: "1px solid #DFDFDF",
                        borderRadius: "20px",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "space-between",
                        alignItems: "flex-start",
                        padding: "20px",
                        backgroundColor: "#020239",
                      }}
                    >
                      <Typography
                        sx={{
                          fontSize: "11px",
                          fontWeight: "500",
                          textAlign: "center",
                          color: "white",
                        }}
                      >
                        Total
                      </Typography>
                      <Typography
                        sx={{
                          fontSize: "28px",
                          fontWeight: "700",
                          textAlign: "center",
                          color: "white",
                          marginBottom: "1px",
                        }}
                      >
                        N{formatter.format(total)}{" "}
                      </Typography>
                    </Box>
                  </Grid>
                </Grid>
              </Box>
            </Box>
          </Drawer>
        )}
      </div>
    </Box>
  );
}
export default Dashboard;
