import React, { useState } from "react";
import CustomButton from "../Button";
import { useTheme } from "@mui/material/styles";
import {
  Dialog,
  Box,
  Typography,
  IconButton,
  InputAdornment,
} from "@mui/material";
import LabeledTextField from "../LabeledTextField";
import { EyeIcon, EyeSlashIcon } from "@heroicons/react/24/outline";
import { useFormik } from "formik";
import * as yup from "yup";

function ConfirmWithrawal({ proceed, cancel }) {
  const theme = useTheme();

  const [showpin, setshowpin] = useState(false);
  const handleClickShowpin = () => {
    setshowpin(!showpin);
  };

  const initialValues = {
    pin: "",
    amount: "",
  };
  let validationSchema = yup.object({
    pin: yup.string().required("this field is required"),
    amount: yup.number().required("this field is required"),
  });

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      proceed(values);
    },
  });

  return (
    <Dialog
      sx={{
        "& .MuiDialog-paper": {
          borderRadius: "30px",
          width: { xs: "100%", sm: "50%", md: "27%" },
        },
      }}
      open={true}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-evenly",
          alignItems: "center",
          padding: "30px",
          width: { xs: "100%" },
          // height: "420px",
          background: "#FFFFFF",
          border: "1px solid #DFDFDF",
          borderRadius: "30px",
          textAlign: "center",
        }}
      >
        <Box
          sx={{
            mb: 4,
            display: "flex",
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          <form style={{ width: "100%" }} onSubmit={formik.handleSubmit}>
            <Box sx={{ margin: "20px 0" }}>
              <LabeledTextField
                label="AMOUNT"
                type="number"
                id="amount"
                name="amount"
                value={formik.values.amount}
                onChange={formik.handleChange}
                error={formik.touched.amount && Boolean(formik.errors.amount)}
                helperText={formik.touched.amount && formik.errors.amount}
                fullWidth
              />
            </Box>

            <Box sx={{ margin: "20px 0" }}>
              <LabeledTextField
                label="PIN"
                type={showpin ? "text" : "pin"}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle pin visibility"
                      onClick={handleClickShowpin}
                      // onMouseDown={handleMouseDownpin}
                      edge="end"
                    >
                      {!showpin ? (
                        <EyeSlashIcon
                          style={{
                            color: "grey",
                            width: "24px",
                          }}
                        />
                      ) : (
                        <EyeIcon
                          style={{
                            color: "grey",
                            width: "24px",
                          }}
                        />
                      )}
                    </IconButton>
                  </InputAdornment>
                }
                id="pin"
                name="pin"
                value={formik.values.pin}
                onChange={formik.handleChange}
                error={formik.touched.pin && Boolean(formik.errors.pin)}
                helperText={formik.touched.pin && formik.errors.pin}
                fullWidth
              />
            </Box>

            <Box sx={{ display: "flex" }}>
              <Box sx={{ mr: 1, width: "39%" }}>
                <CustomButton
                  bgcolor={theme.palette.secondary.main}
                  color={theme.palette.primary.main}
                  onClick={cancel}
                >
                  Cancel
                </CustomButton>
              </Box>
              <Box sx={{ width: "62%" }}>
                <CustomButton
                  bgcolor={theme.palette.primary.main}
                  type="submit"
                >
                  Proceed
                </CustomButton>
              </Box>
            </Box>
          </form>
        </Box>
      </Box>
    </Dialog>
  );
}

export default ConfirmWithrawal;
