import React, { useState } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { TextField, Box, CircularProgress } from "@mui/material";
import { Search } from "@mui/icons-material";

export default function BasicTable({
  columns,
  data,
  action,
  loading,
  setFilter,
}) {
  const [filterInput, setFilterInput] = useState("");

  // Update the state when input changes
  const handleFilterChange = (e) => {
    const value = e.target.value || undefined;
    // setFilter("show.name", value);
    setFilterInput(value);
  };

  return (
    <Box>
      <Box sx={{ display: "flex" }}>
        <Box
          sx={{
            width: { xs: action ? "70%" : "100%", sm: "50%" },
            display: "flex",
            justifyContent: "start",
          }}
        >
          <TextField
            variant="outlined"
            value={filterInput}
            onChange={handleFilterChange}
            onBlur={(e) => setFilter(e.target.value)}
            onKeyDown={(ev) => {
              console.log(`Pressed keyCode ${ev.key}`);
              if (ev.key === "Enter") {
                // Do code here
                ev.preventDefault();
                setFilter(filterInput);
              }
            }}
            size="small"
            placeholder={"Search"}
            sx={{
              width: { xs: "95%", sm: "80%" },
              borderRadius: "10px",
              "& .MuiOutlinedInput-root": {
                borderRadius: "10px",
              },
            }}
            InputProps={{
              startAdornment: (
                <Search color="inherit" sx={{ color: "lightgray" }} />
              ),
            }}
          />
        </Box>
        {action && (
          <Box
            sx={{
              width: { xs: action ? "30%" : "0%", sm: "50%" },
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            {action}
          </Box>
        )}
      </Box>
      {loading ? (
        <Box
          sx={{
            display: "flex",
            minHeight: "200px",
            justifyContent: "center",
            width: "100%",
            alignItems: "center",
          }}
        >
          <CircularProgress />
        </Box>
      ) : (
        <TableContainer sx={{ mt: 5 }}>
          <Table
            sx={{ minWidth: 650, border: "none", boxShadow: "none" }}
            aria-label="simple table"
          >
            <TableHead>
              <TableRow sx={{ border: 0, borderBottom: "1px solid #F5F5F5" }}>
                {columns?.map((v, i) => (
                  <TableCell
                    sx={{
                      fontSize: "10px",
                      fontWeight: "500",
                      color: "rgba(128, 128, 128, 1)",
                    }}
                    key={i}
                  >
                    {v.Header}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {data.map((row, i) => (
                <TableRow
                  key={row.name}
                  sx={{ border: 0, borderBottom: "1px solid #F5F5F5" }}
                >
                  <>
                    {columns?.map((v, i) => (
                      <TableCell key={i}>
                        {v.Cell
                          ? typeof v.Cell == "function"
                            ? v.Cell(row)
                            : v.cell
                          : row[v.accessor]}
                      </TableCell>
                    ))}
                  </>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </Box>
  );
}
