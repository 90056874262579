import { Search } from "@mui/icons-material";
import {
  CircularProgress,
  Divider,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { Box } from "@mui/system";
import { useQuery } from "@tanstack/react-query";
import React, { useState } from "react";
import { getTransactions } from "../../api/userApi";
import LabeledSelectField from "../../components/LabeledSelectField";
import ViewTransaction from "../../components/modals/ViewTransaction";
import TransactionCard from "../../components/User/TransactionCard";

function Transactions() {
  const theme = useTheme();
  const [open, setOpen] = useState(false);

  const [search, setSearch] = useState("");
  const [val, setVal] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [filter, setFilter] = useState("all");

  const { data, isLoading, isRefetching, refetch } = useQuery(
    ["transactions", search, currentPage, filter],
    () => getTransactions({ filter, search, page: currentPage })
  );

  const transactions = data?.data?.data?.data || [];

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
        padding: "0 20px",
      }}
    >
      {open && <ViewTransaction data={open} handleClose={() => setOpen()} />}

      <Box
        sx={{
          display: {
            xs: "none",
            sm: "block",
            margin: "20px 0px 0px",
            textAlign: "left",
          },
        }}
      >
        <Box sx={{ display: "flex" }}>
          <Typography variant="header" color={theme.palette.neutral.N200}>
            Transactions
          </Typography>
        </Box>

        <Typography color={theme.palette.neutral.N200}>
          {/* {user?.role == roles.RESELLER ? "Resellers" : "Customer"} */}
          Transactions
        </Typography>
      </Box>

      <Box
        sx={{
          padding: { sm: "20px" },
          width: {
            xs: "100%",
            lg: "70%",
            xl: "60%",
          },
          border: {
            sm: "1px solid #e6e6ef",
          },
          borderRadius: "20px",
          mt: { xs: 0, sm: 5 },
          minHeight: "70vh",
        }}
      >
        <Box
          sx={{
            display: {
              xs: "flex",
              sm: "none",
              margin: "20px 0px 0px",
              textAlign: "left",
            },
            justifyContent: "space-between",
            mb: 2,
            alignItems: "center",
          }}
        >
          <Typography
            sx={{ fontSize: "16px", color: "#808080", fontWeight: "500" }}
          >
            Transactions
          </Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            mb: 2,
          }}
        >
          <TextField
            sx={{
              width: { xs: "100%", sm: "80%", lg: "70%" },
              borderRadius: "10px",
              "& .MuiOutlinedInput-root": {
                borderRadius: "10px",
              },
            }}
            onKeyDown={(ev) => {
              console.log(`Pressed keyCode ${ev.key}`);
              if (ev.key === "Enter") {
                // Do code here
                ev.preventDefault();
                setSearch(val);
              }
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Search />
                </InputAdornment>
              ),
            }}
            placeholder="search"
            variant="outlined"
            onBlur={(e) => setSearch(e.target.value)}
            onChange={(e) => setVal(e.target.value)}
            value={val}
          />
          <Box
            sx={{
              display: {
                xs: "none",
                sm: "block",

                textAlign: "left",
              },
              width: { sm: "15%", lg: "25%" },
            }}
          >
            <LabeledSelectField
              onChanged={(e) => setFilter(e)}
              value={filter}
              sx={{ fontSize: "10px", padding: "10px 0px" }}
              data={[
                { value: "all", label: "All" },
                { value: "year", label: "This Year" },
                { value: "month", label: "This Month" },
                { value: "week", label: "This Week" },
                { value: "today", label: "Today" },
              ]}
            ></LabeledSelectField>
          </Box>
        </Box>
        <Divider />
        <Box>
          {isLoading || isRefetching ? (
            <Box>
              <CircularProgress />
            </Box>
          ) : (
            transactions.map((transaction, i) => (
              <Box
                sx={{
                  cursor: "pointer",
                }}
                onClick={() => setOpen(transaction)}
              >
                <TransactionCard
                  key={i}
                  transaction={transaction}
                  refetch={refetch}
                />
              </Box>
            ))
          )}
        </Box>
      </Box>
    </Box>
  );
}

export default Transactions;
