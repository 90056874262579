import React, { useState } from "react";
import { useStyles } from "./resetpassword.style.js";
import GeneralHeader from "../../components/LandingPage/generalHeader/index.js";
import Footer from "../../components/LandingPage/footer/index.js";
import img from "../../images/signup-bg.png";
import { Check, Eye, Profile } from "../../icons/index.js";
import * as yup from "yup";
import { useFormik } from "formik";

import { useMutation } from "@tanstack/react-query";
import { forgotpassword } from "../../api/authApi.js";
import { useNavigate } from "react-router";

const ForgotPassword = () => {
  const classes = useStyles();
  const [showPassword, setshowPassword] = useState(false);
  const [error, setError] = useState(null);
  const [message, setMessage] = useState(null);
  const handleClickShowPassword = () => {
    setshowPassword(!showPassword);
  };

  const navigate = useNavigate();

  const { isLoading, mutate } = useMutation(forgotpassword, {
    onSuccess: (res) => {
      setMessage(res.data?.message);
    },
    onError: (e) => {
      setError(
        e.response?.data?.message ??
          e.message ??
          "something went wrong try again"
      );
    },
  });

  const initialValues = {
    email: "",
  };
  let validationSchema = yup.object({
    email: yup.string().required("this field is required"),
  });

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      setError(null);
      const { email } = values;
      const fb = new FormData();
      fb.append("email", email);
      mutate(values);
    },
  });
  return (
    <div className={classes.container}>
      <GeneralHeader />
      <div className={classes.signInSection}>
        <div className="wrapper">
          <div className="formContainer">
            <h6>Reset your password</h6>
            {error && <div className="error-text">{error}</div>}
            {message && <div className="success-text">{message}</div>}
            <form onSubmit={formik.handleSubmit}>
              <div className="form-group">
                <label htmlFor="username">Email</label>
                <div className="input-group">
                  <input
                    type="text"
                    id="email"
                    name="email"
                    value={formik.values.email}
                    onChange={formik.handleChange}
                    error={formik.touched.email && Boolean(formik.errors.email)}
                    helperText={formik.touched.email && formik.errors.email}
                    placeholder="Enter your username"
                  />
                  <div className="input-group-append">
                    <Profile />
                  </div>
                </div>
              </div>

              <div className="button-group">
                <button className="primary" type="submit" disabled={isLoading}>
                  {isLoading ? "Loading..." : "Continue"}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default ForgotPassword;
