import { Box } from "@mui/system";
import React from "react";
// import IdentityCard from "./IdentityCard";

function Identity() {
  return (
    <Box
      sx={{
        justifyContent: "space-between",
        width: { xs: "100%", md: "70%", lg: "50%" },
        position: "relative",
        paddingBottom: 20,
      }}
    >
      {/* <IdentityCard /> */}
    </Box>
  );
}

export default Identity;
