import { Button, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { createBrowserRouter, Outlet, NavLink } from "react-router-dom";
import AdminLayout from "./components/AdminLayout";
import AuthLayout from "./components/AuthLayout";
import UserLayout from "./components/UserLayout";
import Banks from "./pages/Banks";
import LeaderBoard from "./pages/Core/LeaderBoard";
import Customers from "./pages/Customers";
import Dashboard from "./pages/Dashboard";
import Discounts from "./pages/Discounts";
import ForgotPassword from "./pages/ForgotPassword";
import ForgotPasswordSuccess from "./pages/ForgotPassword";
import Groups from "./pages/Groups";
import Landing from "./pages/Landing";
import Maintenance from "./pages/maintenance";
import Otp from "./pages/Otp";
import Privacy from "./pages/privacy";
import Providers from "./pages/Providers";
import Resellers from "./pages/Resellers";
import ResetPassword from "./pages/ResetPassword";
import Services from "./pages/Services";
import SignIn from "./pages/SignIn";
import SignUp from "./pages/SignUp";
import AdminTransactions from "./pages/Transactions";
import Analytics from "./pages/User/Analytic";
import CableTv from "./pages/User/CableTv";
import FundWallet from "./pages/User/FundWallet";
import Home from "./pages/User/Home";
import MobileTopUp from "./pages/User/MobileTopUp";
import NotificationPage from "./pages/User/Notification";
import Profile from "./pages/User/Profile";
import Transactions from "./pages/User/Transactions";
import Transfer from "./pages/User/Transfer";
import Utility from "./pages/User/Utility";
import Withdrawal from "./pages/User/Withdrawal";
import Withdrawals from "./pages/Withdrawals";
import EditCustomer from "./pages/Customers/EditCustomer";
import AccountSuccess from "./pages/AccountSuccess";

export const router = createBrowserRouter([
  {
    path: "/",
    element: <Landing />,
    errorElement: (
      <Box
        sx={{
          display: "flex",
          width: "100vw",
          height: "100vh",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
          backgroundColor: "white",
        }}
      >
        <Typography sx={{ padding: "20px" }}>
          oops! seems you took a wrong turn
        </Typography>
        <NavLink to="/">
          <Button
            variant="contained"
            sx={{
              width: { sm: "120px", md: "170px" },
              height: { sm: "40px", md: "50px" },
              background: "#020239",
              borderRadius: "10px",
            }}
          >
            Back to Home
          </Button>
        </NavLink>
      </Box>
    ),
  },
  {
    path: "/signup",
    element: (
      <AuthLayout>
        <SignUp />
      </AuthLayout>
    ),
  },
  {
    path: "/signup/success",
    element: (
      <AuthLayout>
        <AccountSuccess />
      </AuthLayout>
    ),
  },
  {
    path: "/signin",
    element: (
      <AuthLayout>
        <SignIn />
      </AuthLayout>
    ),
  },
  {
    path: "/otp",
    element: <Otp />,
  },
  {
    path: "/terms-and-privacy",
    element: <Privacy />,
  },
  // {
  //   path: "/updatepassword",
  //   element: (
  //     <AuthLayout>
  //       <UpdatePassword />
  //     </AuthLayout>
  //   ),
  // },
  {
    path: "/password/reset",
    element: (
      <AuthLayout>
        <ResetPassword />
      </AuthLayout>
    ),
  },
  {
    path: "/forgotpassword",
    element: (
      <AuthLayout>
        <ForgotPassword />
      </AuthLayout>
    ),
  },
  {
    path: "/forgotpasswordSuccess",
    element: (
      <AuthLayout>
        <ForgotPasswordSuccess />
      </AuthLayout>
    ),
  },
  {
    path: "/admin",
    element: <AdminLayout />,
    children: [
      {
        path: "dashboard",
        element: <Dashboard />,
      },
      {
        path: "editCustomer",
        element: <EditCustomer />,
      },
      {
        path: "services",
        element: <Services />,
      },
      {
        path: "resellers/",
        element: (
          <div>
            <Outlet />
          </div>
        ),
        children: [
          {
            path: "all",
            element: <Resellers />,
          },
          {
            path: "groups",
            element: <Groups />,
          },
        ],
      },
      {
        path: "customers/",
        element: <Customers />,
      },
      {
        path: "transactions/",
        element: <AdminTransactions />,
      },
      {
        path: "banks/",
        element: <Banks />,
      },
      {
        path: "withdrawals/",
        element: <Withdrawals />,
      },
      {
        path: "discounts/",
        element: <Discounts />,
      },
      {
        path: "providers/",
        element: <Providers />,
      },
      {
        path: "maintenance/",
        element: <Maintenance />,
      },
      {
        path: "profile",
        element: <Profile />,
      },
      {
        path: "leaderboard",
        element: <LeaderBoard />,
      },
      // {
      //   path: "*",
      //   element: (
      //     <Navigate>
      //       <SignIn />
      //     </Navigate>
      //   ),
      // },
    ],
  },
  {
    path: "/user",
    element: <UserLayout />,
    children: [
      {
        path: "home",
        element: <Home />,
      },
      {
        path: "mobile-topup",
        element: (
          <div>
            <Outlet />
          </div>
        ),
        children: [
          {
            path: "data",
            element: <MobileTopUp key={"data"} type={"data"} />,
          },
          {
            path: "airtime",
            element: <MobileTopUp key={"airtime"} type={"airtime"} />,
          },
        ],
      },
      {
        path: "transactions",
        element: <Transactions />,
      },
      {
        path: "profile",
        element: <Profile />,
      },
      {
        path: "withdraw",
        element: <Withdrawal />,
      },
      {
        path: "cable-tv",
        element: <CableTv />,
      },
      {
        path: "utility",
        element: <Utility />,
      },
      {
        path: "transfer",
        element: <Transfer />,
      },
      {
        path: "fund-wallet",
        element: <FundWallet />,
      },
      {
        path: "notification",
        element: <NotificationPage />,
      },
      {
        path: "analytics",
        element: <Analytics />,
      },
      {
        path: "leaderboard",
        element: <LeaderBoard />,
      },
      // {
      //   path: "*",
      //   element: (
      //     <Navigate>
      //       <SignIn />
      //     </Navigate>
      //   ),
      // },
    ],
  },
  // {
  //   path: "*",
  //   element: (
  //     <Navigate>
  //       <SignIn />
  //     </Navigate>
  //   ),
  // },
]);
