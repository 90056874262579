import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles({
  container: {
    textAlign: "left !important",
    fontFamily: "'Public Sans', sans-serif",
    background: "#FAFAFA",
  },
  otpSection: {
    paddingTop: "47px",
    paddingBottom: "98px",
    "& .wrapper": {
      position: "relative",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",

      "& .formContainer": {
        background: "#fff",
        padding: "50px",
        borderRadius: "20px",
        width: "800px",

        "@media (max-width: 900px)": {
          width: "100%",
        },
        "& .error-text": {
          padding: ".75rem 1.25rem",
          color: "#721c24",
          background: "#f8d7da",
          border: "1px solid #f5c6cb",
          borderRadius: ".25rem",
          margin: "32px auto",
        },
        "& .success-text": {
          padding: ".75rem 1.25rem",
          color: "#155724",
          background: "#d4edda",
          border: "1px solid #c3e6cb",
          borderRadius: ".25rem",
          margin: "32px auto",
        },

        "& h6": {
          fontSize: "33px",
          lineHeight: "40px",
          fontWeight: "700",
          color: "#1A3A5E",
          marginBottom: "40px",
          "@media (max-width: 768px)": {
            fontSize: "20px",
            lineHeight: "24px",
            marginBottom: "20px",
          },
        },
        "& form": {
          "& .form-group": {
            marginBottom: "20px",
            "& label": {
              fontSize: "16px",
              lineHeight: "20px",
              fontWeight: "500",
              color: "#000",
              marginBottom: "6px",
            },
            "& .input-group": {
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              position: "relative",
              "& input": {
                width: "100%",
                height: "56px",
                border: "1px solid #868686",
                borderRadius: "10px",
                padding: "16px 20px",
                fontSize: "16px",
                lineHeight: "20px",
                fontWeight: "500",
                color: "#000",
                "&::placeholder": {
                  color: "#Dfdfdf",
                },
              },
              "& .input-group-append": {
                position: "absolute",
                right: "16px",
              },
            },
            "& .forgot-password": {
              paddingTop: "10px",
              marginLeft: "10px",
              fontSize: "11px",
              lineHeight: "13px",
              fontWeight: "500",
              color: "#9C0000",
            },
          },
          "& .otpInput": {
            width: "86px !important",
            height: "86px",
            margin: "0 12px",
            fontSize: "20px",
            borderRadius: 20,
            border: "1px solid #808080",
            outline: "none",
            padding: "10px",
            boxSizing: "border-box",
            fontWeight: "bold",
            color: "#000",
            "@media (max-width: 768px)": {
              width: "50px !important",
              height: "50px",
              margin: "0 5px",
              fontSize: "16px",
            },
            "@media (max-width: 480px)": {
              width: "40px !important",
              height: "40px",
              margin: "0 5px",
              fontSize: "12px",
              borderRadius: 10,
            },

            "&:focus": {
              border: "1px solid #000",
              color: "#000",
            },
            "&:hover": {
              border: "1px solid #000",
              color: "#000",
            },
          },
          "& .button-group": {
            marginTop: "45px",
            display: "flex",
            flexDirection: "column",
            gap: "10px",
            "@media (max-width: 768px)": {
              marginTop: "20px",
            },
            "@media (max-width: 480px)": {
              marginTop: "10px",
            },
            "& button, div.resend": {
              width: "100%",
              height: "56px",
              outline: "none",
              border: "none",
              borderRadius: "10px",
              padding: "16px 20px",
              fontSize: "16px",
              lineHeight: "20px",
              fontWeight: "500",
              textAlign: "center",
              cursor: "pointer",

              "&.primary": {
                background: "#1A3A5E",
                color: "#fff",
              },
              "&.secondary": {
                background: "#e6e6e6",
                color: "#1A3A5E",
              },
            },
            "& .divider": {
              display: "flex",
              alignItems: "center",
              gap: "11px",
              fontSize: "16px",
              lineHeight: "20px",
              fontWeight: "500",
              color: "#808080",
              "&::before, &::after": {
                content: "''",
                flex: "1",
                borderBottom: "1px solid #808080",
              },
            },
          },
        },
      },
    },
  },
});
