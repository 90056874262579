import React, { useState } from "react";
import { Breadcrumbs, Typography } from "@mui/material";
import { NavigateNextOutlined } from "@mui/icons-material";
import { Box } from "@mui/system";
import { useTheme } from "@mui/material/styles";
import Grid from "@mui/material/Unstable_Grid2";
import SummaryCardAlt from "../../components/User/SummaryCardAlt";
import CustomButton from "../../components/Button";
import LabeledTextField from "../../components/LabeledTextField";

import * as yup from "yup";
import { useFormik } from "formik";
import LabeledSelectField from "../../components/LabeledSelectField";
import { useMutation, useQuery } from "@tanstack/react-query";
import { useNotificationStore } from "../../state";
import {
  getTranferBanks,
  makeTranfer,
  validateAccount,
} from "../../api/userApi";
import Confirm from "../../components/modals/Confirm";
import Success from "../../components/modals/Success";
import Failure from "../../components/modals/Failure";

function Transfer() {
  const theme = useTheme();
  const [showConfirm, setShowConfirm] = useState(false);
  const [success, setSuccess] = useState(false);
  const [failure, setFailure] = useState(false);
  const [user, setUser] = useState();

  const initialValues = {
    naration: "",
    amount: "",
    bank_code: "",
    account_number: "",
  };
  let validationSchema = yup.object({
    naration: yup
      .string()
      // .test("len", "Phone number is not valid", (val) => val?.length === 11)
      .required("this field is required"),
    // .matches(phoneRegExp, "Phone number is not valid"),

    amount: yup.string().required("this field is required"),
    bank_code: yup.string().required("this field is required"),
    account_number: yup.string().required("this field is required"),
  });

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      validateMutate(values);
    },
  });

  const { data, isLoading } = useQuery(["transferBanks"], getTranferBanks);
  const handleConfirm = (v) => {
    setShowConfirm(!showConfirm);
  };

  const transferBanks = data?.data?.data || [];

  const displayNotificaton = useNotificationStore(
    (state) => state.displayNotificaton
  );

  const { isLoading: validateLoading, mutate: validateMutate } = useMutation(
    validateAccount,
    {
      onSuccess: (res) => {
        setUser(res?.data?.data);
        handleConfirm();
      },
      onError: (e) => {
        displayNotificaton(
          e.response?.data?.message ??
            e.message ??
            "something went wrong try again"
        );
      },
    }
  );

  const { isLoading: tranferLoading, mutate: tranferMutate } = useMutation(
    makeTranfer,
    {
      onSuccess: (res) => {
        console.info({ res });
        if (res?.data?.status) setSuccess(res?.data?.message);
        else setFailure(res?.data?.message);
        handleConfirm();
        setUser();
        formik.resetForm();
      },
      onError: (e) => {
        setFailure(
          e.response?.data?.message ??
            e.message ??
            "something went wrong try again"
        );
      },
    }
  );
  const tranfer = (pin) => {
    tranferMutate({
      ...formik.values,
      pin,
    });
  };
  return (
    <Box sx={{ p: "0px 20px" }}>
      {showConfirm && (
        <Confirm
          message={`Confirm Transfer of ${formik.values.amount} to ${user?.account_name} `}
          edit={handleConfirm}
          proceed={tranfer}
          loading={tranferLoading}
        />
      )}
      {success && (
        <Success message={success} handleClose={() => setSuccess()} />
      )}
      {failure && (
        <Failure message={failure} handleClose={() => setFailure()} />
      )}
      <Box sx={{ display: { xs: "none", sm: "block", margin: "20px 0px" } }}>
        <Box sx={{ display: "flex" }}>
          <Typography variant="header" color={theme.palette.neutral.N200}>
            Transfer
          </Typography>
        </Box>
        <Breadcrumbs
          separator={<NavigateNextOutlined fontSize="22px" />}
          aria-label="breadcrumb"
        >
          {/* <Typography color={theme.palette.neutral.N200}>Resellers</Typography> */}
          <Typography color="primary"> Transfer</Typography>
        </Breadcrumbs>
      </Box>
      <Box
        sx={{
          display: { xs: "block", sm: "none" },
          textAlign: "left",
        }}
      >
        <Typography
          sx={{ fontWeight: "500", color: "#808080", marginBottom: "10px" }}
        >
          Transfer
        </Typography>
        <SummaryCardAlt />
      </Box>
      <Box sx={{ width: "min(578px ,100% )" }}>
        <Box
          sx={{
            mt: 3,
            minHeight: "60vh",
            minWidth: "243px",
            border: { xs: "none", md: "1px solid #e6e6ef" },
            borderRadius: "20px",
            padding: { xs: "0px", md: "24px" },
          }}
        >
          <form onSubmit={formik.handleSubmit}>
            <Box sx={{ mb: 4 }}>
              <LabeledTextField
                label="Account Number"
                id="account_number"
                name="account_number"
                value={formik.values.account_number}
                onChange={formik.handleChange}
                error={
                  formik.touched.account_number &&
                  Boolean(formik.errors.account_number)
                }
                helperText={
                  formik.touched.account_number && formik.errors.account_number
                }
                fullWidth
                variant="outlined"
              />
            </Box>
            <Box sx={{ mb: 4 }}>
              <LabeledSelectField
                label="Select Bank"
                id="bank_code"
                name="bank_code"
                loading={isLoading}
                value={formik.values.bank_code}
                onChange={formik.handleChange}
                onChanged2={(v) => formik.setFieldValue(v)}
                error={
                  formik.touched.bank_code && Boolean(formik.errors.bank_code)
                }
                helperText={formik.touched.bank_code && formik.errors.bank_code}
                data={
                  transferBanks?.map((v) => ({
                    label: v?.name,
                    value: v?.code,
                  })) ?? []
                }
                fullWidth
                variant="outlined"
              />
            </Box>
            {/* <Box sx={{ mb: 4 }}>
                <LabeledTextField
                  label="bank_code Name"
                  disabled
                  id="bank_code"
                  name="bank_code"
                  value={formik.values.bank_code}
                  onChange={formik.handleChange}
                  onChanged2={(v) => formik.setFieldValue(v)}
                  error={
                    formik.touched.bank_code &&
                    Boolean(formik.errors.bank_code)
                  }
                  helperText={
                    formik.touched.bank_code && formik.errors.bank_code
                  }
                  fullWidth
                  variant="outlined"
                  size="small"
                />
              </Box> */}
            <Box sx={{ mb: 4 }}>
              <LabeledTextField
                label="Amount"
                id="amount"
                name="amount"
                value={formik.values.amount}
                onChange={formik.handleChange}
                error={formik.touched.amount && Boolean(formik.errors.amount)}
                helperText={formik.touched.amount && formik.errors.amount}
                fullWidth
                variant="outlined"
                size="small"
              />
            </Box>

            <Box sx={{ mb: 4 }}>
              <LabeledTextField
                label="Naration"
                // hint="You can add multiple numbers. Ex: 08030302020, 09016161919"
                id="naration"
                name="naration"
                value={formik.values.naration}
                onChange={formik.handleChange}
                error={
                  formik.touched.naration && Boolean(formik.errors.naration)
                }
                helperText={formik.touched.naration && formik.errors.naration}
                fullWidth
                variant="outlined"
              />
            </Box>
            <Box sx={{ mb: 4 }}>
              <CustomButton
                loading={tranferLoading || validateLoading}
                type="submit"
              >
                Continue
              </CustomButton>
            </Box>
          </form>
        </Box>
      </Box>
    </Box>
  );
}

export default Transfer;
