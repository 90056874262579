import { ArrowUpRightIcon } from "@heroicons/react/24/outline";
import { Box } from "@mui/system";
import { useQuery } from "@tanstack/react-query";
import React from "react";
import { NavLink } from "react-router-dom";
import { getTransactions } from "../../api/adminApi";
import Table from "../Table";

function DashBoardTable() {
  const columns = [
    {
      Header: "USER",
      accessor: "user",
    },
    {
      Header: "DESCRIPTION",
      accessor: "description",
    },
    {
      Header: "REFRENCE",
      accessor: "reference",
    },
    {
      Header: "PHONE/IUC",
      accessor: "created_at",
      Cell: (v) => <span>{v?.phone?.data}</span>,
    },
    {
      Header: "AMOUNT",
      accessor: "amount",
    },
    {
      Header: "NETWORK",
      accessor: "network",
    },
    {
      Header: "INITIAL BALANCE",
      accessor: "initial_balance",
    },
    {
      Header: "FINAL BALANCE",
      accessor: "final_balance",
    },
    {
      Header: "DATE",
      accessor: "created_at",
      Cell: (v) => <span>{v?.created_at?.substring(0, 13)}</span>,
    },
    {
      Header: "STATUS",
      accessor: "status",
      Cell: (v) => (
        <Box
          sx={{
            width: "94px",
            height: "39px",
            padding: "10px",
            display: "flex",
            alignItems: "center",
            background:
              v.status === "success"
                ? "#E6F0EA"
                : v.status === "pending"
                ? "lightyellow"
                : "#FFE6E6",
            borderRadius: "100px",
            justifyContent: "center",
          }}
        >
          {v.status}
        </Box>
      ),
    },
  ];

  const { data, isLoading, isRefetching } = useQuery(
    ["transactions"],
    getTransactions,
    { refetchOnWindowFocus: false }
  );

  const transactions =
    data?.data?.data?.data
      ?.map((v) => ({ ...v, user: v.user?.username ?? v.user?.fullname }))
      ?.slice(1, 6) || [];
  return (
    <div style={{ padding: "20px", minHeight: "60vh" }}>
      <Table
        columns={columns}
        loading={isLoading || isRefetching}
        data={transactions}
        action={
          <Box
            sx={{
              "& a": {
                width: "100px",
                backgroundColor: "white",
                textDecoration: "underline",
              },
            }}
          >
            <NavLink
              to="/admin/transactions"
              style={{
                fontSize: "13px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                "& a": {
                  width: "100px",
                  backgroundColor: "white",
                },
              }}
            >
              {" "}
              view all
              <ArrowUpRightIcon style={{ width: "20px" }} />{" "}
            </NavLink>
          </Box>
        }
      />
    </div>
  );
}

DashBoardTable.propTypes = {};

export default DashBoardTable;
