import React from 'react'
import { Box, useTheme,Typography } from '@mui/material';
import { formatter } from "../helpers";

const LeaderBoardCard = ({ name, position, amount, image }) => {
    const theme = useTheme();
      const colors = {
        1: "gold",
        2: "silver",
        3: "#CD7F32",
      };
    
      return (
        <Box
          sx={{
            width: {
              xs: "80%",
              md: "90%",
            },
            height: "100px",
            display: "flex",
            alignItems: "center",
            padding: "22px 20px",
            margin: "5px",
            justifyContent: "space-between",
          }}
        >
          <Box
            sx={{
              width: "65px",
              minWidth: "65px",
              height: "65px",
              backgroundColor: colors[position] || "white",
              borderRadius: "20px",
              margin: "5px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              fontSize: "20px",
              color: colors[position] ? "white" : "grey",
              border: colors[position] ? "none" : "1px solid grey",
              textAlign: "center",
            }}
          >
            {position}
          </Box>
          <Box
            sx={{
              minWidth: {
                xs: "100%",
                md: "90%",
              },
              height: "90px",
              display: "flex",
              alignItems: "center",
              padding: "22px 20px",
              border: "1px solid #DFDFDF",
              borderRadius: "20px",
              justifyContent: "space-between",
              margin: "5px",
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                width: "100%",
                justifyContent: "space-between",
              }}
            >
              <Box
                sx={{
                  width: "60px",
                  height: "60px",
                  backgroundColor: "aqua",
                  borderRadius: "50%",
                  margin: "5px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  fontSize: "20px",
                  color: "white",
                  textAlign: "center",
                  minWidth: "60px",
                }}
              >
                {image && (
                  // eslint-disable-next-line jsx-a11y/alt-text
                  <img
                    src={image}
                    style={{
                      width: "100%",
                      height: "60px",
                      borderRadius: "50%",
                    }}
                  />
                )}
              </Box>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  width: "100%",
                  flexDirection: {
                    xs: "column",
                    md: "row",
                  },
                  overflow: "hidden",
                  whiteSpace: "nowrap",
                  textOverflow: "ellipsis",
                }}
              >
                <Typography
                  sx={{
                    fontWeight: "700",
                    lineHeight: "120%",
                    fontSize: {
                      xs: 12,
                      sm: 14,
                      md: 20,
                    },
                    color: theme.palette.primary.main,
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                    textOverflow: "ellipsis",
                  }}
                >
                  {name}
                </Typography>
    
                <Typography
                  sx={{
                    color: "#020239",
                    fontSize: {
                      xs: 10,
                      sm: 12,
                      md: 14,
                    },
                    fontWeight: "500",
                  }}
                >
                  ₦{formatter.format(amount)}
                </Typography>
              </Box>
            </Box>
          </Box>
        </Box>
      );
}
// function LeaderBoardCard({ name, position, amount, image }) {
    
    // }
export default LeaderBoardCard