import React from "react";
import { Box } from "@mui/system";
import {
  CircularProgress,
  InputAdornment,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";

function LabeledSelectField({
  label,
  hint,
  data = [],
  onChanged,
  error,
  loading,
  helperText,
  sx = {},
  ...rest
}) {
  return (
    <Box>
      {label && (
        <Box sx={{ display: "flex", justifyContent: "start", ...sx }}>
          <Typography variant="body1">{label}</Typography>
        </Box>
      )}
      <Select
        variant="outlined"
        size="small"
        onChange={(v) => onChanged(v.target.value)}
        disabled={loading}
        native
        autoComplete="off"
        endAdornment={
          <InputAdornment position="end">
            {loading && <CircularProgress size={20} />}{" "}
          </InputAdornment>
        }
        sx={{
          width: "100%",
          textAlign: "initial",
          ...sx,
          borderRadius: "10px",
          "& .MuiOutlinedInput-root": {
            borderRadius: "10px",
            textAlign: "initial",
          },
        }}
        error={error}
        {...rest}
      >
        <option val=""></option>
        {data.map((v, i) => (
          <option
            key={i}
            value={v.value}
            sx={{ display: "flex", justifyContent: "start" }}
          >
            {v.label}
          </option>
        ))}
      </Select>
      <Box sx={{ display: "flex", justifyContent: "start" }}>
        {error ? (
          <Typography
            variant="xs"
            sx={{
              color: "#d32f2f",
              marginLeft: "14px",
              marginTop: "4px",
              fontSize: "0.75rem",
            }}
          >
            {helperText}
          </Typography>
        ) : (
          <Typography variant="xs" sx={{ color: "rgba(134, 134, 134, 1)" }}>
            {hint}
          </Typography>
        )}
      </Box>
    </Box>
  );
}

export default LabeledSelectField;
