import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles({
  footer: {
    background: "#1A3A5E",
    color: "#fff",
    paddingTop: "168px",
    paddingBottom: "79px",
    "@media (max-width: 1300px)": {
      paddingTop: "14px",
      paddingBottom: "48px",
    },
    "& .wrapper": {
      "& .big-row": {
        display: "flex",
        // justifyContent: "space-between",
        alignItems: "flex-start",
        gap: "132px",
        "@media (max-width: 1300px)": {
          flexDirection: "column",
          gap: "24px",
        },
        "& .items": {
          display: "grid",
          gridTemplateColumns: "1fr 1fr 1fr",
          gap: "64px",
          marginBottom: "78px",
          "@media (max-width: 1300px)": {
            gridTemplateColumns: "1fr 1fr",
            gap: "64px",
          },
          "@media (max-width: 900px)": {
            gridTemplateColumns: "1fr",
            gap: "32px",
          },

          "& .item": {
            "& h6": {
              fontSize: "23px",
              lineHeight: "28px",
              fontWeight: "700",
              color: "#fff",
              marginBottom: "20px",
            },
            "& .links": {
              display: "flex",
              flexDirection: "column",
              gap: "10px",
              "& a": {
                fontSize: "19px",
                lineHeight: "30px",
                color: "#e6e6ef",
                textDecoration: "none",
                "&:hover": {
                  color: "#fff",
                },
              },
            },
            "& .socials": {
              display: "flex",
              gap: "10px",
            },
          },
        },
      },
      "& .copyright": {
        borderTop: "1px solid #e6e6ef",
        paddingTop: "28px",
        fontSize: "23px",
        lineHeight: "28px",
        fontWeight: "700",
        color: "#fefefe",
        "@media (max-width: 900px)": {
          fontSize: "16px",
          lineHeight: "20px",
        },
      },
    },
  },
});
