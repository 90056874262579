/* eslint-disable react-hooks/exhaustive-deps */
import { Box } from "@mui/system";
import React, { useEffect, useState } from "react";

import Mtn from "../../icons/mtn.png";
import Airtel from "../../icons/airtel.png";
import Etisalat from "../../icons/etisalat.png";
import Glo from "../../icons/glo.png";
import LabeledTextField from "../LabeledTextField";
import LabeledSelectField from "../LabeledSelectField";
import CustomButton from "../Button";

import * as yup from "yup";
import { useFormik } from "formik";
import { Typography } from "@mui/material";
import Confirm from "../modals/Confirm";
import { useNotificationStore, useUserStore } from "../../state";
import { useMutation } from "@tanstack/react-query";
import { getDataPlans, initiateData } from "../../api/userApi";
import Success from "../modals/Success";
import Failure from "../modals/Failure";
import { useUser } from "../../hooks";

function Data() {
  const [selectedNetwork, setselectedNetwork] = useState(null);
  const [showConfirm, setShowConfirm] = useState(false);
  const [success, setSuccess] = useState(false);
  const [failure, setFailure] = useState(false);
  const [plans, setPlans] = useState([]);

  const { role } = useUserStore((state) => state.user);

  const { data_charges } = useUserStore((state) => state.charge);
  const charge = data_charges ? data_charges[`${role}_charge`] : {};

  const displayNotificaton = useNotificationStore(
    (state) => state.displayNotificaton
  );

  const networks = [
    {
      value: "mtn",
      img: Mtn,
    },
    {
      value: "airtel",
      img: Airtel,
    },
    {
      value: "etisalat",
      img: Etisalat,
    },
    {
      value: "glo",
      img: Glo,
    },
  ];
  const initialValues = {
    phone: "",
    type: "",
    network: "",
    plan: "",
  };
  let validationSchema = yup.object({
    phone: yup
      .string()
      // .test("len", "Phone number is not valid", (val) => val?.length === 11)
      .required("this field is required"),
    // .matches(phoneRegExp, "Phone number is not valid"),

    type: yup.string().required("this field is required"),
    plan: yup.string().required("this field is required"),
    network: yup.string().required("select a network"),
  });

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      handleConfirm();
    },
  });
  const chargeData = charge
    ? charge[`${formik.values.network}_${formik.values.type}`.toLowerCase()] ??
      0
    : 0;

  const buy = (pin) => {
    const { phone, type, network, plan } = formik.values;
    buyMutate({
      phones: phone,
      type,
      network: network?.toUpperCase(),
      plan_id: plan,
      pin,
    });
  };

  const handleClick = (v) => {
    setselectedNetwork(v);
    formik.values.network = v;
    formik.setFieldValue("type", "");
    formik.setFieldValue("plan", "");
  };
  const handleConfirm = (v) => {
    setShowConfirm(!showConfirm);
  };

  const { isLoading, mutate } = useMutation(getDataPlans, {
    onSuccess: (res) => {
      //  refetch();
      console.info({ res }, res?.data?.data);
      displayNotificaton(res?.data?.message);

      if (res.data.status) {
        const temp = [];
        res?.data?.data.forEach((v) => {
          temp.push({
            label:
              v.name +
              "    ₦" +
              (parseFloat(v.price) +
                parseFloat((v.price * (chargeData ?? 0)) / 100)),
            value: v.plan_id,
          });
        });
        setPlans(temp);
        console.info({ temp });
      }
      //  handleConfirm();
    },
    onError: (e) => {
      displayNotificaton(
        e.response?.data?.message ??
          e.message ??
          "something went wrong try again"
      );
    },
  });

  useEffect(() => {
    if (formik.values.type && formik.values.type !== "")
      mutate({
        network: selectedNetwork?.toUpperCase(),
        type: formik.values.type,
      });
  }, [formik.values.type]);
  const { fetchUser } = useUser();

  const { isLoading: buyLoading, mutate: buyMutate } = useMutation(
    initiateData,
    {
      onSuccess: (res) => {
        //  refetch();
        fetchUser();
        if (res?.data?.status) setSuccess(res?.data?.message);
        else setFailure(res?.data?.message);
        formik.resetForm();
        setselectedNetwork();
        handleConfirm();
      },
      onError: (e) => {
        setFailure(
          e.response?.data?.message ??
            e.message ??
            "something went wrong try again"
        );
      },
    }
  );
  return (
    <Box>
      {showConfirm &&
        (() => {
          // eslint-disable-next-line eqeqeq
          const plan = plans?.find((v) => v.value == formik.values.plan);

          return (
            <Confirm
              message={`confirm data purchase of  ${plan?.label} to ${formik.values.phone} `}
              recipient={formik.values.phone}
              edit={handleConfirm}
              proceed={buy}
              loading={buyLoading}
            />
          );
        })()}
      {success && (
        <Success message={success} handleClose={() => setSuccess()} />
      )}
      {failure && (
        <Failure message={failure} handleClose={() => setFailure()} />
      )}
      <Box sx={{ display: "flex", justifyContent: "space-between" }}>
        {networks.map((v, i) => (
          <Box
            sx={{
              width: { xs: "23%", md: "23%", lg: "100px" },
              // height: { xs: "20%", md: "23%", lg: "100px" },
              objectFit: "cover",

              borderRadius: "10px",
              cursor: "pointer",
              // padding: "1px",
              border:
                selectedNetwork === v.value
                  ? "2px solid black"
                  : "1px solid lightgrey",
            }}
            onClick={() => handleClick(v.value)}
          >
            <img
              style={{
                // margin: "-20px -20px 0 0",
                objectFit: "cover",
                width: "100%",
                height: "100%",
                borderRadius: "10px",
              }}
              src={v.img}
              alt={v.value}
            />
          </Box>
        ))}
      </Box>
      {formik.touched.network && Boolean(formik.errors.network) && (
        <Typography sx={{ color: "red", fontSize: "11px" }}>
          {formik.errors.network}
        </Typography>
      )}
      <Box sx={{ mt: 3 }}>
        <form onSubmit={formik.handleSubmit}>
          <Box sx={{ mb: 4 }}>
            <LabeledSelectField
              label="Type"
              id="type"
              name="type"
              value={formik.values.type}
              onChange={formik.handleChange}
              error={formik.touched.type && Boolean(formik.errors.type)}
              helperText={formik.touched.type && formik.errors.type}
              fullWidth
              variant="outlined"
              size="small"
              // data={[
              //   { label: "SME", value: "SME" },
              //   { label: "GIFTING", value: "GIFTING" },
              //   { label: "CORPORATE", value: "CORPORATE" },
              // ]}
              data={
                selectedNetwork === 'mtn'
                  ? [
                      { label: "SME", value: "SME" },
                      { label: "GIFTING", value: "GIFTING" },
                      { label: "CORPORATE", value: "CORPORATE" },
                    ]
                  : [
                      { label: "CORPORATE", value: "CORPORATE" },
                    ]
              }
              disabled={!selectedNetwork}
              onClick={
                !selectedNetwork
                  ? () => displayNotificaton("Select a Network ")
                  : null
              }
            />
          </Box>
          <Box sx={{ mb: 4 }}>
            <LabeledSelectField
              label="Plan"
              id="plan"
              name="plan"
              value={formik.values.plan}
              onChange={formik.handleChange}
              error={formik.touched.plan && Boolean(formik.errors.plan)}
              helperText={formik.touched.plan && formik.errors.plan}
              fullWidth
              variant="outlined"
              data={plans}
              disabled={formik.values.type === "" || isLoading}
              onClick={
                formik.values.type === ""
                  ? () => displayNotificaton("Select a data type")
                  : null
              }
              loading={isLoading}
            />
          </Box>
          <Box sx={{ mb: 4 }}>
            <LabeledTextField
              label="Phone Number"
              hint="You can add multiple numbers. Ex: 08030302020, 09016161919"
              id="phone"
              name="phone"
              value={formik.values.phone}
              onChange={(e) =>
                formik.setFieldValue("phone", e.target.value.trim())
              }
              error={formik.touched.phone && Boolean(formik.errors.phone)}
              helperText={formik.touched.phone && formik.errors.phone}
              fullWidth
              variant="outlined"
              placeholder="Ex: 08030302020"
            />
          </Box>
          <Box sx={{ mb: 10 }}>
            <CustomButton loading={buyLoading} type="submit">
              Continue
            </CustomButton>
          </Box>
        </form>
      </Box>
    </Box>
  );
}

export default Data;
