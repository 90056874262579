import React from "react";
import { useStyles } from "./style.js";
import GeneralHeader from "../../components/LandingPage/generalHeader";
import Footer from "../../components/LandingPage/footer";
import img from "../../images/signup-bg.png";
import { Check, Eye, Profile } from "../../icons";

const AccountSuccess = () => {
  const classes = useStyles();
  return (
    <div className={classes.container}>
      <GeneralHeader />
      <div className={classes.signInSection}>
        <div className="wrapper">
          <div className="container">
            <Check />
            <h6>Your account has been successfully created!</h6>

            <div className="button-group">
              <button className="primary">Continue</button>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default AccountSuccess;
