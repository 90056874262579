import React, { useState } from "react";
import { Breadcrumbs, Typography } from "@mui/material";
import { NavigateNextOutlined } from "@mui/icons-material";
import { Box } from "@mui/system";
import { useTheme } from "@mui/material/styles";
import TabHeader from "../../components/TabHeader";
import Grid from "@mui/material/Unstable_Grid2";
import Airtime from "../../components/Services/Airtime";
import Data from "../../components/Services/Data";
import SummaryCardAlt from "../../components/User/SummaryCardAlt";
import { useUserStore } from "../../state";
import { roles } from "../../helpers";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: { xs: 0 } }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function MobileTopUp({ type }) {
  const user = useUserStore((state) => state.user);
  const theme = useTheme();
  const [value, setValue] = useState(type === "airtime" ? 1 : 0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const labels = ["Data", "Airtime"];
  return (
    <Box sx={{ p: "0px 20px" }}>
      <Box sx={{ display: { xs: "none", sm: "block", margin: "20px 0px" } }}>
        <Box sx={{ display: "flex" }}>
          <Typography variant="header" color={theme.palette.neutral.N200}>
            Mobile TopUp
          </Typography>
        </Box>
        <Breadcrumbs
          separator={<NavigateNextOutlined fontSize="22px" />}
          aria-label="breadcrumb"
        >
          <Typography color={theme.palette.neutral.N200}>
            {/* {user?.role == roles.RESELLER ? "Resellers" : "Customer"} */}
            Mobile Top-up
          </Typography>
          <Typography color="primary">{labels[value]}</Typography>
        </Breadcrumbs>
      </Box>
      <Box sx={{ display: { xs: "block", sm: "none" }, textAlign: "left" }}>
        <Typography
          sx={{
            color: "#808080",
            fontWeight: "500",
            textAlign: "start",
            marginBottom: "10px",
          }}
        >
          Mobile Top-up
        </Typography>
      </Box>

      <Box sx={{ width: "min(578px ,100% )" }}>
        <Box sx={{ mb: "20px", display: { xs: "block", sm: "none" } }}>
          <SummaryCardAlt />
        </Box>
        <TabHeader labels={labels} value={value} handleChange={handleChange} />
        <Box
          sx={{
            mt: 3,
            minHeight: "60vh",
            padding: { md: "20px" },
            border: { md: "1px solid #DFDFDF" },
            borderRadius: { md: "30px" },
          }}
        >
          <TabPanel value={value} index={0}>
            <Data />
          </TabPanel>
          <TabPanel value={value} index={1}>
            <Airtime />
          </TabPanel>
        </Box>
      </Box>
    </Box>
  );
}

export default MobileTopUp;
