import React from "react";
import CustomButton from "../Button";
import { useTheme } from "@mui/material/styles";
import { Dialog, Box, Typography } from "@mui/material";

function ConfirmDelete({ confirm, close, loading }) {
  const theme = useTheme();

  return (
    <Dialog
      sx={{
        "& .MuiDialog-paper": {
          borderRadius: "30px",
          width: { xs: "100%", sm: "50%", md: "35%" },
        },
      }}
      open={true}
      onClose={close}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-evenly",
          alignItems: "center",
          padding: "50px",
          gap: "10px",
          width: { xs: "100%" },
          height: "280px",
          background: "#FFFFFF",
          border: "1px solid #DFDFDF",
          borderRadius: "30px",
        }}
      >
        <Typography variant="header2">
          Are you sure you want to Delete?
        </Typography>

        <Box
          sx={{
            mb: 4,
            display: "flex",
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          <Box sx={{ mr: 1, width: "39%" }}>
            <CustomButton
              bgcolor={theme.palette.secondary.main}
              color={theme.palette.primary.main}
              onClick={close}
            >
              Cancel
            </CustomButton>
          </Box>
          <Box sx={{ width: "62%" }}>
            <CustomButton
              loading={loading}
              onClick={confirm}
              bgcolor={theme.palette.alert.main}
            >
              Delete
            </CustomButton>
          </Box>
        </Box>
      </Box>
    </Dialog>
  );
}

export default ConfirmDelete;
