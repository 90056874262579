import React, { useMemo, useState } from "react";
import ResponsiveDrawer from "../../components/AdminLayout";
import { Breadcrumbs, Typography } from "@mui/material";
import { NavigateNextOutlined } from "@mui/icons-material";
import { Box } from "@mui/system";
import { useTheme } from "@mui/material/styles";
import TabHeader from "../../components/TabHeader";
import Grid from "@mui/material/Unstable_Grid2";
import Airtime from "../../components/Services/Airtime";
import Data from "../../components/Services/Data";
import CableForm from "../../components/Services/CableForm";
import UtilityForm from "../../components/Services/UtilityForm";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function Services(props) {
  const theme = useTheme();
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const labels = ["Data", "Airtime", "Cable Subscription", "Bill Payment"];
  return (
    <div>
      <Box sx={{ display: "flex" }}>
        <Typography variant="header" color={theme.palette.neutral.N200}>
          Resellers
        </Typography>
      </Box>
      <Breadcrumbs
        separator={<NavigateNextOutlined fontSize="22px" />}
        aria-label="breadcrumb"
      >
        <Typography color={theme.palette.neutral.N200}>Resellers</Typography>
        <Typography color="primary">{labels[value]}</Typography>
      </Breadcrumbs>
      <Grid container spacing={2} mt={4}>
        <Grid xs={12} sm={11} md={10} lg={7} xl={6}>
          <TabHeader
            labels={labels}
            value={value}
            handleChange={handleChange}
          />
          <Box className="card" sx={{ mt: 5, minHeight: "60vh" }}>
            <TabPanel value={value} index={0}>
              <Data />
            </TabPanel>
            <TabPanel value={value} index={1}>
              <Airtime />
            </TabPanel>
            <TabPanel value={value} index={2}>
              <CableForm admin />
            </TabPanel>
            <TabPanel value={value} index={3}>
              <UtilityForm admin />
            </TabPanel>
          </Box>
        </Grid>
      </Grid>
    </div>
  );
}

export default Services;
