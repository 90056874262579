import React from "react";
import { Box, Typography } from "@mui/material";
import { ReactComponent as Arrow } from "../../icons/arrow.svg";
import { ReactComponent as Chart } from "../../icons/Chart.svg";
import { ReactComponent as Arrow2 } from "../../icons/arrow (1).svg";
import { ReactComponent as Chart2 } from "../../icons/Vector.svg";
import { useTheme } from "@mui/material/styles";
function SummaryCard({ label, value, increase, percentage }) {
  const theme = useTheme();
  return (
    <Box
      sx={{
        minWwidth: "243px",
        minHeight: "132px",
        border: "1px solid #E6E6EF",
        borderRadius: "20px",
        padding: "20px",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        alignItems: "start",
      }}
    >
      <Box sx={{ color: theme.palette.neutral.N500, fontSize: "11px" }}>
        {label}
      </Box>
      <Typography
        sx={{
          fontWeight: "bold",
          lineHeight: "120%",
          fontSize: {
            lg: 30,
            md: 20,
            sm: 15,
            xs: 10,
          },
          color: theme.palette.primary.main,
        }}
      >
        {value}
      </Typography>

      <Box
        sx={{
          color: theme.palette.primary.main,
          fontSize: "8px",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        {increase !== true ? <Arrow /> : <Arrow2 />}
        {percentage}
        {" % "}from last week
        {increase !== true ? <Chart /> : <Chart2 />}
      </Box>
    </Box>
  );
}

SummaryCard.propTypes = {};

export default SummaryCard;
