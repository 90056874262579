import { PlusSmallIcon } from "@heroicons/react/24/outline";
import { CircularProgress, IconButton } from "@mui/material";
import { Box } from "@mui/system";
import { useQuery } from "@tanstack/react-query";
import React, { useState } from "react";
import { getBanks } from "../../api/userApi";
import AddBank from "../modals/AddBank";
import BankCard from "./BankCard";

function Banks() {
  const [showAdd, setshowAdd] = useState(false);

  const handleOpen = () => {
    setshowAdd(!showAdd);
  };

  const { data, isLoading, isRefetching, refetch } = useQuery(
    ["banks"],
    getBanks
  );

  const banks = data?.data?.data || [];

  console.info(banks);

  return (
    <Box
      sx={{
        justifyContent: "space-between",
        width: { xs: "100%", md: "70%", lg: "50%" },
        position: "relative",
        paddingBottom: 20,
      }}
    >
      {showAdd && <AddBank handleOpen={handleOpen} refetch={refetch} />}
      {isLoading || isRefetching ? (
        <Box>
          <CircularProgress />
        </Box>
      ) : (
        banks.map((bank, i) => (
          <BankCard key={i} bank={bank} refetch={refetch} />
        ))
      )}

      <IconButton
        sx={{
          position: "fixed",
          top: "90vh",
          left: { xs: "75vw", sm: "80vw", md: "67%", lg: "52%" },
          padding: "13px 22px",
          backgroundColor: "#020239",
          borderRadius: "12px",
        }}
        onClick={handleOpen}
      >
        <PlusSmallIcon style={{ width: "30px", color: "white" }} />
      </IconButton>
    </Box>
  );
}

export default Banks;
