import { Checkbox, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { useQuery } from "@tanstack/react-query";
import React, { useState } from "react";
import { getBanksforPayment } from "../../api/userApi";
import CustomButton from "../../components/Button";
import FundAccount from "../../components/modals/FundAccount";
import AddNin from "../../components/modals/AddNin";
import SummaryCardAlt from "../../components/User/SummaryCardAlt";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import Avatar from "@mui/material/Avatar";
import Snackbar from "@mui/material/Snackbar";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { useUserStore } from "../../state";

import paystack from "../../icons/paystack.png";

function FundWallet() {
  const [showAdd, setshowAdd] = useState(false);
  const [showNin, setshowNin] = useState(false);
  const [selected, setSelected] = useState();
  const [open, setOpen] = React.useState(false);

  const handleClick = () => {
    setOpen(true);
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  const action = (
    <React.Fragment>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleClose}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </React.Fragment>
  );

  const handleOpen = () => {
    setshowAdd(!showAdd);
  };
  
  const handleOpenNin = () => {
    setshowNin(!showNin);
    // setshowAdd(!showAdd);
  };
  const copyAccountNumber = async (account_number) => {
    await navigator.clipboard.writeText(account_number);
    return handleClick();
  };

  const { data } = useQuery(["banksforPayment"], getBanksforPayment);

  const { virtual_account,nin } = useUserStore((state) => state.user);

  const BANK_LOGO = {
    "Sterling bank": "/sterling.svg",
    "Wema bank": "/wema.svg",
    "Moniepoint Microfinance Bank": "/moniepoint.svg",
  };

  const banksforPayment = data?.data?.data || [];

  return (
    <Box
      sx={{
        padding: "0px 20px",
        width: {
          xs: "100%",
          lg: "70%",
          xl: "60%",
        },
      }}
    >
      {showAdd && <FundAccount handleOpen={handleOpen} selected={selected} />}
      <Typography
        sx={{
          color: "#808080",
          fontWeight: "500",
          textAlign: "start",
          marginBottom: "20px",
          marginTop: { xs: "0px", sm: "20px" },
        }}
      >
        Fund Wallet 
      </Typography>
        
      {showNin && <AddNin handleOpenNin={handleOpenNin} selected={selected} />}
      {
  nin === null ? ( // Check if nin is null
    <Box
      sx={{
        backgroundColor: "#f8d7da", // Background color
        color: "#721c24", // Text color
        fontWeight: "500",
        padding: "16px 60px",
        borderRadius: "10px",
        fontSize: "11px",
        whiteSpace: "nowrap",
        width: "100%", // Adjust width to fill the container
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        border: "1px solid #dc3545", // Border color
        cursor: "pointer",
        marginBottom: "10px",            
      }}
      variant="outlined"
      onClick={handleOpenNin} // Call handleOpenNin function when clicked
    >
      Update Your KYC
    </Box>
  ) : " " // If nin is not null, render nothing
}

          
        
      <SummaryCardAlt />
      <Box
        sx={{
          textAlign: "start",
          flex: 1,
          marginBottom: "20px",
          marginTop: "40px",
        }}
      >
        <Typography sx={{ color: "#020239", fontWeight: "500" }}>
          Direct Bank Transfer(automatic funding)
        </Typography>
      </Box>
      <List
        sx={{
          width: "100%",
          bgcolor: "background.paper",
        }}
      >
        {virtual_account.map((account, i) => (
          <ListItem>
            <ListItemAvatar>
              <Avatar>
                <img
                  src={BANK_LOGO[account.bank_name]}
                  alt={account.bank_name}
                />
              </Avatar>
            </ListItemAvatar>
            <ListItemText
              primary={account.bank_name}
              secondary={account.account_number}
            />
            <span onClick={() => copyAccountNumber(account.account_number)}>
              <ContentCopyIcon />
            </span>
          </ListItem>
        ))}
        <Snackbar
          open={open}
          autoHideDuration={6000}
          onClose={handleClose}
          message="Account Number Copied!"
          action={action}
        />
      </List>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          width: "100%",
          alignItems: "center",
          marginBottom: "20px",
          marginTop: "20px",
        }}
      >
        <Box sx={{ textAlign: "start", flex: 1 }}>
          <Typography sx={{ color: "#020239", fontWeight: "500" }}>
            Paystack(automatic funding)
          </Typography>
        </Box>
      </Box>

      <Box
        sx={{
          justifyContent: "space-between",
          width: "100%",
          position: "relative",
          paddingBottom: 20,
          cursor: "pointer",
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            // padding: "60px 10px 20px 60px",
            border: "1px solid #DFDFDF",
            borderRadius: "20px",
            margin: "10px 0",
            justifyContent: "space-between",
            height: "100px",
            padding: "20px",
          }}
          onClick={() => setSelected({ type: "paystack" })}
        >
          <img src={paystack} width="150px" alt="" />
          <Box>
            <Checkbox checked={selected?.type === "paystack"} />{" "}
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            width: "100%",
            alignItems: "center",
            marginTop: "40px",
            marginBottom: "20px",
          }}
        >
          <Box sx={{ textAlign: "start", flex: 1 }}>
            <Typography sx={{ color: "#020239", fontWeight: "500" }}>
              Direct Bank Transfer(manual funding)
            </Typography>
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            width: "100%",
            alignItems: "center",
            marginTop: "20px",
            marginBottom: "20px",
          }}
        ></Box>
        {banksforPayment.map((v, i) => (
          <Box
            key={i}
            sx={{
              justifyContent: "space-between",
              width: "100%",
              position: "relative",
              cursor: "pointer",
            }}
            onClick={() => setSelected(v)}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                padding: "20px 10px 20px 20px",
                border: "1px solid #DFDFDF",
                borderRadius: "20px",
                margin: "10px 0",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",

                  width: "100%",
                  textAlign: "left",
                }}
              >
                <Typography
                  sx={{
                    fontWeight: "700",
                  }}
                >
                  {v?.bank_name}
                </Typography>

                <Typography> {v?.account_number}</Typography>

                <Typography sx={{}}>{v?.bank_name}</Typography>
              </Box>
              <Box>
                <Checkbox checked={selected?.id === v?.id} />{" "}
              </Box>
            </Box>
          </Box>
        ))}
        <Box
          sx={{
            // position: "fixed",
            bottom: "6vh",
            // left: { xs: "0vw", sm: "260px" },
            width: {
              xs: "100vw",
              sm: "calc(100vw - 260px)",
              xl: "calc(100vw - 460px)",
            },
            // mt: 10,
            display: "flex",
            // justifyContent: "center",
          }}
        >
          <CustomButton
            sx={{
              padding: "13px 22px",
              backgroundColor: "#020239",
              borderRadius: "12px",
              width: { xs: "85vw", sm: "85vw", md: "400px", lg: "65%" },
              // width: '65%'
            }}
            disabled={!selected}
            onClick={handleOpen}
          >
            Continue
          </CustomButton>
        </Box>
      </Box>
    </Box>
  );
}

export default FundWallet;
