import { Tabs, Typography, Tab, Button, CircularProgress } from "@mui/material";
import { Box } from "@mui/system";
import React, { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import { useNotificationStore, useUserStore } from "../../state";
import UserProfile from "../../components/Profile/UserProfile";
import Banks from "../../components/Profile/Banks";
import Identity from "../../components/Profile/Identity";
import { Edit } from "@mui/icons-material";
import { useMutation } from "@tanstack/react-query";
import { updateAvatar } from "../../api/userApi";
import { useUser } from "../../hooks";
import RefereModal from "../../components/modals/RefereModal";

const CustomTab = styled(Tab)(({ theme }) => ({
  // color: theme.palette.success.main,
  fontSize: "19px",
  fontWeight: "500",
  textTransform: "capitalize",
  fontStyle: "normal",
  padding: "0px",
  "&:hover, &.Mui-focusVisible": {
    color: theme.palette.primary.main,
  },
  "&.Mui-selected": {
    fontWeight: "700",
  },
}));
function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

function TabHeader({ labels = [], value, handleChange }) {
  return (
    <Tabs
      value={value}
      onChange={handleChange}
      aria-label="basic tabs example"
      variant="fullWidth"
      sx={{ width: "100%", borderBottom: "1px solid #DFDFDF" }}
      indicatorColor="primary"
    >
      {labels.map((v, i) => (
        <CustomTab label={v} {...a11yProps(i)} />
      ))}
    </Tabs>
  );
}

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function Profile() {
  const [value, setValue] = useState(0);
  const [open, setOpen] = useState();

  const { avatar, fullname, referral_code } = useUserStore(
    (state) => state.user
  );
  const [image, setImage] = useState("");
  const [selectedFile, setSelectedFile] = useState();
  useEffect(() => {
    setImage(avatar);
  }, [avatar]);
  const displayNotificaton = useNotificationStore(
    (state) => state.displayNotificaton
  );

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const { fetchUser, isLoading: uisLoading } = useUser();

  const { isLoading, mutate } = useMutation(updateAvatar, {
    onSuccess: (res) => {
      displayNotificaton(res?.data?.message);
      fetchUser();
    },
    onError: (e) => {
      displayNotificaton(
        e.response?.data?.message ??
          e.message ??
          "something went wrong try again"
      );
    },
  });

  const handleImageChage = (e) => {
    const fb = new FormData();
    console.info({ e });
    fb.append("avatar", e, e.name);
    mutate(fb);

    // I've kept this example simple by using the first image instead of multiple
    setSelectedFile(e);
  };
  useEffect(() => {
    // create the preview
    if (!selectedFile) return;
    const objectUrl = URL.createObjectURL(selectedFile);
    console.info({ objectUrl });
    setImage(objectUrl);

    // free memory when ever this component is unmounted
    return () => URL.revokeObjectURL(objectUrl);
  }, [selectedFile]);

  const labels = ["Profile", "Banks", "Verification"];

  return (
    <div>
      <Box sx={{ position: "relative", height: "40vh" }}>
        {open && <RefereModal message={open} handleClose={() => setOpen()} />}
        <Box
          sx={{
            backgroundImage:
              "linear-gradient(to right, white, rgb(248, 227, 187),rgb(233, 204, 209) , white)",
            height: "40%",
          }}
        ></Box>
        <Box
          sx={{
            height: "60%",
          }}
        ></Box>
        <Box
          sx={{
            position: "absolute",
            top: "0",
            left: "0",
            height: "100%",
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <Box
            sx={{
              width: "120px",
              height: "120px",
              borderRadius: "20px",
              border: "1px solid #020342",
              padding: "2px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              position: "relative",
            }}
          >
            {image && (
              <img
                alt="profile"
                style={{
                  width: "119px",
                  height: "119px",
                  borderRadius: "20px",
                  objectFit: "fill",
                }}
                src={image}
              />
            )}
            {(isLoading || uisLoading) && <CircularProgress />}
            <label
              for="image"
              style={{
                position: "absolute",
                bottom: "-10px",
                right: "-30px",
                zIndex: "2",
                cursor: "pointer",
              }}
            >
              <Edit color="black" />
            </label>
          </Box>
          <input
            type="file"
            name="image"
            id="image"
            style={{
              width: "1px",
              height: "1px",
            }}
            onChange={(e) => handleImageChage(e.target.files[0])}
          />
          <span style={{ display: "flex" }}>
            <Typography sx={{ fontWeight: "700", fontSize: "19px" }}>
              {fullname && fullname?.split(" ")[0]}
            </Typography>
            <Typography sx={{ fontWeight: "300", fontSize: "19px" }}>
              {fullname && fullname?.split(" ")[1]}
            </Typography>
          </span>
          {/* <span style={{ display: "flex" }}>
            <Typography
              sx={{ fontWeight: "500", fontSize: "13px", color: "#969697" }}
            >
              Zainnest Account:
            </Typography>
            <Typography sx={{ fontWeight: "300", fontSize: "13px" }}>
              0023417899
            </Typography>
          </span> */}
          <Button
            sx={{
              // width: "137px",
              height: "45px",
              fontWeight: "500",
              padding: "16px 60px",
              borderRadius: "10px",
              marginTop: "10px",
            }}
            variant="outlined"
            onClick={() => setOpen(referral_code)}
          >
            Refer & Earn
          </Button>
        </Box>
      </Box>
      <TabHeader labels={labels} value={value} handleChange={handleChange} />

      <TabPanel value={value} index={0}>
        <UserProfile />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <Banks />
      </TabPanel>
      <TabPanel value={value} index={2}>
        <Identity />
      </TabPanel>
    </div>
  );
}

export default Profile;
