import { Box } from "@mui/system";
import React, { useState } from "react";

import Mtn from "../../icons/mtn.png";
import Airtel from "../../icons/airtel.png";
import Etisalat from "../../icons/etisalat.png";
import Glo from "../../icons/glo.png";
import LabeledTextField from "../LabeledTextField";
import CustomButton from "../Button";

import * as yup from "yup";
import { useFormik } from "formik";
// import { phoneRegExp } from "../../helpers";
import { Typography } from "@mui/material";
import Confirm from "../modals/Confirm";
import LabeledSelectField from "../LabeledSelectField";
import { useMutation, useQuery } from "@tanstack/react-query";
import { initiateAirtime, getTransactions } from "../../api/userApi";
import Success from "../modals/Success";
import Failure from "../modals/Failure";
import { useUser } from "../../hooks";

function Airtime() {
  const [selectedNetwork, setselectedNetwork] = useState(null);
  const [showConfirm, setShowConfirm] = useState(false);
  const [success, setSuccess] = useState(false);
  const [failure, setFailure] = useState(false);

  const { refetch } = useQuery(["transactions"], getTransactions);
  const { fetchUser } = useUser();

  const { isLoading, mutate } = useMutation(initiateAirtime, {
    onSuccess: (res) => {
      refetch();
      fetchUser();

      if (res?.data?.status) setSuccess(res?.data?.message);
      else setFailure(res?.data?.message);
      setselectedNetwork();
      formik.resetForm();
      handleConfirm();
    },
    onError: (e) => {
      setFailure(
        e.response?.data?.message ??
          e.message ??
          "something went wrong try again"
      );
      // displayNotificaton(
      //   e.response?.data?.message ??
      //     e.message ??
      //     "something went wrong try again"
      // );
    },
  });

  const networks = [
    {
      value: "MTN",
      img: Mtn,
    },
    {
      value: "AIRTEL",
      img: Airtel,
    },
    {
      value: "ETISALAT",
      img: Etisalat,
    },
    {
      value: "GLO",
      img: Glo,
    },
  ];

  const prices = [
    { label: "N50", value: "50" },
    { label: "N100", value: "100" },
    { label: "N200", value: "200" },
    { label: "N500", value: "500" },
    { label: "N1000", value: "1000", lg: true },
    { label: "N2000", value: "2000", lg: true },
  ];

  const initialValues = {
    phones: "",
    amount: "",
    network: "",
    type: "",
  };
  let validationSchema = yup.object({
    phones: yup
      .string()
      // .test("len", "Phone number is not valid", (val) => val?.length === 11)
      .required("this field is required"),
    // .matches(phoneRegExp, "Phone number is not valid"),

    amount: yup
      .number()
      .min(50, "enter an amount greater than N50")
      .required("this field is required"),
    network: yup.string().required("select a network"),
    type: yup.string().required("this field is required"),
  });

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    onSubmit: async () => {
      handleConfirm();
    },
  });

  const handleClick = (v) => {
    setselectedNetwork(v);
    formik.values.network = v;
  };
  const handleConfirm = (v) => {
    setShowConfirm(!showConfirm);
  };

  const buy = (pin) => {
    mutate({
      ...formik.values,
      pin,
    });
  };

  return (
    <Box sx={{ mb: 5 }}>
      <Box>
        {showConfirm && (
          <Confirm
            message={`confirm airtime purchase of ${formik.values.network} ${formik.values.amount} to ${formik.values.phones} `}
            edit={handleConfirm}
            proceed={buy}
            loading={isLoading}
          />
        )}
        {success && (
          <Success message={success} handleClose={() => setSuccess()} />
        )}
        {failure && (
          <Failure message={failure} handleClose={() => setFailure()} />
        )}
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          {networks.map((v, i) => (
            <Box
              sx={{
                width: { xs: "23%", md: "100px" },
                // height: { xs: "20%", md: "23%", lg: "100px" },
                borderRadius: "10px",

                cursor: "pointer",
                padding: "1px",
                border:
                  selectedNetwork === v.value
                    ? "2px solid black"
                    : "1px solid lightgrey",
              }}
              onClick={() => handleClick(v.value)}
            >
              <img
                style={{
                  objectFit: "cover",
                  // margin: "-20px -20px 0 0",
                  width: "99%",
                  height: "99%",
                  borderRadius: "10px",
                }}
                src={v.img}
                alt={v.value}
              />
            </Box>
          ))}
        </Box>
        {formik.touched.network && Boolean(formik.errors.network) && (
          <Typography sx={{ color: "red", fontSize: "11px" }}>
            {formik.errors.network}
          </Typography>
        )}
        <Box sx={{ mt: 3 }}>
          <form onSubmit={formik.handleSubmit}>
            <Box sx={{ mb: 4 }}>
              <LabeledSelectField
                label="Type"
                id="type"
                name="type"
                value={formik.values.type}
                onChange={formik.handleChange}
                error={formik.touched.type && Boolean(formik.errors.type)}
                helperText={formik.touched.type && formik.errors.type}
                fullWidth
                variant="outlined"
                data={[
                  // { label: "SAS", value: "SAS" },
                  { label: "VTU", value: "VTU" },
                ]}
              />
            </Box>
            <Box sx={{ mb: 2 }}>
              <LabeledTextField
                label="Phone Number(s)"
                id="phones"
                name="phones"
                value={formik.values.phones}
                onChange={(e) =>
                  formik.setFieldValue("phones", e.target.value.trim())
                }
                error={formik.touched.phones && Boolean(formik.errors.phones)}
                helperText={formik.touched.phones && formik.errors.phones}
                fullWidth
                variant="outlined"
                size="small"
                hint="You can add multiple numbers seperated by a comma. Ex: 08030302020,08030302020 "
                placeholder="Ex: 08030302020"
              />
            </Box>
            <Box sx={{ display: { xs: "none", md: "block" } }}>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  width: "100%",
                  mb: "10px",
                }}
              >
                {prices?.slice(0, 3).map((v, i) => (
                  <Box
                    sx={{
                      padding: { xs: "10px 25px", md: "15px 50px" },
                      borderRadius: "8px",
                      backgroundColor: "#E6E6EF",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      // margin: "5px",
                      width: "min(143px , 23%)",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      formik.setFieldValue("amount", v.value);
                    }}
                  >
                    {v.label}
                  </Box>
                ))}
              </Box>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  width: "100%",
                  mb: "10px",
                }}
              >
                {prices?.slice(3, 6).map((v, i) => (
                  <Box
                    sx={{
                      padding: { xs: "10px 25px", md: "15px 50px" },
                      borderRadius: "8px",
                      backgroundColor: "#E6E6EF",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      // margin: "5px",
                      width: "min(143px , 23%)",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      formik.setFieldValue("amount", v.value);
                    }}
                  >
                    {v.label}
                  </Box>
                ))}
              </Box>
            </Box>
            <Box
              sx={{
                display: { xs: "flex", md: "none" },
                justifyContent: "space-between",
                width: "100%",
                mb: "10px",
              }}
            >
              {prices
                .filter((v) => !v.lg)
                .map((v, i) => (
                  <Box
                    sx={{
                      padding: { xs: "10px 25px", md: "15px 50px" },
                      borderRadius: "8px",
                      backgroundColor: "#E6E6EF",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      // margin: "5px",
                      width: "min(78px , 23%)",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      formik.setFieldValue("amount", v.value);
                    }}
                  >
                    {v.label}
                  </Box>
                ))}
            </Box>
            <Box sx={{ mb: 4 }}>
              <LabeledTextField
                label="Amount"
                id="amount"
                name="amount"
                value={formik.values.amount}
                onChange={formik.handleChange}
                error={formik.touched.amount && Boolean(formik.errors.amount)}
                helperText={formik.touched.amount && formik.errors.amount}
                fullWidth
                variant="outlined"
              />
            </Box>
            <Box sx={{ mb: 10 }}>
              <CustomButton loading={isLoading} type="submit">
                Continue
              </CustomButton>
            </Box>
          </form>
        </Box>
      </Box>
    </Box>
  );
}

export default Airtime;
