/* eslint-disable eqeqeq */
import React from "react";
import { CircularProgress, Divider, Switch, Typography } from "@mui/material";

import { Box } from "@mui/system";
import { useTheme } from "@mui/material/styles";
import Grid from "@mui/material/Unstable_Grid2";
import { styled } from "@mui/system";
import { useMutation, useQuery } from "@tanstack/react-query";
import { getServices, updateServices } from "../../api/adminApi";
import Mtn from "../../icons/mtn.png";
import Airtel from "../../icons/airtel.png";
import Etisalat from "../../icons/etisalat.png";
import Glo from "../../icons/glo.png";
import { useNotificationStore } from "../../state";
import { BoltIcon, TvIcon } from "@heroicons/react/24/outline";

export const FlexBox = styled(Box)({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  padding: "5px",
  background: "#FFFFFA",
  borderRadius: "20px",
  margin: "5px",
});

function Maintenance(props) {
  const theme = useTheme();

  const { data, isLoading, refetch } = useQuery(["services"], getServices);
  console.info(data?.data?.data);
  const providers = data?.data?.data || [];

  const networks = {
    mtn: Mtn,
    airtel: Airtel,
    glo: Glo,
    etisalat: Etisalat,
    cable: <TvIcon style={{ width: "50%", color: "#020239" }} />,
    electricity: <BoltIcon style={{ width: "50%", color: "#020239" }} />,
  };

  const displayNotificaton = useNotificationStore(
    (state) => state.displayNotificaton
  );

  const { mutate } = useMutation(updateServices, {
    onSuccess: (res) => {
      displayNotificaton("Service updated");
      refetch();
    },
    onError: (e) => {
      displayNotificaton(e.message);
    },
  });

  const handleChange = (service) => {
    const { id, status } = service;
    mutate({ id, data: { status: status == 0 ? 1 : 0 } });
  };

  return (
    <div>
      <Box sx={{ display: "flex" }}>
        <Typography variant="header" color={theme.palette.neutral.N200}>
          Maintenance
        </Typography>
      </Box>

      {isLoading ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            minHeight: "40vh",
            alignContent: "center",
            marginTop: "10vh",
          }}
        >
          <CircularProgress />
        </Box>
      ) : (
        <Grid container spacing={2} mt={4}>
          <Grid xs={12} lg={6} sx={{ padding: "30px" }}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "start",
                color: "#969697",
                paddingLeft: "20px",
              }}
            >
              <Typography>Airtime</Typography>
            </Box>
            <Divider />
            <Box
              sx={{
                mt: 5,
                minHeight: "60vh",
                width: { xs: "100%", md: "50vw", lg: "30vw" },
              }}
            >
              {providers
                ?.filter((v) => v.type === "airtime")
                .map((service, i) => {
                  const name = service.name?.split("_")?.shift();

                  return (
                    <FlexBox key={{ i }}>
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          padding: "5px",
                        }}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            padding: "5px",
                          }}
                        >
                          <img
                            style={{
                              width: "40px",
                              height: "40px",
                              borderRadius: "5px",
                            }}
                            src={networks[name]}
                            alt={name}
                          />
                        </Box>
                        <Typography
                          variant="body1"
                          sx={{ color: "rgba(164, 169, 171, 1)" }}
                        >
                          {name}
                        </Typography>
                      </Box>
                      <Switch
                        // disabled={loading}
                        checked={service?.status == "1"}
                        // sx={{ color: primary }}
                        onChange={() => handleChange(service)}
                        inputProps={{ "aria-label": "controlled" }}
                      />
                    </FlexBox>
                  );
                })}
            </Box>
          </Grid>
          <Grid xs={12} lg={6} sx={{ padding: "30px" }}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "start",
                color: "#969697",
                paddingLeft: "20px",
              }}
            >
              <Typography>Data</Typography>
            </Box>
            <Divider />
            <Box
              sx={{
                mt: 5,
                minHeight: "60vh",
                width: { xs: "100%", md: "50vw", lg: "30vw" },
              }}
            >
              {providers
                ?.filter((v) => v.type === "data")
                .map((service, i) => {
                  const name = service.name?.split("_")?.shift();

                  return (
                    <FlexBox key={{ i }}>
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          padding: "5px",
                        }}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            padding: "5px",
                          }}
                        >
                          <img
                            style={{
                              width: "40px",
                              height: "40px",
                              borderRadius: "5px",
                            }}
                            src={networks[name]}
                            alt={name}
                          />
                        </Box>
                        <Typography
                          variant="body1"
                          sx={{ color: "rgba(164, 169, 171, 1)" }}
                        >
                          {name}
                        </Typography>
                      </Box>
                      <Switch
                        // disabled={loading}
                        checked={service?.status == "1"}
                        // sx={{ color: primary }}
                        onChange={() => handleChange(service)}
                        inputProps={{ "aria-label": "controlled" }}
                      />
                    </FlexBox>
                  );
                })}
            </Box>
          </Grid>
          <Grid xs={12} lg={6} sx={{ padding: "30px" }}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "start",
                color: "#969697",
                paddingLeft: "20px",
              }}
            >
              <Typography>Utility</Typography>
            </Box>
            <Divider />
            <Box
              sx={{
                mt: 5,
                minHeight: "60vh",
                width: { xs: "100%", md: "50vw", lg: "30vw" },
              }}
            >
              {providers
                ?.filter((v) => v.type === "utility")
                .map((service, i) => {
                  const name = service.name?.split("_")?.shift();

                  return (
                    <FlexBox key={{ i }}>
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          padding: "5px",
                        }}
                      >
                        <Box
                          sx={{
                            borderRadius: "5px",
                            padding: "5px",
                            display: "flex",
                            justifyContent: "center",
                            flexDirection: "column",
                            alignItems: "center",
                            width: {
                              xs: "40px",
                            },
                            height: {
                              xs: "40px",
                            },
                            backgroundColor: "#E6E6EF",
                            cursor: "pointer",
                          }}
                        >
                          {networks[name]}
                        </Box>
                        <Typography
                          variant="body1"
                          sx={{
                            color: "rgba(164, 169, 171, 1)",
                            padding: "5px",
                          }}
                        >
                          {name}
                        </Typography>
                      </Box>
                      <Switch
                        // disabled={loading}
                        checked={service?.status == "1"}
                        // sx={{ color: primary }}
                        onChange={() => handleChange(service)}
                        inputProps={{ "aria-label": "controlled" }}
                      />
                    </FlexBox>
                  );
                })}
            </Box>
          </Grid>
        </Grid>
      )}
    </div>
  );
}

export default Maintenance;
