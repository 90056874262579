/* eslint-disable eqeqeq */
import { CallMadeOutlined, CallReceivedOutlined } from "@mui/icons-material";
import { Typography } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import { formatter } from "../../helpers";

function TransactionCard({ transaction }) {
  const { amount, phone, description, created_at, status, type } = transaction;

  return (
    <Box sx={{ display: "flex", justifyContent: "center", padding: "20px 0" }}>
      <Box sx={{ pr: 2 }}>
        <Box
          sx={{
            width: "54px",
            height: "54px",
            borderRadius: "50%",
            backgroundColor: status === "success" ? "#E6F0EA" : "#FFE6E6",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {status === "success" ? (
            <CallMadeOutlined
              sx={{
                width: "50%",
                color: "#00421B",
              }}
            />
          ) : (
            <CallReceivedOutlined
              sx={{
                width: "50%",
                color: "#9C0000",
              }}
            />
          )}
        </Box>
      </Box>
      <Box
        sx={{
          flexGrow: 1,
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
          justifyContent: "space-evenly",
          textAlign: "start",
        }}
      >
        <Typography>{description} </Typography>
        {type != "wallet" && (
          <Box
            sx={{ color: "#2B2B2C", display: "flex", alignItems: "flex-end" }}
          >
            <Typography
              sx={{ fontSize: "13px", fontWeight: "300", lineHeight: 1 }}
            >
              sent to,
            </Typography>
            <Typography sx={{ fontSize: "16px", lineHeight: 1 }}>
              {" "}
              {phone?.data}
            </Typography>
          </Box>
        )}
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-evenly",
        }}
      >
        <Typography sx={{ color: "#2B2B2C", lineHeight: 1 }}>
          N {formatter.format(amount)}
        </Typography>
        <Typography
          sx={{
            fontSize: "13px",
            fontWeight: "300",
            color: "#9D9D9D",
            lineHeight: 1,
          }}
        >
          {created_at?.substring(0, 16)}
        </Typography>
      </Box>
    </Box>
  );
}

export default TransactionCard;
