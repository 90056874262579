import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import MenuIcon from "@mui/icons-material/Menu";
import Toolbar from "@mui/material/Toolbar";

import { ReactComponent as MessageIicon } from "../icons/message.svg";
import { Button, Collapse, Menu, MenuItem } from "@mui/material";
import { ArrowForwardIosOutlined, ExpandMore } from "@mui/icons-material";
import {
  HomeIcon,
  PaperAirplaneIcon,
  UserGroupIcon,
  UserIcon,
  ClockIcon,
} from "@heroicons/react/24/outline";
import {
  NavLink,
  useLocation,
  Outlet,
  Navigate,
  useNavigate,
} from "react-router-dom";
import "./sidebar.css";
import { useNotificationStore, useUserStore } from "../state";
import { useMutation } from "@tanstack/react-query";
import { logOut } from "../api/authApi";
import StorageService from "../StorageService";
import Notification from "./Notification";
import CreateMessage from "./modals/CreateMessage";
import Logo from "../../src/icons/Logo.png";

const drawerWidth = 260;

const sidebarTopItems = [
  {
    label: "Dashboard",
    icon: <HomeIcon style={{ width: "20px" }} />,
    route: "dashboard",
  },
  {
    label: "Services",
    icon: <PaperAirplaneIcon style={{ width: "20px" }} />,
    route: "services",
  },
  // {
  //   label: "Plans",
  //   icon: <QueueListIcon style={{ width: "20px" }} />,
  //   route: "plans",
  // },
  {
    label: "Resellers",
    icon: <UserIcon style={{ width: "20px" }} />,
    icon2: (
      <ArrowForwardIosOutlined
        style={{ width: "16px", marginRight: "-10px" }}
      />
    ),
    route: "resellers",
    sublinks: [
      {
        label: "All Resellers",
        route: "all",
      },
      {
        label: "Groups",
        route: "groups",
      },
    ],
  },
  {
    label: "Customers",
    icon: <UserGroupIcon style={{ width: "20px" }} />,
    route: "customers",
  },
  {
    label: "Transactions",
    icon: <ClockIcon style={{ width: "20px" }} />,
    route: "transactions",
  },
];

const sidebarBottomItems = [
  {
    label: "Discounts",
    // icon: <PaperAirplaneIcon style={{ width: "20px" }} />,
    route: "discounts",
  },
  {
    label: "Banks",
    // icon: <HomeIcon style={{ width: "20px" }} />,
    route: "banks",
  },
  {
    label: "Withdrawals",
    // icon: <HomeIcon style={{ width: "20px" }} />,
    route: "withdrawals",
  },
  {
    label: "Providers",
    // icon: <HomeIcon style={{ width: "20px" }} />,
    route: "providers",
  },
  {
    label: "LeaderBoard",
    // icon: <HomeIcon style={{ width: "20px" }} />,
    route: "leaderboard",
  },
];

function AdminLayout({ window }) {
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [webOpen] = React.useState(true);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const navigate = useNavigate();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const location = useLocation();
  const { pathname } = location;

  const drawer = (
    <div>
      <Toolbar sx={{ height: 100 }} />
      <List sx={{ padding: "10px" }}>
        {sidebarTopItems.map((item, index) =>
          item.sublinks?.length > 0 ? (
            <ListItem
              key={index}
              disablePadding
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
                // width: "calc(100% - 20px)",
              }}
              className={pathname.includes(item.route) ? "active" : ""}
            >
              {" "}
              <NavLink to={`${item.route}/${item.sublinks[0].route}`} end>
                <ListItemButton
                  className={
                    pathname.includes(item.route) ? "active" : "sidebar-links"
                  }
                  sx={{
                    height: "60px",
                    paddingLeft: "30px",
                    width: "100%",
                  }}
                >
                  <ListItemIcon sx={{ minWidth: 40 }}>{item.icon}</ListItemIcon>
                  <ListItemText primary={item.label} />
                  {/* <ListItemIcon
                    sx={{
                      minWidth: 40,
                      "& .MuiListItemIcon-root": {
                        marginLeft: "10px",
                      },
                    }}
                  > */}
                  {item.icon2}
                  {/* </ListItemIcon> */}
                </ListItemButton>
              </NavLink>
              <Collapse
                sx={{ marginLeft: "40px" }}
                in={pathname.includes(item.route)}
              >
                {item.sublinks.map((k, i) => (
                  <NavLink to={`${item.route}/${k.route}`} end>
                    <Box
                      className={"sublink"}
                      sx={{ padding: "10px 0", fontWeight: "400" }}
                    >
                      {k.label}
                    </Box>
                  </NavLink>
                ))}
              </Collapse>
            </ListItem>
          ) : (
            <ListItem key={index} disablePadding>
              <NavLink to={`${item.route}`} end>
                <ListItemButton
                  className={`${
                    pathname === `/${item.route}` ? "active" : "sidebar-links"
                  } `}
                  sx={{ height: "60px", paddingLeft: "30px" }}
                >
                  <ListItemIcon sx={{ minWidth: 40 }}>{item.icon}</ListItemIcon>
                  <ListItemText
                    // sx={{ color: pathname === item.route ? "black" : "" }}
                    primary={item.label}
                  />
                </ListItemButton>
              </NavLink>
            </ListItem>
          )
        )}
      </List>
      <Divider />
      <List>
        {sidebarBottomItems.map((val, index) => (
          <ListItem key={index} disablePadding>
            <NavLink to={`${val.route}`}>
              <ListItemButton
                className={`${
                  pathname === `/${val.route}` ? "active" : "sidebar-links"
                } `}
                sx={{ height: "60px", paddingLeft: "30px" }}
              >
                <ListItemText sx={{ color: "" }} primary={val.label} />
              </ListItemButton>
            </NavLink>
          </ListItem>
        ))}
      </List>
    </div>
  );

  const container =
    window !== undefined ? () => window().document.body : undefined;

  const loggedIn = useUserStore((state) => state.loggedIn);
  const user = useUserStore((state) => state.user);
  const logoutUser = useUserStore((state) => state.logoutUser);

  const [messageOpen, setMessageOpen] = useState("");

  const { open: notificationOpen, message } = useNotificationStore(
    (state) => state
  );

  const { mutate } = useMutation(logOut, {
    onSuccess: (res) => {
      StorageService.clearToken();
      logoutUser();
    },
    onError: (e) => {
      logoutUser();

      // alert(
      //   e.response?.data?.message ??
      //     e.message ??
      //     "something went wrong try again"
      // );
    },
  });

  const logUserOut = () => {
    handleClose();
    mutate();
  };

  // to close  the nav menu on page navigation
  useEffect(() => {
    setMobileOpen(false);
  }, [pathname]);

  if (!loggedIn) return <Navigate to="/signin" replace />;
  if (user?.email_verified === false) return <Navigate to="/otp" />;
  if (user?.role !== "admin") return <Navigate to="/user/dashboard" replace />;

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      {notificationOpen && <Notification message={message} />}
      {messageOpen && (
        <CreateMessage handleOpen={() => setMessageOpen(false)} />
      )}
      {/* mobile */}

      <AppBar
        position="fixed"
        sx={{
          width: { sm: `calc(100% - ${mobileOpen ? drawerWidth : 0}px)` },
          ml: { sm: `${drawerWidth}px` },
          backgroundColor: "white",
          boxShadow: "none",
          borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
          display: { xs: "block", sm: "none" },
        }}
      >
        <Toolbar sx={{ display: "flex" }}>
          <IconButton
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { sm: "block", color: "black" } }}
          >
            <MenuIcon />
          </IconButton>
          <img
            onClick={() => navigate("/")}
            style={{ cursor: "pointer" }}
            src={Logo}
            height="40px"
            alt=""
          />
          <Box
            sx={{
              marginLeft: "auto",
              color: "black",
              width: "120px",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <MessageIicon
              onClick={() => setMessageOpen(true)}
              style={{
                cursor: "pointer",
              }}
            />
            <Box>
              <Button>
                <Box
                  sx={{
                    width: "32px",
                    height: "32px",
                    borderRadius: "8px",
                    backgroundColor: "aqua",
                  }}
                >
                  <img
                    style={{
                      objectFit: "fill",
                      width: "32px",
                      height: "32px",
                      // borderRadius: "8px",
                    }}
                    src={user?.avatar}
                    alt={user?.fullname?.substring(0, 2) ?? ""}
                  />
                </Box>
                <ExpandMore
                  id="basic-button"
                  aria-controls={open ? "basic-menu" : undefined}
                  aria-haspopup="true"
                  aria-expanded={open ? "true" : undefined}
                  onClick={handleClick}
                />
              </Button>
              <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                  "aria-labelledby": "basic-button",
                }}
              >
                <MenuItem
                  onClick={() => {
                    handleClose();
                    navigate("/admin/profile");
                  }}
                >
                  Profile
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    navigate("/admin/maintenance");
                    handleClose();
                  }}
                >
                  Maintenance
                </MenuItem>
                <MenuItem onClick={logUserOut}>Logout</MenuItem>
              </Menu>
            </Box>
          </Box>
        </Toolbar>
      </AppBar>
      <Box
        component="nav"
        sx={{
          width: { xs: 0, sm: mobileOpen ? drawerWidth : 0 },
          flexShrink: { sm: 0 },
        }}
        aria-label="mailbox folders"
      >
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: "block", sm: "none" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
        >
          {drawer}
        </Drawer>
      </Box>
      <Box
        component="main"
        sx={{
          display: { xs: "block", sm: "none" },
          flexGrow: 1,
          p: webOpen ? "20px" : 0,
          // pt: 3,
          width: "100%",
          height: "100vh ",
        }}
      >
        <Toolbar />
        <Outlet />
      </Box>

      {/* desktop */}
      <AppBar
        position="fixed"
        sx={{
          width: { sm: `calc(100% - ${webOpen ? drawerWidth : 0}px)` },
          ml: { sm: `${drawerWidth}px` },
          backgroundColor: "white",
          boxShadow: "none",
          borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
          display: { xs: "none", sm: "block" },
        }}
      >
        <Toolbar sx={{ display: "flex" }}>
          <IconButton
            aria-label="open drawer"
            edge="start"
            // onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { sm: "block", color: "black" } }}
          >
            <MenuIcon />
          </IconButton>
          <img
            onClick={() => navigate("/")}
            style={{ cursor: "pointer" }}
            src={Logo}
            height="40px"
            alt=""
          />
          <Box
            sx={{
              marginLeft: "auto",
              color: "black",
              width: "120px",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <MessageIicon
              onClick={() => setMessageOpen(true)}
              style={{
                cursor: "pointer",
              }}
            />
            <Box>
              <Button>
                <Box
                  sx={{
                    width: "32px",
                    height: "32px",
                    borderRadius: "8px",
                    backgroundColor: "aqua",
                  }}
                >
                  <img
                    style={{
                      objectFit: "fill",
                      width: "32px",
                      height: "32px",
                      borderRadius: "8px",
                    }}
                    src={user?.avatar}
                    alt={user?.fullname?.substring(0, 2) ?? ""}
                  />
                </Box>
                <ExpandMore
                  id="basic-button"
                  aria-controls={open ? "basic-menu" : undefined}
                  aria-haspopup="true"
                  aria-expanded={open ? "true" : undefined}
                  onClick={handleClick}
                />
              </Button>
              <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                  "aria-labelledby": "basic-button",
                }}
              >
                <MenuItem
                  onClick={() => {
                    handleClose();
                    navigate("/admin/profile");
                  }}
                >
                  Profile
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    navigate("/admin/maintenance");
                    handleClose();
                  }}
                >
                  Maintenance
                </MenuItem>
                <MenuItem onClick={logUserOut}>Logout</MenuItem>
              </Menu>
            </Box>
          </Box>
        </Toolbar>
      </AppBar>
      <Box
        component="nav"
        sx={{
          width: { xs: 0, sm: webOpen ? drawerWidth : 0 },
          flexShrink: { sm: 0 },
        }}
        aria-label="mailbox folders"
      >
        <Drawer
          variant="persistent"
          sx={{
            display: { xs: "none", sm: "block" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
          open={webOpen}
        >
          {drawer}
        </Drawer>
      </Box>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          display: { xs: "none", sm: "block" },
          p: webOpen ? "20px" : 0,
          // pt: 3,
          width: "100%",
          height: "100vh ",
        }}
      >
        <Toolbar />
        <Outlet />
      </Box>
    </Box>
  );
}

AdminLayout.propTypes = {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
};

export default AdminLayout;
