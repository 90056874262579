export const constants = {};

export const roles = {
  USER: "user",
  RESELLER: "reseller",
  ADMIN: "admin",
};

export const phoneRegExp =
  /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

export const formatter = new Intl.NumberFormat(undefined, {
  // style: 'currency',
  // currency: '',
  minimumFractionDigits: 1,
  maximumFractionDigits: 1,
});
