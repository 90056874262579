

// function LeaderBoard() {
//   const theme = useTheme();
//   const { data } = useQuery(["leaderboard"], getLeaderboard, {
//     refetchOnWindowFocus: false,
//   });

//   const leaderboard = data?.data?.data || [];

//   return (
//     <Box
//       sx={{
//         display: "flex",
//         flexDirection: "column",
//         alignItems: "flex-start",
//         padding: "0 20px",
//       }}
//     >
//       <Typography
//         sx={{ display: { xs: "none", sm: "block" } }}
//         color={theme.palette.neutral.N200}
//         variant="header"
//       >
//         LeaderBoard
//       </Typography>
//       <Box
//         sx={{
//           padding: { sm: "20px" },
//           width: {
//             xs: "100%",
//             lg: "70%",
//             xl: "60%",
//           },
//           border: {
//             sm: "1px solid #e6e6ef",
//           },
//           borderRadius: "20px",
//           mt: { xs: 0, sm: 5 },
//           minHeight: "70vh",
//         }}
//       >
//         <Box
//           sx={{
//             display: "flex",
//             justifyContent: "space-between",
//             mb: 2,
//             alignItems: "center",
//           }}
//         >
//           <Typography
//             sx={{ fontSize: "16px", color: "#808080", fontWeight: "500" }}
//           >
//             LeaderBoard
//           </Typography>
//         </Box>

//         <Box sx={{ mt: 1 }}>
//           <Divider sx={{ xs: "block", md: "none", margin: 3 }} />
//           <Box
//             sx={{
//               width: "100%",
//               display: "flex",
//               justifyContent: "space-between",
//               flexDirection: "column",
//             }}
//           >
//             {leaderboard.map((v, i) => (
//               <LeaderBoardCard
//                 name={v.username}
//                 position={i + 1}
//                 amount={v.total_amount}
//                 image={v.avatar}
//               />
//             ))}
//           </Box>
//         </Box>
//       </Box>
//     </Box>
//   );
// }

// function LeaderBoardCard({ name, position, amount, image }) {
//   const theme = useTheme();
//   const colors = {
//     1: "gold",
//     2: "silver",
//     3: "#CD7F32",
//   };

//   return (
//     <Box
//       sx={{
//         width: {
//           xs: "80%",
//           md: "90%",
//         },
//         height: "100px",
//         display: "flex",
//         alignItems: "center",
//         padding: "22px 20px",
//         margin: "5px",
//         justifyContent: "space-between",
//       }}
//     >
//       <Box
//         sx={{
//           width: "65px",
//           minWidth: "65px",
//           height: "65px",
//           backgroundColor: colors[position] || "white",
//           borderRadius: "20px",
//           margin: "5px",
//           display: "flex",
//           alignItems: "center",
//           justifyContent: "center",
//           fontSize: "20px",
//           color: colors[position] ? "white" : "grey",
//           border: colors[position] ? "none" : "1px solid grey",
//           textAlign: "center",
//         }}
//       >
//         {position}
//       </Box>
//       <Box
//         sx={{
//           minWidth: {
//             xs: "100%",
//             md: "90%",
//           },
//           height: "90px",
//           display: "flex",
//           alignItems: "center",
//           padding: "22px 20px",
//           border: "1px solid #DFDFDF",
//           borderRadius: "20px",
//           justifyContent: "space-between",
//           margin: "5px",
//         }}
//       >
//         <Box
//           sx={{
//             display: "flex",
//             alignItems: "center",
//             width: "100%",
//             justifyContent: "space-between",
//           }}
//         >
//           <Box
//             sx={{
//               width: "60px",
//               height: "60px",
//               backgroundColor: "aqua",
//               borderRadius: "50%",
//               margin: "5px",
//               display: "flex",
//               alignItems: "center",
//               justifyContent: "center",
//               fontSize: "20px",
//               color: "white",
//               textAlign: "center",
//               minWidth: "60px",
//             }}
//           >
//             {image && (
//               <img
//                 src={image}
//                 style={{
//                   width: "100%",
//                   height: "60px",
//                   borderRadius: "50%",
//                 }}
//               />
//             )}
//           </Box>
//           <Box
//             sx={{
//               display: "flex",
//               justifyContent: "space-between",
//               width: "100%",
//               flexDirection: {
//                 xs: "column",
//                 md: "row",
//               },
//               overflow: "hidden",
//               whiteSpace: "nowrap",
//               textOverflow: "ellipsis",
//             }}
//           >
//             <Typography
//               sx={{
//                 fontWeight: "700",
//                 lineHeight: "120%",
//                 fontSize: {
//                   xs: 12,
//                   sm: 14,
//                   md: 20,
//                 },
//                 color: theme.palette.primary.main,
//                 overflow: "hidden",
//                 whiteSpace: "nowrap",
//                 textOverflow: "ellipsis",
//               }}
//             >
//               {name}
//             </Typography>

//             <Typography
//               sx={{
//                 color: "#020239",
//                 fontSize: {
//                   xs: 10,
//                   sm: 12,
//                   md: 14,
//                 },
//                 fontWeight: "500",
//               }}
//             >
//               ₦{formatter.format(amount)}
//             </Typography>
//           </Box>
//         </Box>
//       </Box>
//     </Box>
//   );
// }

/* eslint-disable eqeqeq */
import React, { useState } from "react";
import { Box } from "@mui/system";
import { useQuery } from "@tanstack/react-query";
import { formatter } from "../../helpers";
import { getLeaderboard } from "../../api/coreApi";
import { Breadcrumbs, Typography, Divider } from "@mui/material";
import {
  NavigateNextOutlined,
} from "@mui/icons-material";
import { useTheme } from "@mui/material/styles";
import TabHeader from "../../components/TabHeader";
import Grid from "@mui/material/Unstable_Grid2";
import "../index.css";
import LeaderBoardCard from "../../components/LeaderBoardCard";
import StorageService from "../../StorageService";

function TabPanel(props) {
    const theme = useTheme();
    const { data } = useQuery(["leaderboard"], getLeaderboard(), {
      refetchOnWindowFocus: false,
    });

    const leaderboard = data?.data?.data || [];
    // console.log(leaderboard)

  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function LeaderBoard(props) {
  const theme = useTheme();
  const [value, setValue] = useState(0);
  const [firstPage, setFirstPage] = useState(true);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [anchorEl2, setAnchorEl2] = React.useState(null);
  const [leaderboardData, setleaderboardData] = React.useState([]);
  const open = Boolean(anchorEl);
  const open2 = Boolean(anchorEl2);
  let token = StorageService.getAccessToken();
  
  const [target, setTarget] = useState();

  React.useEffect(()=>{
    if(firstPage === true){
      fetch(`https://api.zainnest.com/api/leaderboard?type=weekly`,{
        headers:{
          Accept: "application/json",
          "Content-Type": "application/json",
          "Authorization": "Bearer "+ token
        },
      }).then((res)=>res.json()).then((result)=> {
        setleaderboardData(result.data);
        setFirstPage(false);
      });
    }else{
      console.log('not just arrived new page')
    }
  },[firstPage, leaderboardData, token])

  const handleChange = (event, newValue) => {
    setValue(newValue);
    setFirstPage(false);
    // console.log(token)
    // eslint-disable-next-line react-hooks/rules-of-hooks
    fetch(`https://api.zainnest.com/api/leaderboard?type=${newValue === 0 ? 'weekly':'monthly'}`,{
      headers:{
        Accept: "application/json",
        "Content-Type": "application/json",
        "Authorization": "Bearer "+ token
      },
    }).then((res)=>res.json()).then((result)=> {
      console.log(result);
      setleaderboardData(result.data);
    });
    
    // console.log(value)
  };

  
  const labels = ["Weekly", "Monthly"];
 
  return (
    <Box sx={{ width: { xs: "100%", sm: "65%", md: "80%", lg: "100%" }, padding: "10px" }}>
      
      <Box sx={{ display: "flex", padding:"10" }}>
        <Typography variant="header" color={theme.palette.neutral.N200}>
          LeaderBoard
        </Typography>
      </Box>
      <Breadcrumbs
        separator={<NavigateNextOutlined fontSize="22px" />}
        aria-label="breadcrumb"
      >
        <Typography color={theme.palette.neutral.N200}>leaderboard</Typography>
        <Typography color="primary">{labels[value]}</Typography>
      </Breadcrumbs>
      <Grid container spacing={2} mt={4}>
        <Grid xs={12} sm={11.5}>
          <TabHeader
            labels={labels}
            value={value}
            handleChange={handleChange}
          />
          <Box className="card" sx={{ mt: 5, minHeight: "60vh" }}>
            <TabPanel value={value} index={0}>
             {/* first table */}
             <Box sx={{ mt: 1 }}>
                <Divider sx={{ xs: "block", md: "none", margin: 3 }} />
                <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "space-between",
                  flexDirection: "column",
                }}
              >
                {leaderboardData && leaderboardData.map((v, i) => (
                  <LeaderBoardCard
                    name={v.username}
                    position={i + 1}
                    amount={v.total_amount}
                    image={v.avatar}
                  />
                ))}
              </Box>
            </Box>
            </TabPanel>
            <TabPanel value={value} index={1}>
              {/* second table */}
              <Box sx={{ mt: 1 }}>
                <Divider sx={{ xs: "block", md: "none", margin: 3 }} />
                <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "space-between",
                  flexDirection: "column",
                }}
              >
                {leaderboardData && leaderboardData.map((v, i) => (
                  <LeaderBoardCard
                    name={v.username}
                    position={i + 1}
                    amount={v.total_amount}
                    image={v.avatar}
                  />
                ))}
              </Box>
            </Box>
             </TabPanel>
          </Box>
          
        </Grid>
      </Grid>
    </Box>
  );
}

export default LeaderBoard;

