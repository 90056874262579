import { BASEURL, http } from ".";

const ADMINURL = `${BASEURL}/admin`;

// bank
const BANKS = `${ADMINURL}/banks`;
const BANK = `${ADMINURL}/bank`;

export const getBanks = async () => await http.get(BANKS);
export const getBank = async (id) => http.get(`${BANK}/${id}`);
export const adminCreateBank = async (data) => http.post(BANK, data);
export const adminUpdateBank = async ({ id, values: data }) =>
  http.put(`${BANK}/${id}`, data);
export const adminDeleteBank = async (id) => http.delete(`${BANK}/${id}`);

// user
const USERS = `${ADMINURL}/users`;
const RESSELLERS = `${ADMINURL}/resellers`;
const USER = `${ADMINURL}/user`;

export const getusers = async (query) =>
  await http.get(`${USERS}`, { params: query });
export const getresellers = async (query) =>
  await http.get(`${RESSELLERS}?type=${query}`);
export const getuser = async (id) => http.get(`${USER}/${id}`);
export const addfund = async ({ id, data }) =>
  http.post(`${ADMINURL}/add_fund/${id}`, data);
export const deductFund = async ({ id, data }) =>
  http.post(`${ADMINURL}/deduct_fund/${id}`, data);
export const banUser = async (id) => http.post(`${ADMINURL}/ban/${id}`);
export const unbanUser = async (id) => http.post(`${ADMINURL}/unban/${id}`);
export const deleteUser = async (id) => http.delete(`${ADMINURL}/delete/${id}`);
export const createUser = async (data) => http.post(`${USER}`, data);
export const updateUser = async ({ id, data }) =>
  http.put(`${USER}/update/${id}`, data);

// wiithdrawal
const WITHDRAWALS = `${ADMINURL}/withdrawals`;
export const getWithdrawals = async (params) =>
  await http.get(`${WITHDRAWALS}`, { params });
export const approveWithdrawal = async (id) =>
  http.post(`${ADMINURL}/approve_withdrawal/${id}`);
export const rejectWithdrawal = async (id) =>
  http.post(`${ADMINURL}/decline_withdrawal/${id}`);

// transactions
const TRANSACTIONS = `${ADMINURL}/transactions`;
export const getTransactions = async (params) =>
  await http.get(TRANSACTIONS, { params });

// group
const GROUPS = `${ADMINURL}/groups`;
const GROUP = `${ADMINURL}/group`;

export const getGroups = async (params) => await http.get(GROUPS, { params });
export const getGroup = async (id) => http.get(`${GROUP}/${id}`);
export const adminCreateGroup = async (data) => http.post(GROUP, data);
export const adminUpdateGroup = async ({ id, data }) =>
  http.put(`${GROUP}/${id}`, data);

export const adminDeleteGroup = async (id) => http.delete(`${GROUP}/${id}`);

// charges
const CHARGES = `${ADMINURL}/charge`;

export const getCharges = async () => await http.get(CHARGES);

export const updateCharges = async ({ data, id }) =>
  http.put(`${CHARGES}/${id}`, data);

// bank requests
const BANKREQUESTS = `${ADMINURL}/bank_requests`;
export const getBankrequest = async (params) =>
  await http.get(`${BANKREQUESTS}`, { params });
export const approveBankrequest = async (id) =>
  http.post(`${ADMINURL}/approve_bank_request/${id}`);
export const rejectBankrequestl = async (id) =>
  http.post(`${ADMINURL}/decline_bank_request/${id}`);

// dashboard
const REPORT = `${ADMINURL}/salesReport`;
const STATISTICS = `${ADMINURL}/statistics`;
export const getStatistics = async () => await http.get(STATISTICS);
export const getReport = async (query) => http.post(REPORT, query);

// providers
const SERVICES = `${ADMINURL}/service`;

export const getServices = async () => await http.get(SERVICES);
export const updateServices = async ({ id, data }) =>
  http.post(`${SERVICES}/${id}`, data);

// providers
const PROVIDERS = `${ADMINURL}/providers`;

export const getProviders = async () => await http.get(PROVIDERS);
export const getAirtimeProviders = async () =>
  await http.get(`${ADMINURL}/airtime-provider`);
export const getDataProviders = async () =>
  await http.get(`${ADMINURL}/data-provider`);
export const getCableProviders = async () =>
  await http.get(`${ADMINURL}/cable-provider`);
export const getElectricityProviders = async () =>
  await http.get(`${ADMINURL}/electricity-provider`);

export const updateAirtimeProviders = async ({ id, data }) =>
  http.post(`${ADMINURL}/update-airtime-provider/${id}`, data);
export const updateDataProviders = async ({ id, data }) =>
  http.post(`${ADMINURL}/update-data-provider/${id}`, data);

export const updateCableProviders = async (id) =>
  http.post(`${ADMINURL}/update-cable-provider/${id}`);
export const updateElectricityProviders = async (id) =>
  http.post(`${ADMINURL}/update-electricity-provider/${id}`);

export const createNotification = async (data) =>
  http.post(`${ADMINURL}/notification`, data);
