import React from "react";
import { useTheme } from "@mui/material/styles";
import { LoadingButton } from "@mui/lab";

function CustomButton({ children, bgcolor, sx, color, ...props }) {
  const theme = useTheme();
  return (
    <LoadingButton
      sx={{
        fontSize: "16px",
        fontWeight: "500",
        textTransform: "capitalize",
        fontStyle: "normal",
        color: color ?? "white",
        width: "100%",
        borderRadius: "10px",
        backgroundColor: bgcolor ?? theme.palette.primary.main,
        "&:hover, &.Mui-focusVisible": {
          color: theme.palette.primary.main,
        },
        "&:disabled ": {
          color: "white",
          backgroundColor: "lightgray",
        },
        ...sx,
      }}
      {...props}
      loadingPosition="end"
      // loadingIndicator
    >
      {children}
    </LoadingButton>
  );
}

export default CustomButton;
