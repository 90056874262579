/* eslint-disable eqeqeq */
import React, { useState } from "react";

import * as yup from "yup";
import { useFormik } from "formik";
import { useNotificationStore, useUserStore } from "../../state";
import { useMutation } from "@tanstack/react-query";
import LabeledTextField from "../LabeledTextField";
import { useUser } from "../../hooks";
import { Box } from "@mui/system";

import { Button } from "@mui/material";
import { phoneRegExp } from "../../helpers";
import ChangePassword from "../modals/ChangePassword";
import { updateProfile } from "../../api/userApi";
import ChangePin from "../modals/ChangePin";

function UserProfile() {
  const [showPasswordEditModal, setshowPasswordEditModal] = useState(false);
  const [showPin, setShowPin] = useState(false);
  const isAddedPin = useUserStore((state) => state.user?.isAddedPin);

  const handleShowPin = () => {
    setShowPin(!showPin);
  };

  const handleClickShowEditPassword = () => {
    setshowPasswordEditModal(!showPasswordEditModal);
  };

  const displayNotificaton = useNotificationStore(
    (state) => state.displayNotificaton
  );

  const { fetchUser } = useUser();

  const { mutate } = useMutation(updateProfile, {
    onSuccess: (res) => {
      displayNotificaton(res?.data?.message);
      fetchUser();
    },
    onError: (e) => {
      alert(
        e.response?.data?.message ??
          e.message ??
          "something went wrong try again"
      );
    },
  });

  const { fullname, username, email, phone, state, country } = useUserStore(
    (state) => state.user
  );

  const initialValues = {
    fullname,
    username,
    email,
    phone,
    state,
    country,
  };
  let validationSchema = yup.object({
    fullname: yup.string().required("this field is required"),
    email: yup
      .string()
      .email("invalid email format")
      .required("this field is required"),
    username: yup.string().required("this field is required"),
    phone: yup
      .string()
      .test(
        "len",
        "Phone number is not valid",
        (val) => !val || val?.length === 11
      )
      .matches(phoneRegExp, "Phone number is not valid"),
    // state: yup.string().required("this field is required"),
    // country: yup.string().required("this field is required"),
  });

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      console.info(values);
      if (values.pin === "") delete values.pin;
      if (values.username === "") delete values.username;
      mutate(values);
    },
  });

  const hasError = (field) => {
    return formik.touched[field] && Boolean(formik.errors[field]);
  };

  return (
    <div>
      {showPasswordEditModal && (
        <ChangePassword
          handleClickShowEditPassword={handleClickShowEditPassword}
        />
      )}
      {showPin && <ChangePin handleShowPin={handleShowPin} />}
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          flexDirection: "column",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            width: { xs: "100%", md: "70%", lg: "50%" },
          }}
        >
          <Button
            sx={{
              width: "49%",
              height: "45px",
              fontWeight: "500",
              padding: "16px 60px",
              borderRadius: "10px",
              marginTop: "10px",
              whiteSpace: "nowrap",
            }}
            variant="outlined"
            onClick={handleClickShowEditPassword}
          >
            Change Password
          </Button>

          <Button
            sx={{
              width: "49%",
              height: "45px",
              fontWeight: "500",
              padding: "16px 60px",
              borderRadius: "10px",
              marginTop: "10px",
              whiteSpace: "nowrap",
            }}
            variant="outlined"
            onClick={handleShowPin}
          >
            {isAddedPin == 0 ? "Set" : "Change"} Pin
          </Button>
        </Box>
        <form style={{ width: "100%" }} onSubmit={formik.handleSubmit}>
          <Box sx={{ width: { xs: "100%", md: "70%", lg: "50%" } }}>
            <Box sx={{ margin: "20px 0" }}>
              <LabeledTextField
                label="Fullname"
                id="fullname"
                name="fullname"
                value={formik.values.fullname}
                onChange={formik.handleChange}
                error={
                  formik.touched.fullname && Boolean(formik.errors.fullname)
                }
                FormHelperTextProps={{
                  sx: {
                    color: () => (hasError("fullname") ? "red" : "green"),
                  },
                }}
                helperText={formik.touched.fullname && formik.errors.fullname}
                onBlur={formik.submitForm}
              />
            </Box>
            <Box sx={{ margin: "20px 0" }}>
              <LabeledTextField
                label="Username"
                id="username"
                name="username"
                value={formik.values.username}
                onChange={formik.handleChange}
                onBlur={formik.submitForm}
                disabled
                error={
                  formik.touched.username && Boolean(formik.errors.username)
                }
                helperText={formik.touched.username && formik.errors.username}
              />
            </Box>
            <Box sx={{ margin: "20px 0" }}>
              <LabeledTextField
                label="Email"
                id="email"
                disabled
                name="email"
                value={formik.values.email}
                onChange={formik.handleChange}
                error={formik.touched.email && Boolean(formik.errors.email)}
                helperText={formik.touched.email && formik.errors.email}
                fullWidth
                onBlur={formik.submitForm}
              />
            </Box>
            <Box sx={{ margin: "20px 0" }}>
              <LabeledTextField
                label="Phone Number"
                id="phone"
                name="phone"
                value={formik.values.phone}
                onChange={formik.handleChange}
                error={formik.touched.phone && Boolean(formik.errors.phone)}
                helperText={formik.touched.phone && formik.errors.phone}
                fullWidth
                onBlur={formik.submitForm}
              />
            </Box>
            <Box sx={{ margin: "20px 0" }}>
              <LabeledTextField
                label="State"
                id="state"
                name="state"
                value={formik.values.state}
                onChange={formik.handleChange}
                error={formik.touched.state && Boolean(formik.errors.state)}
                helperText={formik.touched.state && formik.errors.state}
                fullWidth
                onBlur={formik.submitForm}
              />
            </Box>
            <Box sx={{ margin: "20px 0" }}>
              <LabeledTextField
                label="Country"
                id="country"
                name="country"
                value={formik.values.country}
                onChange={formik.handleChange}
                error={formik.touched.country && Boolean(formik.errors.country)}
                helperText={formik.touched.country && formik.errors.country}
                fullWidth
                onBlur={formik.submitForm}
              />
            </Box>
            {/* <Box sx={{ margin: "20px 0" }}>
              <LabeledTextField
                label="Pin"
                id="pin"
                name="pin"
                type="number"
                value={formik.values.pin}
                onChange={formik.handleChange}
                error={formik.touched.pin && Boolean(formik.errors.pin)}
                helperText={formik.touched.pin && formik.errors.pin}
                fullWidth
                onBlur={formik.submitForm}
                autoComplete="off"
              />
            </Box> */}
          </Box>
        </form>
      </Box>
    </div>
  );
}

export default UserProfile;
