import React, { useState } from "react";
import { useStyles } from "./resetpassword.style.js";
import GeneralHeader from "../../components/LandingPage/generalHeader";
import Footer from "../../components/LandingPage/footer";
import { EyeIcon, EyeSlashIcon } from "@heroicons/react/24/outline";
import { Eye, Profile } from "../../icons";
import { NavLink, useNavigate, useLocation } from "react-router-dom";
import * as yup from "yup";
import { useFormik } from "formik";

import { useMutation } from "@tanstack/react-query";
import { resetpassword } from "../../api/authApi";

const ResetPassword = () => {
  const classes = useStyles();
  const navigate = useNavigate();

  const searchQuery = useLocation().search;
  let email = new URLSearchParams(searchQuery).get("email");
  let token = new URLSearchParams(searchQuery).get("token");

  console.info({ token, email });

  const [showPassword, setshowPassword] = useState(false);
  const [showSuccess, setshowSuccess] = useState(false);
  const [error, setError] = useState(null);
  const [message, setMessage] = useState(null);
  const [showConfirmPassword, setshowConfirmPassword] = useState(false);
  const handleClickShowPassword = () => {
    setshowPassword(!showPassword);
  };
  const handleClickShowConfirmPassword = () => {
    setshowConfirmPassword(!showConfirmPassword);
  };

  const { isLoading, mutate } = useMutation(resetpassword, {
    onSuccess: (res) => {
      setMessage(res.data?.message);
    },
    onError: (e) => {
      setError(
        e.response?.data?.message ??
          e.message ??
          "something went wrong try again"
      );
    },
  });

  const initialValues = {
    email,
    token,
    password: "",
    password_confirmation: "",
  };
  let validationSchema = yup.object({
    password: yup.string().required("this field is required"),
    password_confirmation: yup
      .string()
      .required("this field is required")
      .oneOf([yup.ref("password"), null], "Passwords must match"),
  });

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      setError(null);
      mutate(values);
    },
  });
  return (
    <div className={classes.container}>
      <GeneralHeader />
      <div className={classes.signInSection}>
        <div className="wrapper">
          <div className="formContainer">
            <h6>Reset your password</h6>
            {error && <div className="error-text">{error}</div>}
            {message && <div className="success-text">{message}</div>}
            <form onSubmit={formik.handleSubmit}>
              <div className="form-group">
                <label htmlFor="password">Password</label>
                <div className="input-group">
                  <input
                    type={showPassword ? "text" : "password"}
                    id="password"
                    name="password"
                    value={formik.values.password}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.password && Boolean(formik.errors.password)
                    }
                    helperText={
                      formik.touched.password && formik.errors.password
                    }
                    placeholder="Enter your new password"
                  />
                  <div
                    className="input-group-append"
                    onClick={handleClickShowPassword}
                  >
                    {!showPassword ? (
                      <EyeSlashIcon
                        style={{
                          color: "#1A3A5E",
                          width: "24px",
                        }}
                      />
                    ) : (
                      <EyeIcon
                        style={{
                          color: "#1A3A5E",
                          width: "24px",
                        }}
                      />
                    )}
                  </div>
                </div>
              </div>
              <div className="form-group">
                <label htmlFor="password_confirmation">Confirm Password</label>
                <div className="input-group">
                  <input
                    type={showConfirmPassword ? "text" : "password"}
                    id="password_confirmation"
                    name="password_confirmation"
                    value={formik.values.password_confirmation}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.password_confirmation &&
                      Boolean(formik.errors.password_confirmation)
                    }
                    helperText={
                      formik.touched.password_confirmation &&
                      formik.errors.password_confirmation
                    }
                    placeholder="Confirm your new password"
                  />
                  <div
                    className="input-group-append"
                    onClick={handleClickShowConfirmPassword}
                  >
                    {!showConfirmPassword ? (
                      <EyeSlashIcon
                        style={{
                          color: "grey",
                          width: "24px",
                        }}
                      />
                    ) : (
                      <EyeIcon
                        style={{
                          color: "grey",
                          width: "24px",
                        }}
                      />
                    )}
                  </div>
                </div>
              </div>

              <div className="button-group">
                <button className="primary" type="submit" disabled={isLoading}>
                  {isLoading ? "Loading..." : "Reset Password"}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default ResetPassword;
