import React, { useState } from "react";
import CustomButton from "../Button";
import { useTheme } from "@mui/material/styles";
import { Dialog, Box, Typography, Tabs, Tab } from "@mui/material";
import LabeledTextField from "../LabeledTextField";
import { useFormik } from "formik";
import * as yup from "yup";
import { useNotificationStore } from "../../state";
import { useMutation } from "@tanstack/react-query";
import { adminCreateGroup, adminUpdateGroup } from "../../api/adminApi";
import { styled } from "@mui/material/styles";

const CustomTab = styled(Tab)(({ theme }) => ({
  // color: theme.palette.success.main,
  fontSize: "19px",
  fontWeight: "500",
  textTransform: "capitalize",
  fontStyle: "normal",
  padding: "0px",
  "&:hover, &.Mui-focusVisible": {
    color: theme.palette.primary.main,
  },
  "&.Mui-selected": {
    fontWeight: "700",
  },
}));
function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
function TabHeader({ labels = [], value, handleChange }) {
  return (
    <Tabs
      value={value}
      onChange={handleChange}
      aria-label="basic tabs example"
      variant="fullWidth"
      sx={{ width: "100%", borderBottom: "1px solid #DFDFDF" }}
      indicatorColor="primary"
    >
      {labels.map((v, i) => (
        <CustomTab label={v} {...a11yProps(i)} />
      ))}
    </Tabs>
  );
}
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: "20px" }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function EditGroup({ handleOpen, refetch, group }) {
  const theme = useTheme();

  const [value, setValue] = useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const { id, name, airtime_charges, cable_charges, data_charges, ...rest } =
    group || {};

  const [error, setError] = useState(null);

  const displayNotificaton = useNotificationStore(
    (state) => state.displayNotificaton
  );

  const { isLoading: updateLoading, mutate: updateMutate } = useMutation(
    adminUpdateGroup,
    {
      onSuccess: (res) => {
        displayNotificaton(res?.data?.message);
        refetch();
        handleOpen();
      },
      onError: (e) => {
        setError(
          e.response?.data?.message ??
            e.message ??
            "something went wrong try again"
        );
      },
    }
  );

  const dataInitials = {};
  Object.entries(data_charges?.reseller_charge)?.forEach(([k, v]) => {
    dataInitials[`reseller_${k}`] = v;
  });

  Object.entries(data_charges?.user_charge)?.forEach(([k, v]) => {
    dataInitials[`user_${k}`] = v;
  });

  console.info({ dataInitials });

  const initialValues = {
    name,
    ...airtime_charges,
    ...cable_charges,
    ...dataInitials,
    ...rest,
  };
  const schemaOptions = {};
  Object.keys(airtime_charges).forEach(
    (k) => (schemaOptions[k] = yup.string().required("this field is required"))
  );
  Object.keys(cable_charges).forEach(
    (k) => (schemaOptions[k] = yup.string().required("this field is required"))
  );
  Object.keys(data_charges?.reseller_charge).forEach(
    (k) =>
      (schemaOptions[`reseller_${k}`] = yup
        .string()
        .required("this field is required"))
  );
  Object.keys(data_charges?.user_charge).forEach(
    (k) =>
      (schemaOptions[`user_${k}`] = yup
        .string()
        .required("this field is required"))
  );
  Object.keys(rest).forEach(
    (k) => (schemaOptions[k] = yup.string().required("this field is required"))
  );

  let validationSchema = yup.object(schemaOptions);

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      const {
        reseller_charge,
        reseller_dstv,
        reseller_electricity,
        reseller_gotv,
        reseller_sas,
        reseller_startimes,
        reseller_transfer,
        reseller_vtu,
        reseller_withdrawal,
        user_dstv,
        user_electricity,
        user_gotv,
        user_sas,
        user_startimes,
        user_transfer,
        user_vtu,
        user_withdrawal,
        name,
        reseller_airtel_corporate,
        reseller_airtel_gifting,
        reseller_airtel_sme,
        reseller_etisalat_corporate,
        reseller_etisalat_gifting,
        reseller_etisalat_sme,
        reseller_glo_corporate,
        reseller_glo_gifting,
        reseller_glo_sme,
        reseller_mtn_corporate,
        reseller_mtn_gifting,
        reseller_mtn_sme,
        user_airtel_corporate,
        user_airtel_gifting,
        user_airtel_sme,
        user_etisalat_corporate,
        user_etisalat_gifting,
        user_etisalat_sme,
        user_glo_corporate,
        user_glo_gifting,
        user_glo_sme,
        user_mtn_corporate,
        user_mtn_gifting,
        user_mtn_sme,
      } = values;

      const data = {
        name,
        airtime_charges: {
          reseller_sas,
          reseller_vtu,
          user_sas,
          user_vtu,
        },
        cable_charges: {
          reseller_dstv,
          reseller_gotv,
          reseller_startimes,
          user_dstv,
          user_gotv,
          user_startimes,
        },
        data_charges: {
          reseller_charge: {
            airtel_corporate: reseller_airtel_corporate,
            airtel_gifting: reseller_airtel_gifting,
            airtel_sme: reseller_airtel_sme,
            etisalat_corporate: reseller_etisalat_corporate,
            etisalat_gifting: reseller_etisalat_gifting,
            etisalat_sme: reseller_etisalat_sme,
            glo_corporate: reseller_glo_corporate,
            glo_gifting: reseller_glo_gifting,
            glo_sme: reseller_glo_sme,
            mtn_corporate: reseller_mtn_corporate,
            mtn_gifting: reseller_mtn_gifting,
            mtn_sme: reseller_mtn_sme,
          },
          user_charge: {
            airtel_corporate: user_airtel_corporate,
            airtel_gifting: user_airtel_gifting,
            airtel_sme: user_airtel_sme,
            etisalat_corporate: user_etisalat_corporate,
            etisalat_gifting: user_etisalat_gifting,
            etisalat_sme: user_etisalat_sme,
            glo_corporate: user_glo_corporate,
            glo_gifting: user_glo_gifting,
            glo_sme: user_glo_sme,
            mtn_corporate: user_mtn_corporate,
            mtn_gifting: user_mtn_gifting,
            mtn_sme: user_mtn_sme,
          },
        },
        reseller_charge,
        reseller_electricity,
        reseller_transfer,
        reseller_withdrawal,
        user_electricity,
        user_transfer,
        user_withdrawal,
      };

      console.info({ data });

      updateMutate({ id, data });
    },
  });

  const labels = ["Airtime", "Cable", "User Data", "Res Data", "Others"];

  return (
    <Dialog
      sx={{
        "& .MuiDialog-paper": {
          borderRadius: "30px",
          width: { xs: "100%", sm: "80%", lg: "65%" },
        },
      }}
      onClose={handleOpen}
      open={true}
    >
      {/* <Box sx={{height:"40px"}}/> */}
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-evenly",
          alignItems: "center",
          padding: "80px 20px",
          width: "100%",
          height: "95vh",
          background: "#FFFFFF",
          border: "1px solid #DFDFDF",
          borderRadius: "30px",
          textAlign: "center",
          overflowY: "scroll",
        }}
      >
        <Typography variant="header" sx={{ fontSize: "24px", padding: "20px" }}>
          Edit Group
        </Typography>
        <Box sx={{ marginTop: "20px", width: "100%" }}>
          <LabeledTextField
            label={"NAME"}
            id={name}
            name={name}
            value={formik.values.name}
            onChange={formik.handleChange}
            error={formik.touched.name && Boolean(formik.errors.name)}
            helperText={formik.touched.name && formik.errors.name}
            fullWidth
            variant="outlined"
          />
        </Box>
        <Box sx={{ display: "flex", width: "100%" }}>
          <Typography
            variant="header"
            sx={{ fontSize: "19px", marginTop: "20px", mb: "20px" }}
          >
            Discounts
          </Typography>
        </Box>
        <Typography
          sx={{
            color: "#9C0000",
            textAlign: "start",
          }}
        >
          {error}
        </Typography>
        <Box
          sx={{
            width: "100%",
            height: "80vh",
          }}
        >
          <TabHeader
            labels={labels}
            value={value}
            handleChange={handleChange}
          />
          <TabPanel value={value} index={0}>
            <form style={{ width: "100%" }} onSubmit={formik.handleSubmit}>
              {airtime_charges &&
                Object.entries(airtime_charges)?.map((charge, i) => (
                  <Box sx={{ mb: 4, mt: 2 }} key={i}>
                    <LabeledTextField
                      label={charge[0].replace("_", " ").toUpperCase()}
                      id={charge[0]}
                      name={charge[0]}
                      value={formik.values[charge[0]]}
                      onChange={formik.handleChange}
                      error={
                        formik.touched[charge[0]] &&
                        Boolean(formik.errors[charge[0]])
                      }
                      helperText={
                        formik.touched[charge[0]] && formik.errors[charge[0]]
                      }
                      fullWidth
                      variant="outlined"
                    />
                  </Box>
                ))}
              <Box
                sx={{ mb: 4, display: "flex", justifyContent: "space-between" }}
              >
                <Box sx={{ mr: 1, width: "39%" }}>
                  <CustomButton
                    bgcolor={theme.palette.secondary.main}
                    color={theme.palette.primary.main}
                    onClick={handleOpen}
                  >
                    Cancel
                  </CustomButton>
                </Box>
                <Box sx={{ width: "62%" }}>
                  <CustomButton loading={updateLoading} type="submit">
                    Continue
                  </CustomButton>
                </Box>
              </Box>
            </form>
          </TabPanel>
          <TabPanel value={value} index={1}>
            <form style={{ width: "100%" }} onSubmit={formik.handleSubmit}>
              {cable_charges &&
                Object.entries(cable_charges)?.map((charge, i) => (
                  <Box sx={{ mb: 4, mt: 2 }} key={i}>
                    <LabeledTextField
                      label={charge[0].replace("_", " ").toUpperCase()}
                      id={charge[0]}
                      name={charge[0]}
                      value={formik.values[charge[0]]}
                      onChange={formik.handleChange}
                      error={
                        formik.touched[charge[0]] &&
                        Boolean(formik.errors[charge[0]])
                      }
                      helperText={
                        formik.touched[charge[0]] && formik.errors[charge[0]]
                      }
                      fullWidth
                      variant="outlined"
                    />
                  </Box>
                ))}

              <Box
                sx={{ mb: 4, display: "flex", justifyContent: "space-between" }}
              >
                <Box sx={{ mr: 1, width: "39%" }}>
                  <CustomButton
                    bgcolor={theme.palette.secondary.main}
                    color={theme.palette.primary.main}
                    onClick={handleOpen}
                  >
                    Cancel
                  </CustomButton>
                </Box>
                <Box sx={{ width: "62%" }}>
                  <CustomButton loading={updateLoading} type="submit">
                    Continue
                  </CustomButton>
                </Box>
              </Box>
            </form>
          </TabPanel>
          <TabPanel value={value} sx={{ height: "95vh" }} index={2}>
            <form
              style={{ width: "100%", height: "100%" }}
              onSubmit={formik.handleSubmit}
            >
              {data_charges &&
                Object.entries(data_charges.user_charge ?? {})?.map(
                  (charge, i) => (
                    <Box sx={{ mb: 4, mt: 2 }} key={i}>
                      <LabeledTextField
                        label={charge[0].replace("_", " ").toUpperCase()}
                        id={`user_${charge[0]}`}
                        name={`user_${charge[0]}`}
                        value={formik.values[`user_${charge[0]}`]}
                        onChange={formik.handleChange}
                        error={
                          formik.touched[`user_${charge[0]}`] &&
                          Boolean(formik.errors[`user_${charge[0]}`])
                        }
                        helperText={
                          formik.touched[`user_${charge[0]}`] &&
                          formik.errors[`user_${charge[0]}`]
                        }
                        fullWidth
                        variant="outlined"
                      />
                    </Box>
                  )
                )}

              <Box
                sx={{ mb: 4, display: "flex", justifyContent: "space-between" }}
              >
                <Box sx={{ mr: 1, width: "39%" }}>
                  <CustomButton
                    bgcolor={theme.palette.secondary.main}
                    color={theme.palette.primary.main}
                    onClick={handleOpen}
                  >
                    Cancel
                  </CustomButton>
                </Box>
                <Box sx={{ width: "62%" }}>
                  <CustomButton loading={updateLoading} type="submit">
                    Continue
                  </CustomButton>
                </Box>
              </Box>
            </form>
          </TabPanel>
          <TabPanel value={value} sx={{ height: "95vh" }} index={3}>
            <form
              style={{ width: "100%", height: "100%" }}
              onSubmit={formik.handleSubmit}
            >
              {data_charges &&
                Object.entries(data_charges?.reseller_charge ?? {})?.map(
                  (charge, i) => (
                    <Box sx={{ mb: 4, mt: 2 }} key={i}>
                      <LabeledTextField
                        label={charge[0].replace("_", " ").toUpperCase()}
                        id={`reseller_${charge[0]}`}
                        name={`reseller_${charge[0]}`}
                        value={formik.values[`reseller_${charge[0]}`]}
                        onChange={formik.handleChange}
                        error={
                          formik.touched[`reseller_${charge[0]}`] &&
                          Boolean(formik.errors[`reseller_${charge[0]}`])
                        }
                        helperText={
                          formik.touched[`reseller_${charge[0]}`] &&
                          formik.errors[`reseller_${charge[0]}`]
                        }
                        fullWidth
                        variant="outlined"
                      />
                    </Box>
                  )
                )}

              <Box
                sx={{ mb: 4, display: "flex", justifyContent: "space-between" }}
              >
                <Box sx={{ mr: 1, width: "39%" }}>
                  <CustomButton
                    bgcolor={theme.palette.secondary.main}
                    color={theme.palette.primary.main}
                    onClick={handleOpen}
                  >
                    Cancel
                  </CustomButton>
                </Box>
                <Box sx={{ width: "62%" }}>
                  <CustomButton loading={updateLoading} type="submit">
                    Continue
                  </CustomButton>
                </Box>
              </Box>
            </form>
          </TabPanel>
          <TabPanel value={value} index={4}>
            <form style={{ width: "100%" }} onSubmit={formik.handleSubmit}>
              {rest &&
                Object.entries(rest)?.map((charge, i) => (
                  <Box sx={{ mb: 4, mt: 2 }} key={i}>
                    <LabeledTextField
                      label={charge[0].replace("_", " ").toUpperCase()}
                      id={charge[0]}
                      name={charge[0]}
                      value={formik.values[charge[0]]}
                      onChange={formik.handleChange}
                      error={
                        formik.touched[charge[0]] &&
                        Boolean(formik.errors[charge[0]])
                      }
                      helperText={
                        formik.touched[charge[0]] && formik.errors[charge[0]]
                      }
                      fullWidth
                      variant="outlined"
                    />
                  </Box>
                ))}
              <Box
                sx={{ mb: 4, display: "flex", justifyContent: "space-between" }}
              >
                <Box sx={{ mr: 1, width: "39%" }}>
                  <CustomButton
                    bgcolor={theme.palette.secondary.main}
                    color={theme.palette.primary.main}
                    onClick={handleOpen}
                  >
                    Cancel
                  </CustomButton>
                </Box>
                <Box sx={{ width: "62%" }}>
                  <CustomButton loading={updateLoading} type="submit">
                    Continue
                  </CustomButton>
                </Box>
              </Box>
            </form>
          </TabPanel>
        </Box>
      </Box>
    </Dialog>
  );
}

export default EditGroup;
