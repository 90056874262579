import React, {useState} from 'react';
import CustomButton from "../Button";
import { useTheme } from "@mui/material/styles";
import { Dialog, Box, Typography } from "@mui/material";
import { useFormik } from "formik";
import * as yup from "yup";
import { useNotificationStore } from "../../state";
import { useMutation, useQuery } from "@tanstack/react-query";
import {getGroups} from "../../api/adminApi";
import LabeledSelectField from "../LabeledSelectField";


function EditUserGroup({ user, handleClose }) {
      const theme = useTheme();
      const [error, setError] = useState(null);

      const displayNotificaton = useNotificationStore(
        (state) => state.displayNotificaton
      );

      const { data, isLoading: groupLoading } = useQuery(
        ["groups"],
        getGroups,
        {
          refetchOnWindowFocus: false,
        }
      );

    const groups = data?.data?.data?.data || [];

    const groupsMenu = groups?.map((v) => ({ label: v.name, value: v.id }));

    const updateUserGroup = () => {
            
    };
    
    const { isLoading, mutate } = useMutation(updateUserGroup, {
      onSuccess: (res) => {
        displayNotificaton(res?.data?.message);
        handleClose();
      },
      onError: (e) => {
        setError(
          e.response?.data?.message ??
            e.message ??
            "something went wrong try again"
        );
      },
    });

    const initialValues = {
      group_id: user.group_id ?? "",
    };

    let validationSchema = yup.object({
      group_id: yup.string().required("this field is required"),
    });

    const formik = useFormik({
      initialValues: initialValues,
      validationSchema: validationSchema,
      onSubmit: async (values) => {
      },
    });

     <Dialog
       sx={{
         "& .MuiDialog-paper": {
           borderRadius: "30px",
           width: { xs: "100%", sm: "50%", md: "27%" },
           zIndex: 2,
         },
       }}
       open={true}
       onClose={handleClose}
     >
       <Box
         sx={{
           display: "flex",
           flexDirection: "column",
           justifyContent: "space-evenly",
           alignItems: "center",
           padding: "30px",
           width: { xs: "100%" },
           // height: "420px",
           background: "#FFFFFF",
           border: "1px solid #DFDFDF",
           borderRadius: "30px",
           textAlign: "center",
         }}
       >
         <Typography variant="header" sx={{ fontSize: "19px" }}>
           Edit User Group
         </Typography>
         <Typography
           sx={{
             color: "#9C0000",
             textAlign: "start",
           }}
         >
           {error}
         </Typography>
         <form style={{ width: "100%" }} onSubmit={formik.handleSubmit}>
             <Box sx={{ mb: 4 }}>
               <LabeledSelectField
                 label="Group (Optional)"
                 // hint="You can add multiple numbers. Ex: 08030302020, 09016161919"
                 id="group_id"
                 name="group_id"
                 value={formik.values.group_id}
                 onChange={formik.handleChange}
                 error={
                   formik.touched.group_id && Boolean(formik.errors.group_id)
                 }
                 helperText={formik.touched.group_id && formik.errors.group_id}
                 fullWidth
                 variant="outlined"
                 data={groupsMenu}
                 loading={groupLoading}
               />
             </Box>
           <Box
             sx={{ mb: 4, display: "flex", justifyContent: "space-between" }}
           >
             <Box sx={{ mr: 1, width: "39%" }}>
               <CustomButton
                 bgcolor={theme.palette.secondary.main}
                 color={theme.palette.primary.main}
                 onClick={handleClose}
               >
                 Cancel
               </CustomButton>
             </Box>
             <Box sx={{ width: "62%" }}>
               <CustomButton loading={isLoading} type="submit">
                 Continue
               </CustomButton>
             </Box>
           </Box>
         </form>
       </Box>
     </Dialog>;

}

export default EditUserGroup;