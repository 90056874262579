import React from "react";
import PropTypes from "prop-types";
import { Box, Tab, Tabs } from "@mui/material";
import { styled } from "@mui/material/styles";

const CustomTab = styled(Tab)(({ theme }) => ({
  // color: theme.palette.success.main,
  fontSize: "19px",
  fontWeight: "500",
  textTransform: "capitalize",
  fontStyle: "normal",
  padding: "0px",
  "&:hover, &.Mui-focusVisible": {
    color: theme.palette.primary.main,
  },
  "&.Mui-selected": {
    fontWeight: "700",
  },
}));
function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

function TabHeader({ labels = [], value, handleChange }) {
  return (
    <Box
      sx={{
        background: "#FFFFFF",
        border: "1px solid #DFDFDF",
        borderRadius: "20px",
        height: "60px",
        width: "100%",
        display: "flex",
        // justifyContent: "space-evenly",
        whiteSpace: "nowrap",
        overflow: "visible",
        textOverflow: "ellipsis",
        alignItems: "center",
        "& .MuiButtonBase-root": {
          fontSize: { xs: "14px", sm: "12px", md: "16px", lg: "18px" },
        },
      }}
    >
      <Tabs
        value={value}
        onChange={handleChange}
        aria-label="basic tabs example"
        variant="fullWidth"
        sx={{ width: "100%" }}
        indicatorColor="none"
      >
        {labels.map((v, i) => (
          <CustomTab label={v} {...a11yProps(i)} />
        ))}
      </Tabs>
    </Box>
  );
}

TabHeader.propTypes = {};

export default TabHeader;
