import React from "react";
import { Navigate, useLocation } from "react-router-dom";
import { roles } from "../helpers";
import { useUserStore } from "../state";

function AuthLayout({ children }) {
  const loggedIn = useUserStore((state) => state.loggedIn);
  const user = useUserStore((state) => state.user);
  const location = useLocation();
  const from = location.state?.from?.pathname;
  console.info({ loggedIn });
  if (loggedIn) {
    if (user?.email_verified === false && location.pathname !== "/otp")
      return <Navigate to="/otp" />;
    if (from && from !== location.pathname)
      return <Navigate to={from} replace />;
    if (user?.role === roles.ADMIN)
      return <Navigate to="/admin/dashboard" replace />;
    if (user?.role === roles.USER || user?.role === roles.RESELLER)
      return <Navigate to="/user/home" replace />;
  }
  return <div>{children}</div>;
}

export default AuthLayout;
