import React from "react";
import { useStyles } from "./item.style";
import { Icon1 } from "../../icons";

const Item = (props) => {
  const classes = useStyles();
  const { icon, title, description, mr, ml } = props;
  return (
    <div className={classes.item} style={{ marginRight: mr, marginLeft: ml }}>
      {icon}
      <div style={{ display: "flex", flexDirection: "column" }}>
        <h6>{title}</h6>
        <p>{description}</p>
      </div>
    </div>
  );
};

export default Item;
