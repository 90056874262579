import { makeStyles } from "@mui/styles";
// import { colors } from "../../../styles/colors";
import hero from "../../../images/hero-bg.svg";
import hero2 from "../../../images/hero-bg.png";

export const useStyles = makeStyles({
  header: {
    // marginTop: "16px",
    background: `url(${hero}) no-repeat`,
    backgroundSize: "cover",
    backgroundPosition: "center",

    // height: "954px",
    "@media (max-width: 1300px)": {
      background: `url(${hero2}) no-repeat`,
      backgroundSize: "cover",
      backgroundPosition: "center",
    },

    "& .wrapper": {
      "& .navbar": {
        "& .menu": {
          display: "none",
        },
        "& .close": {
          display: "none",
        },
        padding: "20px 0px",
        borderBottom: "1px solid #E6E6EF",
        display: "flex",
        gap: "49px",
        alignItems: "center",
        "@media (max-width: 1300px)": {
          justifyContent: "space-between",
          zIndex: "90000000000000000",

          "& img": {
            height: "19px",
            width: "auto",
          },
          "& .menu": {
            display: "block",
          },
        },
        "@media (max-width: 900px)": {
          gap: "20px",
        },

        "& ul.nav-links": {
          display: "flex",
          gap: "45px",
          listStyle: "none",
          // [theme.breakpoints.down("md")]: {
          //   display: "none",
          // },

          "@media (max-width: 1300px)": {
            "& .menu": {
              display: "block",
            },
            "& .close": {
              display: "block",
              position: "absolute",
              top: "28px",
              right: "29px",
              cursor: "pointer",
            },
            position: "fixed",
            top: "0",
            left: "0",
            right: "0",
            bottom: "0",
            paddingTop: "118px",
            flexDirection: "column",
            alignItems: "center",
            backgroundColor: "rgba(90, 90, 94, 0.9)",
            width: "100%",
            height: "100%",
            display: "none",
            zIndex: "9888888800",
          },

          "& li:not(.button)": {
            "& a": {
              color: "#808080",
              textDecoration: "none",
              fontSize: "23px",
              fontWeight: "400",
              "&.active-link": {
                fontWeight: "700",
                color: "#1A3A5E",
              },
              "&:hover": {
                color: "#1A3A5E",
              },
              "@media (max-width: 1300px)": {
                fontWeight: "700",
                fontSize: "48px",
                color: "#2b2b2c",
              },
              "@media (max-width: 600px)": {
                fontSize: "28px",
              },
            },
            "@media (max-width: 1300px)": {
              textAlign: "center",
              width: "100%",
            },
          },
          "& li.button": {
            display: "none",
            "@media (max-width: 1300px)": {
              display: "block",
            },
            backgroundColor: "gray",
            width: "max-content",
            padding: "25px 45px",
            borderRadius: "10px",
            textAlign: "center",

            "& a": {
              border: "none",
              outline: "none",
              cursor: "pointer",
              fontSize: "16px",
              fontWeight: "bold",
              textAlign: "center",

              color: "#1A3A5E",
              width: "100%",
            },
            "@media (max-width: 600px)": {
              margin: "0 auto",

              width: "80%",
            },
          },
        },
        "& .buttons": {
          display: "flex",
          gap: "10px",
          "& .button": {
            border: "none",
            outline: "none",
            cursor: "pointer",
            fontSize: "16px",
            fontWeight: "500",
            padding: "25px 45px",
            borderRadius: "10px",
            // width: "150px",
            width: "max-content",
            "@media (max-width: 1300px)": {
              padding: "14px 35px",
            },

            "&.sign-in": {
              backgroundColor: "#E6E6EF",
              color: "#1A3A5E",
              "&:hover": {
                backgroundColor: "#D9D9E6",
              },
              "@media (max-width: 1300px)": {
                display: "none",
              },
            },
            "&.get-started": {
              backgroundColor: "#1A3A5E",
              color: "#fff",
            },
          },
        },
      },
      "& .hero": {
        marginTop: "43px",
        // marginBottom: "",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        position: "relative",
        "& .hero-text": {
          textAlign: "center !important",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          gap: "20px",
          "& h1": {
            fontSize: "64px",
            fontWeight: "bold",
            color: "#1A3A5E",
            lineHeight: "80px",
            "@media (max-width: 1300px)": {
              fontSize: "48px",
              lineHeight: "60px",
            },
            "@media (max-width: 900px)": {
              fontSize: "28px",
              lineHeight: "34px",
            },
          },
          "& p": {
            fontSize: "24px",
            fontWeight: "400",
            color: "#808080",
            maxWidth: "820px",
            "@media (max-width: 1300px)": {
              fontSize: "18px",
              lineHeight: "28px",
            },
            "@media (max-width: 900px)": {
              fontSize: "13px",
              lineHeight: "16px",
            },
          },
        },
        "& .downloads": {
          display: "flex",
          justifyContent: "center",
          gap: "30px",
          marginTop: "39px",

          "@media (max-width: 900px)": {
            justifyContent: "space-between",
          },
          "& a": {
            textDecoration: "none",
            display: "flex",
            gap: "14px",
            alignItems: "center",
            backgroundColor: "#fff",
            border: "1px solid #E6E6EF",
            padding: "15px 30px",
            boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.1)",
            borderRadius: "20px",
            width: "max-content",
            "@media (max-width: 1300px)": {
              gap: "10px",
              padding: "15px 20px",
            },
            "@media (max-width: 900px)": {
              gap: "5px",
              padding: "10px 15px",
            },

            "& span.title": {
              fontSize: "28px",
              fontWeight: "700",
              color: "#1A3A5E",
              display: "block",
              "@media (max-width: 1300px)": {
                fontSize: "18px",
              },
              "@media (max-width: 900px)": {
                fontSize: "13px",
              },
            },
            "& span.sub": {
              fontSize: "16px",
              fontWeight: "500",
              color: "#1A3A5E",
              display: "block",
              marginBottom: "8px",
              "@media (max-width: 1300px)": {
                fontSize: "13px",
              },
              "@media (max-width: 900px)": {
                fontSize: "10px",
              },
            },
          },
        },
        "& .phoneContainer": {
          position: "absolute",
          right: "0",
          left: "0",
          bottom: "0",
          zIndex: "-1",
          // width: "533px",
          height: "400px",
          overflow: "hidden",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          "@media (max-width: 1300px)": {
            height: "300px",
          },

          "& .phoneImageContainer": {
            position: "relative",

            width: "100%",
            height: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            "& .phone": {
              width: "533px",
              position: "absolute",
              top: "0", // Center vertically
              // left: "50%", // Center horizontally
              // transform: "translate(-50%, -50%)", // Translate to center
              "@media (max-width: 1300px)": {
                width: "400px",
              },
              "@media (max-width: 900px)": {
                width: "300px",
              },
            },
          },
        },
        "& .buttons": {
          display: "flex",
          justifyContent: "center",
          gap: "32px",
          backgroundColor: "white",
          padding: "24px",
          // width: "max-content",
          borderRadius: "20px",

          marginTop: "269px",
          marginBottom: "68px",
          width: "550px",
          "@media (max-width: 1300px)": {
            marginTop: "200px",
            marginBottom: "60px",
            width: "440px",
            gap: "20px",
          },
          "@media (max-width: 900px)": {
            marginTop: "150px",

            gap: "10px",
            width: "auto",
          },

          "& a": {
            textDecoration: "none",
            padding: "19px 67px",
            borderRadius: "20px",
            width: "max-content",
            fontSize: "16px",
            fontWeight: "bold",
            "@media (max-width: 1300px)": {
              padding: "14px 35px",
            },
            "&.transfer": {
              background: "#fff",
              color: "#1A3A5E",
              border: "1px solid #1A3A5E",
            },
            "&.fund-wallet": {
              background: "#1A3A5E",
              color: "#fff",
            },
          },
        },
      },
    },
  },
  openMenu: {
    display: "flex !important",
  },
});
