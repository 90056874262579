import { useTheme } from "@emotion/react";
import { InputAdornment, TextField, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { useQuery } from "@tanstack/react-query";
import React, { useState } from "react";
import Logo1 from "../../icons/logo1.png";
import Logo2 from "../../icons/logo2.png";
// import rebon from "../../icons/rebon.png";
import announce from "../../icons/announce.png";
import { getNotifications } from "../../api/userApi";
import { Search } from "@mui/icons-material";
import LabeledSelectField from "../../components/LabeledSelectField";

function NotificationPage() {
  const theme = useTheme();
  const [filter, setFilter] = useState("all");
  const [search, setSearch] = useState("");
  const [val, setVal] = useState("");

  const { data } = useQuery(["notifications", search], () =>
    getNotifications({ search })
  );

  const notifications = data?.data?.data?.notifications?.data || [];

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
        padding: "0 20px",
      }}
    >
      <Box
        sx={{
          display: {
            xs: "none",
            sm: "block",
            margin: "20px 0px 0px",
            textAlign: "left",
          },
        }}
      >
        <Box sx={{ display: "flex" }}>
          <Typography variant="header" color={theme.palette.neutral.N200}>
            Notifications
          </Typography>
        </Box>

        <Typography color={theme.palette.neutral.N200}>
          {/* {user?.role == roles.RESELLER ? "Resellers" : "Customer"} */}
          Notifications
        </Typography> 
      </Box>
      <Box
        sx={{
          padding: { sm: "20px" },
          width: {
            xs: "100%",
            lg: "70%",
            xl: "60%",
          },
          border: {
            sm: "1px solid #e6e6ef",
          },
          borderRadius: "20px",
          mt: { xs: 0, sm: 5 },
          minHeight: "70vh",
        }}
      >
        <Box sx={{ mt: 1 }}>
          {/* <Box sx={{ display: "flex", justifyContent: "start" }}>
            <TextField
              sx={{
                width: { xs: "100%", md: "80%", lg: "70%" },
                borderRadius: "10px",
                "& .MuiOutlinedInput-root": {
                  borderRadius: "10px",
                },
                mb: 2,
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Search />
                  </InputAdornment>
                ),
              }}
              placeholder="search"
              variant="outlined"
            />
          </Box> */}
          <Box
            sx={{
              display: {
                xs: "flex",
                sm: "none",
                margin: "20px 0px 0px",
                textAlign: "left",
              },
              justifyContent: "space-between",
              mb: 2,
              alignItems: "center",
            }}
          >
            <Typography
              sx={{ fontSize: "16px", color: "#808080", fontWeight: "500" }}
            >
              Notifications
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              mb: 2,
            }}
          >
            <TextField
              sx={{
                width: { xs: "100%", sm: "80%", lg: "70%" },
                borderRadius: "10px",
                "& .MuiOutlinedInput-root": {
                  borderRadius: "10px",
                },
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Search />
                  </InputAdornment>
                ),
              }}
              placeholder="search"
              variant="outlined"
              onBlur={(e) => setSearch(e.target.value)}
              onChange={(e) => setVal(e.target.value)}
              value={val}
            />
            <Box
              sx={{
                display: {
                  xs: "none",
                  sm: "block",

                  textAlign: "left",
                },
                width: { sm: "15%", lg: "25%" },
              }}
            >
              <LabeledSelectField
                onChanged={(e) => setFilter(e)}
                value={filter}
                sx={{ fontSize: "10px", padding: "10px 0px" }}
                data={[
                  { value: "all", label: "All" },
                  { value: "year", label: "This Year" },
                  { value: "month", label: "This Month" },
                  { value: "week", label: "This Week" },
                  { value: "today", label: "Today" },
                ]}
              ></LabeledSelectField>
            </Box>
            
          </Box>

          {
          notifications?.map((v) => (
            <NotificationCard title={v?.title} message={v?.body} createdAt={v?.created_at} />
          ))}
        </Box>
      </Box>
    </Box>
  );
}

export default NotificationPage;

function NotificationCard({ title, message ,createdAt}) {
  const [open, setOpen] = useState(false);
  const createdAtDate = new Date(createdAt);
   // Format the date into a desired format (e.g., "April 20, 2024 11:00 PM")
   const formattedCreatedAt = formatTime(createdAtDate);
  return (
    <Box
      sx={{
        width: "100%",
      }}
    >
      <Box
        sx={{
          width: "100%",
          height: "47px",
          display: "flex",
          alignItems: "center",
          justifyContent: "left",
        }}
      >
        <img
          alt=""
          src={Logo1}
          style={{
            width: "33px",
            height: "33px",
          }}
        />
        <Box
          sx={{
            width: "100%",
            height: "100%",
            display: "flex",
            alignItems: "flex-start",
            justifyContent: "flex-start",
            flexDirection: "column",
          }}
        >
          <img
            alt=""
            src={Logo2}
            style={{
              width: "81px",
              height: "23px",
            }}
          />
          <Typography
            sx={{
              color: "#9D9D9D",
              fontWeight: "500",
              fontSize: "11px",
              mt: "3px",
              ml:"3px"
            }}
          >
         {formattedCreatedAt}
          </Typography>
        </Box>
      </Box>
      <Box
        sx={{
          width: "100%",
          height: open ? "400px" : "200px",
          display: "flex",
          justifyContent: "flex-end",
          position: "relative",
        }}
      >
        <Typography
          onClick={() => setOpen(!open)}
          sx={{
            position: "absolute",
            bottom: open ? "-1px" : "12px",
            right: "5px",
            color: "#020239",
            backgroundColor: "#F5F5F5",
            zIndex: "2",
            textDecoration: "underline",
            padding: "0px 4px 0px 2px",
          }}
        >
          {" "}
          see {open ? " less" : " all"}
        </Typography>
        <Box
          sx={{
            width: "calc(100% - 47px)",
            height: open ? "400px" : "200px",
            backgroundColor: "#F5F5F5",
            borderRadius: "8px",
            mt: "5px",
            padding: "15px",
          }}
        >
          <Box
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Box
              sx={{
                width: "65%",
                color: "#020239",
                fontWeight: "700",
                textAlign: "start",
              }}
            >
              {title}
            </Box>

            <img
              alt=""
              src={announce}
              style={{
                width: "47px",
                height: "47px",
              }}
            />
          </Box>
          <Box
            sx={{
              height: open ? "85%" : "70%",
              overflow: open ? "auto" : "hidden",
              paddingBottom: "5px",
            }}
          >
            {message}
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
function formatTime(time) {
  // Options for formatting the date
  const options = { year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric', second: 'numeric' };

  // Format the date using the options
  return time.toLocaleString('en-US', options);
}