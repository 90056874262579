import {
  PanoramaFishEye,
  VisibilityOffOutlined,
  VisibilityOutlined,
} from "@mui/icons-material";
import { Button, Typography } from "@mui/material";
import { Box, padding } from "@mui/system";
import React, { useState } from "react";
import CustomButton from "../Button";
import { useTheme } from "@mui/material/styles";
import { NavLink } from "react-router-dom";
import { useUserStore } from "../../state";
import walletImg1 from "../../icons/walletImg1.png";
import { formatter } from "../../helpers";

function SummaryCardAlt({ amount }) {
  const [show, setshow] = useState(false);

  const user = useUserStore((state) => state.user);

  const { balance } = user;

  const theme = useTheme();
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "flex-start",
        // height: "112px",
        // padding: "20px",
      }}
    >
      <Box
        sx={{
          backgroundColor: theme.palette.primary.main,
          borderRadius: "20px",
          padding: "35px 20px",
          width: "100%",
          position: "relative",
        }}
      >
        <img
          alt=""
          src={walletImg1}
          style={{
            height: "98%",
            width: "98%",
            position: "absolute",
            zIndex: "1",
            borderRadius: "23px",
            top: "0px",
            left: "0px",
          }}
        />
        <Box
          sx={{
            // backgroundColor: theme.palette.primary.main,
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            width: {
              sm: "100%",
              md: "304px",
            },
            alignItems: "flex-start",
            zIndex: "2",
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              width: "100%",
              zIndex: "2",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flex: "1",
                justifyContent: "space-between",
                fontSize: "23px",
                fontWeight: "700",
              }}
            >
              <Box>
                {show ? (
                  <Typography sx={{ color: "white" }} variant="header2">
                    ₦ {formatter.format(balance) || 0}
                  </Typography>
                ) : (
                  <Typography sx={{ color: "white" }} variant="header2">
                    ₦ XXXXX
                  </Typography>
                )}
              </Box>
              <span onClick={() => setshow(!show)}>
                {show ? (
                  <VisibilityOffOutlined sx={{ color: "white" }} />
                ) : (
                  <VisibilityOutlined sx={{ color: "white" }} />
                )}
              </span>
            </Box>
            <Box
              sx={{ flex: "1", justifyContent: "flex-end", display: "flex" }}
            >
              <NavLink
                style={{ padding: 0, margin: 0, width: "90px" }}
                to={"/user/fund-wallet"}
              >
                <Button
                  sx={{
                    width: "90px",
                    height: "27px",
                    backgroundColor: theme.palette.secondary.main,
                    color: theme.palette.primary.main,
                    fontStyle: "normal",
                    textTransform: "none",
                    fontSize: "10px",
                    borderRadius: "10px",
                    padding: "10px 20px",
                    whiteSpace: "nowrap",
                  }}
                >
                  Fund Wallet
                </Button>
              </NavLink>
            </Box>
          </Box>
          <Typography sx={{ color: "white", zIndex: "2" }}>
            Wallet Balance
          </Typography>
        </Box>
      </Box>
    </Box>
  );
}

export default SummaryCardAlt;
