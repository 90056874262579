import React from "react";
import { Dialog, Box, Typography } from "@mui/material";
import error from "../../icons/error.png";

function Failure({ message, handleClose }) {
  return (
    <Dialog
      sx={{
        "& .MuiDialog-paper": {
          borderRadius: "30px",
          width: { xs: "100%", sm: "50%", md: "27%" },
        },
      }}
      open={true}
      onClose={handleClose}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-evenly",
          alignItems: "center",
          padding: "30px",
          width: { xs: "100%" },
          // height: "420px",
          background: "#FFFFFF",
          border: "1px solid #DFDFDF",
          borderRadius: "30px",
          textAlign: "center",
          // paddingBottom: "60px",
        }}
      >
        <img src={error} style={{}} alt="Failure" />
        <Typography
          sx={{ color: "#B30000", mt: "20px", mb: "20px", fontWeight: "700" }}
        >
          {message ?? " Transaction Failed"}
        </Typography>
      </Box>
    </Dialog>
  );
}

export default Failure;
