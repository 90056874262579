import React, { useEffect, useState } from "react";
import CustomButton from "../Button";
import { useTheme } from "@mui/material/styles";
import { Dialog, Box, Typography } from "@mui/material";
import LabeledTextField from "../LabeledTextField";
import { useFormik } from "formik";
import * as yup from "yup";
import { useNotificationStore } from "../../state";
import { useMutation, useQuery } from "@tanstack/react-query";
import {
  createBank,
  getTranferBanks,
  updateBank,
  validateAccount,
} from "../../api/userApi";
import { adminCreateBank, adminUpdateBank } from "../../api/adminApi";
import LabeledSelectField from "../LabeledSelectField";

function AddBank({ handleOpen, refetch, bank, admin }) {
  const { bank_code, account_name, account_number, id } = bank || {};
  const theme = useTheme();
  const [error, setError] = useState(null);

  const displayNotificaton = useNotificationStore(
    (state) => state.displayNotificaton
  );

  const { isLoading: createIsLoading, mutate } = useMutation(
    admin ? adminCreateBank : createBank,
    {
      onSuccess: (res) => {
        displayNotificaton(res?.data?.message);
        refetch();
        handleOpen();
      },
      onError: (e) => {
        setError(
          e.response?.data?.message ??
            e.message ??
            "something went wrong try again"
        );
      },
    }
  );

  const { isLoading: updateIsLoading, mutate: updateMutate } = useMutation(
    admin ? adminUpdateBank : updateBank,
    {
      onSuccess: (res) => {
        displayNotificaton(res?.data?.message);
        refetch();
        handleOpen();
      },
      onError: (e) => {
        setError(
          e.response?.data?.message ??
            e.message ??
            "something went wrong try again"
        );
      },
    }
  );

  const initialValues = {
    bank_code: bank_code || "",
    account_name: account_name || "",
    account_number: account_number || "",
  };
  let validationSchema = yup.object({
    bank_code: yup.string().required("this field is required"),
    account_name: yup.string().required("this field is required"),
    account_number: yup.string().required("this field is required"),
  });

  const { data, isLoading } = useQuery(["transferBanks"], getTranferBanks, {
    refetchOnWindowFocus: false,
  });

  const transferBanks = data?.data?.data || [];

  const transferBanksRef = {};
  transferBanks.forEach((v) => (transferBanksRef[v?.bank_code] = v?.bank_name));

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      bank
        ? updateMutate({
            id,
            values: {
              ...values,
              bank_name: transferBanksRef[values.bank_code],
            },
          })
        : mutate({ ...values, bank_name: transferBanksRef[values.bank_code] });
    },
  });

  // ...........................................................

  const { isLoading: validateLoading, mutate: validateMutate } = useMutation(
    validateAccount,
    {
      onSuccess: (res) => {
        formik.setFieldValue("account_name", res?.data?.data?.account_name);
      },
      onError: (e) => {
        displayNotificaton(
          e.response?.data?.message ??
            e.message ??
            "something went wrong try again"
        );
      },
    }
  );

  useEffect(() => {
    if (formik.values.account_number?.length > 9 && formik.values.bank_code) {
      formik.setFieldValue("account_name", "");
      validateMutate(formik.values);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.values.account_number, formik.values.bank_code]);

  return (
    <Dialog
      sx={{
        "& .MuiDialog-paper": {
          borderRadius: "30px",
          width: { xs: "100%", sm: "50%", md: "27%" },
        },
      }}
      open={true}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-evenly",
          alignItems: "center",
          padding: "30px",
          width: { xs: "100%" },
          // height: "420px",
          background: "#FFFFFF",
          border: "1px solid #DFDFDF",
          borderRadius: "30px",
          textAlign: "center",
        }}
      >
        <Typography variant="header" sx={{ fontSize: "19px" }}>
          {id ? "Edit" : "Add"} Bank
        </Typography>
        <Typography
          sx={{
            color: "#9C0000",
            textAlign: "start",
          }}
        >
          {error}
        </Typography>
        <form style={{ width: "100%" }} onSubmit={formik.handleSubmit}>
          <Box sx={{ mb: 4 }}>
            <LabeledTextField
              label="Account Number"
              id="account_number"
              name="account_number"
              value={formik.values.account_number}
              onChange={formik.handleChange}
              error={
                formik.touched.account_number &&
                Boolean(formik.errors.account_number)
              }
              helperText={
                formik.touched.account_number && formik.errors.account_number
              }
              fullWidth
              variant="outlined"
              size="small"
            />
          </Box>
          <Box sx={{ mb: 4, mt: 2 }}>
            <LabeledSelectField
              label="Select Bank"
              id="bank_code"
              name="bank_code"
              loading={isLoading}
              value={formik.values.bank_code}
              onChange={formik.handleChange}
              onChanged2={(v) => formik.setFieldValue(v)}
              error={
                formik.touched.bank_code && Boolean(formik.errors.bank_code)
              }
              helperText={formik.touched.bank_code && formik.errors.bank_code}
              data={
                transferBanks?.map((v) => ({
                  label: v?.bank_name,
                  value: v?.bank_code,
                })) ?? []
              }
              fullWidth
              variant="outlined"
            />
          </Box>
          <Box sx={{ mb: 4 }}>
            <LabeledTextField
              label="Account Name"
              disabled
              id="account_name"
              name="account_name"
              value={formik.values.account_name}
              onChange={formik.handleChange}
              error={
                formik.touched.account_name &&
                Boolean(formik.errors.account_name)
              }
              helperText={
                formik.touched.account_name && formik.errors.account_name
              }
              fullWidth
              variant="outlined"
            />
          </Box>
          <Box sx={{ mb: 4, display: "flex", justifyContent: "space-between" }}>
            <Box sx={{ mr: 1, width: "39%" }}>
              <CustomButton
                bgcolor={theme.palette.secondary.main}
                color={theme.palette.primary.main}
                onClick={handleOpen}
              >
                Cancel
              </CustomButton>
            </Box>
            <Box sx={{ width: "62%" }}>
              <CustomButton
                loading={createIsLoading || updateIsLoading || validateLoading}
                type="submit"
                disabled={formik.values.account_name === ""}
              >
                Continue
              </CustomButton>
            </Box>
          </Box>
        </form>
      </Box>
    </Dialog>
  );
}

export default AddBank;
