import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles({
  container: {
    textAlign: "left !important",
    fontFamily: "'Public Sans', sans-serif",
    background: "#FAFAFA",
  },
  signInSection: {
    paddingTop: "47px",
    paddingBottom: "98px",
    "& .wrapper": {
      position: "relative",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",

      "& .container": {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        background: "#fff",
        padding: "50px",
        borderRadius: "20px",
        width: "491px",
        "@media (max-width: 600px)": {
          "& svg": {
            width: "48px",
            height: "48px",
          },
        },

        "& h6": {
          fontSize: "33px",
          lineHeight: "40px",
          fontWeight: "700",
          color: "#1A3A5E",
          marginBottom: "40px",
          textAlign: "center",
          "@media (max-width: 600px)": {
            fontSize: "24px",
            lineHeight: "29px",
            marginBottom: "24px",
          },
        },

        "& .button-group": {
          display: "flex",
          flexDirection: "column",
          gap: "10px",
          "& button": {
            width: "100%",
            height: "56px",
            outline: "none",
            border: "none",
            borderRadius: "10px",
            padding: "16px 60px",
            fontSize: "16px",
            lineHeight: "20px",
            fontWeight: "500",
            textAlign: "center",
            cursor: "pointer",

            "&.primary": {
              background: "#1A3A5E",
              color: "#fff",
            },
          },
        },
      },
    },
  },
});
