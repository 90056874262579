import React, { useState } from "react";
import {
  Breadcrumbs,
  Button,
  ButtonBase,
  CircularProgress,
  Menu,
  MenuItem,
  Typography,
} from "@mui/material";
import {
  ArrowBackIos,
  ArrowForwardIos,
  MoreVert,
  NavigateNextOutlined,
} from "@mui/icons-material";
import { Box } from "@mui/system";
import { useTheme } from "@mui/material/styles";
import Grid from "@mui/material/Unstable_Grid2";
import Table from "../../components/Table";
import DefaultButton from "../../components/Button";
import { useMutation, useQuery } from "@tanstack/react-query";
import { useNotificationStore } from "../../state";
import { adminDeleteGroup, getGroups } from "../../api/adminApi";
import AddGroup from "../../components/modals/AddGroup";
import EditGroup from "../../components/modals/EditGroup";
import ConfirmDelete from "../../components/modals/ConfirmDelete";

function Groups(props) {
  const theme = useTheme();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const [target, setTarget] = useState();
  const [showAdd, setshowAdd] = useState(false);
  const [showEdit, setshowEdit] = useState(false);
  const [showDelete, setshowDelete] = useState(false);

  const handleClick = (event, target) => {
    setAnchorEl(event.currentTarget);
    setTarget(target);
    console.info(target);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleAddOpen = () => {
    if (showAdd) setTarget();
    setshowAdd(!showAdd);
  };

  const handleEditOpen = () => {
    if (showEdit) setTarget();
    setshowEdit(!showEdit);
  };

  const handleDeleteOpen = () => {
    if (showDelete) setTarget();
    setshowDelete(!showDelete);
  };

  const columns = [
    {
      Header: "NAME",
      accessor: "name",
    },
    {
      Header: "",
      accessor: "",
    },
    {
      Header: "",
      accessor: "",
    },
    {
      Header: "",
      accessor: "",
    },
    {
      Header: "",
      accessor: "",
    },
    {
      Header: "",
      accessor: "",
    },
    {
      Header: "DATE",
      accessor: "date",
    },

    {
      Header: "",
      accessor: "",
      Cell: (v) => (
        <Box sx={{ minHeight: "10px", display: "flex", alignItems: "center" }}>
          {target?.id === v?.id && deleteLoading ? (
            <CircularProgress size={20} color="primary" />
          ) : (
            <Button onClick={(e) => handleClick(e, v)}>
              <MoreVert
                id="basic-button"
                aria-controls={open ? "basic-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={open ? "true" : undefined}
              />
            </Button>
          )}
          <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            transformOrigin={{ horizontal: "right", vertical: "top" }}
            anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
            sx={{
              "& .MuiMenu-paper": {
                boxShadow: "0px 2px 25px rgba(0, 0, 0, 0.11)",
                display: v.is_banned ? "none" : "block",
              },
              display: v.is_banned ? "none" : "block",
            }}
            open={open}
            onClose={handleClose}
            MenuListProps={{
              "aria-labelledby": "basic-button",
            }}
          >
            <MenuItem
              sx={{ color: " #9D9D9D", fontSize: "13px" }}
              onClick={() => {
                handleClose();
                setshowEdit(true);
              }}
            >
              Edit
            </MenuItem>
            <MenuItem
              onClick={() => {
                handleClose();
                handleDeleteOpen();
              }}
              sx={{ color: " #9D9D9D", fontSize: "13px" }}
            >
              Delete
            </MenuItem>
          </Menu>
        </Box>
      ),
    },
  ];
  const [search, setSearch] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const { data, isLoading, isRefetching, refetch } = useQuery(
    ["groups", search, currentPage],
    () => getGroups({ search, page: currentPage })
  );

  const groups = data?.data?.data?.data || [];
  const lastPage = data?.data?.data?.meta?.last_page;

  const displayNotificaton = useNotificationStore(
    (state) => state.displayNotificaton
  );

  const { isLoading: deleteLoading, mutate: deleteMutate } = useMutation(
    () => adminDeleteGroup(target?.id),
    {
      onSuccess: (res) => {
        console.info({ res });
        displayNotificaton(res?.data?.message);
        handleDeleteOpen();
        refetch();
      },
      onError: (e) => {
        displayNotificaton(
          e.response?.data?.message ??
            e.message ??
            "something went wrong try again"
        );
      },
    }
  );

  return (
    <Box sx={{ width: { xs: "100%", sm: "55%", md: "85%", lg: "100%" } }}>
      {showAdd && <AddGroup refetch={refetch} handleOpen={handleAddOpen} />}
      {showDelete && (
        <ConfirmDelete
          close={handleDeleteOpen}
          confirm={deleteMutate}
          loading={deleteLoading}
        />
      )}
      {showEdit && (
        <EditGroup
          refetch={refetch}
          handleOpen={handleEditOpen}
          group={target}
        />
      )}

      <Box sx={{ display: "flex" }}>
        <Typography variant="header" color={theme.palette.neutral.N200}>
          Groups
        </Typography>
      </Box>
      <Breadcrumbs
        separator={<NavigateNextOutlined fontSize="22px" />}
        aria-label="breadcrumb"
      >
        <Typography color="primary">Groups</Typography>
        {/* <Typography color="primary">{labels[value]}</Typography> */}
      </Breadcrumbs>
      <Grid container spacing={2} mt={0}>
        <Grid xs={12} sm={12} md={12} xl={12}>
          <Box
            className="card"
            sx={{ mt: 5, minHeight: "60vh", padding: "20px" }}
          >
            <Table
              columns={columns}
              data={groups}
              loading={isLoading || isRefetching}
              setFilter={setSearch}
              action={
                <Box sx={{ width: "min(250%,250px)" }}>
                  <DefaultButton onClick={handleAddOpen}>
                    + Create
                    <Box sx={{ display: { xs: "none", md: "contents" } }}>
                      {" "}
                      Group
                    </Box>
                  </DefaultButton>
                </Box>
              }
            />
          </Box>
          <Box
            sx={{ display: "flex", justifyContent: "flex-end", width: "100%" }}
          >
            <Box sx={{ display: "flex", justifyContent: "end", width: 120 }}>
              <ButtonBase
                sx={{
                  color: "rgb(59, 59, 59))",
                  backgroundColor: "grey",
                  marginLeft: "auto",
                  width: "40px",
                  height: "40px",
                  cursor: "pointer",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  borderRadius: 1,
                }}
                disabled={currentPage <= 1}
                onClick={() => setCurrentPage(currentPage - 1)}
              >
                <ArrowBackIos />
              </ButtonBase>
              <Box
                sx={{
                  marginLeft: "auto",
                  width: "40px",
                  height: "40px",
                  cursor: "pointer",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  borderRadius: 1,
                }}
              >
                {currentPage}
              </Box>
              <ButtonBase
                sx={{
                  color: "rgb(59, 59, 59))",
                  backgroundColor: "grey",
                  marginLeft: "auto",
                  width: "40px",
                  height: "40px",
                  cursor: "pointer",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  borderRadius: 1,
                }}
                disabled={currentPage >= lastPage}
                onClick={() => setCurrentPage(currentPage + 1)}
              >
                <ArrowForwardIos />
              </ButtonBase>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
}

export default Groups;
