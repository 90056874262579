/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import CustomButton from "../Button";
import { useTheme } from "@mui/material/styles";
import { Dialog, Box, Typography } from "@mui/material";
import LabeledTextField from "../LabeledTextField";
import { useFormik } from "formik";
import * as yup from "yup";
import { useNotificationStore, useUserStore } from "../../state";
import { useMutation } from "@tanstack/react-query";
import { fundWallet, getMe, verifyFundWallet } from "../../api/userApi";
import { usePaystackPayment } from "react-paystack";
import { useUser } from "../../hooks";

function FundAccount({ handleOpen, refetch, selected }) {
  const publicKey = process.env.REACT_APP_PAYSTACK_KEY;
  const { id } = selected || {};
  const [reference, setReference] = useState();
  const theme = useTheme();
  const [error, setError] = useState(null);
  const { email } = useUserStore((state) => state.user);
  const setUser = useUserStore((state) => state.setUser);

  const displayNotificaton = useNotificationStore(
    (state) => state.displayNotificaton
  );

  const { isLoading, mutate } = useMutation(fundWallet, {
    onSuccess: (res) => {
      // refetch();
      const { payment_method, reference } = res?.data?.data || {};
      if (payment_method === "card") setReference(reference);
      else {
        displayNotificaton(res?.data?.message);
        handleOpen();
      }
    },
    onError: (e) => {
      setError(
        e.response?.data?.message ??
          e.message ??
          "something went wrong try again"
      );
    },
  });

  const { isLoading: verifyIsLoading, mutate: varifyMutate } = useMutation(
    verifyFundWallet,
    {
      onSuccess: async (res) => {
        if (res.data.status) {
          const res = await getMe();
          console.info({ res });
          if (res.data?.status) setUser(res.data?.data);
        }
        displayNotificaton(res?.data?.message);
        handleOpen();
      },
      onError: (e) => {
        setError(
          e.response?.data?.message ??
            e.message ??
            "something went wrong try again"
        );
      },
    }
  );

  const initialValues = {
    amount: "",
  };
  let validationSchema = yup.object({
    amount: yup.string().required("this field is required"),
  });
  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      if (id) {
        values["bank_id"] = id;
        values["payment_method"] = "bank";
      } else values["payment_method"] = "card";
      mutate(values);
    },
  });

  // paystack
  const config = {
    reference,
    email,
    amount:
      formik.values.amount > 2500
        ? parseInt(
            (parseFloat(formik.values.amount) +
              parseFloat(formik.values.amount * 0.015) +
              100) *
              100
          )
        : parseInt(
            (parseFloat(formik.values.amount) +
              parseFloat(formik.values.amount * 0.015)) *
              100
          ),
    publicKey,
  };

  // you can call this function anything
  const onSuccess = () => {
    // Implementation for whatever you want to do with reference and after success call.
    console.log(reference);
    varifyMutate(reference);
  };

  // you can call this function anything
  const onClose = () => {
    // implementation for  whatever you want to do when the Paystack dialog closed.
    console.log("closed");
  };

  const initializePayment = usePaystackPayment(config);

  useEffect(() => {
    if (reference) initializePayment(onSuccess, onClose);
  }, [reference]);

  const { fetchUser } = useUser();

  useEffect(() => {
    fetchUser();
  }, []);
  return (
    <Dialog
      sx={{
        "& .MuiDialog-paper": {
          borderRadius: "30px",
          width: { xs: "100%", sm: "50%", md: "27%" },
        },
      }}
      open={true}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-evenly",
          alignItems: "center",
          padding: "30px",
          width: { xs: "100%" },
          // height: "420px",
          background: "#FFFFFF",
          border: "1px solid #DFDFDF",
          borderRadius: "30px",
          textAlign: "center",
        }}
      >
        <Typography variant="header" sx={{ fontSize: "19px" }}>
          Enter Amount
        </Typography>
        <Typography
          sx={{
            color: "#9C0000",
            textAlign: "start",
          }}
        >
          {error}
        </Typography>
        <form style={{ width: "100%" }} onSubmit={formik.handleSubmit}>
          <Box sx={{ mb: 4 }}>
            <LabeledTextField
              label="Amount "
              id="amount"
              name="amount"
              value={formik.values.amount}
              onChange={formik.handleChange}
              error={formik.touched.amount && Boolean(formik.errors.amount)}
              helperText={formik.touched.amount && formik.errors.amount}
              fullWidth
              variant="outlined"
              size="small"
            />
          </Box>

          <Box sx={{ mb: 4, display: "flex", justifyContent: "space-between" }}>
            <Box sx={{ mr: 1, width: "39%" }}>
              <CustomButton
                bgcolor={theme.palette.secondary.main}
                color={theme.palette.primary.main}
                onClick={handleOpen}
              >
                Cancel
              </CustomButton>
            </Box>
            <Box sx={{ width: "62%" }}>
              <CustomButton
                loading={isLoading || verifyIsLoading}
                type="submit"
              >
                Continue
              </CustomButton>
            </Box>
          </Box>
        </form>
      </Box>
    </Dialog>
  );
}

export default FundAccount;
