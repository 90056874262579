import React, { useState } from "react";

import { Box } from "@mui/system";
import { CircularProgress, Typography } from "@mui/material";
import AddBank from "../modals/AddBank";
import { useMutation } from "@tanstack/react-query";
import { useNotificationStore } from "../../state";
import { deleteBank } from "../../api/userApi";

function BankCard({ bank, refetch }) {
  const [showAdd, setshowAdd] = useState(false);

  const handleOpen = () => {
    setshowAdd(!showAdd);
  };

  const displayNotificaton = useNotificationStore(
    (state) => state.displayNotificaton
  );

  const { isLoading, mutate } = useMutation(deleteBank, {
    onSuccess: (res) => {
      displayNotificaton(res?.data?.message);
      refetch();
      handleOpen();
    },
    onError: (e) => {
      displayNotificaton(
        e.response?.data?.message ??
          e.message ??
          "something went wrong try again"
      );
    },
  });

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        padding: "20px 10px 20px 20px",
        border: "1px solid #DFDFDF",
        borderRadius: "20px",
        width: "100%",
        textAlign: "left",
        margin: "10px 0",
      }}
    >
      {showAdd && (
        <AddBank handleOpen={handleOpen} refetch={refetch} bank={bank} />
      )}

      <Typography
        sx={{
          fontWeight: "700",
        }}
      >
        {bank.account_name}
      </Typography>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        <Typography>
          {bank.account_number?.substring(0, 5)}
          ******
        </Typography>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
          }}
        >
          <Typography
            sx={{
              marginRight: "5px",
              cursor: "pointer",
              fontSize: "13px",
              fontWeight: "500",
            }}
            onClick={handleOpen}
          >
            Edit
          </Typography>
          {isLoading ? (
            <CircularProgress
              sx={{
                margin: "5px",
              }}
              size={16}
            />
          ) : (
            <Typography
              sx={{
                marginRight: "5px",
                cursor: "pointer",
                color: "#808080",
                fontSize: "13px",
                fontWeight: "500",
              }}
              onClick={() => mutate(bank.id)}
            >
              Delete
            </Typography>
          )}
        </Box>
      </Box>
      <Typography sx={{ marginTop: "5px" }}>{bank.bank_name}</Typography>
    </Box>
  );
}

export default BankCard;
