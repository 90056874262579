/* eslint-disable eqeqeq */
import React, { useRef } from "react";
import CustomButton from "../Button";
import { useTheme } from "@mui/material/styles";
import { Dialog, Box, Typography } from "@mui/material";
import LabeledTextField from "../LabeledTextField";
import { useFormik } from "formik";
import * as yup from "yup";
import { useMutation } from "@tanstack/react-query";
import { addfund, deductFund } from "../../api/adminApi";
import { useNotificationStore } from "../../state";

function FundUser({ user, handleClose, refetch }) {
  const theme = useTheme();
  const { fullname, id } = user;

  const displayNotificaton = useNotificationStore(
    (state) => state.displayNotificaton
  );

  const { isLoading: deductLoading, mutate: deductMutate } = useMutation(
    (val) => deductFund({ id, data: val }),
    {
      onSuccess: (res) => {
        console.info({ res });
        displayNotificaton(res?.data?.data?.message);
        refetch();
        handleClose();
      },
      onError: (e) => {
        displayNotificaton(
          e.response?.data?.message ??
            e.message ??
            "something went wrong try again"
        );
      },
    }
  );

  const { isLoading: addLoading, mutate: addMutate } = useMutation(
    (val) => addfund({ id, data: val }),
    {
      onSuccess: (res) => {
        console.info({ res });
        refetch();

        displayNotificaton(res?.data?.message);
        handleClose();
      },
      onError: (e) => {
        displayNotificaton(
          e.response?.data?.message ??
            e.message ??
            "something went wrong try again"
        );
      },
    }
  );

  const submitRef = useRef();
  const initialValues = {
    amount: "",
  };
  let validationSchema = yup.object({
    amount: yup.string().required("this field is required"),
  });

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      if (submitRef.current == "add") addMutate(values);
      if (submitRef.current == "deduct") deductMutate(values);
    },
  });

  return (
    <Dialog
      sx={{
        "& .MuiDialog-paper": {
          borderRadius: "30px",
          width: { xs: "100%", sm: "50%", md: "27%" },
        },
      }}
      open={true}
      onClose={handleClose}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-evenly",
          alignItems: "center",
          padding: "30px",
          width: { xs: "100%" },
          // height: "420px",
          background: "#FFFFFF",
          border: "1px solid #DFDFDF",
          borderRadius: "30px",
          textAlign: "center",
        }}
      >
        <Typography>
          Add/Remove fund from user
          <Typography variant="header2">{fullname}</Typography>
        </Typography>

        <Box
          sx={{
            mb: 4,
            display: "flex",
            justifyContent: "space-between",
            width: "100%",
            padding: "30px",
          }}
        >
          <form style={{ width: "100%" }} onSubmit={formik.handleSubmit}>
            <Box sx={{ margin: "20px 0" }}>
              <LabeledTextField
                label="Amount"
                id="amount"
                name="amount"
                value={formik.values.amount}
                onChange={formik.handleChange}
                error={formik.touched.amount && Boolean(formik.errors.amount)}
                helperText={formik.touched.amount && formik.errors.amount}
                fullWidth
              />
            </Box>

            <Box sx={{ display: "flex" }}>
              <Box sx={{ mr: 1, width: "49%" }}>
                <CustomButton
                  bgcolor={theme.palette.secondary.main}
                  color={theme.palette.primary.main}
                  onClick={() => (submitRef.current = "deduct")}
                  type="submit"
                  loading={deductLoading}
                >
                  Deduct
                </CustomButton>
              </Box>
              <Box sx={{ width: "49%" }}>
                <CustomButton
                  onClick={() => (submitRef.current = "add")}
                  bgcolor={theme.palette.primary.main}
                  type="submit"
                  loading={addLoading}
                >
                  Add
                </CustomButton>
              </Box>
            </Box>
          </form>
        </Box>
      </Box>
    </Dialog>
  );
}

export default FundUser;
