import React, { useState } from "react";
import CustomButton from "../Button";
import { useTheme } from "@mui/material/styles";
import { Dialog, Box, Typography } from "@mui/material";
import LabeledTextField from "../LabeledTextField";
import { useFormik } from "formik";
import * as yup from "yup";
import { useNotificationStore } from "../../state";
import { useMutation, useQuery } from "@tanstack/react-query";
import { createUser, getGroups, updateUser } from "../../api/adminApi";
import LabeledSelectField from "../LabeledSelectField";

function AddUser({ handleClose, refetch, user, type }) {
  const { username, phone, email, id, role } = user || {};
  const theme = useTheme();
  const [error, setError] = useState(null);

  const displayNotificaton = useNotificationStore(
    (state) => state.displayNotificaton
  );

  const { data, isLoading: groupLoading } = useQuery(["groups"], getGroups, {
    refetchOnWindowFocus: false,
  });

  const groups = data?.data?.data?.data || [];

  const groupsMenu = groups?.map((v) => ({ label: v.name, value: v.id }));

  const { isLoading, mutate } = useMutation(createUser, {
    onSuccess: (res) => {
      displayNotificaton(res?.data?.message);
      refetch();
      handleClose();
    },
    onError: (e) => {
      setError(
        e.response?.data?.message ??
          e.message ??
          "something went wrong try again"
      );
    },
  });
  const { isLoading: editIsloading, mutate: editMutate } = useMutation(
    updateUser,
    {
      onSuccess: (res) => {
        displayNotificaton(res?.data?.message);
        refetch();
        handleClose();
      },
      onError: (e) => {
        displayNotificaton(
          e.response?.data?.message ??
            e.message ??
            "something went wrong try again"
        );

        setError(
          e.response?.data?.message ??
            e.message ??
            "something went wrong try again"
        );
      },
    }
  );
  const initialValues = {
    username: username || "",
    phone: phone || "",
    email: email || "",
    group_id: "",
  };
  let validationSchema = yup.object({
    username: yup.string().required("this field is required"),
    phone: yup.string().required("this field is required"),
    email: yup.string().required("this field is required"),
  });

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      console.info(values);
      if (values?.group_id === "") delete values.group_id;
      id
        ? editMutate({
            id,
            data: {
              ...values,
              role,
            },
          })
        : mutate({
            ...values,
            role: type === "Reseller" ? "reseller" : "user",
          });
    },
  });

  return (
    <Dialog
      sx={{
        "& .MuiDialog-paper": {
          borderRadius: "30px",
          width: { xs: "100%", sm: "50%", md: "27%" },
        },
      }}
      open={true}
      onClose={handleClose}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-evenly",
          alignItems: "center",
          padding: "30px",
          width: { xs: "100%" },
          // height: "420px",
          background: "#FFFFFF",
          border: "1px solid #DFDFDF",
          borderRadius: "30px",
          textAlign: "center",
        }}
      >
        <Typography variant="header" sx={{ fontSize: "19px" }}>
          {id ? "Edit " : "Add "} {type}
        </Typography>
        <Typography
          sx={{
            color: "#9C0000",
            textAlign: "start",
          }}
        >
          {error}
        </Typography>
        <form style={{ width: "100%" }} onSubmit={formik.handleSubmit}>
          <Box sx={{ mb: 4, mt: 2 }}>
            <LabeledTextField
              label="Username"
              id="username"
              name="username"
              value={formik.values.username}
              onChange={formik.handleChange}
              error={formik.touched.username && Boolean(formik.errors.username)}
              helperText={formik.touched.username && formik.errors.username}
              fullWidth
              variant="outlined"
            />
          </Box>
          <Box sx={{ mb: 4 }}>
            <LabeledTextField
              label="Email"
              id="email"
              name="email"
              value={formik.values.email}
              onChange={formik.handleChange}
              error={formik.touched.email && Boolean(formik.errors.email)}
              helperText={formik.touched.email && formik.errors.email}
              fullWidth
              variant="outlined"
              size="small"
            />
          </Box>
          <Box sx={{ mb: 4 }}>
            <LabeledTextField
              label="Phone Number"
              // hint="You can add multiple numbers. Ex: 08030302020, 09016161919"
              id="phone"
              name="phone"
              value={formik.values.phone}
              onChange={formik.handleChange}
              error={formik.touched.phone && Boolean(formik.errors.phone)}
              helperText={formik.touched.phone && formik.errors.phone}
              fullWidth
              variant="outlined"
              type="number"
            />
          </Box>
          {type === "Reseller" && (
            <Box sx={{ mb: 4 }}>
              <LabeledSelectField
                label="Group (Optional)"
                // hint="You can add multiple numbers. Ex: 08030302020, 09016161919"
                id="group_id"
                name="group_id"
                value={formik.values.group_id}
                onChange={formik.handleChange}
                error={
                  formik.touched.group_id && Boolean(formik.errors.group_id)
                }
                helperText={formik.touched.group_id && formik.errors.group_id}
                fullWidth
                variant="outlined"
                data={groupsMenu}
                loading={groupLoading}
              />
            </Box>
          )}

          <Box sx={{ mb: 4, display: "flex", justifyContent: "space-between" }}>
            <Box sx={{ mr: 1, width: "39%" }}>
              <CustomButton
                bgcolor={theme.palette.secondary.main}
                color={theme.palette.primary.main}
                onClick={handleClose}
              >
                Cancel
              </CustomButton>
            </Box>
            <Box sx={{ width: "62%" }}>
              <CustomButton loading={isLoading || editIsloading} type="submit">
                Continue
              </CustomButton>
            </Box>
          </Box>
        </form>
      </Box>
    </Dialog>
  );
}

export default AddUser;
