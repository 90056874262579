import React, { useState } from "react";
import CustomButton from "../Button";
import { useTheme } from "@mui/material/styles";
import {
  Dialog,
  Box,
  Typography,
  InputAdornment,
  IconButton,
} from "@mui/material";
import LabeledTextField from "../LabeledTextField";
import * as yup from "yup";
import { useFormik } from "formik";
import { useMutation } from "@tanstack/react-query";
import { EyeIcon, EyeSlashIcon } from "@heroicons/react/24/outline";
import { updatePassword } from "../../api/userApi";
import { useNotificationStore } from "../../state";

function ChangePassword({ handleClickShowEditPassword }) {
  const theme = useTheme();

  const [error, setError] = useState(null);
  const [showPassword, setshowPassword] = useState(false);
  const [showNewPassword, setshowNewPassword] = useState(false);
  const [showConfirmPassword, setshowConfirmPassword] = useState(false);
  const handleClickShowPassword = () => {
    setshowPassword(!showPassword);
  };
  const handleClickShowNewPassword = () => {
    setshowNewPassword(!showNewPassword);
  };
  const handleClickShowConfirmPassword = () => {
    setshowConfirmPassword(!showConfirmPassword);
  };

  const displayNotificaton = useNotificationStore(
    (state) => state.displayNotificaton
  );
  const { isLoading, mutate } = useMutation(updatePassword, {
    onSuccess: (res) => {
      displayNotificaton(res?.data?.message);
    },
    onError: (e) => {
      setError(
        e.response?.data?.message ??
          e.message ??
          "something went wrong try again"
      );
    },
  });

  const initialValues = {
    password: "",
    new_password: "",
    password_confirmation: "",
  };
  let validationSchema = yup.object({
    password: yup.string().required("this field is required"),
    new_password: yup.string().required("this field is required"),
    password_confirmation: yup
      .string()
      .required("this field is required")
      .oneOf([yup.ref("new_password"), null], "Passwords must match"),
  });
  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    onSubmit: async ({ password, new_password, password_confirmation }) => {
      mutate({
        current_password: password,
        new_password,
        new_password_confirmation: password_confirmation,
      });
    },
  });

  return (
    <Dialog
      sx={{
        "& .MuiDialog-paper": {
          borderRadius: "30px",
          width: { xs: "100%", sm: "50%", md: "27%" },
        },
      }}
      open={true}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-evenly",
          alignItems: "center",
          padding: "30px",
          width: { xs: "100%" },
          // height: "420px",
          background: "#FFFFFF",
          border: "1px solid #DFDFDF",
          borderRadius: "30px",
        }}
      >
        <form style={{ width: "100%" }} onSubmit={formik.handleSubmit}>
          <Box sx={{ width: "100%", marginTop: "20px" }}>
            <Box
              sx={{
                marginBottom: "60px",
              }}
            >
              <Typography
                sx={{
                  fontSize: "33px",
                  fontWeight: "700",
                  textAlign: "start",
                }}
              >
                Change Password
              </Typography>
              <Typography
                sx={{
                  color: "#9C0000",
                  textAlign: "start",
                }}
              >
                {error}
              </Typography>
            </Box>
            <Box sx={{ margin: "20px 0" }}>
              <LabeledTextField
                label="Old Password"
                type={showPassword ? "text" : "password"}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      // onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {!showPassword ? (
                        <EyeSlashIcon
                          style={{
                            color: "grey",
                            width: "24px",
                          }}
                        />
                      ) : (
                        <EyeIcon
                          style={{
                            color: "grey",
                            width: "24px",
                          }}
                        />
                      )}
                    </IconButton>
                  </InputAdornment>
                }
                id="password"
                name="password"
                value={formik.values.password}
                onChange={formik.handleChange}
                error={
                  formik.touched.password && Boolean(formik.errors.password)
                }
                helperText={formik.touched.password && formik.errors.password}
                fullWidth
              />
            </Box>
            <Box sx={{ margin: "20px 0" }}>
              <LabeledTextField
                label="New Password"
                type={showNewPassword ? "text" : "password"}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowNewPassword}
                      // onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {!showNewPassword ? (
                        <EyeSlashIcon
                          style={{
                            color: "grey",
                            width: "24px",
                          }}
                        />
                      ) : (
                        <EyeIcon
                          style={{
                            color: "grey",
                            width: "24px",
                          }}
                        />
                      )}
                    </IconButton>
                  </InputAdornment>
                }
                id="new_password"
                name="new_password"
                value={formik.values.new_password}
                onChange={formik.handleChange}
                error={
                  formik.touched.new_password &&
                  Boolean(formik.errors.new_password)
                }
                helperText={
                  formik.touched.new_password && formik.errors.new_password
                }
                fullWidth
              />
            </Box>
            <Box sx={{ margin: "20px 0" }}>
              <LabeledTextField
                label="Password Confirmation"
                type={showConfirmPassword ? "text" : "password"}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowConfirmPassword}
                      // onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {!showConfirmPassword ? (
                        <EyeSlashIcon
                          style={{
                            color: "grey",
                            width: "24px",
                          }}
                        />
                      ) : (
                        <EyeIcon
                          style={{
                            color: "grey",
                            width: "24px",
                          }}
                        />
                      )}
                    </IconButton>
                  </InputAdornment>
                }
                id="password_confirmation"
                name="password_confirmation"
                value={formik.values.password_confirmation}
                onChange={formik.handleChange}
                error={
                  formik.touched.password_confirmation &&
                  Boolean(formik.errors.password_confirmation)
                }
                helperText={
                  formik.touched.password_confirmation &&
                  formik.errors.password_confirmation
                }
                fullWidth
              />
            </Box>
            <Box
              sx={{
                mb: 4,
                display: "flex",
                justifyContent: "space-between",
                width: "100%",
              }}
            >
              <Box sx={{ mr: 1, width: "39%" }}>
                <CustomButton
                  bgcolor={theme.palette.secondary.main}
                  color={theme.palette.primary.main}
                  onClick={handleClickShowEditPassword}
                >
                  Cancel
                </CustomButton>
              </Box>
              <Box sx={{ width: "62%" }}>
                <CustomButton
                  type="submit"
                  bgcolor={theme.palette.primary.main}
                  loading={isLoading}
                >
                  Save
                </CustomButton>
              </Box>
            </Box>
          </Box>
        </form>
      </Box>
    </Dialog>
  );
}

export default ChangePassword;
