import create from "zustand";
import { persist } from "zustand/middleware";

export const useUserStore = create(
  persist(
    (set, get) => ({
      user: null,
      loggedIn: false,
      charge: {},
      updateUser: (user) =>
        set((state) => ({ user: { ...state.user, ...user } })),
      setUser: (data) => set({ user: data }),
      setCharge: (data) => set({ charge: data }),
      clearUser: () => set({ user: null }),
      loginUser: (user) => set({ user, loggedIn: true }),
      logoutUser: () => set({ user: null, loggedIn: false }),
    }),
    {
      name: "zain-storage", // unique name
      getStorage: () => sessionStorage, // (optional) by default, 'localStorage' is used
    }
  )
);

export const useAppStore = create((set) => ({
  loading: false,
  setLoading: (val) => set({ loading: val }),
}));

export const useNotificationStore = create((set) => ({
  open: false,
  message: "",
  setOpen: (val) => set({ open: val }),
  displayNotificaton: (message) => {
    set({ open: true, message });

    setTimeout(() => {
      set({ open: false });
    }, 5000);
  },
}));
