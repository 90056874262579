/* eslint-disable eqeqeq */
import React, { useState } from "react";
import {
  Breadcrumbs,
  Button,
  ButtonBase,
  CircularProgress,
  Menu,
  MenuItem,
  Typography,
  Modal,
} from "@mui/material";
import {
  ArrowBackIos,
  ArrowForwardIos,
  MoreVert,
  NavigateNextOutlined,
} from "@mui/icons-material";
import { Box } from "@mui/system";
import { useTheme } from "@mui/material/styles";
import TabHeader from "../../components/TabHeader";
import Grid from "@mui/material/Unstable_Grid2";
import Table from "../../components/Table";
import DefaultButton from "../../components/Button";
import { useMutation, useQuery } from "@tanstack/react-query";
import { useNotificationStore } from "../../state";
import { banUser, deleteUser, getusers, unbanUser } from "../../api/adminApi";
import FundUser from "../../components/modals/FundUser";
import "../index.css";
import AddUser from "../../components/modals/AddUser";
import EditUserGroup from "../../components/modals/EditUserGroup";
import StorageService from "../../StorageService";
import { NavLink } from "react-router-dom";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function Customers(props) {
  const theme = useTheme();
  const [value, setValue] = useState(0);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [anchorEl2, setAnchorEl2] = React.useState(null);
  const open = Boolean(anchorEl);
  const open2 = Boolean(anchorEl2);
  const [showAdd, setshowAdd] = useState(false);

  const [showEditGroupModal, setShowEditGroupModal] = useState(false);

  const [search, setSearch] = useState("");
  const [currentPage, setCurrentPage] = useState(1);

  const [fundModalOpen, setFundModalOpen] = useState();
  const [target, setTarget] = useState();

  const handleClick = (event, target) => {
    setAnchorEl(event.currentTarget);
    setTarget(target);
  };
  const handleClick2 = (event, target) => {
    setAnchorEl2(event.currentTarget);

    setTarget(target);
  };
  const handleClose = () => {
    setAnchorEl(null);
    setAnchorEl2(null);
  };
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleFund = () => {
    setFundModalOpen(!fundModalOpen);
  };

  const handleAddOpen = (isEdit) => {
    if (showAdd) setTarget();
    setshowAdd(!showAdd);
  };

  const handleEditGroupModal = () => {
    setShowEditGroupModal(true);
  };
  const handleCloseEditGroupModal = () => {
    setShowEditGroupModal(false);
  };
  const columns = [
    {
      Header: "USER",
      accessor: "username",
    },
    {
      Header: "EMAIL",
      accessor: "email",
    },

    {
      Header: "PHONE NUMBER",
      accessor: "phone",
    },
    {
      Header: "WALLET BALANCE",
      accessor: "balance",
    },
    {
      Header: "GROUP",
      accessor: "show.runtime",
    },
    {
      Header: "DATE",
      accessor: "show.runtime",
    },
    {
      Header: "",
      accessor: "",
      // Cell method will provide the cell value; we pass it to render a custom component
      Cell: (v) => (
        <Box sx={{ minHeight: "10px", display: "flex", alignItems: "center" }}>
          {target?.id === v?.id &&
          (banLoading || unbanLoading || deleteLoading) ? (
            <CircularProgress color="primary" size={20} />
          ) : v?.is_banned ? (
            <Button onClick={(e) => handleClick2(e, v)}>
              <MoreVert
                id="basic-button"
                aria-controls={
                  open ? "basic-menu" : open2 ? "basic-menu2" : undefined
                }
                aria-haspopup="true"
                aria-expanded={open ? "true" : undefined}
              />
            </Button>
          ) : (
            <Button onClick={(e) => handleClick(e, v)}>
              <MoreVert
                id="basic-button"
                aria-controls={
                  open ? "basic-menu" : open2 ? "basic-menu2" : undefined
                }
                aria-haspopup="true"
                aria-expanded={open ? "true" : undefined}
              />
            </Button>
          )}

          <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            transformOrigin={{ horizontal: "right", vertical: "top" }}
            anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
            sx={{
              "& .MuiMenu-paper": {
                boxShadow: "0px 2px 25px rgba(0, 0, 0, 0.11)",
              },
            }}
            open={open && target}
            onClose={handleClose}
            MenuListProps={{
              "aria-labelledby": "basic-button",
            }}
          >
            <MenuItem
              onClick={() => {
                handleFund();
                handleClose();
              }}
              sx={{ fontSize: "13px" }}
              // onClick={handleClose}
            >
              Add and Deduct
            </MenuItem>
            {/* <MenuItem
              sx={{ color: " #9D9D9D", fontSize: "13px" }}
              onClick={() => {
                handleAddOpen(true);
              }}
            >
              Edit
            </MenuItem> */}
            <MenuItem
              sx={{ color: " #9D9D9D", fontSize: "13px" }}
              onClick={() => {
                handleClose();
                banMutate();
              }}
            >
              Ban
            </MenuItem>
            <MenuItem
              onClick={() => {
                // handleEditGroupModal();
                handleClose();
              }}
              sx={{ fontSize: "13px" }}
            >
              <NavLink to="/admin/editCustomer" state={{ user: target }}>
                Edit Group
              </NavLink>
            </MenuItem>
            <MenuItem
              onClick={() => {
                handleClose();
                deleteMutate();
              }}
              sx={{ color: " #9D9D9D", fontSize: "13px" }}
            >
              Delete
            </MenuItem>
          </Menu>

          <Menu
            id="basic-menu2"
            anchorEl={anchorEl2}
            transformOrigin={{ horizontal: "right", vertical: "top" }}
            anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
            sx={{
              "& .MuiMenu-paper": {
                boxShadow: "0px 2px 25px rgba(0, 0, 0, 0.11)",
              },
            }}
            open={open2 && target}
            onClose={handleClose}
            MenuListProps={{
              "aria-labelledby": "basic-button",
            }}
          >
            <MenuItem
              onClick={() => {
                handleFund();
                handleClose();
              }}
              sx={{ fontSize: "13px" }}
              // onClick={handleClose}
            >
              Add and Deduct
            </MenuItem>
            {/* <MenuItem
              sx={{ color: " #9D9D9D", fontSize: "13px" }}
              onClick={() => {
                handleAddOpen(true);
              }}
            >
              Edit
            </MenuItem> */}
            <MenuItem
              sx={{ color: " #9D9D9D", fontSize: "13px" }}
              onClick={() => {
                handleClose();
                unbanMutate();
              }}
            >
              Unban
            </MenuItem>
            <MenuItem
              onClick={() => {
                handleClose();
                deleteMutate();
              }}
              sx={{ color: " #9D9D9D", fontSize: "13px" }}
            >
              Delete
            </MenuItem>
          </Menu>
        </Box>
      ),
      // Cell: () => <Box>{v.is_banned}</Box>,
    },
  ];
  const labels = ["All Customers", "Banned Customers"];

  const { data, isLoading, isRefetching, refetch } = useQuery(
    ["getusers", value, search, currentPage],
    () =>
      getusers({
        role: "user",
        status: value == 0 ? "unbanned" : "banned",
        filter: "year",
        search,
        page: currentPage,
      })
  );

  const customers = data?.data?.data?.users?.data || [];
  const lastPage = data?.data?.data?.users?.meta?.last_page;

  const displayNotificaton = useNotificationStore(
    (state) => state.displayNotificaton
  );

  console.info({ customers });

  // useEffect(() => {
  //   refetch();
  // }, []);

  const { isLoading: banLoading, mutate: banMutate } = useMutation(
    () => banUser(target?.id),
    {
      onSuccess: (res) => {
        console.info({ res });
        displayNotificaton(res?.data?.message);
        refetch();
      },
      onError: (e) => {
        displayNotificaton(
          e.response?.data?.message ??
            e.message ??
            "something went wrong try again"
        );
      },
    }
  );

  const { isLoading: unbanLoading, mutate: unbanMutate } = useMutation(
    () => unbanUser(target?.id),
    {
      onSuccess: (res) => {
        console.info({ res });
        displayNotificaton(res?.data?.message);
        refetch();
      },
      onError: (e) => {
        displayNotificaton(
          e.response?.data?.message ??
            e.message ??
            "something went wrong try again"
        );
      },
    }
  );

  const { isLoading: deleteLoading, mutate: deleteMutate } = useMutation(
    () => deleteUser(target?.id),
    {
      onSuccess: (res) => {
        console.info({ res });
        displayNotificaton(res?.data?.message);
        refetch();
      },
      onError: (e) => {
        displayNotificaton(
          e.response?.data?.message ??
            e.message ??
            "something went wrong try again"
        );
      },
    }
  );

  return (
    <Box sx={{ width: { xs: "100%", sm: "65%", md: "80%", lg: "100%" } }}>
      {fundModalOpen && (
        <FundUser user={target} handleClose={handleFund} refetch={refetch} />
      )}
      {showAdd && (
        <AddUser
          user={target}
          handleClose={handleAddOpen}
          refetch={refetch}
          type="Customer"
        />
      )}
      {showEditGroupModal && (
        <EditUserGroup user={target} handleClose={handleCloseEditGroupModal} />
      )}
      <Box sx={{ display: "flex" }}>
        <Typography variant="header" color={theme.palette.neutral.N200}>
          Customers
        </Typography>
      </Box>
      <Breadcrumbs
        separator={<NavigateNextOutlined fontSize="22px" />}
        aria-label="breadcrumb"
      >
        <Typography color={theme.palette.neutral.N200}>Customers</Typography>
        <Typography color="primary">{labels[value]}</Typography>
      </Breadcrumbs>
      <Grid container spacing={2} mt={4}>
        <Grid xs={12} sm={11.5}>
          <TabHeader
            labels={labels}
            value={value}
            handleChange={handleChange}
          />
          <Box className="card" sx={{ mt: 5, minHeight: "60vh" }}>
            <TabPanel value={value} index={0}>
              <Table
                columns={columns}
                data={customers}
                action={
                  <Box sx={{ width: "min(250%,250px)" }}>
                    <DefaultButton onClick={handleAddOpen}>
                      + Add
                      <Box sx={{ display: { xs: "none", md: "contents" } }}>
                        {" "}
                        Customer
                      </Box>
                    </DefaultButton>
                  </Box>
                }
                loading={isLoading || isRefetching}
                setFilter={setSearch}
              />
            </TabPanel>
            <TabPanel value={value} index={1}>
              <Table
                columns={columns}
                data={customers}
                loading={isLoading || isRefetching}
                setFilter={setSearch}
              />
            </TabPanel>
          </Box>
          <Box
            sx={{ display: "flex", justifyContent: "flex-end", width: "100%" }}
          >
            <Box sx={{ display: "flex", justifyContent: "end", width: 120 }}>
              <ButtonBase
                sx={{
                  color: "rgb(59, 59, 59))",
                  backgroundColor: "grey",
                  marginLeft: "auto",
                  width: "40px",
                  height: "40px",
                  cursor: "pointer",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  borderRadius: 1,
                }}
                disabled={currentPage <= 1}
                onClick={() => setCurrentPage(currentPage - 1)}
              >
                <ArrowBackIos />
              </ButtonBase>
              <Box
                sx={{
                  marginLeft: "auto",
                  width: "40px",
                  height: "40px",
                  cursor: "pointer",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  borderRadius: 1,
                }}
              >
                {currentPage}
              </Box>
              <ButtonBase
                sx={{
                  color: "rgb(59, 59, 59))",
                  backgroundColor: "grey",
                  marginLeft: "auto",
                  width: "40px",
                  height: "40px",
                  cursor: "pointer",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  borderRadius: 1,
                }}
                disabled={currentPage >= lastPage}
                onClick={() => setCurrentPage(currentPage + 1)}
              >
                <ArrowForwardIos />
              </ButtonBase>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
}

export default Customers;
