import React, { useState } from "react";
import "./style.css";
import { useStyles } from "./header.style";
import { Link } from "react-router-dom";
import logo from "../../../images/logo.png";
import phone from "../../../images/phone.png";
import { AppStore, Close, GooglePlay } from "../../../icons";
import { Menu } from "@mui/icons-material";

const Header = () => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const toggleMenu = () => {
    setOpen(!open);
  };
  return (
    <div className={classes.header}>
      <div className={"wrapper "}>
        <div className="navbar">
          <Link to="/">
            <img src={logo} alt="logo" height={"47px"} />
          </Link>
          <ul className={open ? "nav-links " + classes.openMenu : "nav-links"}>
            <Close className="close" onClick={toggleMenu} />
            <li>
              <Link to="/" className="active-link">
                Home
              </Link>
            </li>
            <li>
              <Link to="/">Services</Link>
            </li>
            <li>
              <Link to="/">Documentation</Link>
            </li>
            <li>
              <Link to="/">About</Link>
            </li>
            <li className="button">
              <Link to={"/signin"}>Sign In</Link>
            </li>
          </ul>
          <div className="buttons">
            <Link to={"/signin"} className="button sign-in">
              Sign In
            </Link>
            <Link to="/signup" className="button get-started">
              Get Started
            </Link>
          </div>
          <Menu className="menu" onClick={toggleMenu} />
        </div>
        <div className="hero ">
          <div className="hero-text animate__animated animate__backInDown">
            <h1>
              Transforming the Way <br />
              You Pay
            </h1>
            <p>
              Delivering Customer-Focused Solutions: Empowering Users and
              Resellers with a Robust and User-Friendly Platform.
            </p>
          </div>
          <div className="downloads animate__animated animate__backInDown">
            <Link to={"/"}>
              <GooglePlay />
              <div>
                <span className="sub">GET IT ON</span>
                <span className="title">Google Play</span>
              </div>
            </Link>
            <Link to={"/"}>
              <AppStore />
              <div>
                <span className="sub">Download on the</span>
                <span className="title">Apple Store </span>
              </div>
            </Link>
          </div>
          <div className={"phoneContainer"}>
            <div className="phoneImageContainer">
              <img
                src={phone}
                alt="phone"
                className="phone animate__animated animate__bounceInUp"
              />
            </div>
          </div>

          <div className="buttons animate__animated animate__fadeIn">
            <Link to={"/"} className="transfer">
              Transfer
            </Link>
            <Link to={"/"} className="fund-wallet">
              Fund Wallet
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;
