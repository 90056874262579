import React from "react";
import { Box, Typography } from "@mui/material";

function Privacy(props) {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        padding: "10px 20%",
        flexDirection: "column",
      }}
    >
      <Box
        sx={{
          textAlign: "center",
        }}
      >
        <Typography
          sx={{
            fontSize: "18px",
            fontWeight: "600",
            p: 2,
          }}
        >
          Our Terms & Privacy Policy
        </Typography>
        <Typography
          sx={{
            p: 2,
            textAlign: "justify",
          }}
        >
          These Terms constitute a contract between you and Zainnest. You may
          not use the Service if you do not accept these Terms. Please read
          carefully. This page contains our house rules, or what we officially
          refer to as our Terms of Use or simply “Terms” (for short). It also
          contains our Privacy Policy, we will call it “Your Privacy “ in this
          document. These Terms are a legally binding contract between you and
          Zainnest. It sets out your rights and responsibilities when you use
          Zainnest.com or any other services provided by Zainnest (we’ll refer
          to Zainnest.com, our social media, Zainnest Wallet, and other services
          as “Our Services” and "the services").
        </Typography>
      </Box>
      <Box
        sx={{
          textAlign: "center",
        }}
      >
        <Typography
          sx={{
            fontSize: "18px",
            fontWeight: "600",
            p: 2,
          }}
        >
          Account
        </Typography>
        <Typography
          sx={{
            p: 2,
            textAlign: "justify",
          }}
        >
          You’ll need to be registered with Zainnest to use our Services and to
          enjoy improved experience, you may also upgrade your account for an
          even better service experience. Everyone who is legally fit to enter
          into a contract and buy or sell may use our Services. You should at
          every point in time provide us accurate information about yourself.
          It’s not OK to use false information or impersonate another person or
          company through your account.
        </Typography>
        <Typography
          sx={{
            p: 2,
            textAlign: "justify",
          }}
        >
          You are solely responsible for any activity on your account and so it
          is equally important you keep your password and such secure . If
          you’re sharing an account with other people, then the person whose
          information is on the account will ultimately be responsible for all
          activities. If you’re registering as a business entity, you personally
          guarantee that you have the authority to agree to the Terms on behalf
          of the business. Also, your accounts are not transferable. It is
          equally important to keep your account password secure.
        </Typography>
      </Box>
      <Box
        sx={{
          textAlign: "center",
        }}
      >
        <Typography
          sx={{
            fontSize: "18px",
            fontWeight: "600",
            p: 2,
          }}
        >
          Your Privacy & Verification
        </Typography>
        <Typography
          sx={{
            p: 2,
            textAlign: "justify",
          }}
        >
          We know you care about your privacy, that’s why, we at Zainnest also
          care deeply about it and will never share your information with a
          third party without your consent and approval. We are transparent, and
          committed to being open about our privacy practices, including how we
          treat your personal information. Keep calm, your information is
          protected as much as the site is secure.
        </Typography>
        <Typography
          sx={{
            p: 2,
            textAlign: "justify",
          }}
        >
          We use the information that we gather from users in a number of ways.
          For example, if you purchase an item, we use Information provided to
          promptly deliver your request; we could use your forum suggestions to
          improve the Services; we may also use data analytics and work with
          certain third-party service providers to ensure site functionality, to
          improve the Services, and for targeted online and offline advertising
          and marketing; and of course, we use your email address to communicate
          with you. We also partner with service providers and other third
          parties that collect, use, promote, and analyze information about
          Zainnest and visitors.
        </Typography>
        <Typography
          sx={{
            p: 2,
            textAlign: "justify",
          }}
        >
          We need to process your personal data to run Zainnest effectively and
          provide you with the best of Service experience always and by
          accepting our Terms of Use, you are confirming that you have read this
          policy and consent to us using your information in the ways we
          describe. Zainnest will not sell or disclose your personal information
          to third parties without your consent, except in legal situations such
          as for criminal investigations, if such situation arises, but highly
          unlikely, and as generally specified in our Privacy Policy.
        </Typography>
        <Typography
          sx={{
            p: 2,
            textAlign: "justify",
          }}
        >
          You reserve the right to your account setting functions, to change
          your information or remove certain optional pieces of information as
          appropriate. We may partner with third parties to manage our
          advertising on other sites. Our third-party partners may use cookies
          or similar technologies in order to provide you with advertising based
          upon your browsing activities and interests. In few words, your
          information is protected!
        </Typography>
      </Box>
      <Box
        sx={{
          textAlign: "center",
        }}
      >
        <Typography
          sx={{
            fontSize: "18px",
            fontWeight: "600",
            p: 2,
          }}
        >
          Using our Services
        </Typography>
        <Typography
          sx={{
            p: 2,
            textAlign: "justify",
          }}
        >
          We offer you a limited, non-exclusive, non-transferable, and revocable
          license to use our Service subject to the Terms and the following
          restrictions in particular:
        </Typography>
        <Typography
          sx={{
            p: 2,
            textAlign: "justify",
          }}
        >
          You agree that you will not violate any laws in connection with your
          use of the Services. This includes any local, state, federal, and
          international laws that may apply to you. For example, it’s your
          responsibility to obtain any permits or licenses that your business
          requires; you must comply with our consequent policies and do not
          engage in fraud, theft, threatening conduct, or any other unlawful
          acts or crimes against Zainnest, another Zainnest user, or a third
          party.
        </Typography>
        <Typography
          sx={{
            p: 2,
            textAlign: "justify",
          }}
        >
          You are responsible for paying all fees that you owe to Zainnest, you
          are also solely responsible for collecting and/or paying any
          applicable taxes for any purchases or sales you make through our
          Services.
        </Typography>
        <Typography
          sx={{
            p: 2,
            textAlign: "justify",
          }}
        >
          You agree not to misuse, “scrape,”, “spider” or hurt any part or page
          of the Services or to reverse engineer or attempt to obtain the source
          code of the Services. In other words, you agree not to interfere with
          or try to disrupt our Services, for example by distributing a virus or
          other harmful computer code.
        </Typography>
        <Typography
          sx={{
            p: 2,
            textAlign: "justify",
          }}
        >
          We love your suggestions and ideas! They can help us improve your
          experience and our Services. Any unsolicited ideas or other materials
          you submit to Zainnest are considered non-confidential and
          non-proprietary to you. You grant us a non-exclusive, worldwide,
          royalty-free, irrevocable, sub-licensable, perpetual license to use
          and publish those ideas and materials for any purpose.
        </Typography>
      </Box>
      <Box
        sx={{
          textAlign: "center",
        }}
      >
        <Typography
          sx={{
            fontSize: "18px",
            fontWeight: "600",
            p: 2,
          }}
        >
          Limitation of Liability
        </Typography>
        <Typography
          sx={{
            p: 2,
            textAlign: "justify",
          }}
        >
          Availability: While we make efforts at every point in time to keep
          Zainnest running ultimately and at her very best, things can go quite
          wrong. We are not perfect and we do not suggest any guarantee as to
          the accuracy, timeliness, performance, completeness or availability of
          zainnest.com at any point in time but we surely do not relent at
          making our services available to you at all times, 24/7 with all we've
          got, having some of the most brilliant minds working round the clock
          to deliver Zainnest users such unmatched service experience.
        </Typography>
        <Typography
          sx={{
            p: 2,
            textAlign: "justify",
          }}
        >
          Third-Party Services: Our Services may contain links to third-party
          websites or services that we don’t own or control (for example, links
          to Facebook, Instagram, Tinuda). When you access these third-party
          services, you do so at your own risk. The third parties may require
          you to accept their own terms of use. Zainnest is not a party to those
          agreements; they are solely between you and the third party and to the
          fullest extent permitted by law, neither zainnest, nor our employees
          or administration shall be liable to you for any lost profits or
          revenues, or for any consequential, incidental, indirect, special, or
          punitive damages arising out of or in connection with these Services
          and their Terms.
        </Typography>
      </Box>
      <Box
        sx={{
          textAlign: "center",
        }}
      >
        <Typography
          sx={{
            fontSize: "18px",
            fontWeight: "600",
            p: 2,
          }}
        >
          Indemnification
        </Typography>
        <Typography
          sx={{
            p: 2,
            textAlign: "justify",
          }}
        >
          We hope this never happens, but if Zainnest gets sued because of
          something that you did, you agree to defend and indemnify us. That
          means you’ll defend Zainnest including any of our employees and hold
          us harmless from any legal claim or demand that arises from your
          actions, your use or misuse of our Services, your breach of the Terms,
          or your account’s infringement of someone else’s rights.
        </Typography>
      </Box>
      <Box
        sx={{
          textAlign: "center",
        }}
      >
        <Typography
          sx={{
            fontSize: "18px",
            fontWeight: "600",
            p: 2,
          }}
        >
          Governing Law
        </Typography>
        <Typography
          sx={{
            p: 2,
            textAlign: "justify",
          }}
        >
          Kindly let us know in a case that you are upset or have cause for
          dispute with us, and hopefully we can resolve the issue. But if we
          can’t, then these rules will be governing rules of the terms and will
          be subjected to the laws of Nigeria. We are currently in Ibadan,
          Nigeria, so any legal action must be filed and take place in Ibadan.
          These laws will apply no matter where in the world you live.
        </Typography>
      </Box>
      <Box
        sx={{
          textAlign: "center",
        }}
      >
        <Typography
          sx={{
            fontSize: "18px",
            fontWeight: "600",
            p: 2,
          }}
        >
          Changes to the Terms
        </Typography>
        <Typography
          sx={{
            p: 2,
            textAlign: "justify",
          }}
        >
          These Terms may be updated from time to time. If we believe that the
          changes are significant to your knowledge, we’ll surely let you know
          by doing one or more of the following: posting the changes through the
          Services or sending you an email or message about the changes. That
          way you can decide whether you want to continue using the Services.
          Changes will be effective upon the posting of the changes. You are
          responsible for reviewing and becoming familiar with any changes. Your
          use of the Services following the changes constitutes your acceptance
          of the updated Terms.
        </Typography>
      </Box>
      <Box
        sx={{
          textAlign: "center",
        }}
      >
        <Typography
          sx={{
            fontSize: "18px",
            fontWeight: "600",
            p: 2,
          }}
        >
          Termination
        </Typography>
        <Typography
          sx={{
            p: 2,
            textAlign: "justify",
          }}
        >
          We wouldn’t like to see you go, but to terminate your account with
          Zainnest, you may simply stop using our services at any time.
          Terminating your account will not affect the availability of some of
          Your Content that you posted through the Services prior to
          termination. And yes, you’ll still have to pay any outstanding bills.
        </Typography>
        <Typography
          sx={{
            p: 2,
            textAlign: "justify",
          }}
        >
          We may also terminate or suspend your account, any related accounts
          and your access to the Services at any time, for any reason, and
          without advance notice. If we do so, it’s important to understand that
          you don’t have a contractual or legal right to continue using our
          Services, for example, to sell or buy on our sites and others. Zainnet
          may refuse service to anyone, at any time, for any reason. If you or
          Zainnest terminates your account, you may lose any information
          associated with your account, including Your Content.
        </Typography>
        <Typography
          sx={{
            p: 2,
            textAlign: "justify",
          }}
        >
          Zainnest reserves the right to change, suspend, or discontinue any of
          the Services at any time, for any reason. We will not be liable to you
          for the effect that any changes to the Services may have on you,
          including your income or your ability to generate revenue through the
          Services.
        </Typography>
      </Box>
      <Box
        sx={{
          textAlign: "center",
        }}
      >
        <Typography
          sx={{
            fontSize: "18px",
            fontWeight: "600",
            p: 2,
          }}
        >
          Survival
        </Typography>
        <Typography
          sx={{
            p: 2,
            textAlign: "justify",
          }}
        >
          The Terms will remain in effect even after your access to the Service
          is terminated, or your use of the Service ends.
        </Typography>
        <Typography
          sx={{
            p: 2,
            textAlign: "justify",
          }}
        >
          The Terms, including all of the policies that make up the Terms,
          supersede any other agreement between you and Zainnest regarding the
          Services and our failure to enforce any part of the Terms is not a
          waiver of our right to later enforce that or any other part of the
          Terms.
        </Typography>
      </Box>
      <Box
        sx={{
          textAlign: "center",
        }}
      >
        <Typography
          sx={{
            fontSize: "18px",
            fontWeight: "600",
            p: 2,
          }}
        >
          Contact Information
        </Typography>
        <Typography
          sx={{
            p: 2,
            textAlign: "justify",
          }}
        >
          In case you have any question about the Terms, please email us at:
          <a href="zainnestng@gmail.com">zainnestng@gmail.com</a>
        </Typography>
      </Box>
    </Box>
  );
}

export default Privacy;
