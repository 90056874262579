import React from "react";
import { Box } from "@mui/system";
import { TextField, Typography } from "@mui/material";

function LabeledTextField({ label, hint, endAdornment, ...rest }) {
  return (
    <Box>
      <Box sx={{ display: "flex", justifyContent: "start" }}>
        <Typography variant="body1">{label}</Typography>
      </Box>
      <TextField
        variant="outlined"
        size="small"
        sx={{
          width: "100%",
          borderRadius: "10px",
          "& .MuiOutlinedInput-root": {
            borderRadius: "10px",
          },
        }}
        {...rest}
        InputProps={{
          endAdornment: endAdornment,
        }}
      />
      <Box sx={{ display: "flex", justifyContent: "start" }}>
        <Typography variant="xs" sx={{ color: "rgba(134, 134, 134, 1)" }}>
          {hint}
        </Typography>
      </Box>
    </Box>
  );
}

export default LabeledTextField;
