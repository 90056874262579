import { useQuery } from "@tanstack/react-query";
import { useEffect } from "react";
import { getMe, getStatistics } from "./api/userApi";
import { useUserStore } from "./state";

export const useUser = () => {
  const { data, isLoading, isRefetching, refetch } = useQuery(["me"], getMe);
  const setUser = useUserStore((state) => state.setUser);

  useEffect(() => {
    if (data?.data?.data) setUser(data.data.data);
  }, [data]);

  return { fetchUser: refetch, isLoading, isRefetching };
};

export const useAnalytic = () => {
  const { data, isLoading, isRefetching, refetch } = useQuery(
    ["userstatistics"],
    getStatistics
  );
  const setCharge = useUserStore((state) => state.setCharge);
  console.info({ data: data?.data?.data?.charge[0] });
  useEffect(() => {
    if (data?.data?.data) setCharge(data?.data?.data?.charge[0]);
  }, [data]);

  return { fetchAnalytic: refetch, isLoading, isRefetching };
};
