import React, { useState } from "react";
import CustomButton from "../Button";
import { useTheme } from "@mui/material/styles";
import {
  Dialog,
  Box,
  Typography,
  RadioGroup,
  FormControlLabel,
  Radio,
} from "@mui/material";
import LabeledTextField from "../LabeledTextField";
import { useFormik } from "formik";
import * as yup from "yup";
import { useNotificationStore } from "../../state";
import { useMutation } from "@tanstack/react-query";
import { createNotification } from "../../api/adminApi";

function CreateMessage({ handleOpen, refetch }) {
  const theme = useTheme();
  const [error, setError] = useState(null);

  const displayNotificaton = useNotificationStore(
    (state) => state.displayNotificaton
  );

  const { isLoading, mutate } = useMutation(createNotification, {
    onSuccess: (res) => {
      displayNotificaton(res?.data?.message);
      console.log(res);
      //refetch();
      handleOpen();
      
    },
    onError: (e) => {
      setError(
        e.response?.data?.message ??
          e.message ??
          "something went wrong try again"
      );
    },
  });

  const initialValues = {
    title: "",
    body: "",
  };
  let validationSchema = yup.object({
    title: yup.string().required("this field is required"),
    body: yup.string().required("this field is required"),
  });

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      mutate(values);
    },
  });

  return (
    <Dialog
      sx={{
        "& .MuiDialog-paper": {
          borderRadius: "30px",
          width: { xs: "100%", sm: "60%", md: "30%" },
        },
      }}
      open={true}
      onClose={handleOpen}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-evenly",
          alignItems: "center",
          padding: "30px",
          width: { xs: "100%" },
          // height: "420px",
          background: "#FFFFFF",
          border: "1px solid #DFDFDF",
          borderRadius: "30px",
          textAlign: "center",
        }}
      >
        <Typography variant="header" sx={{ fontSize: "19px" }}>
        New Message
        </Typography>
        <Typography
          sx={{
            color: "#9C0000",
            textAlign: "start",
          }}
        >
          {error}
        </Typography>
        <form style={{ width: "100%" }} onSubmit={formik.handleSubmit}>
          <Box sx={{ mb: 4, mt: 2 }}>
            <LabeledTextField
              label="Subject"
              id="title"
              name="title"
              value={formik.values.title}
              onChange={formik.handleChange}
              error={formik.touched.title && Boolean(formik.errors.title)}
              helperText={formik.touched.title && formik.errors.title}
              fullWidth
              variant="outlined"
            />
          </Box>
          <Box sx={{ mb: 4, display: "flex" }}>
            <RadioGroup
              aria-labelledby="demo-radio-buttons-group-label"
              defaultValue="all"
              name="radio-buttons-group"
              row
              sx={{ justifyContent: "space-between" }}
            >
              <FormControlLabel value="all" control={<Radio />} label="All" />
              <FormControlLabel
                value="resseller"
                control={<Radio />}
                label="Resseller"
                sx={{ fontSize: { xs: "13px", md: "16px" } }}
              />
              <FormControlLabel
                value="customer"
                control={<Radio />}
                label="Customer"
              />
              <FormControlLabel value="web" control={<Radio />} label="Web" />
            </RadioGroup>
          </Box>
          <Box sx={{ mb: 4 }}>
            <LabeledTextField
              label="Message"
              // hint="You can add multiple numbers. Ex: 08030302020, 09016161919"
              id="body"
              name="body"
              value={formik.values.body}
              onChange={formik.handleChange}
              error={formik.touched.body && Boolean(formik.errors.body)}
              helperText={formik.touched.body && formik.errors.body}
              fullWidth
              variant="outlined"
            />
          </Box>
          <Box sx={{ mb: 4, display: "flex", justifyContent: "space-between" }}>
            <Box sx={{ mr: 1, width: "39%" }}>
              <CustomButton
                bgcolor={theme.palette.secondary.main}
                color={theme.palette.primary.main}
                onClick={handleOpen}
              >
                Cancel
              </CustomButton>
            </Box>
            <Box sx={{ width: "62%" }}>
              <CustomButton
                loading={isLoading}
                type="submit"
                onClick={formik.submitForm}
              >
                Send
              </CustomButton>
            </Box>
          </Box>
        </form>
      </Box>
    </Dialog>
  );
}

export default CreateMessage;
