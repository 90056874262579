import { FormControl, Select,InputLabel,MenuItem, TextField,Breadcrumbs,Box,Typography,Button,Modal } from '@mui/material';
import React from 'react'
import { Link, useLocation } from 'react-router-dom'
import StorageService from '../../StorageService';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 300,
    bgcolor: '#000',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};
const EditCustomer = (props) => {
    const locationed = useLocation();
    let token = StorageService.getAccessToken();
    const [user,setUser] = React.useState();
    const [usergroup,setUserGroup] = React.useState([]);
    const [group,setGroup] = React.useState({
        name: '',
        id: ''
    });
    const [groupName,setGroupName] = React.useState('');
    const [message,setMessage] = React.useState('');
    
    const [modal,setModal] = React.useState(false);
    const [isTrue,setIsTrue] = React.useState(false);
    React.useEffect(()=>{
        // let token = StorageService.getAccessToken();
        setUser(locationed.state.user);
        if(usergroup?.length <= 0){
            fetch('https://api.zainnest.com/api/admin/groups',{
            headers:{
                Accept: "application/json",
                "Content-Type": "application/json",
                "Authorization": "Bearer "+ token
            }
            }).then(res => res.json()).then((result)=>{
                console.log(result.data.data)
                setUserGroup(result.data.data)
            })
        }
    }, [locationed.state.user, token, usergroup?.length])
    const handleUpdate = () =>{
        console.log({
            user_id:user?.id,
            group_id: group?.id
        })
        setModal(true);
        fetch('https://api.zainnest.com/api/admin/user/update/group',{
            method: 'POST',
            headers:{
                Accept: "application/json",
                "Content-Type": "application/json",
                "Authorization": "Bearer "+ token
            },
            body: JSON.stringify({
                user_id:user?.id,
                group_id: group?.id
            })
        }).then(res => res.json()).then((result)=>{
            console.log(result);
            setTimeout(()=>{
                setModal(false);
                setMessage(result.message)
                if(result.status !== true){
                    setIsTrue(false);
                }else{
                    setIsTrue(true);
                }
            },2000);
        }).catch(err=>{
            setTimeout(()=>{
                setModal(false);
                setMessage('An error occured')
            },2000);
        })
    }
  return (
    <div style={{paddingTop:'10%'}}>
        <Breadcrumbs aria-label="breadcrumb">
            <Link underline="hover" color="inherit" href="/admin/dashboard">
                Dashboard
            </Link>
            <Link
                underline="hover"
                color="inherit"
                href="#"
            >
                Customers
            </Link>
            <Typography variant="h5" style={{textAlign:'start', fontWeight: '700'}} component="h5" color="text.primary">Edit Customers Group</Typography>
        </Breadcrumbs>
        <div style={{marginTop:"10%"}}>
            <Typography variant="p" style={{textAlign:'center',marginBottom: "10%", fontWeight: '400'}} component="p" color="text.primary">{message}</Typography>
            <TextField id="outlined-basic" value={user?.username} label="Username" disabled fullWidth variant="outlined" />
            <br />
            <br />
            <TextField id="outlined-basic" value={user?.email} label="Email Address" disabled fullWidth variant="outlined" />
            <br />
            <br />
        </div>
        <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">Group</InputLabel>
            <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={groupName}
                label="Group"
                onChange={(e)=>{
                    let val = e.target.value;
                    let valid = val.split(/\_/);
                    // console.log(valid);
                    setGroup({
                        name:valid[1],
                        id:valid[0]
                    });
                    setGroupName(e.target.value);
                    console.log(group);
                }}
            >
                {usergroup && usergroup.map((items, index)=>(
                    <MenuItem value={items.id + '_'+ items.name} key={index}>{items.name}</MenuItem>
                ))}
            </Select>
        </FormControl>
        <br />
        <br />
        <FormControl fullWidth>
            <Button variant="contained" disabled={isTrue} onClick={handleUpdate}>Update Group</Button>
        </FormControl>
        
        {/* Modal */}
        <Modal
        open={modal}
        onClose={()=>{
            setModal(false);
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        >
        <Box sx={style}>
            <Typography id="modal-modal-title" style={{color:'#f7f7f7'}} variant="h6" component="h2">
                Updating.....
            </Typography>
            <Typography id="modal-modal-description" style={{color:'#f7f7f7'}} sx={{ mt: 2 }}>
                please wait while we update {user?.username}'s information.
            </Typography>
        </Box>
        </Modal>
    </div>
  )
}

export default EditCustomer