import React, { useState } from "react";
import CustomButton from "../Button";
import { useTheme } from "@mui/material/styles";
import { Dialog, Box, Typography } from "@mui/material";
import LabeledTextField from "../LabeledTextField";
import { useFormik } from "formik";
import * as yup from "yup";
import { useNotificationStore } from "../../state";
import { useMutation } from "@tanstack/react-query";
import { adminCreateGroup, adminUpdateGroup } from "../../api/adminApi";

function AddGroup({ handleOpen, refetch, plan }) {
  const { name, account_name, account_number, id } = plan || {};
  const theme = useTheme();
  const [error, setError] = useState(null);

  const displayNotificaton = useNotificationStore(
    (state) => state.displayNotificaton
  );

  const { isLoading, mutate } = useMutation(adminCreateGroup, {
    onSuccess: (res) => {
      displayNotificaton(res?.data?.message);
      refetch();
      handleOpen();
    },
    onError: (e) => {
      setError(
        e.response?.data?.message ??
          e.message ??
          "something went wrong try again"
      );
    },
  });

  const { isLoading: updateIsLoading, mutate: updateMutate } = useMutation(
    adminUpdateGroup,
    {
      onSuccess: (res) => {
        displayNotificaton(res?.data?.message);
        refetch();
        handleOpen();
      },
      onError: (e) => {
        setError(
          e.response?.data?.message ??
            e.message ??
            "something went wrong try again"
        );
      },
    }
  );

  const initialValues = {
    name: name || "",
  };
  let validationSchema = yup.object({
    name: yup.string().required("this field is required"),
  });

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      plan ? updateMutate({ id, values }) : mutate(values);
    },
  });

  return (
    <Dialog
      sx={{
        "& .MuiDialog-paper": {
          borderRadius: "30px",
          width: { xs: "100%", sm: "50%", md: "27%" },
        },
      }}
      open={true}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-evenly",
          alignItems: "center",
          padding: "30px",
          width: { xs: "100%" },
          // height: "420px",
          background: "#FFFFFF",
          border: "1px solid #DFDFDF",
          borderRadius: "30px",
          textAlign: "center",
        }}
      >
        <Typography variant="header" sx={{ fontSize: "19px" }}>
          {id ? "Edit" : "Add"} Group
        </Typography>
        <Typography
          sx={{
            color: "#9C0000",
            textAlign: "start",
          }}
        >
          {error}
        </Typography>
        <form style={{ width: "100%" }} onSubmit={formik.handleSubmit}>
          <Box sx={{ mb: 4, mt: 2 }}>
            <LabeledTextField
              label="Group Name"
              id="name"
              name="name"
              value={formik.values.name}
              onChange={formik.handleChange}
              error={formik.touched.name && Boolean(formik.errors.name)}
              helperText={formik.touched.name && formik.errors.name}
              fullWidth
              variant="outlined"
            />
          </Box>

          <Box sx={{ mb: 4, display: "flex", justifyContent: "space-between" }}>
            <Box sx={{ mr: 1, width: "39%" }}>
              <CustomButton
                bgcolor={theme.palette.secondary.main}
                color={theme.palette.primary.main}
                onClick={handleOpen}
              >
                Cancel
              </CustomButton>
            </Box>
            <Box sx={{ width: "62%" }}>
              <CustomButton
                loading={isLoading || updateIsLoading}
                type="submit"
              >
                Continue
              </CustomButton>
            </Box>
          </Box>
        </form>
      </Box>
    </Dialog>
  );
}

export default AddGroup;
