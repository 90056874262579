// import { createMuiTheme } from "@mui/material";
import { createTheme } from "@mui/material/styles";

let theme = createTheme();

theme = createTheme(theme, {
  palette: {
    ...theme.palette,
    primary: {
      main: "rgba(2, 2, 57, 1)",
      light: "rgba(230, 230, 239, 1)",
    },
    secondary: {
      main: "rgba(230, 230, 239, 1)",
    },
    alert: {
      main: "rgba(156, 0, 0, 1)",
    },
    neutral: {
      N500: "rgba(157, 157, 157, 1)",
      N900: "rgba(128, 128, 128, 1)",
      N200: "rgba(179, 179, 179, 1)",
      N50: "rgba(223, 223, 223, 1)",
    },
  },
  components: {
    MuiTableBody: {
      styleOverrides: {
        root: {
          // overrides here
        },
      },
    },
  },
  typography: {
    subtitle1: {
      fontSize: 13,
    },
    body1: {
      fontWeight: 500,
    },
    button: {
      // fontStyle: "italic",
    },
    tabheader: {
      fontWeight: 600,
      fontSize: 19,
    },
    small: {
      fontSize: 13,
      fontWeight: 500,
    },
    xs: {
      fontSize: 11,
      fontWeight: 500,
    },
    header: {
      fontSize: "33px",
      fontWeight: 500,
    },
    header2: {
      fontSize: { xs: "19px", sm: "23px" },
      fontWeight: 500,
    },
  },
});

export default theme;
