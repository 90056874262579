import { useTheme } from "@emotion/react";
import { Divider, MenuItem, Select, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import { ReactComponent as Arrow } from "../../icons/arrow.svg";
import { ReactComponent as Arrow2 } from "../../icons/arrow (1).svg";
import {
  BoltIcon,
  DevicePhoneMobileIcon,
  TvIcon,
  WifiIcon,
} from "@heroicons/react/24/outline";
import { useQuery } from "@tanstack/react-query";
import { getStatistics } from "../../api/userApi";
import { formatter } from "../../helpers";

function Analytics() {
  const theme = useTheme();
  const { data } = useQuery(["userstatistics"], getStatistics);

  const t = data?.data?.data?.transactions_amount ?? {};

  const { cable, electricity, airtime, data: dataData } = t;

  const airtimeTotal = airtime
    ? Object.values(airtime).reduce((t, i) => parseFloat(t) + parseFloat(i), 0)
    : 0;

  const dataTotal = dataData
    ? Object.values(dataData).reduce((t, i) => parseFloat(t) + parseFloat(i), 0)
    : 0;

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
        padding: "0 20px",
      }}
    >
      <Typography
        sx={{ display: { xs: "none", sm: "block" } }}
        color={theme.palette.neutral.N200}
        variant="header"
      >
        Analytics
      </Typography>
      <Box
        sx={{
          padding: { sm: "20px" },
          width: {
            xs: "100%",
            lg: "70%",
            xl: "60%",
          },
          border: {
            sm: "1px solid #e6e6ef",
          },
          borderRadius: "20px",
          mt: { xs: 0, sm: 5 },
          minHeight: "70vh",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            mb: 2,
            alignItems: "center",
          }}
        >
          <Typography
            sx={{ fontSize: "16px", color: "#808080", fontWeight: "500" }}
          >
            Analytics
          </Typography>
          <Select
            sx={{
              width: "120px",
              fontSize: "13px",
              fontWeight: "500",
              color: "#808080",
              borderRadius: "10px",
              "& .MuiOutlinedInput-root": {
                borderRadius: "10px",
                height: "39px",
              },
            }}
            variant="outlined"
            value={10}
          >
            <MenuItem sx={{}} value={10}>
              This Week
            </MenuItem>
          </Select>
        </Box>

        <Box sx={{ mt: 1 }}>
          <Divider sx={{ xs: "block", md: "none", margin: 3 }} />
          <Box
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <AnalyticCard
              label="Total Sales"
              value={`N${formatter.format(
                data?.data?.data?.sales?.total ?? 0
              )}`}
            />
            <AnalyticCard
              label="Total Transactions"
              value={`${data?.data?.data?.transactions?.total ?? 0}`}
            />
          </Box>

          <Box
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
              height: "110px",
            }}
          >
            <AnalyticCard2
              label="Total Data Sales"
              value={`N ${formatter.format(dataTotal ?? 0)}`}
              bgColor="#E6F0EA"
              icon={<WifiIcon style={{ width: "50%", color: "#020239" }} />}
            />
            <AnalyticCard2
              label="Total Airtime Sales"
              value={`N ${formatter.format(airtimeTotal ?? 0)}`}
              bgColor="#E6E6EF"
              icon={
                <DevicePhoneMobileIcon
                  style={{ width: "50%", color: "#020239" }}
                />
              }
            />
          </Box>
          <Box
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
              height: "110px",
            }}
          >
            <AnalyticCard2
              label="Total Cable Sales"
              value={`N ${formatter.format(cable ?? 0)}`}
              bgColor="#F5F3E9"
              icon={<TvIcon style={{ width: "50%", color: "#020239" }} />}
            />
            <AnalyticCard2
              label="Total Utility Sales"
              value={`N ${formatter.format(electricity ?? 0)}`}
              bgColor="#FFE6E6"
              icon={<BoltIcon style={{ width: "50%", color: "#020239" }} />}
            />
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

function AnalyticCard({ label, value, increase, percentage }) {
  const theme = useTheme();

  return (
    <Box
      sx={{
        width: "min(47% , 300px)",
        height: "141px",
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
        padding: "22px 20px",
        border: "1px solid #DFDFDF",
        borderRadius: "20px",
        justifyContent: "space-between",
      }}
    >
      <Box sx={{ color: theme.palette.neutral.N500, fontSize: "11px" }}>
        {label}
      </Box>
      <Typography
        sx={{
          fontWeight: "700",
          lineHeight: "120%",
          fontSize: {
            xs: 28,
          },
          color: theme.palette.primary.main,
        }}
      >
        {value}
      </Typography>

      <Box
        sx={{
          color: theme.palette.primary.main,
          fontSize: "8px",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          width: "100%",
        }}
      >
        {increase !== true ? <Arrow /> : <Arrow2 />}
        <Typography
          sx={{ color: "#020239", fontSize: "8px", fontWeight: "500" }}
        >
          {percentage}
          {" % "}from last week
        </Typography>
      </Box>
    </Box>
  );
}

function AnalyticCard2({ label, value, color, icon, bgColor }) {
  const theme = useTheme();

  return (
    <Box
      sx={{
        width: "min(47% , 300px)",
        height: "141px",
        display: "flex",
        alignItems: "center",
      }}
    >
      <Box
        sx={{
          width: "56px",
          height: "52px",
          backgroundColor: bgColor,
          color,
          borderRadius: "10px",
          marginRight: "3px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {icon}
      </Box>
      <Box>
        <Box sx={{ color: theme.palette.neutral.N500, fontSize: "11px" }}>
          {label}
        </Box>
        <Box
          sx={{
            fontWeight: "700",
            lineHeight: "120%",
            fontSize: {
              xs: 16,
            },
            color: theme.palette.primary.main,
            textAlign: "start",
          }}
        >
          {value}
        </Box>
      </Box>
    </Box>
  );
}

export default Analytics;
