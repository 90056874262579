import React from "react";
import { Breadcrumbs, Typography } from "@mui/material";
import { NavigateNextOutlined } from "@mui/icons-material";
import { Box } from "@mui/system";
import { useTheme } from "@mui/material/styles";
import SummaryCardAlt from "../../components/User/SummaryCardAlt";

import UtilityForm from "../../components/Services/UtilityForm";

function Utility() {
  const theme = useTheme();

  // useEffect(() => {
  //   Object.keys(formik.values).forEach((v) => console.info(v));
  // }, []);

  return (
    <Box sx={{ p: "0px 20px" }}>
      <Box sx={{ display: { xs: "none", sm: "block", margin: "20px 0px" } }}>
        <Box sx={{ display: "flex" }}>
          <Typography variant="header" color={theme.palette.neutral.N200}>
            Utility
          </Typography>
        </Box>
        <Breadcrumbs
          separator={<NavigateNextOutlined fontSize="22px" />}
          aria-label="breadcrumb"
        >
          {/* <Typography color={theme.palette.neutral.N200}>Resellers</Typography> */}
          <Typography color="primary"> Utility</Typography>
        </Breadcrumbs>
      </Box>
      <Box sx={{ display: { xs: "block", sm: "none" }, textAlign: "left" }}>
        <Typography
          sx={{ fontWeight: "500", color: "#808080", marginBottom: "10px" }}
        >
          Utility
        </Typography>
        <SummaryCardAlt />
      </Box>

      <UtilityForm />
    </Box>
  );
}

export default Utility;
