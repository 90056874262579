import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles({
  container: {
    textAlign: "left !important",
    fontFamily: "'Public Sans', sans-serif",
    background: "#FAFAFA",
  },
  signUpSection: {
    paddingTop: "47px",
    paddingBottom: "98px",
    "& .wrapper": {
      position: "relative",
      display: "flex",
      flexDirection: "column",
      "@media (max-width: 1300px)": {
        justifyContent: "center",
        alignItems: "center",
      },
      "& .imgContainer": {
        "@media (max-width: 1300px)": {
          display: "none",
        },
      },

      "& .formContainer": {
        marginTop: "-450px",
        marginLeft: "105px",
        background: "#fff",
        padding: "50px",
        borderRadius: "20px",
        width: "491px",
        "@media (max-width: 1300px)": {
          marginTop: "0",
          marginLeft: "0",
        },
        "@media (max-width: 900px)": {
          width: "100%",
        },
        "& .error-text": {
          padding: ".75rem 1.25rem",
          color: "#721c24",
          background: "#f8d7da",
          border: "1px solid #f5c6cb",
          borderRadius: ".25rem",
          margin: "32px auto",
        },
        "& h6": {
          fontSize: "33px",
          lineHeight: "40px",
          fontWeight: "700",
          color: "#1A3A5E",
          marginBottom: "40px",
        },
        "& form": {
          "& .form-group": {
            marginBottom: "20px",
            "& label": {
              fontSize: "16px",
              lineHeight: "20px",
              fontWeight: "500",
              color: "#000",
              marginBottom: "6px",
            },
            "& .input-group": {
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              position: "relative",
              "& input": {
                width: "100%",
                height: "56px",
                border: "1px solid #868686",
                borderRadius: "10px",
                padding: "16px 20px",
                fontSize: "16px",
                lineHeight: "20px",
                fontWeight: "500",
                color: "#000",
                "&::placeholder": {
                  color: "#Dfdfdf",
                },
              },
              "& .input-group-append": {
                position: "absolute",
                right: "16px",
              },
            },
          },
          "& .button-group": {
            display: "flex",
            flexDirection: "column",
            gap: "10px",
            "& a, button": {
              textDecoration: "none",
              width: "100%",
              height: "56px",
              outline: "none",
              border: "none",
              borderRadius: "10px",
              padding: "16px 20px",
              fontSize: "16px",
              lineHeight: "20px",
              fontWeight: "500",
              textAlign: "center",
              cursor: "pointer",

              "&.primary": {
                background: "#1A3A5E",
                color: "#fff",
              },
              "&.secondary": {
                background: "#e6e6e6",
                color: "#1A3A5E",
              },
            },
            "& .divider": {
              display: "flex",
              alignItems: "center",
              gap: "11px",
              fontSize: "16px",
              lineHeight: "20px",
              fontWeight: "500",
              color: "#808080",
              "&::before, &::after": {
                content: "''",
                flex: "1",
                borderBottom: "1px solid #808080",
              },
            },
          },
        },
      },
    },
  },
});
