import React from "react";
import "./style.css";
import { useStyles } from "./landing.style";
import "animate.css";
import { Link } from "react-router-dom";
import Header from "../../components/LandingPage/header";
import {
  Icon1,
  Icon2,
  Icon3,
  Icon4,
  Icon5,
  Icon6,
  Lock,
  One,
  Person,
  RightArrow,
  Support,
  Three,
  Two,
} from "../../icons";
import banner from "../../images/services-banner.png";
import bigbanner from "../../images/bigbanner.png";
import firstcard from "../../images/first-card.png";
import secondcard from "../../images/second-card.png";
import Footer from "../../components/LandingPage/footer";
import Item from "../../components/Item";

const Landing = () => {
  const classes = useStyles();
  return (
    <div className={classes.landing + " "}>
      <Header />
      <div className={classes.solutionSection}>
        <div className="wrapper">
          <div className="bannerContainer">
            <h1 className="wow">
              Our solution drives profound financial transformation.
            </h1>

            <img src={banner} alt="banner" height={"531px"} />
          </div>

          <div className="cards">
            <div className="card-item">
              <Person />
              <h6 className="wow">Accessible and Intuitive</h6>
              <p>
                Whether you're a customer or a reseller, our intuitive design
                ensures seamless experience for everyone.
              </p>
              <div className="line"></div>
            </div>
            <div className="card-item">
              <Lock />
              <h6>Advanced Security Measures</h6>
              <p>
                Our platform uses cutting-edge security measures to safeguard
                your information and privacy.
              </p>
              <div className="line"></div>
            </div>
            <div className="card-item">
              <Support />
              <h6>Efficient Customer Support</h6>
              <p>
                Count on us to quickly address any concerns and questions,
                making your experience with us enjoyable.
              </p>
              <div className="line"></div>
            </div>
          </div>
        </div>
      </div>
      <div className={classes.stepsSection}>
        <div className="wrapper">
          <h1>How to get started.</h1>
          <div className="cards">
            <div className="card-item">
              <One />
              <h6>Create an Account</h6>
              <p>
                Sign up on our platform by providing your relevant details and
                Verify your account
              </p>
            </div>
            <div className="card-item">
              <Two />
              <h6>Fund your Wallet</h6>
              <p>
                Select the "Fund Wallet" button and Choose your preferred
                payment method.
              </p>
            </div>
            <div className="card-item">
              <Three />
              <h6>Enjoy our Services</h6>
              <p>
                Explore our wide range of services tailored to your needs, e.g
                Data or Airtime Purchase.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className={classes.bigBanner}>
        <div className="wrapper">
          <div className="items" style={{ marginBottom: "40px" }}>
            <div className="empty"></div>

            <Item
              icon={<Icon1 />}
              title="Buy Data and Airtime"
              description="Anytime, Anywhere"
              mr=""
              ml=""
            />
          </div>
          <div className="items">
            <Item
              icon={<Icon2 />}
              title="International Airtime"
              description="Stay Connected, Anywhere."
              mr=""
              ml=""
            />
            <div className="empty"></div>
          </div>
          <div className="items" style={{ marginBottom: "60px" }}>
            <div className="empty"></div>

            <Item
              icon={<Icon3 />}
              title="E-Voucher"
              description="Unleash the Joy with Gift Cards!"
              mr=""
              ml=""
            />
          </div>
          <div className="items" style={{ marginBottom: "40px" }}>
            <Item
              icon={<Icon4 />}
              title="Cable TV"
              description="From the comfort of your home"
              mr=""
              ml=""
            />
            <div className="empty"></div>
          </div>
          <div className="items" style={{ marginBottom: "30px" }}>
            <div className="empty"></div>

            <Item
              icon={<Icon5 />}
              title="Fund Transfer "
              description="Effortlessly Send & Receive Funds!"
              mr=""
              ml=""
            />
          </div>
          <div className="items" style={{ marginBottom: "40px" }}>
            <Item
              icon={<Icon6 />}
              title="Utility"
              description="Conveniently from Home!"
              mr=""
              ml=""
            />
            <div></div>
          </div>
        </div>
      </div>
      <div className={classes.cardBanners}>
        <div className="wrapper">
          <div className="cards">
            <div className="first-card">
              <div className="text">
                <span>Exclusive Gifts and Bonuses awaits you</span>
                <h6>Unlock Amazing Discounts and Swag as a Top Reseller!</h6>
              </div>
              <img src={firstcard} alt="firstcard" height={"831px"} />
            </div>
            <div className="second-card">
              <div className="text">
                <h6>Unlock Amazing Discounts and Swag as a Top Reseller!</h6>
              </div>
              <img src={secondcard} alt="secondcard" height={"831px"} />
            </div>
          </div>
        </div>
        <div className="explore">
          <div className="wrapper">
            <h6>Ready to experience seamless and secure payments?</h6>
            <p>Join thousands of satisfied users on our payment platform</p>
            <Link to="/signup">
              Get Started Now <RightArrow />
            </Link>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Landing;
