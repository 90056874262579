import React, { useEffect, useState } from "react";
import { Breadcrumbs, ButtonBase, Typography } from "@mui/material";
import {
  ArrowBackIos,
  ArrowForwardIos,
  NavigateNextOutlined,
} from "@mui/icons-material";
import { Box } from "@mui/system";
import { useTheme } from "@mui/material/styles";
import Grid from "@mui/material/Unstable_Grid2";
import Table from "../../components/Table";
import { useQuery } from "@tanstack/react-query";
import { getTransactions } from "../../api/adminApi";
import LabeledSelectField from "../../components/LabeledSelectField";
function AdminTransactions() {
  const theme = useTheme();
  const columns = [
    {
      Header: "USER",
      accessor: "user",
    },
    {
      Header: "DESCRIPTION",
      accessor: "description",
    },
    {
      Header: "REFRENCE",
      accessor: "reference",
    },
    {
      Header: "Phone/Iuc",
      accessor: "created_at",
      Cell: (v) => <span>{v?.phone?.data}</span>,
    },
    {
      Header: "AMOUNT",
      accessor: "amount",
    },
    {
      Header: "NETWORK",
      accessor: "network",
    },
    {
      Header: "INITIAL BALANCE",
      accessor: "initial_balance",
    },
    {
      Header: "FINAL BALANCE",
      accessor: "final_balance",
    },
    {
      Header: "DATE",
      accessor: "created_at",
      Cell: (v) => <span>{v?.created_at?.substring(0, 13)}</span>,
    },
    {
      Header: "STATUS",
      accessor: "status",
      Cell: (v) => (
        <Box
          sx={{
            width: "94px",
            height: "39px",
            padding: "10px",
            display: "flex",
            alignItems: "center",
            background:
              v.status === "success"
                ? "#E6F0EA"
                : v.status === "pending"
                ? "lightyellow"
                : "#FFE6E6",
            borderRadius: "100px",
            justifyContent: "center",
          }}
        >
          {v.status}
        </Box>
      ),
    },
  ];
  const [search, setSearch] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [filter, setFilter] = useState("all");
  const { data, isLoading, isRefetching } = useQuery(
    ["getTransactions", search, currentPage, filter],
    () =>
      getTransactions({
        filter,
        search,
        page: currentPage,
      })
  );
  const transactions =
    data?.data?.data?.data?.map((v) => ({
      ...v,
      user: v.user?.username ?? v.user?.fullname,
    })) || [];
  const lastPage = data?.data?.data?.meta?.last_page;
  return (
    <Box sx={{ width: { xs: "100%", sm: "55%", md: "85%", lg: "100%" } }}>
      <Box sx={{ display: "flex" }}>
        <Typography variant="header" color={theme.palette.neutral.N200}>
          Transactions
        </Typography>
      </Box>
      <Breadcrumbs
        separator={<NavigateNextOutlined fontSize="22px" />}
        aria-label="breadcrumb"
      >
        <Typography color="primary">Transactions</Typography>
        {/* <Typography color="primary">{labels[value]}</Typography> */}
      </Breadcrumbs>
      <Grid container spacing={2} mt={0}>
        <Grid xs={12} sm={12} md={12} xl={12}>
          <Box
            className="card"
            sx={{ mt: 5, minHeight: "60vh", padding: "20px" }}
          >
            <Table
              columns={columns}
              data={transactions}
              loading={isLoading || isRefetching}
              setFilter={(v) => {
                setSearch(v);
                setCurrentPage(1);
              }}
              action={
                <Box sx={{ width: "min(250%,250px)" }}>
                  <LabeledSelectField
                    onChanged={(e) => setFilter(e)}
                    value={filter}
                    data={[
                      { value: "all", label: "All" },
                      { value: "year", label: "Year" },
                      { value: "month", label: "Month" },
                      { value: "week", label: "Week" },
                      { value: "today", label: "Today" },
                    ]}
                  ></LabeledSelectField>
                </Box>
              }
            />
          </Box>
          <Box
            sx={{ display: "flex", justifyContent: "flex-end", width: "100%" }}
          >
            <Box sx={{ display: "flex", justifyContent: "end", width: 120 }}>
              <ButtonBase
                sx={{
                  color: "rgb(59, 59, 59))",
                  backgroundColor: "grey",
                  marginLeft: "auto",
                  width: "40px",
                  height: "40px",
                  cursor: "pointer",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  borderRadius: 1,
                }}
                disabled={currentPage <= 1}
                onClick={() => setCurrentPage(currentPage - 1)}
              >
                <ArrowBackIos />
              </ButtonBase>
              <Box
                sx={{
                  marginLeft: "auto",
                  width: "40px",
                  height: "40px",
                  cursor: "pointer",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  borderRadius: 1,
                }}
              >
                {currentPage}
              </Box>
              <ButtonBase
                sx={{
                  color: "rgb(59, 59, 59))",
                  backgroundColor: "grey",
                  marginLeft: "auto",
                  width: "40px",
                  height: "40px",
                  cursor: "pointer",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  borderRadius: 1,
                }}
                disabled={currentPage >= lastPage}
                onClick={() => setCurrentPage(currentPage + 1)}
              >
                <ArrowForwardIos />
              </ButtonBase>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
}
export default AdminTransactions;
