import React, { useState } from "react";
import CustomButton from "../Button";
import { useTheme } from "@mui/material/styles";
import {
  Dialog,
  Box,
  Typography,
  InputAdornment,
  IconButton,
} from "@mui/material";
import LabeledTextField from "../LabeledTextField";
import * as yup from "yup";
import { useFormik } from "formik";
import { useMutation, useQuery } from "@tanstack/react-query";
import { EyeIcon, EyeSlashIcon } from "@heroicons/react/24/outline";
import {
  createPin,
  getMe,
  getSecurityQquestions,
  updatePin,
} from "../../api/userApi";
import { useNotificationStore, useUserStore } from "../../state";
import LabeledSelectField from "../LabeledSelectField";

function ChangePin({ handleShowPin }) {
  const theme = useTheme();
  const isAddedPin = useUserStore((state) => state.user?.isAddedPin);

  const [error, setError] = useState(null);
  const [showPassword, setshowPassword] = useState(false);
  const handleClickShowPassword = () => {
    setshowPassword(!showPassword);
  };

  const { data, isLoading: questionsLoading } = useQuery(
    ["questions"],
    getSecurityQquestions
  );

  const questions = data?.data?.data || [];
  const setUser = useUserStore((state) => state.setUser);

  const displayNotificaton = useNotificationStore(
    (state) => state.displayNotificaton
  );
  const { isLoading, mutate } = useMutation(
    isAddedPin == "0" ? createPin : updatePin,
    {
      onSuccess: async (res) => {
        displayNotificaton(res?.data?.message);
        if (res.data.status) {
          const res = await getMe();
          console.info({ res });
          if (res.data?.status) setUser(res.data?.data);
        }
        handleShowPin();
      },
      onError: (e) => {
        setError(
          e.response?.data?.message ??
            e.message ??
            "something went wrong try again"
        );
      },
    }
  );

  const initialValues = {
    pin: "",
    question: "",
    answer: "",
  };
  let validationSchema = yup.object({
    pin: yup.string().required("this field is required"),
    question: yup.string().required("this field is required"),
    answer: yup.string().required("this field is required"),
  });
  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      console.info({ values });
      mutate(isAddedPin == "0" ? values : { ...values, new_pin: values.pin });
    },
  });

  return (
    <Dialog
      sx={{
        "& .MuiDialog-paper": {
          borderRadius: "30px",
          width: { xs: "100%", sm: "50%", md: "27%" },
        },
      }}
      open={true}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-evenly",
          alignItems: "center",
          padding: "30px",
          width: { xs: "100%" },
          // height: "420px",
          background: "#FFFFFF",
          border: "1px solid #DFDFDF",
          borderRadius: "30px",
        }}
      >
        <form style={{ width: "100%" }} onSubmit={formik.handleSubmit}>
          <Box sx={{ width: "100%", marginTop: "20px" }}>
            <Box
              sx={{
                marginBottom: "60px",
              }}
            >
              <Typography
                sx={{
                  fontSize: "33px",
                  fontWeight: "700",
                  textAlign: "center",
                }}
              >
                {isAddedPin == "0" ? "Set" : "Change"} Pin
              </Typography>
              <Typography
                sx={{
                  color: "#9C0000",
                  textAlign: "start",
                }}
              >
                {error}
              </Typography>
            </Box>
            <Box sx={{ margin: "20px 0" }}>
              <LabeledTextField
                label="Pin"
                type={showPassword ? "text" : "password"}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      // onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {!showPassword ? (
                        <EyeSlashIcon
                          style={{
                            color: "grey",
                            width: "24px",
                          }}
                        />
                      ) : (
                        <EyeIcon
                          style={{
                            color: "grey",
                            width: "24px",
                          }}
                        />
                      )}
                    </IconButton>
                  </InputAdornment>
                }
                id="pin"
                name="pin"
                value={formik.values.pin}
                onChange={formik.handleChange}
                error={formik.touched.pin && Boolean(formik.errors.pin)}
                helperText={formik.touched.pin && formik.errors.pin}
                fullWidth
              />
            </Box>
            <Box sx={{ margin: "20px 0" }}>
              <LabeledSelectField
                label="Security Question"
                id="question"
                name="question"
                value={formik.values.question}
                onChange={formik.handleChange}
                error={
                  formik.touched.question && Boolean(formik.errors.question)
                }
                helperText={formik.touched.question && formik.errors.question}
                fullWidth
                loading={questionsLoading}
                data={questions?.map((v) => ({
                  label: v?.question,
                  value: v?.id,
                }))}
              />
            </Box>
            <Box sx={{ margin: "20px 0" }}>
              <LabeledTextField
                label="Answer(case sensitive)"
                id="answer"
                name="answer"
                value={formik.values.answer}
                onChange={formik.handleChange}
                error={formik.touched.answer && Boolean(formik.errors.answer)}
                helperText={formik.touched.answer && formik.errors.answer}
                fullWidth
              />
            </Box>

            <Box
              sx={{
                mb: 4,
                display: "flex",
                justifyContent: "space-between",
                width: "100%",
              }}
            >
              <Box sx={{ mr: 1, width: "39%" }}>
                <CustomButton
                  bgcolor={theme.palette.secondary.main}
                  color={theme.palette.primary.main}
                  onClick={handleShowPin}
                >
                  Cancel
                </CustomButton>
              </Box>
              <Box sx={{ width: "62%" }}>
                <CustomButton
                  type="submit"
                  bgcolor={theme.palette.primary.main}
                  loading={isLoading}
                >
                  Save
                </CustomButton>
              </Box>
            </Box>
          </Box>
        </form>
      </Box>
    </Dialog>
  );
}

export default ChangePin;
