import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles({
  item: {
    padding: "10px",
    display: "flex",
    background: "#FFFFFF",
    borderRadius: "24px",
    alignItems: "center",
    gap: "16px",
    boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
    cursor: "pointer",
    transition: "transform 0.2s ease",
    minWidth: "425px",
    //add animation on hover
    "@media (max-width: 900px)": {
      minWidth: "auto",
      width: "100%",
      marginBottom: "20px",
      "& svg": {
        width: "50px",
        height: "50px",
      },
    },
    "&:hover": {
      transform: "translateY(-20px)",
    },
    "& h6": {
      fontSize: "23px",
      lineHeight: "28px",
      color: "#1A3A5E",
      fontWeight: "700",
      marginBottom: "8px",
      "@media (max-width: 900px)": {
        fontSize: "16px",
        lineHeight: "19px",
      },
    },
    "& p": {
      fontSize: "16px",
      lineHeight: "19px",
      color: "#2b2b2c",
      fontWeight: "500",
      "@media (max-width: 900px)": {
        fontSize: "14px",
        lineHeight: "16px",
      },
    },
  },
});
