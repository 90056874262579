import React, { useEffect, useState } from "react";
import { Box } from "@mui/system";
import Grid from "@mui/material/Unstable_Grid2";
import CustomButton from "../Button";
import LabeledTextField from "../LabeledTextField";
import * as yup from "yup";
import { useFormik } from "formik";
import LabeledSelectField from "../LabeledSelectField";
import { useMutation, useQuery } from "@tanstack/react-query";
import { useNotificationStore } from "../../state";
import {
  buyCable,
  CableProviderProducts,
  getCableProviders,
  validateCable,
} from "../../api/userApi";
import Confirm from "../modals/Confirm";
import Success from "../modals/Success";
import Failure from "../modals/Failure";
import { useUser } from "../../hooks";

export default function CableForm({ admin }) {
  const [user, setUser] = useState();
  const [success, setSuccess] = useState(false);
  const [failure, setFailure] = useState(false);
  const initialValues = {
    naration: "",
    amount: "",
    beneficiary: "",
    provider_id: "",
    product_id: "",
  };
  let validationSchema = yup.object({
    naration: yup.string(),
    // .test("len", "Phone number is not valid", (val) => val?.length === 11)
    // .required("this field is required"),
    // .matches(phoneRegExp, "Phone number is not valid"),
    iuc: yup.string().required("this field is required"),
    provider_id: yup.string().required("this field is required"),
    product_id: yup.string().required("this field is required"),
    // beneficiary: yup.string().required("this field is required"),
    amount: yup.string().required("this field is required"),
  });

  const { data, isLoading } = useQuery(["cableProviders"], getCableProviders);

  const cableProviders = data?.data?.data || [];

  const displayNotificaton = useNotificationStore(
    (state) => state.displayNotificaton
  );

  const [products, setProducts] = useState([]);
  const { isLoading: productLoading, mutate: productMutate } = useMutation(
    CableProviderProducts,
    {
      onSuccess: (res) => {
        console.info({ res });
        const data = res?.data?.data?.map((v) => ({
          label: v.name,
          value: v.id,
          fee: v.meta?.fee,
        }));
        setProducts(data);
      },
      onError: (e) => {
        setProducts([]);
        displayNotificaton(
          e.response?.data?.message ??
            e.message ??
            "something went wrong try again"
        );
      },
    }
  );

  const { isLoading: validateLoading, mutate: validateMutate } = useMutation(
    validateCable,
    {
      onSuccess: (res) => {
        setUser(res?.data?.data);
      },
      onError: (e) => {
        displayNotificaton(
          e.response?.data?.message ??
            e.message ??
            "something went wrong try again"
        );
      },
    }
  );
  const { fetchUser } = useUser();

  const { isLoading: buyLoading, mutate: buyMutate } = useMutation(buyCable, {
    onSuccess: (res) => {
      fetchUser();

      if (res?.data?.status) setSuccess(res?.data?.message);
      else setFailure(res?.data?.message);
      setUser();
      formik.resetForm();
    },
    onError: (e) => {
      setFailure(
        e.response?.data?.message ??
          e.message ??
          "something went wrong try again"
      );
    },
  });

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      validateMutate(values);
    },
  });

  useEffect(() => {
    const product = products?.find(
      (v) => v?.value === formik.values?.product_id
    );
    console.info({ product }, formik.values?.product_id);
    formik.setFieldValue("amount", product?.fee);
  }, [formik.values?.product_id]);

  useEffect(() => {
    if (formik.values?.provider_id)
      productMutate({ provider_id: formik.values?.provider_id });
  }, [formik.values?.provider_id]);

  const buy = (pin) => {
    const { amount, provider_id, product_id, iuc } = formik.values;
    const type = cableProviders?.find(
      (v) => v.id === formik?.values?.provider_id
    )?.name;

    buyMutate({
      provider_id,
      type,
      product_id,
      iuc,
      pin,
      payment_reference: user?.payment_reference,
      amount,
    });
  };

  return (
    <>
      {user && (
        <Confirm
          message={`confirm cable purchase of ${
            products?.find((v) => v.value === formik?.values?.product_id)?.label
          } to ${user?.name}  iuc:${user?.unique_id}  `}
          proceed={buy}
          edit={() => setUser()}
          loading={buyLoading}
        />
      )}
      {success && (
        <Success message={success} handleClose={() => setSuccess()} />
      )}
      {failure && (
        <Failure message={failure} handleClose={() => setFailure()} />
      )}
      <Box sx={{ width: "min(578px ,100% )" }}>
        <Box
          sx={{
            mt: { xs: 3, md: 1 },
            minHeight: "60vh",
            minWidth: "243px",
            border: { xs: "none", md: "1px solid #e6e6ef" },
            borderRadius: "20px",
            padding: { xs: "0px", md: "24px" },
          }}
        >
          <form onSubmit={formik.handleSubmit}>
            <Box sx={{ mb: 4 }}>
              <LabeledSelectField
                label="Biller"
                id="provider_id"
                name="provider_id"
                value={formik.values.provider_id}
                onChange={formik.handleChange}
                error={
                  formik.touched.provider_id &&
                  Boolean(formik.errors.provider_id)
                }
                helperText={
                  formik.touched.provider_id && formik.errors.provider_id
                }
                fullWidth
                variant="outlined"
                data={cableProviders?.map((v) => ({
                  label: v.name,
                  value: v.id,
                }))}
                loading={isLoading}
                disabled={isLoading}
              />
            </Box>
            <Box sx={{ mb: 4 }}>
              <LabeledSelectField
                label="Product"
                id="product_id"
                name="product_id"
                value={formik.values.product_id}
                onChange={formik.handleChange}
                error={
                  formik.touched.product_id && Boolean(formik.errors.product_id)
                }
                helperText={
                  formik.touched.product_id && formik.errors.product_id
                }
                fullWidth
                variant="outlined"
                data={products}
                loading={productLoading}
                disabled={productLoading}
              />
            </Box>
            <Box sx={{ mb: 4 }}>
              <LabeledTextField
                label="Amount"
                id="amount"
                name="amount"
                value={formik.values.amount}
                onChange={formik.handleChange}
                error={formik.touched.amount && Boolean(formik.errors.amount)}
                helperText={formik.touched.amount && formik.errors.amount}
                fullWidth
                variant="outlined"
                size="small"
                disabled
              />
            </Box>
            <Box sx={{ mb: 4 }}>
              <LabeledTextField
                label="Smart Card Number"
                id="iuc"
                name="iuc"
                value={formik.values.iuc}
                onChange={(e) =>
                  formik.setFieldValue("iuc", e.target.value.trim())
                }
                error={formik.touched.iuc && Boolean(formik.errors.iuc)}
                helperText={formik.touched.iuc && formik.errors.iuc}
                fullWidth
                variant="outlined"
                size="small"
              />
            </Box>
            {/* <Box sx={{ mb: 4 }}>
        <LabeledTextField
          label="Beneficiary Name"
          id="beneficiary"
          name="beneficiary"
          value={formik.values.beneficiary}
          onChange={formik.handleChange}
          error={
            formik.touched.beneficiary &&
            Boolean(formik.errors.beneficiary)
          }
          helperText={
            formik.touched.beneficiary && formik.errors.beneficiary
          }
          fullWidth
          variant="outlined"
          size="small"
        />
      </Box> */}
            {/* <Box sx={{ mb: 4 }}>
        <LabeledTextField
          label="Naration"
          // hint="You can add multiple numbers. Ex: 08030302020, 09016161919"
          id="naration"
          name="naration"
          value={formik.values.naration}
          onChange={formik.handleChange}
          error={
            formik.touched.naration && Boolean(formik.errors.naration)
          }
          helperText={formik.touched.naration && formik.errors.naration}
          fullWidth
          variant="outlined"
        />
      </Box> */}
            <Box sx={{ mb: 4 }}>
              <CustomButton loading={validateLoading} type="submit">
                Continue
              </CustomButton>
            </Box>
          </form>
        </Box>
      </Box>
    </>
  );
}
