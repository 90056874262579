import React from "react";
import { Dialog, Box, Typography } from "@mui/material";

import CustomButton from "../Button";
import { Info } from "@mui/icons-material";
import { useUserStore } from "../../state";
import { formatter } from "../../helpers";

function ConfirmReseller({ handleClose, confirm, loading }) {
  const { reseller_charge } = useUserStore((state) => state.charge);

  return (
    <Dialog
      sx={{
        "& .MuiDialog-paper": {
          borderRadius: "30px",
          width: { xs: "100%", sm: "50%", md: "27%" },
        },
      }}
      open={true}
      onClose={handleClose}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-evenly",
          alignItems: "center",
          padding: "30px",
          width: { xs: "100%" },
          // height: "420px",
          background: "#FFFFFF",
          border: "1px solid #DFDFDF",
          borderRadius: "30px",
          textAlign: "center",
        }}
      >
        <Info />
        <Typography
          sx={{ color: "#00421B", mt: "10px", mb: "20px", fontWeight: "700" }}
        >
          Pay the sum of N{formatter.format(reseller_charge)} to become a
          reseller and enjoy amazing discounts
        </Typography>
        <CustomButton loading={loading} type="submit" onClick={confirm}>
          Pay now
        </CustomButton>
      </Box>
    </Dialog>
  );
}

export default ConfirmReseller;
