/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import CustomButton from "../Button";
import { useTheme } from "@mui/material/styles";
import { Dialog, Box, Typography } from "@mui/material";
import LabeledTextField from "../LabeledTextField";
import { useFormik } from "formik";
import * as yup from "yup";
import { useMutation } from "@tanstack/react-query";
import Success from "../modals/Success";
import Failure from "../modals/Failure";
import { addNin} from "../../api/userApi";
import { useUser } from "../../hooks";

function AddNin({ handleOpenNin, refetch, selected }) {
 
  const { id } = selected || {};

  const theme = useTheme();
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(false);
  const [failure, setFailure] = useState(false);

 

  const { isLoading, mutate } = useMutation(addNin, {
    onSuccess: (res) => {
        fetchUser();
        if (res?.data?.status) setSuccess(res?.data?.message);
        else setFailure(res?.data?.message);
        formik.resetForm();   
    },
    onError: (e) => {
      setError(
        e.response?.data?.message ??
          e.message ??
          "something went wrong try again"
      );
    },
  });

 

  const initialValues = {
    nin: "",
  };
  let validationSchema = yup.object({
    nin: yup.string().required("this field is required"),
  });
  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      if (id) {
        values["nin"] = id;       
      } ;
      mutate(values);
    },
  });


  const { fetchUser } = useUser();

  useEffect(() => {
    fetchUser();
  }, []);
  return ( 
    <Box>
    <Box>
    {success && (
    <Success message={success} handleClose={() => setSuccess()} />
  )}
  {failure && (
    <Failure message={failure} handleClose={() => setFailure()} />
  )}</Box> 
    <Dialog
      sx={{
        "& .MuiDialog-paper": {
          borderRadius: "30px",
          width: { xs: "100%", sm: "50%", md: "27%" },
        },
      }}
      open={true}
    >
    <Box
      sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-evenly",
          alignItems: "center",
          padding: "30px",
          width: { xs: "100%" },
          // height: "420px",
          background: "#FFFFFF",
          border: "1px solid #DFDFDF",
          borderRadius: "30px",
          textAlign: "center",
        }}
      >
        <Typography variant="header" sx={{ fontSize: "19px" }}>
          Update Your KYC
        </Typography>
        <Typography
          sx={{
            color: "#9C0000",
            textAlign: "start",
          }}
        >
          {error}
        </Typography>
        <form style={{ width: "100%" }} onSubmit={formik.handleSubmit}>
          <Box sx={{ mb: 4 }}>
            <LabeledTextField
              label="NIN"
              id="nin"
              name="nin"
              value={formik.values.nin}
              onChange={formik.handleChange}
              error={formik.touched.nin && Boolean(formik.errors.nin)}
              helperText={formik.touched.nin && formik.errors.nin}
              fullWidth
              variant="outlined"
              size="small"
            />
          </Box>

          <Box sx={{ mb: 4, display: "flex", justifyContent: "space-between" }}>
            <Box sx={{ mr: 1, width: "39%" }}>
              <CustomButton
                bgcolor={theme.palette.secondary.main}
                color={theme.palette.primary.main}
                onClick={handleOpenNin}
              >
                Cancel
              </CustomButton>
            </Box>
            <Box sx={{ width: "62%" }}>
              <CustomButton
                loading={isLoading}
                type="submit"
              >
                Continue
              </CustomButton>
            </Box>
          </Box>
        </form>
      </Box>
    </Dialog>
    </Box> 
  );
}

export default AddNin;
