import React, { useEffect, useRef, useState } from "react";
import { Breadcrumbs, CircularProgress, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { useTheme } from "@mui/material/styles";
import Grid from "@mui/material/Unstable_Grid2";
import { getCharges, updateCharges } from "../../api/adminApi";
import { useMutation, useQuery } from "@tanstack/react-query";
import LabeledTextField from "../../components/LabeledTextField";
import CustomButton from "../../components/Button";
import TabHeader from "../../components/TabHeader";

import { useFormik } from "formik";
import * as yup from "yup";
import { useNotificationStore } from "../../state";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function Discounts(props) {
  const theme = useTheme();
  const [value, setValue] = useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const displayNotificaton = useNotificationStore(
    (state) => state.displayNotificaton
  );

  const { isLoading: updateLoading, mutate } = useMutation(updateCharges, {
    onSuccess: (res) => {
      console.info({ res });
      displayNotificaton(res?.data?.message);
      refetch();
    },
    onError: (e) => {
      displayNotificaton(
        e.response?.data?.message ??
          e.message ??
          "something went wrong try again"
      );
    },
  });

  const { data, isLoading, isRefetching, refetch } = useQuery(
    ["charges"],
    getCharges
  );

  const charges = data?.data?.data[0];
  const { id, name, airtime_charges, cable_charges, data_charges, ...rest } =
    charges || {};

  useEffect(() => {
    setValue(0);
    if (!charges) return;

    const { id, name, airtime_charges, cable_charges, data_charges, ...rest } =
      charges || {};

    const dataInitials = {};
    Object.entries(data_charges?.reseller_charge ?? {})?.forEach(([k, v]) => {
      dataInitials[`reseller_${k}`] = v;
    });

    Object.entries(data_charges?.user_charge ?? {})?.forEach(([k, v]) => {
      dataInitials[`user_${k}`] = v;
    });

    formik.setValues({
      ...dataInitials,
      ...airtime_charges,
      ...cable_charges,
      ...dataInitials,
      ...rest,
    });
  }, [charges]);

  const initialValues = {};
  const schemaOptions = {};
  Object.keys(airtime_charges ?? {}).forEach(
    (k) => (schemaOptions[k] = yup.string().required("this field is required"))
  );
  Object.keys(cable_charges ?? {}).forEach(
    (k) => (schemaOptions[k] = yup.string().required("this field is required"))
  );
  Object.keys(data_charges?.reseller_charge ?? {}).forEach(
    (k) =>
      (schemaOptions[`reseller_${k}`] = yup
        .string()
        .required("this field is required"))
  );
  Object.keys(data_charges?.user_charge ?? {}).forEach(
    (k) =>
      (schemaOptions[`user_${k}`] = yup
        .string()
        .required("this field is required"))
  );
  Object.keys(rest ?? {}).forEach(
    (k) => (schemaOptions[k] = yup.string().required("this field is required"))
  );
  let validationSchema = yup.object(schemaOptions);

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      const {
        reseller_charge,
        reseller_dstv,
        reseller_electricity,
        reseller_gotv,
        reseller_sas,
        reseller_startimes,
        reseller_transfer,
        reseller_vtu,
        reseller_withdrawal,
        user_dstv,
        user_electricity,
        user_gotv,
        user_sas,
        user_startimes,
        user_transfer,
        user_vtu,
        user_withdrawal,
        name,
        reseller_airtel_corporate,
        reseller_airtel_gifting,
        reseller_airtel_sme,
        reseller_etisalat_corporate,
        reseller_etisalat_gifting,
        reseller_etisalat_sme,
        reseller_glo_corporate,
        reseller_glo_gifting,
        reseller_glo_sme,
        reseller_mtn_corporate,
        reseller_mtn_gifting,
        reseller_mtn_sme,
        user_airtel_corporate,
        user_airtel_gifting,
        user_airtel_sme,
        user_etisalat_corporate,
        user_etisalat_gifting,
        user_etisalat_sme,
        user_glo_corporate,
        user_glo_gifting,
        user_glo_sme,
        user_mtn_corporate,
        user_mtn_gifting,
        user_mtn_sme,
        referral_reward,
      } = values;

      const data = {
        id,
        name,
        airtime_charges: {
          reseller_sas,
          reseller_vtu,
          user_sas,
          user_vtu,
        },
        cable_charges: {
          reseller_dstv,
          reseller_gotv,
          reseller_startimes,
          user_dstv,
          user_gotv,
          user_startimes,
        },
        data_charges: {
          reseller_charge: {
            airtel_corporate: parseFloat(reseller_airtel_corporate),
            airtel_gifting: reseller_airtel_gifting,
            airtel_sme: reseller_airtel_sme,
            etisalat_corporate: reseller_etisalat_corporate,
            etisalat_gifting: reseller_etisalat_gifting,
            etisalat_sme: reseller_etisalat_sme,
            glo_corporate: reseller_glo_corporate,
            glo_gifting: reseller_glo_gifting,
            glo_sme: reseller_glo_sme,
            mtn_corporate: reseller_mtn_corporate,
            mtn_gifting: reseller_mtn_gifting,
            mtn_sme: reseller_mtn_sme,
          },
          user_charge: {
            airtel_corporate: user_airtel_corporate,
            airtel_gifting: user_airtel_gifting,
            airtel_sme: user_airtel_sme,
            etisalat_corporate: user_etisalat_corporate,
            etisalat_gifting: user_etisalat_gifting,
            etisalat_sme: user_etisalat_sme,
            glo_corporate: user_glo_corporate,
            glo_gifting: user_glo_gifting,
            glo_sme: user_glo_sme,
            mtn_corporate: user_mtn_corporate,
            mtn_gifting: user_mtn_gifting,
            mtn_sme: user_mtn_sme,
          },
        },
        reseller_charge,
        reseller_electricity,
        reseller_transfer,
        reseller_withdrawal,
        user_electricity,
        user_transfer,
        user_withdrawal,
        referral_reward,
      };

      mutate({ data, id });
    },
  });

  const labels = ["Airtime", "Cable", "User Data", "Reseller Data", "Others"];

  return (
    <div>
      <Box sx={{ display: "flex" }}>
        <Typography variant="header" color={theme.palette.neutral.N200}>
          Discounts
        </Typography>
      </Box>
      <Breadcrumbs aria-label="breadcrumb">
        <Typography color={theme.palette.primary}>Discounts</Typography>
      </Breadcrumbs>
      <Grid container spacing={2} mt={4}>
        <Grid xs={12} sm={11} md={10} lg={7} xl={6}>
          <Box
            className="card"
            sx={{ mt: 5, minHeight: "60vh", padding: "20px" }}
          >
            {isLoading || isRefetching ? (
              <Box sx={{ display: "flex", justifyContent: "center" }}>
                <CircularProgress />
              </Box>
            ) : (
              <Box
                sx={
                  {
                    // width: "100%",
                    // height: "80vh",
                  }
                }
              >
                <TabHeader
                  labels={labels}
                  value={value}
                  handleChange={handleChange}
                />
                <TabPanel value={value} index={0}>
                  <form
                    style={{ width: "100%" }}
                    onSubmit={formik.handleSubmit}
                  >
                    {airtime_charges &&
                      Object.entries(airtime_charges)?.map((charge, i) => (
                        <Box sx={{ mb: 4, mt: 2 }} key={i}>
                          <LabeledTextField
                            label={charge[0].replace("_", " ").toUpperCase()}
                            id={charge[0]}
                            name={charge[0]}
                            value={formik.values[charge[0]]}
                            onChange={formik.handleChange}
                            error={
                              formik.touched[charge[0]] &&
                              Boolean(formik.errors[charge[0]])
                            }
                            helperText={
                              formik.touched[charge[0]] &&
                              formik.errors[charge[0]]
                            }
                            fullWidth
                            variant="outlined"
                          />
                        </Box>
                      ))}
                    <Box
                      sx={{
                        mb: 4,
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <Box sx={{ width: "100%" }}>
                        <CustomButton loading={updateLoading} type="submit">
                          Continue
                        </CustomButton>
                      </Box>
                    </Box>
                  </form>
                </TabPanel>
                <TabPanel value={value} index={1}>
                  <form
                    style={{ width: "100%" }}
                    onSubmit={formik.handleSubmit}
                  >
                    {cable_charges &&
                      Object.entries(cable_charges)?.map((charge, i) => (
                        <Box sx={{ mb: 4, mt: 2 }} key={i}>
                          <LabeledTextField
                            label={charge[0].replace("_", " ").toUpperCase()}
                            id={charge[0]}
                            name={charge[0]}
                            value={formik.values[charge[0]]}
                            onChange={formik.handleChange}
                            error={
                              formik.touched[charge[0]] &&
                              Boolean(formik.errors[charge[0]])
                            }
                            helperText={
                              formik.touched[charge[0]] &&
                              formik.errors[charge[0]]
                            }
                            fullWidth
                            variant="outlined"
                          />
                        </Box>
                      ))}

                    <Box
                      sx={{
                        mb: 4,
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <Box sx={{ width: "100%" }}>
                        <CustomButton loading={updateLoading} type="submit">
                          Continue
                        </CustomButton>
                      </Box>
                    </Box>
                  </form>
                </TabPanel>
                <TabPanel value={value} sx={{ height: "95vh" }} index={2}>
                  <form
                    style={{ width: "100%", height: "100%" }}
                    onSubmit={formik.handleSubmit}
                  >
                    {data_charges &&
                      Object.entries(data_charges.user_charge ?? {})?.map(
                        (charge, i) => (
                          <Box sx={{ mb: 4, mt: 2 }} key={i}>
                            <LabeledTextField
                              label={charge[0].replace("_", " ").toUpperCase()}
                              id={`user_${charge[0]}`}
                              name={`user_${charge[0]}`}
                              value={formik.values[`user_${charge[0]}`]}
                              onChange={formik.handleChange}
                              error={
                                formik.touched[`user_${charge[0]}`] &&
                                Boolean(formik.errors[`user_${charge[0]}`])
                              }
                              helperText={
                                formik.touched[`user_${charge[0]}`] &&
                                formik.errors[`user_${charge[0]}`]
                              }
                              fullWidth
                              variant="outlined"
                            />
                          </Box>
                        )
                      )}

                    <Box
                      sx={{
                        mb: 4,
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <Box sx={{ width: "100%" }}>
                        <CustomButton loading={updateLoading} type="submit">
                          Continue
                        </CustomButton>
                      </Box>
                    </Box>
                  </form>
                </TabPanel>
                <TabPanel value={value} sx={{ height: "95vh" }} index={3}>
                  <form
                    style={{ width: "100%", height: "100%" }}
                    onSubmit={formik.handleSubmit}
                  >
                    {data_charges &&
                      Object.entries(data_charges?.reseller_charge ?? {})?.map(
                        (charge, i) => (
                          <Box sx={{ mb: 4, mt: 2 }} key={i}>
                            <LabeledTextField
                              label={charge[0].replace("_", " ").toUpperCase()}
                              id={`reseller_${charge[0]}`}
                              name={`reseller_${charge[0]}`}
                              value={formik.values[`reseller_${charge[0]}`]}
                              onChange={formik.handleChange}
                              error={
                                formik.touched[`reseller_${charge[0]}`] &&
                                Boolean(formik.errors[`reseller_${charge[0]}`])
                              }
                              helperText={
                                formik.touched[`reseller_${charge[0]}`] &&
                                formik.errors[`reseller_${charge[0]}`]
                              }
                              fullWidth
                              variant="outlined"
                            />
                          </Box>
                        )
                      )}

                    <Box
                      sx={{
                        mb: 4,
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <Box sx={{ width: "100%" }}>
                        <CustomButton loading={updateLoading} type="submit">
                          Continue
                        </CustomButton>
                      </Box>
                    </Box>
                  </form>
                </TabPanel>
                <TabPanel value={value} index={4}>
                  <form
                    style={{ width: "100%" }}
                    onSubmit={formik.handleSubmit}
                  >
                    {rest &&
                      Object.entries(rest)?.map((charge, i) => (
                        <Box sx={{ mb: 4, mt: 2 }} key={i}>
                          <LabeledTextField
                            label={charge[0].replace("_", " ").toUpperCase()}
                            id={charge[0]}
                            name={charge[0]}
                            value={formik.values[charge[0]]}
                            onChange={formik.handleChange}
                            error={
                              formik.touched[charge[0]] &&
                              Boolean(formik.errors[charge[0]])
                            }
                            helperText={
                              formik.touched[charge[0]] &&
                              formik.errors[charge[0]]
                            }
                            fullWidth
                            variant="outlined"
                          />
                        </Box>
                      ))}
                    <Box
                      sx={{
                        mb: 4,
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <Box sx={{ width: "100%" }}>
                        <CustomButton loading={updateLoading} type="submit">
                          Continue
                        </CustomButton>
                      </Box>
                    </Box>
                  </form>
                </TabPanel>
              </Box>
            )}
          </Box>
        </Grid>
      </Grid>
    </div>
  );
}

export default Discounts;
