import React from "react";
import { useStyles } from "./footer.style";
import logo from "../../../images/logo-whitepng.png";
import { Link } from "react-router-dom";
import { Facebook, Instagram, Linkedin, Tiktok, Twitter } from "../../../icons";

const Footer = () => {
  const classes = useStyles();

  const year = new Date().getFullYear();
  return (
    <div className={classes.footer}>
      <div className="wrapper">
        <div className="big-row">
          <img src={logo} alt="logo" height={"66px"} />
          <div className="items">
            <div className="item">
              <h6>Company</h6>
              <div className="links">
                <Link to="/">Home</Link>
                <Link to="/">Services</Link>
                <Link to="/">Documentation</Link>
                <Link to="/">About</Link>
              </div>
            </div>
            <div className="item">
              <h6>Legal</h6>
              <div className="links">
                <Link to="/">Terms of Service</Link>
                <Link to="/">Privacy Policys</Link>
                <Link to="/">Cookie Policy</Link>
              </div>
            </div>
            <div className="item">
              <h6>Contact</h6>
              <div className="links">
                <a href="tel:+2348062718818">(+234)8062718818</a>
                <a href="mailto:support@zainnest.com">support@zainnest.com</a>
                <div className="socials">
                  <a href="https://www.linkedin.com/company/zainnest/">
                    <Linkedin />{" "}
                  </a>
                  <a href="https://Instagram.com/zainnest.ng">
                    <Instagram />
                  </a>
                  <a href="https://www.tiktok.com/@zainnestng?_t=8dhpPdpg0U2&_r=1">
                    <Tiktok />
                  </a>
                  <a href="https://www.facebook.com/zainnestng?mibextid=ZbWKwL">
                    <Facebook />
                  </a>
                  <a href="https://twitter.com/zainnestng">
                    <Twitter />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="copyright">
          &copy; {year} Zainnest Digital Services Limited.
        </div>
      </div>
    </div>
  );
};

export default Footer;
