import React from "react";
import { Dialog, Box, Typography } from "@mui/material";
import icecream from "../../icons/icecream.png";

function Success({ message, handleClose, data }) {
  return (
    <Dialog
      sx={{
        "& .MuiDialog-paper": {
          borderRadius: "30px",
          width: { xs: "100%", sm: "50%", md: "27%" },
        },
      }}
      open={true}
      onClose={handleClose}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-evenly",
          alignItems: "center",
          padding: "30px",
          width: { xs: "100%" },
          // height: "420px",
          background: "#FFFFFF",
          border: "1px solid #DFDFDF",
          borderRadius: "30px",
          textAlign: "center",
        }}
      >
        <img
          src={icecream}
          style={{ margin: "-50px -20px 0 0" }}
          alt="success"
        />
        <Typography
          sx={{ color: "#00421B", mt: "10px", mb: "20px", fontWeight: "700" }}
        >
          {message ?? "Transaction Successful"}
        </Typography>
        {/* <CustomButton
          // loading={isLoading}
          type="submit"
          onClick={print}
        >
          <Download style={{ width: "30px", marginRight: "5px" }} /> Download
          Receipt
        </CustomButton> */}
      </Box>
    </Dialog>
  );
}

export default Success;
