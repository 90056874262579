import React, { useRef } from "react";
import { Dialog, Box } from "@mui/material";
import CustomButton from "../Button";
import { ShareIcon } from "@heroicons/react/24/outline";
import Pdf from "react-to-pdf";
import { Download } from "@mui/icons-material";

// Create Document Component

function ViewTransaction({ data, handleClose }) {
  const { amount, phone, description, created_at, status, type, network, pin } =
    data;
  const fields = {
    amount,
    to: phone?.data,
    description,
    date: created_at?.substring(0, 13),
    type,
  };
  if (network) fields["network"] = network;
  if (pin) fields["pin"] = pin?.data;
  const ref = useRef();
  return (
    <Dialog
      sx={{
        "& .MuiDialog-paper": {
          borderRadius: "30px",
          width: { xs: "100%", sm: "50%", md: "27%" },
        },
      }}
      open={true}
      onClose={handleClose}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-evenly",
          alignItems: "center",
          padding: "30px",
          width: { xs: "100%" },
          // height: "420px",
          background: "#FFFFFF",
          border: "1px solid #DFDFDF",
          borderRadius: "30px",
          textAlign: "center",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-evenly",
            alignItems: "center",
            padding: "30px",
            width: { xs: "100%" },
            // height: "420px",
            background: "#FFFFFF",
            // border: "1px solid #DFDFDF",
            borderRadius: "30px",
            textAlign: "center",
          }}
          ref={ref}
        >
          <Box>
            <Box
              sx={{
                width: "94px",
                height: "39px",
                borderRadius: "100px",
                backgroundColor: status === "success" ? "#E6F0EA" : "#FFE6E6",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                padding: "10px",
                mb: "20px",
              }}
            >
              {status}
            </Box>
          </Box>

          {Object.entries(fields)?.map(([k, v], i) => (
            <>
              <Box
                sx={{
                  display: "flex",
                  width: "100%",
                  padding: "10px 0px",
                }}
              >
                <Box
                  sx={{
                    flex: "0.8",
                    textAlign: "left",
                    textTransform: "capitalize",
                  }}
                >
                  {k}{" "}
                </Box>
                <Box
                  sx={{
                    flex: "1.2",
                    textAlign: "right",
                  }}
                >
                  {v}
                </Box>
              </Box>

              {Object.keys(fields).length > i + 1 && (
                <Box
                  sx={{ borderBottom: "0.5px solid #DFDFDF", width: "100%" }}
                />
              )}
            </>
          ))}
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            width: "100%",
            mt: "20px",
          }}
        >
          <CustomButton
            onClick={handleClose}
            sx={{ backgroundColor: "#E6E6EF", width: "25%" }}
          >
            Close
          </CustomButton>
          <Pdf targetRef={ref} filename="document.pdf">
            {({ toPdf }) => (
              <CustomButton
                sx={{ width: "70%", whiteSpace: "nowrap" }}
                type="submit"
                onClick={toPdf}
              >
                <Download style={{ width: "30px", marginRight: "5px" }} />{" "}
                Download Receipt
              </CustomButton>
            )}
          </Pdf>
        </Box>
      </Box>
    </Dialog>
  );
}

export default ViewTransaction;
