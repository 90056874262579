import React, { useState } from "react";
import { useStyles } from "./signup.style";
import GeneralHeader from "../../components/LandingPage/generalHeader";
import Footer from "../../components/LandingPage/footer";
import img from "../../images/signup-bg.png";
import { Basket, Eye, Inbox, Person, Profile ,BvnNin} from "../../icons";
import { Link, NavLink, useNavigate } from "react-router-dom";
import { EyeIcon, EyeSlashIcon } from "@heroicons/react/24/outline";
import * as yup from "yup";
import { useFormik } from "formik";

import { useMutation } from "@tanstack/react-query";
import { signUp } from "../../api/authApi";
import StorageService from "../../StorageService";
import { useUserStore } from "../../state";

const SignUp = () => {
  const classes = useStyles();
  const [showPassword, setshowPassword] = useState(false);
  const [error, setError] = useState(null);
  const [showConfirmPassword, setshowConfirmPassword] = useState(false);
  const handleClickShowPassword = () => {
    setshowPassword(!showPassword);
  };
  const handleClickShowConfirmPassword = () => {
    setshowConfirmPassword(!showConfirmPassword);
  };

  const loginUser = useUserStore((state) => state.loginUser);

  const { isLoading, mutate } = useMutation(signUp, {
    onSuccess: (res) => {
      const { access_token, user } = res.data?.data;
      StorageService.setToken({ access_token });
      loginUser({ ...(user || {}), email_verified: false });
    },
    onError: (e) => {
      setError(
        e.response?.data?.message ??
          e.message ??
          "something went wrong try again"
      );
    },
  });

  const initialValues = {
    email: "",
    username: "",
    referral: "",
    password: "",
    password_confirmation: "",
  };
  let validationSchema = yup.object({
    email: yup
      .string()
      .email("invalid email format")
      .required("this field is required"),
    username: yup.string().required("this field is required"),
    // referral: yup.string().required("this field is required"),
    password: yup.string().required("this field is required"),
    password_confirmation: yup
      .string()
      .required("this field is required")
      .oneOf([yup.ref("password"), null], "Passwords must match"),
  });

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      setError(null);
      mutate(values);
    },
  });
  const navigate = useNavigate();
  return (
    <div className={classes.container}>
      <GeneralHeader />
      <div className={classes.signUpSection}>
        <div className="wrapper">
          <img
            src={img}
            alt="signup"
            height={"545px"}
            width={"auto"}
            className="imgContainer"
          />

          <div className="formContainer">
            <h6>Create your account profile</h6>
            {error && <div className="error-text">{error}</div>}
            <form onSubmit={formik.handleSubmit}>
              <div className="form-group">
                <label htmlFor="name">Full Name</label>
                <div className="input-group">
                  <input
                    type="text"
                    id="name"
                    name="name"
                    placeholder="Enter your full name"
                  />
                  <div className="input-group-append">
                    <Profile />
                  </div>
                </div>
              </div>

              <div className="form-group">
                <label htmlFor="username">Username</label>
                <div className="input-group">
                  <input
                    type="text"
                    id="usernamme"
                    name="username"
                    placeholder="Enter your username"
                    value={formik.values.username}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.username && Boolean(formik.errors.username)
                    }
                    helperText={
                      formik.touched.username && formik.errors.username
                    }
                  />
                  <div className="input-group-append">
                    <Profile />
                  </div>
                </div>
              </div>
              <div className="form-group">
                <label htmlFor="email">Email Address</label>
                <div className="input-group">
                  <input
                    name="email"
                    type="email"
                    id="email"
                    placeholder="Enter your email address"
                    value={formik.values.email}
                    onChange={formik.handleChange}
                    error={formik.touched.email && Boolean(formik.errors.email)}
                    helperText={formik.touched.email && formik.errors.email}
                  />
                  <div className="input-group-append">
                    <Inbox />
                  </div>
                </div>
              </div>
              <div className="form-group">
                <label htmlFor="password">Password</label>
                <div className="input-group">
                  <input
                    type={showPassword ? "text" : "password"}
                    id="password"
                    name="password"
                    placeholder="Enter your password"
                    value={formik.values.password}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.password && Boolean(formik.errors.password)
                    }
                    helperText={
                      formik.touched.password && formik.errors.password
                    }
                  />
                  <div
                    className="input-group-append"
                    onClick={handleClickShowPassword}
                  >
                    {!showPassword ? (
                      <EyeSlashIcon
                        style={{
                          color: "#1A3A5E",
                          width: "24px",
                        }}
                      />
                    ) : (
                      <EyeIcon
                        style={{
                          color: "#1A3A5E",
                          width: "24px",
                        }}
                      />
                    )}
                  </div>
                </div>
              </div>
              <div className="form-group">
                <label htmlFor="confirmPassword">Confirm Password</label>
                <div className="input-group">
                  <input
                    type={showConfirmPassword ? "text" : "password"}
                    placeholder="Confirm your password"
                    id="password_confirmation"
                    name="password_confirmation"
                    value={formik.values.password_confirmation}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.password_confirmation &&
                      Boolean(formik.errors.password_confirmation)
                    }
                    helperText={
                      formik.touched.password_confirmation &&
                      formik.errors.password_confirmation
                    }
                  />

                  <div
                    className="input-group-append"
                    onClick={handleClickShowConfirmPassword}
                  >
                    {!showConfirmPassword ? (
                      <EyeSlashIcon
                        style={{
                          color: "#1A3A5E",
                          width: "24px",
                        }}
                      />
                    ) : (
                      <EyeIcon
                        style={{
                          color: "#1A3A5E",
                          width: "24px",
                        }}
                      />
                    )}
                  </div>
                </div>
              </div>
              <div className="form-group">
                <label htmlFor="referralCode">Referral (Optional)</label>
                <div className="input-group">
                  <input
                    type="text"
                    id="referral"
                    name="referral"
                    value={formik.values.referral}
                    onChange={formik.handleChange}
                    placeholder="Enter your referral code"
                  />
                  <div className="input-group-append">
                    <Basket />
                  </div>
                </div>
              </div>
              <div className="form-group">
                <label htmlFor="ninCode">NIN</label>
                <div className="input-group">
                  <input
                    type="text"
                    id="nin"
                    name="nin"
                    value={formik.values.nin}
                    onChange={formik.handleChange}
                    placeholder="Enter your NIN"
                  />
                  <div className="input-group-append">
                    <BvnNin />
                  </div>
                </div>
              </div>
              <div className="button-group">
                <button className="primary" type="submit" disabled={isLoading}>
                  {isLoading ? "Loading..." : "Create Account"}
                </button>
                <div className="divider">Already have an account?</div>
                <Link to="/signin" className="secondary">
                  Sign in
                </Link>
              </div>
            </form>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default SignUp;
