import React, { useEffect, useState } from "react";
import { Box } from "@mui/system";
import Grid from "@mui/material/Unstable_Grid2";
import CustomButton from "../Button";
import LabeledTextField from "../LabeledTextField";
import * as yup from "yup";
import { useFormik } from "formik";
import LabeledSelectField from "../LabeledSelectField";
import { useMutation, useQuery } from "@tanstack/react-query";
import {
  buyUtility,
  getElectricityProviders,
  utilityProviderProducts,
  validateUtility,
} from "../../api/userApi";
import { useNotificationStore } from "../../state";
import Confirm from "../modals/Confirm";
import Success from "../modals/Success";
import Failure from "../modals/Failure";
import { useUser } from "../../hooks";

export default function UtilityForm({ admin }) {
  const [user, setUser] = useState();
  const [success, setSuccess] = useState(false);
  const [failure, setFailure] = useState(false);

  const initialValues = {
    naration: "",
    amount: "",
    beneficiary: "",
    provider_id: "",
    type: "",
    product_id: "",
    meter_no: "",
  };
  let validationSchema = yup.object({
    naration: yup.string(),
    // .test("len", "Phone number is not valid", (val) => val?.length === 11)
    // .required("this field is required"),
    // .matches(phoneRegExp, "Phone number is not valid"),
    amount: yup.string().required("this field is required"),
    provider_id: yup.string().required("this field is required"),
    type: yup.string().required("this field is required"),
    product_id: yup.string().required("this field is required"),
    // beneficiary: yup.string().required("this field is required"),
    meter_no: yup.string().required("this field is required"),
  });

  const { data, isLoading } = useQuery(
    ["electricityProviders"],
    getElectricityProviders
  );

  const electricityProviders = data?.data?.data || [];

  const displayNotificaton = useNotificationStore(
    (state) => state.displayNotificaton
  );

  const [products, setProducts] = useState([]);
  const { isLoading: productLoading, mutate: productMutate } = useMutation(
    utilityProviderProducts,
    {
      onSuccess: (res) => {
        console.info({ res });
        const data = res?.data?.data?.map((v) => ({
          label: v.name,
          value: v.id,
        }));
        setProducts(data);
      },
      onError: (e) => {
        setProducts([]);
        displayNotificaton(
          e.response?.data?.message ??
            e.message ??
            "something went wrong try again"
        );
      },
    }
  );

  const { isLoading: validateLoading, mutate: validateMutate } = useMutation(
    validateUtility,
    {
      onSuccess: (res) => {
        setUser(res?.data?.data);
      },
      onError: (e) => {
        displayNotificaton(
          e.response?.data?.message ??
            e.message ??
            "something went wrong try again"
        );
      },
    }
  );
  const { fetchUser } = useUser();

  const { isLoading: buyLoading, mutate: buyMutate } = useMutation(buyUtility, {
    onSuccess: (res) => {
      fetchUser();

      if (res?.data?.status) setSuccess(res?.data?.message);
      else setFailure(res?.data?.message);
      setUser();
      formik.resetForm();
    },
    onError: (e) => {
      setFailure(
        e.response?.data?.message ??
          e.message ??
          "something went wrong try again"
      );
    },
  });

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      validateMutate(values);
    },
  });

  useEffect(() => {
    if (formik.values?.provider_id)
      productMutate({ provider_id: formik.values?.provider_id });
  }, [formik.values?.provider_id]);

  const types = [
    {
      label: "Prepaid",
      value: "prepaid",
    },
    {
      label: "Postpaid",
      value: "postpaid",
    },
  ];

  const buy = (pin) => {
    const { amount, provider_id, type, product_id, meter_no } = formik.values;
    buyMutate({
      provider_id,
      meter_type: type,
      product_id,
      meter_number: meter_no,
      pin,
      payment_reference: user?.payment_reference,
      amount,
    });
  };

  return (
    <>
      {user && (
        <Confirm
          message={`confirm electricity purchase of ${formik?.values?.amount} to  ${user?.name} ${formik?.values?.meter_no}`}
          proceed={buy}
          edit={() => setUser()}
          loading={buyLoading}
        />
      )}
      {success && (
        <Success message={success} handleClose={() => setSuccess()} />
      )}
      {failure && (
        <Failure message={failure} handleClose={() => setFailure()} />
      )}
      <Box sx={{ width: "min(578px ,100% )" }}>
        <Box
          sx={{
            mt: { xs: 3, md: 1 },
            minHeight: "60vh",
            minWidth: "243px",
            border: { xs: "none", md: "1px solid #e6e6ef" },
            borderRadius: "20px",
            padding: { xs: "0px", md: "24px" },
          }}
        >
          <form onSubmit={formik.handleSubmit}>
            <Box sx={{ mb: 4 }}>
              <LabeledSelectField
                label="Biller"
                id="provider_id"
                name="provider_id"
                value={formik.values.provider_id}
                onChange={formik.handleChange}
                error={
                  formik.touched.provider_id &&
                  Boolean(formik.errors.provider_id)
                }
                helperText={
                  formik.touched.provider_id && formik.errors.provider_id
                }
                fullWidth
                loading={isLoading}
                disabled={isLoading}
                variant="outlined"
                data={electricityProviders?.map((v) => ({
                  label: v.name,
                  value: v.id,
                }))}
              />
            </Box>
            <Box sx={{ mb: 4 }}>
              <LabeledSelectField
                label="Product"
                id="product_id"
                name="product_id"
                value={formik.values.product_id}
                onChange={formik.handleChange}
                error={
                  formik.touched.product_id && Boolean(formik.errors.product_id)
                }
                helperText={
                  formik.touched.product_id && formik.errors.product_id
                }
                fullWidth
                variant="outlined"
                loading={productLoading}
                disabled={productLoading}
                data={products}
              />
            </Box>
            <Box sx={{ mb: 4 }}>
              <LabeledSelectField
                label="Type"
                id="type"
                name="type"
                value={formik.values.type}
                onChange={formik.handleChange}
                error={formik.touched.type && Boolean(formik.errors.type)}
                helperText={formik.touched.type && formik.errors.type}
                fullWidth
                variant="outlined"
                data={types}
              />
            </Box>
            <Box sx={{ mb: 4 }}>
              <LabeledTextField
                label="Amount"
                id="amount"
                name="amount"
                value={formik.values.amount}
                onChange={formik.handleChange}
                error={formik.touched.amount && Boolean(formik.errors.amount)}
                helperText={formik.touched.amount && formik.errors.amount}
                fullWidth
                variant="outlined"
                size="small"
              />
            </Box>
            <Box sx={{ mb: 4 }}>
              <LabeledTextField
                label="Meter Number"
                id="meter_no"
                name="meter_no"
                value={formik.values.meter_no}
                onChange={(e) =>
                  formik.setFieldValue("meter_no", e.target.value.trim())
                }
                error={
                  formik.touched.meter_no && Boolean(formik.errors.meter_no)
                }
                helperText={formik.touched.meter_no && formik.errors.meter_no}
                fullWidth
                variant="outlined"
                size="small"
              />
            </Box>
            {/* <Box sx={{ mb: 4 }}>
              <LabeledTextField
                label="Beneficiary Name"
                id="beneficiary"
                name="beneficiary"
                value={formik.values.beneficiary}
                onChange={formik.handleChange}
                error={
                  formik.touched.beneficiary &&
                  Boolean(formik.errors.beneficiary)
                }
                helperText={
                  formik.touched.beneficiary && formik.errors.beneficiary
                }
                fullWidth
                variant="outlined"
                size="small"
              />
            </Box> */}
            {/* <Box sx={{ mb: 4 }}>
              <LabeledTextField
                label="Naration"
                // hint="You can add multiple numbers. Ex: 08030302020, 09016161919"
                id="naration"
                name="naration"
                value={formik.values.naration}
                onChange={formik.handleChange}
                error={
                  formik.touched.naration && Boolean(formik.errors.naration)
                }
                helperText={formik.touched.naration && formik.errors.naration}
                fullWidth
                variant="outlined"
              />
            </Box> */}
            <Box sx={{ mb: 4 }}>
              <CustomButton
                loading={validateLoading || buyLoading}
                type="submit"
              >
                Continue
              </CustomButton>
            </Box>
          </form>
        </Box>
      </Box>
    </>
  );
}
