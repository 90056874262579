import { BASEURL, http } from ".";

const LOGIN = `${BASEURL}/auth/login`;
const SIGNUP = `${BASEURL}/auth/register`;
const LOGOUT = `${BASEURL}/auth/logout`;
const VERIFYOTP = `${BASEURL}/verify-otp`;
const RESENDOTP = `${BASEURL}/resend-otp`;
const UPGRADERESSELLER = `${BASEURL}/upgrade-reseller`;
const FORGOTPASSWORD = `${BASEURL}/auth/forgot-password`;
const RESETPASSWORD = `${BASEURL}/auth/reset-password`;

export const logIn = async (data) => http.post(LOGIN, data);
// export const logIn = async (data) =>
//   fetch(LOGIN, {
//     method: "post",
//     body: data,
//     headers: {
//       "content-type": "application/json",
//     },
//   });
export const signUp = async (data) => http.post(SIGNUP, data);
export const logOut = async (data) => http.post(LOGOUT, data);
export const resendOtp = async (data) => http.post(RESENDOTP, data);
export const verifyOtp = async (data) => http.post(VERIFYOTP, data);
export const upgradeResseller = async () => http.post(UPGRADERESSELLER);
export const forgotpassword = async (data) => http.post(FORGOTPASSWORD, data);
export const resetpassword = async (data) => http.post(RESETPASSWORD, data);
