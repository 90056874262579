import React from "react";
import { Dialog, Box, Typography } from "@mui/material";
import CustomButton from "../Button";
import { CopyAll } from "@mui/icons-material";
import { useNotificationStore, useUserStore } from "../../state";
import { formatter } from "../../helpers";

function RefereModal({ message, handleClose }) {
  const displayNotificaton = useNotificationStore(
    (state) => state.displayNotificaton
  );
  const { referral_reward } = useUserStore((state) => state.charge);

  const print = () => {
    navigator.clipboard.writeText(message);
    displayNotificaton("Referral code copied");
  };

  return (
    <Dialog
      sx={{
        "& .MuiDialog-paper": {
          borderRadius: "30px",
          width: { xs: "100%", sm: "50%", md: "27%" },
        },
      }}
      open={true}
      onClose={handleClose}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-evenly",
          alignItems: "center",
          padding: "30px",
          width: { xs: "100%" },
          // height: "420px",
          background: "#FFFFFF",
          border: "1px solid #DFDFDF",
          borderRadius: "30px",
          textAlign: "center",
        }}
      >
        <Typography
          sx={{ color: "#00421B", mt: "10px", mb: "20px", fontWeight: "700" }}
        >
          {""}share your referral code to recieve the sum of N
          {formatter.format(referral_reward)} when a user joins using your
          referral code and becomes a reseller
        </Typography>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            padding: "10px ",
            borderRadius: "8px",
            backgroundColor: "lightgray",
            alignItems: "center",
            width: "100%",
          }}
        >
          {message}
          <CustomButton
            // loading={isLoading}
            sx={{ width: "100px" }}
            type="submit"
            onClick={print}
          >
            <CopyAll style={{ width: "30px", marginRight: "5px" }} /> Copy
          </CustomButton>
        </Box>
      </Box>
    </Dialog>
  );
}

export default RefereModal;
