import React, { useEffect, useRef, useState } from "react";
import {
  Box,
  CircularProgress,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
// import { ReactComponent as Graph } from "../../icons/Graph.svg";
import { useTheme } from "@mui/material/styles";
import { useMutation } from "@tanstack/react-query";
import { getReport } from "../../api/adminApi";
import { useNotificationStore } from "../../state";
import { Line } from "react-chartjs-2";
import "chart.js/auto";
function ReportCard() {
  const ref = useRef();
  const displayNotificaton = useNotificationStore(
    (state) => state.displayNotificaton
  );
  const [labels, setLabels] = useState([]);
  const [data, setData] = useState([]);
  const [value, setValue] = useState("year");

  const { isLoading, mutate } = useMutation(getReport, {
    onSuccess: (res) => {
      const temp = [];
      const temp2 = [];
      res?.data.forEach((v) => {
        const val = Object.values(v);
        temp.push(v["dates"] ?? val[0]);
        temp2.push(v["total"] ?? val[1]);
      });
      setLabels(temp);
      setData(temp2);
    },
    onError: (e) => {
      displayNotificaton(e.message);
    },
  });

  useEffect(() => {
    // mutate({ type: "week" });
    mutate({ type: value });
  }, [value]);

  const theme = useTheme();
  return (
    <Box
      sx={{
        // minWwidth: "243px",
        height: "385px",
        border: "1px solid #E6E6EF",
        borderRadius: "20px",
        padding: "20px",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        "&canvas": {
          width: "100%",
        },
      }}
    >
      <Box
        sx={{
          color: theme.palette.neutral.N900,
          fontSize: "11px",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <Typography
          sx={{
            color: "rgba(128, 128, 128, 1)",
            fontSize: "19px",
            fontWeight: "bold",
          }}
          color=""
        >
          Traffic Source
        </Typography>
        <Select
          variant="outlined"
          size="small"
          value={value}
          onChange={(v) => setValue(v.target.value)}
        >
          <MenuItem value={"year"}>This Year</MenuItem>
          <MenuItem value={"month"}>This Month</MenuItem>
          <MenuItem value={"week"}>This Week</MenuItem>
        </Select>
      </Box>
      <Box
        sx={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          minHeight: "25vh",
        }}
      >
        {isLoading ? (
          <Box>
            <CircularProgress />
          </Box>
        ) : (
          <Line
            ref={ref}
            style={{ width: "100%" }}
            datasetIdKey="iddds"
            data={{
              labels,
              datasets: [
                {
                  id: 1,
                  label: "",
                  data,
                },
              ],
            }}
          />
        )}
      </Box>
    </Box>
  );
}

ReportCard.propTypes = {};

export default ReportCard;
