import React, { useState } from "react";
import { useStyles } from "./style";
import GeneralHeader from "../../components/LandingPage/generalHeader";
import Footer from "../../components/LandingPage/footer";
import { Link } from "react-router-dom";
import OtpInput from "react-otp-input";
import { Navigate, useNavigate } from "react-router-dom";
import { useUserStore } from "../../state";
import * as yup from "yup";
import { useFormik } from "formik";
import { useMutation } from "@tanstack/react-query";
import { resendOtp, verifyOtp } from "../../api/authApi";

const Otp = () => {
  const classes = useStyles();
  const [otp, setOtp] = useState("");

  const handleOtpChange = (value) => {
    setOtp(value);
  };
  const [error, setError] = useState(null);
  const [message, setMessage] = useState(null);

  const loggedIn = useUserStore((state) => state.loggedIn);

  const logoutUser = useUserStore((state) => state.logoutUser);

  const navigate = useNavigate();

  const { isLoading, mutate } = useMutation(verifyOtp, {
    onSuccess: (res) => {
      setMessage(res.data?.message);

      setTimeout(() => {
        logoutUser();
      }, 1000);
    },
    onError: (e) => {
      setError(
        e.response?.data?.message ??
          e.message ??
          "something went wrong try again"
      );
    },
  });

  const { isLoading: resendLoading, mutate: resendMutate } = useMutation(
    resendOtp,
    {
      onSuccess: (res) => {
        setMessage(res.data?.message);
      },
      onError: (e) => {
        setError(
          e.response?.data?.message ??
            e.message ??
            "something went wrong try again"
        );
      },
    }
  );

  const initialValues = {
    code: otp,
  };

  const formik = useFormik({
    initialValues: initialValues,

    onSubmit: async (values) => {
      console.log(otp);
      setError(null);
      mutate({ otp: otp });
    },
  });

  // if (loggedIn) {
  //   if (from &&from!=="") return <Navigate to={from} />;
  //   if (user?.role === roles.ADMIN) return <Navigate to="/admin/dashboard" />;
  //   if (user?.role === roles.USER) return <Navigate to="/user/home" />;
  // }

  if (!loggedIn) return <Navigate to="/signin" />;
  return (
    <div className={classes.container}>
      <GeneralHeader />
      <div className={classes.otpSection}>
        <div className="wrapper">
          <div className="formContainer">
            <h6>We just sent you a confirmation code to your email. </h6>
            {error && <div className="error-text">{error}</div>}
            {message && <div className="success-text">{message}</div>}
            <form onSubmit={formik.handleSubmit}>
              <OtpInput
                value={otp}
                onChange={handleOtpChange}
                numInputs={6}
                renderInput={(props) => <input {...props} />}
                inputStyle={"otpInput"}
              />

              <div className="button-group">
                <button className="primary" type="submit" disabled={isLoading}>
                  {isLoading ? "Loading..." : "Confirm"}
                </button>
                <div className="divider">Didn’t received a code? </div>
                <div
                  onClick={() => resendMutate()}
                  className="resend secondary"
                >
                  Resend
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Otp;
