import React from "react";
import {
  Typography,
  Divider,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
} from "@mui/material";

import { Box } from "@mui/system";
import { useTheme } from "@mui/material/styles";
import Grid from "@mui/material/Unstable_Grid2";
import { styled } from "@mui/system";
import { useMutation, useQuery } from "@tanstack/react-query";
import {
  getProviders,
  getDataProviders,
  getAirtimeProviders,
  getElectricityProviders,
  getCableProviders,
  updateAirtimeProviders,
  updateDataProviders,
  updateCableProviders,
  updateElectricityProviders,
} from "../../api/adminApi";
import { useNotificationStore } from "../../state";

export const FlexBox = styled(Box)({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  padding: "20px",
});

function Providers(props) {
  const theme = useTheme();

  const { data } = useQuery(["providers"], getProviders);

  const providers = data?.data?.data || [];

  const displayNotificaton = useNotificationStore(
    (state) => state.displayNotificaton
  );
  const { data: airtimeData, refetch: airtimeRefresh } = useQuery(
    ["airtimeProviders"],
    getAirtimeProviders
  );

  const airtimeProviders = airtimeData?.data?.data || [];
  const { mutate: airtimeMutate } = useMutation(updateAirtimeProviders, {
    onSuccess: (res) => {
      displayNotificaton("Provider updated");
      airtimeRefresh();
    },
    onError: (e) => {
      displayNotificaton(e.message);
    },
  });

  const { data: dataData, refetch: dataRefresh } = useQuery(
    ["dataProviders"],
    getDataProviders
  );

  const dataProviders = dataData?.data?.data || [];
  const { mutate: dataMutate } = useMutation(updateDataProviders, {
    onSuccess: (res) => {
      displayNotificaton("Service updated");
      dataRefresh();
    },
    onError: (e) => {
      displayNotificaton(e.message);
    },
  });

  const {
    data: cableData,
    refetch: cableRefresh,
    isLoading: cableIsLoading,
  } = useQuery(["cableProviders"], getCableProviders);

  const cableProviders = cableData?.data?.data || [];
  const { mutate: cableMutate } = useMutation(updateCableProviders, {
    onSuccess: (res) => {
      displayNotificaton("Service updated");
      cableRefresh();
    },
    onError: (e) => {
      displayNotificaton(e.message);
    },
  });

  const {
    data: electricityData,
    refetch: electricityRefresh,
    isLoading: elctricityIsLoading,
  } = useQuery(["electricityProviders"], getElectricityProviders);

  const electricityProviders = electricityData?.data?.data || [];
  const { mutate: electricityMutate } = useMutation(
    updateElectricityProviders,
    {
      onSuccess: (res) => {
        displayNotificaton("Service updated");
        electricityRefresh();
      },
      onError: (e) => {
        displayNotificaton(e.message);
      },
    }
  );

  const handleChange = (type, id, provider_id) => {
    if (type === "airtime") airtimeMutate({ id, data: { provider_id } });
    if (type === "data") dataMutate({ id, data: { provider_id } });
    if (type === "cable") cableMutate(id);
    if (type === "electricity") electricityMutate(id);
  };

  return (
    <div>
      <Box sx={{ display: "flex" }}>
        <Typography variant="header" color={theme.palette.neutral.N200}>
          Providers
        </Typography>
      </Box>

      <Grid container spacing={2} mt={4}>
        <Grid xs={12} md={6} sx={{ padding: "30px" }}>
          <Box
            className="card"
            sx={{
              mt: 5,
              minHeight: "60vh",
              width: { xs: "100%" },
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "start",
                color: "#969697",
                padding: "20px",
              }}
              providers
            >
              <Typography>Data</Typography>
            </Box>
            <Divider />
            {dataProviders?.map((service, i) => (
              <FlexBox key={{ i }}>
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">
                    {` ${service.network} ${service.type}`}{" "}
                  </InputLabel>
                  <Select
                    label={service.type}
                    value={service.provider?.id}
                    onChange={(e) =>
                      handleChange("data", service?.id, e.target.value)
                    }
                  >
                    {providers?.map((v) => (
                      <MenuItem value={v?.id}>{v.name}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </FlexBox>
            ))}
          </Box>
        </Grid>
        <Grid xs={12} md={6} sx={{ padding: "30px" }}>
          <Box
            className="card"
            sx={{
              mt: 5,
              minHeight: "20vh",
              width: { xs: "100%" },
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "start",
                color: "#969697",
                padding: "20px",
              }}
            >
              <Typography>Airtime</Typography>
            </Box>
            <Divider />
            {airtimeProviders?.map((service, i) => (
              <FlexBox key={{ i }}>
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">
                    {service.network} {service.type}
                  </InputLabel>
                  <Select
                    label={service.type}
                    value={service.provider?.id}
                    onChange={(e) =>
                      handleChange("airtime", service?.id, e.target.value)
                    }
                  >
                    {providers?.map((v) => (
                      <MenuItem value={v?.id}>{v.name}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </FlexBox>
            ))}
          </Box>
          <Box
            className="card"
            sx={{
              mt: 5,
              minHeight: "20vh",
              width: { xs: "100%" },
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "start",
                color: "#969697",
                padding: "20px",
              }}
            >
              <Typography>Utility</Typography>
            </Box>
            <Divider />
            {!cableIsLoading && (
              <FlexBox>
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">Cable</InputLabel>
                  <Select
                    label="Cable"
                    value={cableProviders?.find((v) => v.status === 1)?.id}
                    onChange={(e) => handleChange("cable", e.target.value)}
                  >
                    {cableProviders?.map((v) => (
                      <MenuItem value={v?.id}>{v.name}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </FlexBox>
            )}
            {!elctricityIsLoading && (
              <FlexBox>
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">
                    Electricity
                  </InputLabel>
                  <Select
                    label="Electricity"
                    value={electricityProviders?.find((v) => v.status === 1)?.id}
                    onChange={(e) =>
                      handleChange("electricity", e.target.value)
                    }
                  >
                    {electricityProviders?.map((v) => (
                      <MenuItem value={v?.id}>{v.name}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </FlexBox>
            )}
          </Box>
        </Grid>
      </Grid>
    </div>
  );
}

export default Providers;
