import {
  PanoramaFishEye,
  VisibilityOffOutlined,
  VisibilityOutlined,
} from "@mui/icons-material";
import { Button, Typography } from "@mui/material";
import { Box, padding } from "@mui/system";
import React, { useState } from "react";
import CustomButton from "../Button";
import { useTheme } from "@mui/material/styles";
import { NavLink } from "react-router-dom";
import { useUserStore } from "../../state";
import { formatter } from "../../helpers";
import walletImg1 from "../../icons/walletImg1.png";

function SummaryCard({ amount }) {
  const [show, setShow] = useState(false);
  const user = useUserStore((state) => state.user);

  const { balance } = user;

  const theme = useTheme();
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "flex-start",
        // backgroundImage: `url(${walletImg1})`,
        backgroundClip: "content-box",
        objectFit: "cover",
        borderRadius: "22px",
        padding: "1px",
        // backgroundSize: "98%",
        "&::before": {},
        position: "relative",
        overflow: "hidden",
        backgroundColor: theme.palette.primary.main,
      }}
    >
      <img
        alt=""
        src={walletImg1}
        style={{
          height: "98%",
          width: "98%",
          position: "absolute",
          zIndex: "1",
          borderRadius: "23px",
        }}
      />
      <Box
        sx={{
          borderTopRightRadius: "20px",
          borderTopLeftRadius: "20px",
          padding: "45px 20px",
          width: "100%",
          zIndex: "2",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            width: {
              sm: "100%",
              md: "304px",
            },
            alignItems: "flex-start",
            fontSize: "23px",
            fontWeight: "700",
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              width: "70%",
            }}
          >
            <Box>
              {show ? (
                <Typography
                  sx={{ color: "white", whiteSpace: "nowrap" }}
                  variant="header2"
                >
                  ₦ {formatter.format(balance)}
                </Typography>
              ) : (
                <Typography
                  sx={{ color: "white", whiteSpace: "nowrap" }}
                  variant="header2"
                >
                  ₦ XXXXX
                </Typography>
              )}
            </Box>
            <span onClick={() => setShow(!show)}>
              {show ? (
                <VisibilityOffOutlined sx={{ color: "white" }} />
              ) : (
                <VisibilityOutlined sx={{ color: "white" }} />
              )}
            </span>
          </Box>
          <Typography
            sx={{ color: "white", fontSize: "11px", fontWeight: "500" }}
          >
            Wallet Balance
          </Typography>
        </Box>
      </Box>

      <Box
        sx={{
          backgroundColor: "#DFDFDF",
          borderBottomRightRadius: "20px",
          borderBottomLeftRadius: "20px",
          padding: "10px 30px",
          width: "100%",
          zIndex: "2",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            // width: "100%",
            width: {
              xs: "100%",
              md: "304px",
            },
            fontSize: "11px",
            fontWeight: "500",
          }}
        >
          <Box
            sx={{
              width: {
                xs: "124px",
                md: "130px",
              },
            }}
          >
            <NavLink style={{ width: "100%" }} to="/user/withdraw">
              <Button
                sx={{
                  border: "1px solid #020239",
                  borderRadius: "10px",
                  width: "100%",
                  height: "39px",
                }}
              >
                <Typography
                  sx={{
                    fontFamily: "Public Sans",
                    textTransform: "none",
                    fontStyle: "normal",
                  }}
                >
                  Withdraw
                </Typography>
              </Button>
            </NavLink>
          </Box>
          <Box
            sx={{
              width: {
                xs: "124px",
                md: "130px",
              },
            }}
          >
            <NavLink style={{ width: "100%" }} to="/user/fund-wallet">
              <Button
                sx={{
                  border: "1px solid #020239",
                  borderRadius: "10px",
                  width: "100%",
                  height: "39px",
                }}
              >
                <Typography
                  sx={{
                    fontFamily: "Public Sans",
                    textTransform: "none",
                    fontStyle: "normal",
                  }}
                >
                  Fund Wallet
                </Typography>
              </Button>
            </NavLink>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

export default SummaryCard;
