import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles({
  header: {
    background: "#fafafa",
    "& .wrapper": {
      "& .navbar": {
        zIndex: 900000000000,
        "& .menu": {
          display: "none",
        },
        "& .close": {
          display: "none",
        },
        padding: "20px 0px",
        borderBottom: "1px solid #E6E6EF",
        display: "flex",
        gap: "49px",
        alignItems: "center",
        "@media (max-width: 1300px)": {
          justifyContent: "space-between",

          "& img": {
            height: "19px",
            width: "auto",
          },
          "& .menu": {
            display: "block",
          },
        },
        "@media (max-width: 900px)": {
          gap: "20px",
        },

        "& ul.nav-links": {
          display: "flex",
          gap: "45px",
          listStyle: "none",
          // [theme.breakpoints.down("md")]: {
          //   display: "none",
          // },

          "@media (max-width: 1300px)": {
            "& .menu": {
              display: "block",
            },
            "& .close": {
              display: "block",
              position: "absolute",
              top: "28px",
              right: "29px",
              cursor: "pointer",
            },
            position: "fixed",
            top: "0",
            left: "0",
            right: "0",
            bottom: "0",
            paddingTop: "118px",
            flexDirection: "column",
            alignItems: "center",
            backgroundColor: "rgba(90, 90, 94, 0.9)",
            width: "100%",
            height: "100%",
            display: "none",
            zIndex: "900000000000",
          },

          "& li:not(.button)": {
            "& a": {
              color: "#808080",
              textDecoration: "none",
              fontSize: "23px",
              fontWeight: "400",
              "&.active-link": {
                fontWeight: "700",
                color: "#1A3A5E",
              },
              "&:hover": {
                color: "#1A3A5E",
              },
              "@media (max-width: 1300px)": {
                fontWeight: "700",
                fontSize: "48px",
                color: "#2b2b2c",
              },
              "@media (max-width: 600px)": {
                fontSize: "28px",
              },
            },
            "@media (max-width: 1300px)": {
              textAlign: "center",
              width: "100%",
            },
          },
          "& li.button": {
            display: "none",
            "@media (max-width: 1300px)": {
              display: "block",
            },
            backgroundColor: "gray",
            width: "max-content",
            padding: "25px 45px",
            borderRadius: "10px",
            textAlign: "center",

            "& a": {
              border: "none",
              outline: "none",
              cursor: "pointer",
              fontSize: "16px",
              fontWeight: "bold",
              textAlign: "center",

              color: "#1A3A5E",
              width: "100%",
            },
            "@media (max-width: 600px)": {
              margin: "0 auto",

              width: "80%",
            },
          },
        },
        "& .buttons": {
          display: "flex",
          gap: "10px",
          "& .button": {
            border: "none",
            outline: "none",
            cursor: "pointer",
            fontSize: "16px",
            fontWeight: "500",
            padding: "25px 45px",
            borderRadius: "10px",
            // width: "150px",
            width: "max-content",
            "@media (max-width: 1300px)": {
              padding: "14px 35px",
            },

            "&.sign-in": {
              backgroundColor: "#E6E6EF",
              color: "#1A3A5E",
              "&:hover": {
                backgroundColor: "#D9D9E6",
              },
              "@media (max-width: 1300px)": {
                display: "none",
              },
            },
            "&.get-started": {
              backgroundColor: "#1A3A5E",
              color: "#fff",
            },
          },
        },
      },
    },
  },
  openMenu: {
    display: "flex !important",
  },
});
